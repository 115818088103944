import { GpuModel } from '@typings';
import { ensureArray, getFormattedGpuMake } from '@utils';

import { Render } from '@components';

type Model = Partial<GpuModel.Interface>;

type Props = {
  className?: string;
  models: Model | Model[];
};

export const GpuModels = ({ className, models }: Props) => {
  const gpuModels = ensureArray(models);

  const makeGpuModel = ({ gpuQuantity, gpuMake, gpuModelName }: Model) =>
    gpuQuantity
      ? `${gpuQuantity} x ${gpuModelName ?? getFormattedGpuMake(gpuMake)}`
      : null;

  return (
    <Render if={gpuModels}>
      <p className={className}>
        {gpuModels.map(makeGpuModel).filter(Boolean).join(', ')}
      </p>
    </Render>
  );
};
