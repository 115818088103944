import { areClustersPresentSelector } from '@selectors';
import { useSelector } from '@hooks';

import { Render } from '@components';

import { UserPanelClusters } from './UserPanelClusters';
import { UserPanelCredits } from './UserPanelCredits';
import { UserPanelProjects } from './UserPanelProjects';

export const UserPanel = () => {
  /**
   * During onboarding and no added to clusters
   *
   * todo: check when organizations are absent
   */
  const areClustersPresent = useSelector(areClustersPresentSelector);

  return (
    <div className="flex items-center gap-1">
      <Render if={areClustersPresent}>
        <UserPanelCredits />
        <div className="h-12 w-px bg-white/25" />
      </Render>
      <UserPanelClusters />
      <UserPanelProjects />
    </div>
  );
};
