import { useCallback, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import {
  faLockKeyhole,
  faPlus,
  faTrashCan,
} from '@fortawesome/pro-regular-svg-icons';
import { faLockKeyhole as faLockKeyholeThin } from '@fortawesome/pro-thin-svg-icons';

import { Secret } from '@typings';
import { getSecrets } from '@services';
import { clusterContextSelector, contextNamesSelector } from '@selectors';
import { useFetchList, useHelmetTitle, useSelector } from '@hooks';
import { as } from '@utils';

import {
  Button,
  ChaseSpinner,
  Field,
  Helmet,
  Icon,
  Modal,
  Table,
} from '@components';
import { Layout } from '@components/Layouts';
import { CreateSecretModal } from '@components/Modals';
import { DeleteSecretModal } from '@components/Modals/Secret/DeleteSecretModal';
import { EmptyContent } from '@components/Ui';

export const SecretsPage = () => {
  const cluster = useSelector(clusterContextSelector);
  const { organizationName, projectName } = useSelector(contextNamesSelector);

  const { state } = useLocation();
  const { makeTitle } = useHelmetTitle();
  const {
    isFetched,
    isEmpty,
    list: secrets,
    getList,
  } = useFetchList<Secret[]>({
    getList: getSecrets,
    fetchOnMount: false,
  });

  const [search, setSearch] = useState('');

  const { secretsUrl } = as.c(cluster);

  const getSecretList = useCallback(async () => {
    if (projectName) {
      await getList({ secretsUrl, organizationName, projectName });
    }
  }, [organizationName, projectName, secretsUrl, getList]);

  useEffect(() => {
    getSecretList();
  }, [getSecretList]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(event.target.value);

  const makeSecret = ({ key }: Secret) => (
    <Table.Row key={key}>
      <Table.Cell>
        <div className="flex items-center gap-2">
          <Icon icon={faLockKeyhole} />
          <span>{key}</span>
        </div>
      </Table.Cell>

      <Table.Cell className="flex-row">
        <Modal
          content={
            <DeleteSecretModal keyName={key} getSecrets={getSecretList} />
          }
        >
          <Button
            variant="ghost-error"
            className="h-auto self-center p-0 text-[16px] text-error"
          >
            <Icon icon={faTrashCan} className="h-8 w-8" />
          </Button>
        </Modal>
      </Table.Cell>
    </Table.Row>
  );

  const renderContent = () => {
    const formattedSecretList = search
      ? secrets.filter(({ key }) => key.includes(search))
      : secrets;

    if (!isFetched) {
      return (
        <div className="relative flex min-h-[400px] w-full items-center justify-center">
          <ChaseSpinner color="black" className="h-12 w-12" />
        </div>
      );
    }

    if (isEmpty) {
      return (
        <EmptyContent
          icon={faLockKeyholeThin}
          className="mt-20"
          title="No secrets found"
        />
      );
    }

    return (
      <Table className="w-full auto-rows-min grid-cols-[auto_min-content] overflow-auto">
        <Table.Header>
          <Table.Row>
            <Table.Head>Name</Table.Head>
            <Table.Head />
          </Table.Row>
        </Table.Header>
        <Table.Body>{formattedSecretList.map(makeSecret)}</Table.Body>
      </Table>
    );
  };

  return (
    <Layout title="Secrets">
      <Helmet
        title={makeTitle('Secrets', '%p', '%c')}
        description="Securely manage your secrets. Easily add, delete, and organize your confidential data to ensure your sensitive information is safely stored and accessible when needed"
      />
      <Layout.Content>
        <div className="mb-14 flex items-center gap-10">
          <Modal
            defaultOpen={state?.action === 'create'}
            content={<CreateSecretModal getSecrets={getSecretList} />}
          >
            <Button variant="rebecca" className="capitalize">
              <Icon icon={faPlus} />
              Create new secret
            </Button>
          </Modal>
          <Field.Input
            containerClassName="flex-1"
            className="border-neural-03"
            label="Search"
            value={search}
            onChange={handleSearchChange}
          />
        </div>
        {renderContent()}
      </Layout.Content>
    </Layout>
  );
};
