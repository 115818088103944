import { faCoins, faGear, faPlus } from '@fortawesome/pro-regular-svg-icons';

import { AnyFunction, Organization } from '@typings';
import { NO_ORGANIZATION, PATH } from '@constants';
import { setContext } from '@slices';
import {
  configSelector,
  contextNamesSelector,
  userClustersSelector,
} from '@selectors';
import { useDispatch, useSelector, useTenantRole } from '@hooks';
import { as, noop } from '@utils';

import { Button, Icon, Link, Modal, Render } from '@components';
import { CreateOrganizationModal } from '@components/Modals';

import { UserPanelOrganizationItem as OrganizationItem } from './UserPanelOrganizationItem';

type Props = {
  hidePopover?: AnyFunction;
};

export const UserPanelPlatform = ({ hidePopover = noop }: Props) => {
  const dispatch = useDispatch();
  const {
    orgs: organizations = [],
    clusters: configClusters = [],
    projects = [],
  } = useSelector(configSelector);
  const userClusters = useSelector(userClustersSelector);
  const { organizationName } = useSelector(contextNamesSelector);
  const { isOrganizationManager } = useTenantRole();

  const noOrganizationProjectCount = projects.filter(
    ({ orgName }) => orgName === NO_ORGANIZATION,
  ).length;
  const noOrganizationClusters = userClusters.filter(
    ({ orgName }) => as(orgName, NO_ORGANIZATION) === NO_ORGANIZATION,
  );

  const handleOrganizationChange = (organization: Organization.Model) => {
    const cluster =
      configClusters.find(({ orgs }) => orgs.includes(organization.name)) ??
      null;
    const project =
      projects.find(
        ({ orgName, clusterName }) =>
          as(orgName, NO_ORGANIZATION) === organization.name &&
          clusterName === cluster?.name,
      ) ?? null;

    dispatch(setContext({ organization, project, cluster }));

    hidePopover();
  };

  const handleNoOrganizationChange = () => {
    const cluster =
      configClusters.find(({ orgs }) => orgs.includes(NO_ORGANIZATION)) ?? null;
    const project =
      projects.find(
        ({ orgName, clusterName }) =>
          as(orgName, NO_ORGANIZATION) === NO_ORGANIZATION &&
          clusterName === cluster?.name,
      ) ?? null;

    dispatch(setContext({ organization: NO_ORGANIZATION, project, cluster }));

    hidePopover();
  };

  const makeOrganization = (organization: Organization.Model) => {
    const { name } = organization;
    const active = name === organizationName;
    const projectCount = projects.filter(
      ({ orgName }) => orgName === name,
    ).length;

    return (
      <OrganizationItem
        key={name}
        active={active}
        name={name}
        projectCount={projectCount}
        onClick={() => handleOrganizationChange(organization)}
      />
    );
  };

  return (
    <div className="mt-2 w-full">
      <p className="mb-4 px-2 text-caption uppercase text-neural-04">
        Organization
      </p>
      <div className="mb-2 flex max-h-[320px] flex-col overflow-y-auto">
        {organizations.map(makeOrganization)}
        <Render if={noOrganizationClusters}>
          <OrganizationItem
            active={organizationName === NO_ORGANIZATION}
            name="No organization"
            projectCount={noOrganizationProjectCount}
            onClick={handleNoOrganizationChange}
          />
        </Render>
      </div>
      <Render if={!!organizationName && organizations?.length > 0}>
        <Link
          variant="ghost"
          to={PATH.ORGANIZATION_SETTINGS}
          className="w-full justify-start px-2 font-400"
        >
          <Icon icon={faGear} className="mr-2" />
          Settings
        </Link>
      </Render>
      <Render if={isOrganizationManager}>
        <Link
          variant="ghost"
          to={PATH.BILLING_USAGE}
          className="w-full justify-start px-2 font-400"
        >
          <Icon icon={faCoins} className="mr-2" />
          Billing
        </Link>
      </Render>
      <Modal content={<CreateOrganizationModal onSuccess={hidePopover} />}>
        <Button variant="ghost" className="w-full justify-start px-2 font-400">
          <Icon icon={faPlus} className="mr-1" />
          Create new organization
        </Button>
      </Modal>
      <div className="my-6 h-px w-full bg-neural-01 " />
    </div>
  );
};
