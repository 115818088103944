import { useEffect, useState } from 'react';
import AudioPlayer from 'react-h5-audio-player';
import ReactPlayer from 'react-player/file';

import { clusterContextSelector } from '@selectors';
import { useSelector } from '@hooks';
import { getFormattedStorageFile, getStorageDownloadUrl } from '@utils';

type Props = {
  path: string;
  currentPath: string;
};

export const StorageFileContent = ({ currentPath, path }: Props) => {
  const cluster = useSelector(clusterContextSelector);

  const [text, setText] = useState('');

  const { storageUrl = '' } = cluster ?? {};
  const { isImage, isAudio, isVideo, isText } = getFormattedStorageFile(path);

  const url = getStorageDownloadUrl({
    storageUrl,
    currentPath,
    objectPath: path,
  });

  useEffect(() => {
    const run = async () => {
      const response = await fetch(url);

      setText(await response.text());
    };

    if (isText) {
      run();
    }
  }, [isText, url]);

  if (isImage) {
    return <img src={url} alt={path} />;
  }

  if (isAudio) {
    return <AudioPlayer src={url} showJumpControls={false} />;
  }

  if (isVideo) {
    return <ReactPlayer url={url} controls pip width="100%" height="100%" />;
  }

  if (isText) {
    return <p className="max-h-[120px] overflow-auto text-neural-03">{text}</p>;
  }

  return null;
};
