import { Children, useCallback, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import Carousel from 'react-slick';
import * as sentry from '@sentry/react';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';

import { Project, Role } from '@typings';
import { CAROUSEL_SETTINGS, PATH } from '@constants';
import { toast } from '@features';
import { checkProjectExists, createProject } from '@services';
import { setContext } from '@slices';
import { getConfig } from '@thunks';
import { contextSelector } from '@selectors';
import { useDispatch, useHelmetTitle, useSelector } from '@hooks';
import {
  debounce,
  formatModelName,
  formatToCamelCase,
  isString,
  normalizeFormErrors,
  parseResponseError,
} from '@utils';

import { Button, Field, Helmet, Icon } from '@components';

import { OnboardingInput } from './OnboardingInput';

const INITIAL_SLIDE_COUNT = 5;

const nameValidationSchema = {
  required: 'Name is required',
  pattern: {
    value: /^[a-zA-Z0-9 -]*$/,
    message: 'Project name is invalid',
  },
};

const roleOptions = [
  {
    value: Role.Reader,
    text: 'Reader',
  },
  {
    value: Role.Writer,
    text: 'Writer',
  },
  {
    value: Role.Manager,
    text: 'Manager',
  },
];

export const OnboardingProject = () => {
  const dispatch = useDispatch();
  const { organization, cluster } = useSelector(contextSelector);

  const navigate = useNavigate();
  const { makeTitle } = useHelmetTitle();
  const {
    register,
    formState,
    clearErrors,
    setValue,
    setError,
    handleSubmit,
    trigger,
  } = useForm();

  const carouselRef = useRef<Carousel>(null);
  const [loading, setLoading] = useState(false);
  const [slideIndex, setSlideIndex] = useState(0);
  const [slideCount, setSlideCount] = useState(INITIAL_SLIDE_COUNT);
  const [projectCreated, setProjectCreated] = useState(false);

  const organizationName = organization?.name;
  const slideClassName = '!flex h-[500px] flex-col justify-center';
  const isFirstSlide = slideIndex === 0;
  const isLastSlide = slideIndex === slideCount - 1;
  const isFinalSlideAllowed = slideIndex === slideCount - 2 && !projectCreated;
  const errors = normalizeFormErrors<'name' | 'isDefault' | 'defaultRole'>(
    formState.errors,
  );

  const handleSliderInitialization = () => {
    const slideCount = Children.count(carouselRef.current?.props.children);

    if (slideCount) {
      setSlideCount(slideCount);
    }
  };

  const handleSlideNext = useCallback(() => {
    carouselRef.current?.slickNext();
  }, []);

  const handleSlidePrev = useCallback(() => {
    carouselRef.current?.slickPrev();
  }, []);

  const goToNameSlide = useCallback(() => {
    carouselRef.current?.slickGoTo(1);
  }, []);

  const handleFieldNext = async () => {
    const isValid = await trigger('name');

    if (isValid) {
      handleSlideNext();
    }
  };

  const handleNameError = useCallback(
    (message: string) => {
      setError('name', { message });
    },
    [setError],
  );

  const handleConfigFetch = useCallback(async () => {
    try {
      setLoading(true);

      await dispatch(getConfig());

      if (projectCreated) {
        navigate(PATH.HOME, { replace: true });
      }

      /**
       * Project name validation slides view to
       * project name step on requests failure
       */
      goToNameSlide();
    } catch (error) {
      /**
       * Continue regardless error
       */
    } finally {
      setLoading(false);
    }
  }, [projectCreated, goToNameSlide, navigate, dispatch]);

  const handleProjectSubmit = handleSubmit(
    async ({ name, isDefault, defaultRole }) => {
      const formattedName = formatModelName(name);

      setLoading(true);

      try {
        const payload = {
          is_default: isDefault,
          default_role: defaultRole,
          name: formattedName,
        };
        const project = await createProject({
          organization: organizationName,
          cluster: cluster!.name,
          payload,
        });
        const formattedProject = formatToCamelCase({
          ...project,
          role: Role.Admin,
        }) as Project;

        setProjectCreated(true);
        dispatch(setContext({ project: formattedProject }));
        handleSlideNext();
      } catch (error) {
        const parsedError = parseResponseError(error);
        const normalizedError = isString(parsedError)
          ? parsedError
          : parsedError.messages[0];

        if (normalizedError) {
          handleNameError(normalizedError);
        } else {
          toast.error('Something went wrong');
          sentry.captureException(error);
        }
      } finally {
        setLoading(false);
      }
    },
    goToNameSlide,
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceNameChange = useCallback(
    debounce(async (name: string) => {
      const formattedName = formatModelName(name);

      setLoading(true);

      try {
        await checkProjectExists({
          organization: organizationName,
          cluster: cluster!.name,
          project: formattedName,
        });

        handleNameError('Project with such name already exits');
      } catch (error) {
        /**
         * Continue regardless error
         */
      } finally {
        setLoading(false);
      }
    }, 500),
    [organization],
  );

  const handleChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = event.target;
      const formattedValue = formatModelName(value);

      if (formattedValue) {
        debounceNameChange(formattedValue);
      } else {
        debounceNameChange.clear();
      }

      clearErrors('name');
      setValue('name', value);
    },
    [debounceNameChange, setValue, clearErrors],
  );

  return (
    <>
      <div className="mr-32 flex flex-col gap-10">
        <Helmet
          title={makeTitle('Create Project', '%c')}
          description="Set up your new project effortlessly with our interactive sliding tool. Follow the guided steps to create your project and start managing tasks and resources efficiently"
        />
        <Button
          variant="secondary"
          className="w-12 p-2"
          disabled={projectCreated || isFirstSlide}
          onClick={handleSlidePrev}
        >
          <Icon icon={faChevronUp} />
        </Button>
        <Button
          variant="secondary"
          className="w-12 p-2"
          disabled={isLastSlide || isFinalSlideAllowed}
          onClick={handleSlideNext}
        >
          <Icon icon={faChevronDown} />
        </Button>
      </div>
      <Carousel
        {...CAROUSEL_SETTINGS}
        afterChange={setSlideIndex}
        onInit={handleSliderInitialization}
        ref={carouselRef}
      >
        <div className={slideClassName}>
          <h3 className="text-h3 capitalize">Create new project</h3>
          <p className="mt-6 text-h6 font-500 text-neural-05">
            Your organization need to have at last one project 😉
          </p>
          <Button className="mt-10 w-[148px]" onClick={handleSlideNext}>
            Let&apos;s do it!
          </Button>
        </div>

        <div className={slideClassName}>
          <h5 className="text-h5 text-black">Name your project:</h5>
          <OnboardingInput
            {...register('name', nameValidationSchema)}
            onChange={handleChange}
            error={errors.name}
          />
          <Button
            className="mt-10 w-[148px]"
            loading={loading}
            onClick={handleFieldNext}
          >
            Next
          </Button>
        </div>

        <div className={slideClassName}>
          <h5 className="text-h5 text-black">
            How do you want to use your project?
          </h5>
          <Field.Checkbox
            {...register('isDefault')}
            containerClassName="my-6"
            error={errors.isDefault}
          >
            Make project as default
          </Field.Checkbox>
          <Button
            type="submit"
            className="mt-4 w-[148px]"
            loading={loading}
            onClick={handleSlideNext}
          >
            Next
          </Button>
        </div>

        <div className={slideClassName}>
          <h5 className="text-h5 text-black">
            What default role is assigned to each new user?
          </h5>
          <Field.Select
            {...register('defaultRole')}
            label="Default Role"
            containerClassName="my-6 w-[320px]"
            options={roleOptions}
            error={errors.defaultRole}
          />
          <Button
            type="submit"
            className="mt-4 w-[148px]"
            loading={loading}
            onClick={handleProjectSubmit}
          >
            Next
          </Button>
        </div>

        <div className={slideClassName}>
          <h3 className="text-h3">That&apos;s it 🥳</h3>
          <p className="mt-6 text-h6 font-500 text-neural-05">
            Now go and change the world! <br /> And don&apos;t forget to have
            fun 😉
          </p>
          <Button
            className="mt-10 w-[148px]"
            loading={loading}
            onClick={handleConfigFetch}
          >
            Finish
          </Button>
        </div>
      </Carousel>
    </>
  );
};
