import { OrganizationInvite } from '@typings';

export const formatOrganizationInvite = ({
  roleName: role,
  orgName: organizationName,
  ...invite
}: OrganizationInvite.Response): OrganizationInvite.Interface => ({
  ...invite,
  role,
  organizationName,
});
