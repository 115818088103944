import { faKeySkeleton } from '@fortawesome/pro-solid-svg-icons';

import { Bucket, Mode } from '@typings';

import { Button, Icon, Modal } from '@components';
import { DrawerDetailsItem } from '@components/Ui';

import { BucketCreateCredentialsModal, BucketCredentialsModal } from './Modals';

type Props = {
  bucketId: string;
  bucketName: string;
  credentials: Bucket.PersistentCredentials[];
  getCredentials: () => Promise<void>;
};

export const BucketCredentials = ({
  bucketId,
  bucketName,
  credentials,
  getCredentials,
}: Props) => {
  const makeCredential = (credentials: Bucket.PersistentCredentials) => {
    const { id, readOnly } = credentials;
    const readOnlyText = readOnly ? Mode.ReadOnly : Mode.ReadWrite;

    return (
      <Modal
        key={id}
        content={<BucketCredentialsModal credentials={credentials} />}
      >
        <Button
          theme
          className="flex max-w-full items-center gap-3 border-b border-neural-01 py-2 transition-colors hover:border-black"
        >
          <Icon
            icon={faKeySkeleton}
            className="h-10 w-10 rounded-full bg-stroke text-[18px]"
          />
          <div className="text-left">
            <p className="w-1/2 truncate">{id}</p>
            <p className="text-caption text-neural-04">
              <span className="space-after uppercase">{readOnlyText}</span>
              Credentials
            </p>
          </div>
        </Button>
      </Modal>
    );
  };

  return (
    <div className="flex flex-col items-start gap-6">
      <DrawerDetailsItem title="Select Credentials" />
      {credentials.map(makeCredential)}
      <Modal
        content={
          <BucketCreateCredentialsModal
            bucketId={bucketId}
            bucketName={bucketName}
            getCredentials={getCredentials}
          />
        }
      >
        <Button className="capitalize">New credentials</Button>
      </Modal>
    </div>
  );
};
