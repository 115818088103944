import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import * as sentry from '@sentry/react';

import { JWT_TOKEN } from '@constants';
import { TERMS_OF_USE } from '@constants/path.constants';
import { toast } from '@features';
import { agreeUserTerms } from '@services';
import { resetContext, setUserTerms } from '@slices';
import { userSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import {
  getUsernameFromToken,
  localStorage,
  toastifyResponseError,
} from '@utils';

import { Button, Field, Link, Modal } from '@components';

export const UserAgreeTermsModal = () => {
  const dispatch = useDispatch();
  const { email } = useSelector(userSelector);

  const { logout } = useAuth0();

  const [isLoggingOut, setLoggingOut] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isAgreed, setAgreed] = useState(false);

  const username = getUsernameFromToken(localStorage.get(JWT_TOKEN));

  useEffect(() => {
    if (!username && !isLoggingOut) {
      sentry.captureMessage('No username on Terms of Use page');
      toast.error('Something went wrong. Please contact Apolo support team');
    }
  }, [username, isLoggingOut]);

  const handleLogout = () => {
    localStorage.clear(JWT_TOKEN);
    setLoggingOut(true);
    dispatch(resetContext());
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const handleAgree = async () => {
    if (!username) {
      sentry.captureMessage('User submitted Terms of Use without username');

      return;
    }

    try {
      setLoading(true);

      await agreeUserTerms({ username, email });

      dispatch(setUserTerms(true));
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal.Content title="Terms of Use Agreement" className="w-[480px]">
      <p className="text-neural-04">
        By using our services, you agree to our&nbsp;
        <Link theme external blank className="text-primary" to={TERMS_OF_USE}>
          Terms of Use
        </Link>
      </p>
      <Field.Checkbox
        checked={isAgreed}
        onChange={(event) => setAgreed(event.target.checked)}
        containerClassName="mt-6"
      >
        I have read and agree to the Terms of Use
      </Field.Checkbox>
      <Modal.Footer>
        <Button variant="ghost" onClick={handleLogout}>
          Log out
        </Button>
        <Button
          loading={loading}
          disabled={!isAgreed || isLoggingOut}
          className="px-14 capitalize"
          onClick={handleAgree}
        >
          I agree
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
