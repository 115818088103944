import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { ModalProps } from '@typings';
import { noop, normalizeFormErrors } from '@utils';

import { Button, Field, Modal } from '@components';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  name: z.string().min(1),
  value: z.string().min(1),
});

type Props = ModalProps & {
  onAdd: (field: { name: string; value: string; type: 'variable' }) => void;
};

export const JobConstructorVariableModal = ({
  onAdd,
  closeModal = noop,
}: Props) => {
  const { register, formState, handleSubmit } = useForm<Schema>({
    resolver: zodResolver(schema),
  });

  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const handleFormSubmit = handleSubmit(async ({ name, value }) => {
    onAdd({ name, value, type: 'variable' });
    closeModal();
  });

  return (
    <Modal.Content title="The Environment Variable" className="w-[640px]">
      <form className="flex flex-col gap-4">
        <Field.Input {...register('name')} label="Name" error={errors.name} />
        <Field.Input
          {...register('value')}
          label="Value"
          error={errors.value}
        />
        <Modal.Footer className="px-0">
          <Button className="px-10 capitalize" onClick={handleFormSubmit}>
            Create variable
          </Button>
        </Modal.Footer>
      </form>
    </Modal.Content>
  );
};
