import { Helmet as HelmetComponent } from 'react-helmet';

type Props = {
  title?: string;
  description?: string;
  children?: React.ReactNode;
};

export const Helmet = ({ title, description, children }: Props) => {
  return (
    <HelmetComponent title={title} titleTemplate="%s - Apolo Console">
      {description && <meta name="description" content={description} />}
      {children}
    </HelmetComponent>
  );
};
