import { useState } from 'react';
import { faFolder } from '@fortawesome/pro-solid-svg-icons';

import { BILLING_ALL_ENTRY } from '@constants';

import { BillingPopoverItem, BillingPopper } from '@components/Billing';

type Props = {
  disabled?: boolean;
  isGroupedByProject: boolean;
  projectName: string;
  /**
   * Selected cluster name
   */
  clusterName: string;
  projects: string[];
  onSelect: (projectName: string) => void;
};
export const BillingPopoverProjects = ({
  disabled,
  isGroupedByProject,
  projectName: billingProjectName,
  clusterName: billingClusterName,
  projects,
  onSelect,
}: Props) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  /**
   * For all clusters we cannot to specficy project
   * due to billing request with `projectName` param
   */
  const isClusterSpecified = billingClusterName !== BILLING_ALL_ENTRY;
  const isAllSelected = billingProjectName === BILLING_ALL_ENTRY;
  const popperTitle = isAllSelected ? 'All Projects' : billingProjectName;

  const currySelect = (projectName: string) => () => {
    onSelect(projectName);
    setPopoverOpen(false);
  };

  const makeItem = (projectName: string) => (
    <BillingPopoverItem
      key={projectName}
      active={billingProjectName === projectName}
      title={projectName}
      icon={faFolder}
      onClick={currySelect(projectName)}
    />
  );

  return (
    <BillingPopper
      open={popoverOpen}
      onOpenChange={setPopoverOpen}
      disabled={!isClusterSpecified || !isGroupedByProject || disabled}
      title={popperTitle}
      align="start"
    >
      <BillingPopoverItem.All
        active={isAllSelected}
        icon={faFolder}
        onClick={currySelect(BILLING_ALL_ENTRY)}
      />
      {projects.map(makeItem)}
    </BillingPopper>
  );
};
