import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { UserInfoCluster } from '@typings';

export type UserClustersState = UserInfoCluster[];

const initialState = [] as UserClustersState;

export const userClustersSlice = createSlice({
  name: 'userClusters',
  initialState,
  reducers: {
    setUserClusters: (_, action: PayloadAction<UserClustersState>) =>
      action.payload,
  },
});

export const { setUserClusters } = userClustersSlice.actions;

export default userClustersSlice.reducer;
