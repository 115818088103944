import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type UserState = {
  email: string;
  emailVerified: boolean;
  familyName: string;
  givenName: string;
  name: string;
  nickname: string;
  picture: string;
  username: string;
  termsAccepted?: boolean;
};

const initialState = {} as UserState;

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser: (_, action: PayloadAction<UserState | undefined>) =>
      action.payload ?? initialState,
    setUserTerms: (
      state,
      action: PayloadAction<UserState['termsAccepted']>,
    ) => {
      state.termsAccepted = action.payload;
    },
    resetUser: () => initialState,
  },
});

export const { setUser, setUserTerms, resetUser } = userSlice.actions;

export default userSlice.reducer;
