import { useCallback } from 'react';

import { contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import { as, path } from '@utils';

type Argument = string | null | undefined;

export const useHelmetTitle = () => {
  const { organizationName, clusterName, projectName } =
    useSelector(contextNamesSelector);

  const makeTitle = useCallback(
    (...args: Argument[]) => {
      const title = path.create(...args, { prefix: '', separator: ' - ' });

      return title
        .replace('%c', as<string>(clusterName, ''))
        .replace('%p', as<string>(projectName, ''))
        .replace('%o', as<string>(organizationName, 'No organization'));
    },
    [organizationName, clusterName, projectName],
  );

  return { makeTitle };
};
