import { useEffect, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { faXmark } from '@fortawesome/pro-solid-svg-icons';

import { formatModelName } from '@utils';

import { Badge, Button, Field, Icon, Render } from '@components';

type Props = {
  staticTags?: string[];
  error?: string;
};

export const JobConstructorMetadaTags = ({ staticTags = [], error }: Props) => {
  const { register, setValue } = useFormContext();

  const inputRef = useRef<HTMLInputElement>(null);
  const [tags, setTags] = useState<string[]>([]);

  const tagList = [...staticTags, ...tags];

  useEffect(() => {
    register('tags');
  }, [register]);

  useEffect(() => {
    setValue('tags', tags);
  }, [tags, setValue]);

  const handleTagsAdd = () => {
    const input = inputRef.current!;
    const newTags = input.value.split(',');
    const formattedTags = newTags
      .map(formatModelName)
      .filter((tag) => tag && !tagList.includes(tag));

    setTags(tags.concat(formattedTags));
    input.value = '';
  };

  const handleTagRemove = (targetTag: string) => {
    const filteredTags = tags.filter((tag) => tag !== targetTag);

    setTags(filteredTags);
  };

  const makeTag = (tag: string) => {
    const isTagRemovable = !staticTags.includes(tag);

    return (
      <Badge key={tag} className="gap-1">
        {tag}
        <Render if={isTagRemovable}>
          <Button
            theme
            className="text-neural-03 transition-colors hover:text-black"
            onClick={() => handleTagRemove(tag)}
          >
            <Icon icon={faXmark} className="h-3 w-3" />
          </Button>
        </Render>
      </Badge>
    );
  };

  return (
    <div>
      <div className="flex w-full">
        <Field.Input
          ref={inputRef}
          label="Tags"
          containerClassName="flex-1"
          className="rounded-r-none"
          note="Split tags by comma"
          error={error}
        />
        <Button className="h-14 rounded-l-none px-8" onClick={handleTagsAdd}>
          Add
        </Button>
      </div>
      <Render if={tagList}>
        <div className="mt-4 flex flex-wrap gap-3 gap-y-4">
          {tagList.map(makeTag)}
        </div>
      </Render>
    </div>
  );
};
