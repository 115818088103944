import { faHeadset } from '@fortawesome/pro-regular-svg-icons';

import { useMailContext } from '@hooks/useMailContext';

import { Icon, Link } from '@components';

export const SidebarSupportLink = () => {
  const { mailto } = useMailContext();

  return (
    <Link
      external
      variant="ghost"
      to={mailto}
      className="group items-center justify-start gap-3 px-4 text-body text-neural-04 transition-colors"
    >
      <Icon icon={faHeadset} className="text-[20px]" />
      Contact support
    </Link>
  );
};
