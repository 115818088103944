import { Job } from '@typings';
import { getFormattedMemory } from '@utils';

import { Table } from '@components';

type Resource = {
  title: string;
  value: null | undefined | string | number;
};

type Props = {
  resources: Job.ContainerResources | undefined;
};

export const JobDetailsPresetResources = ({
  resources: {
    memory = 0,
    cpu = 0,
    gpu,
    gpuModel,
    shm = false,
  } = {} as Job.ContainerResources,
}: Props) => {
  const resources: Resource[] = [
    {
      title: 'Memory',
      value: getFormattedMemory(memory),
    },
    {
      title: 'CPU',
      value: cpu,
    },
    {
      title: 'GPU',
      value: gpu && `${gpu} x ${gpuModel}`,
    },
    {
      title: 'Extended SHM Space',
      value: shm ? 'True' : 'False',
    },
  ];

  const makeResource = ({ title, value }: Resource) => {
    const cellClassName = 'h-auto truncate border-none p-0';

    if (!value) {
      return null;
    }

    return (
      <Table.Row key={title}>
        <Table.Cell className={cellClassName}>{title}</Table.Cell>
        <Table.Cell className={cellClassName}>{value}</Table.Cell>
      </Table.Row>
    );
  };

  return (
    <Table className="grid grid-cols-2 gap-x-8 gap-y-2">
      <Table.Body>{resources.map(makeResource)}</Table.Body>
    </Table>
  );
};
