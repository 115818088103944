import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { faEnvelope, faXmarkCircle } from '@fortawesome/pro-thin-svg-icons';

import { JWT_TOKEN } from '@constants';
import { getConfig, getUserClusters } from '@thunks';
import { useDispatch } from '@hooks';
import { capitalize, getUsernameFromToken, localStorage } from '@utils';

import { Layout } from '@components/Layouts';
import { EmptyContent } from '@components/Ui';

type AuthError = Error & {
  error?: string;
  error_description?: string;
};

type Props = {
  error: AuthError;
};

export const AuthErrorPage = ({
  error: { message, error, error_description: description },
}: Props) => {
  const dispatch = useDispatch();

  const { getAccessTokenSilently } = useAuth0();

  const formattedErrorTitle = capitalize(error?.replaceAll('_', ' '));

  const icon = description?.includes('email') ? faEnvelope : faXmarkCircle;

  useEffect(() => {
    const run = async () => {
      try {
        const token = await getAccessTokenSilently({ cacheMode: 'off' });

        localStorage.set<string>(JWT_TOKEN, token);

        const username = getUsernameFromToken(token);

        const { adminUrl } = await dispatch(getConfig());

        if (username) {
          await dispatch(getUserClusters(adminUrl, username));
        }
      } catch (error) {
        /**
         * Continue regardless error
         */
      }
    };

    run();
  }, [dispatch, getAccessTokenSilently]);

  return (
    <Layout
      showFeatures={false}
      clusterContextTracker={false}
      projectContextTracker={false}
      organizationContextTracker={false}
      className="flex justify-center"
    >
      <EmptyContent
        variant="layout"
        icon={icon}
        title={formattedErrorTitle}
        text={message || description}
      />
    </Layout>
  );
};
