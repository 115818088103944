import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';

import { Job, OutletContext } from '@typings';

import { Render } from '@components';
import { ErrorBoundary } from '@components/ErrorBoundary';
import {
  JobDetailsContent,
  JobDetailsStatuses,
  JobTelemetry,
} from '@components/Job';

type Props = {
  job: Job.Model;
};

export const JobObservabilityPage = ({
  job: {
    id: jobId,
    presetName,
    statuses,
    status,
    container: { resources },
  },
}: Props) => {
  const { setTabTitle } = useOutletContext<OutletContext.Job>();

  const isJobRunning = status === 'running';

  useEffect(() => {
    setTabTitle(Job.Tab.Observability);
  }, [setTabTitle]);

  return (
    <JobDetailsContent>
      <JobDetailsStatuses statuses={statuses} />
      <ErrorBoundary>
        <Render if={isJobRunning}>
          <JobTelemetry
            jobId={jobId}
            presetName={presetName}
            resources={resources}
          />
        </Render>
      </ErrorBoundary>
    </JobDetailsContent>
  );
};
