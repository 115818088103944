import { Controller, useFieldArray, useFormContext } from 'react-hook-form';

import { cn } from '@utils';

import { Button } from '@components';

type FieldArrayElement = {
  id: string;
  name: string;
  value: string;
};

export const ClusterResourcePresetChips = () => {
  const { control } = useFormContext();
  const { fields } = useFieldArray({
    control,
    name: 'resourcePoolNames',
  });

  const makeItem = ({ id }: FieldArrayElement, index: number) => {
    return (
      <Controller
        key={id}
        control={control}
        name={`resourcePoolNames.${index}`}
        render={({
          field: {
            value: { name, value },
            onChange,
          },
        }) => {
          const payload = {
            target: {
              value: { name, value: !value },
            },
          };

          return (
            <Button
              theme
              className={cn(
                'flex h-8 items-center rounded-2xl bg-neural-01 px-4 py-1.5 text-body transition-colors hover:text-primary',
                { 'text-primary bg-primary-subtle': value },
              )}
              onClick={() => onChange(payload)}
            >
              {name}
            </Button>
          );
        }}
      />
    );
  };

  return (
    <div className="flex flex-wrap gap-4">
      {(fields as FieldArrayElement[]).map(makeItem)}
    </div>
  );
};
