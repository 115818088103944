import dayjs from 'dayjs';

import { Billing, UnixTimestamp } from '@typings';
import { BILLING_ALL_ENTRY } from '@constants';

export const months = [
  'january',
  'february',
  'march',
  'april',
  'may',
  'june',
  'july',
  'august',
  'september',
  'october',
  'november',
  'december',
];

export const billingYears = (() => {
  const BILLING_TRACKING_YEAR = 2024;

  const currentYear = new Date().getFullYear();
  const years = Array.from(
    { length: currentYear - BILLING_TRACKING_YEAR + 1 },
    (_, i) => (currentYear - i).toString(),
  );

  return years;
})();

export const defaultBillingParams: Billing.Params = {
  groups: [Billing.Group.Category, Billing.Group.Day],
  clusterName: BILLING_ALL_ENTRY,
  projectName: BILLING_ALL_ENTRY,
  startDate: dayjs.utc().startOf('month').unix() as UnixTimestamp,
  endDate: dayjs.utc().unix() as UnixTimestamp,
  isGroupedByProject: true,
  monthToDateUsage: [],
};
