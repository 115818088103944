import { Flow, Params } from '@typings';
import { API_ROOT } from '@constants';
import { axios } from '@features';
import { path } from '@utils';

export const getFlows = ({
  clusterName,
  organizationName,
  projectName,
}: Params.GetFlows) => {
  const params = {
    cluster: clusterName,
    org_name: organizationName ?? '',
    project_name: projectName,
  };

  return axios.get<never, Flow.Model[]>(
    path.create(API_ROOT, 'flow', 'projects'),
    { params },
  );
};

export const deleteFlow = (projectId: string) => {
  return axios.delete(path.create(API_ROOT, 'flow', 'projects', projectId));
};

export const getFlowBakes = ({ projectId }: { projectId: string }) => {
  const params = {
    project_id: projectId,
    fetch_last_attempt: true,
    reverse: true,
  };

  return axios.get<never, Flow.Bake[]>(path.create(API_ROOT, 'flow', 'bakes'), {
    params,
    formatResponse: false,
  });
};

export const getFlowBake = (bakeId: string) => {
  const params = {
    fetch_last_attempt: true,
  };

  return axios.get<never, Flow.Bake>(
    path.create(API_ROOT, 'flow/bakes', bakeId),
    { params },
  );
};

export const getFlowBakeTasks = (lastAttemptId: string) => {
  const params = {
    attempt_id: lastAttemptId,
  };

  return axios.get<never, Flow.BakeTask[]>(
    path.create(API_ROOT, 'flow/tasks'),
    { params },
  );
};

export const killBake = ({
  lastAttempt: { bakeId, number, executorId, configsMeta },
}: Params.KillBake) => {
  const payload = {
    bake_id: bakeId,
    number,
    result: 'cancelled',
    configs_meta: configsMeta,
    executor_id: executorId,
  };

  return axios.put(path.create(API_ROOT, 'flow/attempts/replace'), payload);
};
