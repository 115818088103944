import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import clsx from 'clsx';

import { ModalProps, Payload, Permission } from '@typings';
import { toast } from '@features';
import {
  capitalize,
  noop,
  normalizeFormErrors,
  toastifyResponseError,
} from '@utils';

import { Button, Field, Modal } from '@components';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  username: z.string().min(1),
  permission: z.nativeEnum(Permission),
});

type Props = ModalProps & {
  title: string;
  onShare?: (payload: Payload.ShareAccess) => void;
};

export const ShareAccessModal = ({
  title,
  onShare = noop,
  closeModal = noop,
}: Props) => {
  const { register, handleSubmit, formState } = useForm({
    defaultValues: {
      username: '',
      permission: Permission.Read,
    },
    resolver: zodResolver(schema),
  });

  const [loading, setLoading] = useState(false);

  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const permissionOptions = Object.values(Permission).map((permission) => (
    <option key={permission} value={permission}>
      {capitalize(permission)}
    </option>
  ));

  const handleAccessShare = handleSubmit(async ({ username, permission }) => {
    setLoading(true);

    try {
      await onShare({ username, permission });

      toast.success(`Granted permission for ${username}`);

      closeModal();
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  });

  return (
    <Modal.Content className="w-[488px]">
      <Modal.Header className="truncate">{title}</Modal.Header>
      <form onSubmit={handleAccessShare}>
        <div className="flex gap-4">
          <Field.Input
            {...register('username')}
            required
            label="Share access with"
            className={clsx('w-full', {
              'border !border-error': !!errors.username,
            })}
            containerClassName="flex-1"
            error={errors.username}
          />
          <Field.Select
            {...register('permission')}
            label="Permission"
            containerClassName="w-[120px]"
          >
            {permissionOptions}
          </Field.Select>
        </div>
      </form>
      <Modal.Footer className="flex justify-end">
        <Button
          type="submit"
          loading={loading}
          className="px-10 capitalize"
          onClick={handleAccessShare}
        >
          Share access
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
