import { useState } from 'react';

import { ModalProps } from '@typings';
import { declineOrganizationInvite } from '@services';
import { getOrganizationsInvites } from '@thunks';
import { useDispatch } from '@hooks';
import { noop, toastifyResponseError } from '@utils';

import { Button, Modal } from '@components';

type Props = ModalProps & {
  inviteId: string;
  organizationName: string;
};

export const OrganizationDeclineInviteModal = ({
  inviteId,
  organizationName,
  closeModal = noop,
}: Props) => {
  const dispatch = useDispatch();

  const [loading, setLoading] = useState(false);

  const handleInviteDecline = async () => {
    try {
      setLoading(true);

      await declineOrganizationInvite({ inviteId });
      await dispatch(getOrganizationsInvites());

      closeModal();
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal.Content title="Decline Invite" className="flex flex-col gap-2">
      <p>
        Are you sure you want to decline the invitation to{' '}
        <span className="font-600">{organizationName}</span>?
      </p>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          loading={loading}
          variant="error"
          onClick={handleInviteDecline}
          className="px-6"
        >
          Decline invite
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
