import { useFieldArray, useFormContext } from 'react-hook-form';
import { faLockKeyhole, faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import { Button, Field, Icon, Modal, Render, Table, Theme } from '@components';

import {
  JobConstructorSecretModal,
  JobConstructorVariableModal,
} from './Modals';

type FieldArrayElement = {
  id: string;
  name: string;
  value: string;
  type: 'variable' | 'secret';
};

export const JobConstructorEnvironments = () => {
  const { control } = useFormContext();
  const { fields, append, remove } = useFieldArray({ control, name: 'envs' });

  const handleFieldAppend = ({
    name,
    value,
    type,
  }: Omit<FieldArrayElement, 'id'>) => {
    append({ name, value, type });
  };

  const makeVariable = (
    { id, name, value, type }: FieldArrayElement,
    index: number,
  ) => {
    const isSecret = type === 'secret';

    return (
      <Table.Row key={id}>
        <Table.Cell className="py-2">{name}</Table.Cell>
        <Table.Cell className="py-2">
          <div className="flex items-center gap-2">
            <Render if={isSecret}>
              <Icon icon={faLockKeyhole} className="h-5 w-5" />
            </Render>
            {value}
          </div>
        </Table.Cell>
        <Table.Cell className="py-2">
          <Button theme onClick={() => remove(index)}>
            <Icon icon={faTrashAlt} className="text-error" />
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  };

  return (
    <div className="flex flex-col gap-5 rounded-lg bg-background p-5">
      <Field.Label>Environment Variables</Field.Label>
      <Theme.Container className="p-0">
        <Table className="w-full grid-cols-[repeat(2,auto)_min-content] overflow-auto">
          <Table.Header>
            <Table.Row>
              <Table.Head>Name</Table.Head>
              <Table.Head>Value</Table.Head>
              <Table.Head />
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {(fields as FieldArrayElement[]).map(makeVariable)}
          </Table.Body>
        </Table>
      </Theme.Container>
      <div className="flex justify-end gap-4">
        <Modal
          content={<JobConstructorSecretModal onAdd={handleFieldAppend} />}
        >
          <Button>
            <Icon icon={faPlus} />
            Add secret
          </Button>
        </Modal>
        <Modal
          content={<JobConstructorVariableModal onAdd={handleFieldAppend} />}
        >
          <Button>
            <Icon icon={faPlus} />
            Add variable
          </Button>
        </Modal>
      </div>
    </div>
  );
};
