import { Controller, useFormContext } from 'react-hook-form';

import { Job } from '@typings';
import { getRunningJobs } from '@services';
import { useFetchList } from '@hooks';
import { as } from '@utils';
import { dedicatedApps } from '@content';

import { Field } from '@components';
import { Mlflow } from '@components/Ui';

export const JobMlflowField = () => {
  const { control } = useFormContext();

  const mlflowTags = dedicatedApps.find(({ name }) => name === 'mlflow')!.tags!;

  const { isEmpty, list } = useFetchList<Job.Model[]>({
    fetchOnFilterChange: false,
    // @ts-ignore
    getList: getRunningJobs,
    responseParser: ({ jobs }) => jobs,
    defaultFilters: {
      reverse: '1',
      status: 'running',
      /**
       * todo: resolve default filters type
       */
      // @ts-ignore
      tags: mlflowTags,
    },
  });

  const findJobByHostname = (
    hostname: string | undefined,
  ): Job.Model | undefined =>
    list.find(({ internalHostname, internalHostnameNamed }) =>
      [internalHostname, internalHostnameNamed].includes(hostname),
    );

  const makeJob = ({
    name,
    owner,
    projectName,
    internalHostname,
    internalHostnameNamed,
  }: Job.Model) => {
    const value = internalHostnameNamed ?? internalHostname;

    return (
      <Field.SelectItem
        key={name}
        value={value}
        className="z-50 w-full transition-colors hover:bg-background"
      >
        <div className="flex items-center gap-1">
          <p className="flex-1 self-center pr-8">{name}</p>
          <span className="text-caption text-neural-03">
            {projectName} by {owner}
          </span>
        </div>
      </Field.SelectItem>
    );
  };

  return (
    <Controller
      control={control}
      name="mlflow"
      render={({ field: { value, onChange }, fieldState: { error } }) => {
        const job = findJobByHostname(value);
        const { owner, name } = as.o<Job.Model>(job);

        return (
          <Field.CustomSelect
            note="Connect MLFlow server"
            value={value}
            disabled={isEmpty}
            onChange={(value) => onChange({ target: { value } })}
            error={error?.message}
          >
            <div className="flex items-center gap-2" slot="value">
              <Mlflow />
              <div className="text-left">
                <p className="text-caption text-neural-03">MLFlow Server</p>
                <p>
                  {name}
                  <span className="space-before ml-2 text-caption text-neural-03">
                    (by {owner})
                  </span>
                </p>
              </div>
            </div>
            <div className="flex items-center gap-2" slot="placeholder">
              <Mlflow />
              <p className="text-neural-03">Choose a MLFlow Server</p>
            </div>
            {list.map(makeJob)}
          </Field.CustomSelect>
        );
      }}
    />
  );
};
