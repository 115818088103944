import { Any } from '@typings';
import { isNumber } from '@utils';

type Props = {
  if?: Any;
  children?: React.ReactNode;
};

/**
 * Conditional render to improve readability
 */
export const Render = ({ if: source = false, children }: Props) => {
  if (source === null) {
    return source;
  }

  if (Array.isArray(source)) {
    return source.filter(Boolean).length ? children : null;
  }

  if (isNumber(source)) {
    return children;
  }

  if (source) {
    return children;
  }

  return null;
};
