import { Organization } from '@typings';
import { NO_ORGANIZATION, NO_ORGANIZATION_NAME } from '@constants';

/**
 * Returns organization name / no organization name for request payload
 *
 * @example getOrganizationName("org-name"); // "org-name"
 * @example getOrganizationName(null); // "NO_ORG";
 */
export const getOrganizationName = (
  organizationName: Organization.Name,
): string | typeof NO_ORGANIZATION_NAME =>
  organizationName === NO_ORGANIZATION
    ? NO_ORGANIZATION_NAME
    : organizationName;

export const getFormattedOrganizationName = (
  organizationName: Organization.Name | undefined,
) => {
  if (organizationName === NO_ORGANIZATION) {
    return 'No organization';
  }

  return organizationName;
};
