import { Params, PermissionModel, UserInfoCluster } from '@typings';
import { API_ADMIN, API_ROOT } from '@constants';
import { axios } from '@features';
import { ConfigState } from '@slices';
import { path, removeEmptyProperties } from '@utils';

export const getConfig = async () => {
  return axios.get<never, ConfigState>(`${API_ROOT}/config`);
};

export const getPermissions = ({
  usersUrl = path.create(API_ROOT, 'users'),
  username,
  uri,
  path: restPath,
}: Params.GetPermissions) => {
  return axios.get<never, PermissionModel[]>(
    path.create(usersUrl, username, 'permissions', restPath),
    { params: { uri } },
  );
};

export const getUserClusters = (adminUrl: string, username: string) => {
  return axios.get<
    never,
    { termsAccepted?: boolean; clusters: UserInfoCluster[] }
  >(`${adminUrl}/users/${username}`, {
    params: { include: 'clusters' },
  });
};

export const shareAccess = ({
  action,
  uri,
  usersUrl,
  username,
}: Params.ShareAccess) => {
  const payload = [{ action, uri }];

  return axios.post(path.create(usersUrl, username, 'permissions'), payload);
};

export const unshareAccess = ({
  uri,
  usersUrl,
  username,
}: Params.UnshareAccess) =>
  axios.delete(path.create(usersUrl, username, 'permissions'), {
    params: { uri },
  });

export const buyCredits = ({
  organizationName,
  clusterName,
  amountInCents,
  cancelUrl,
  successUrl,
}: Params.BuyCredits) => {
  const payload = removeEmptyProperties({
    cluster_name: clusterName,
    org_name: organizationName,
    amount_in_cents: amountInCents,
    success_url: successUrl,
    cancel_url: cancelUrl,
  });

  return axios.post<never, { checkoutUrl: string }>(
    `${API_ROOT}/payments/stripe/checkout`,
    payload,
  );
};

export const agreeUserTerms = ({ username, email }: Params.AgreeUserTerms) => {
  return axios.put(`${API_ADMIN}/users/${username}/profile`, {
    terms_accepted: true,
    name: username,
    email,
  });
};
