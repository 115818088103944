import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { AsyncFunction, ModalProps, TenantRole } from '@typings';
import { toast } from '@features';
import { editClusterUser } from '@services';
import { configSelector, contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import {
  capitalize,
  noop,
  normalizeFormErrors,
  toastifyResponseError,
} from '@utils';

import { Button, Field, Modal } from '@components';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  role: z.nativeEnum(TenantRole),
  credits: z.string().optional(),
});

type Props = ModalProps & {
  username: string;
  role: TenantRole;
  credits?: string;
  getUsers: AsyncFunction;
};

export const ClusterEditUserModal = ({
  username,
  role,
  credits,
  getUsers,
  closeModal = noop,
}: Props) => {
  const { adminUrl } = useSelector(configSelector);
  const { clusterName, organizationName } = useSelector(contextNamesSelector);

  const { register, formState, handleSubmit } = useForm({
    resolver: zodResolver(schema),
    defaultValues: { role, credits },
  });

  const [loading, setLoading] = useState<boolean>(false);

  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const handleUserSubmit = handleSubmit(async ({ role, credits }) => {
    setLoading(true);

    try {
      await editClusterUser({
        adminUrl,
        clusterName: clusterName!,
        organizationName,
        role,
        credits,
        username,
      });
      await getUsers();

      toast.success(`User ${username} is updated`);

      closeModal();
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  });

  const roleOptions = Object.values(TenantRole).map((role) => ({
    value: role,
    text: capitalize(role),
  }));

  return (
    <Modal.Content title={`Edit User ${username}`} className="w-[540px]">
      <form className="flex flex-col gap-6" onSubmit={handleUserSubmit}>
        <Field.Select
          {...register('role')}
          label="Role"
          options={roleOptions}
          error={errors.role}
        />
        <Field.Input
          {...register('credits')}
          label="Credits"
          type="number"
          note="Empty value means unlimited credits"
          className="w-full"
          error={errors.credits}
        />
      </form>

      <Modal.Footer>
        <Button loading={loading} className="px-10" onClick={handleUserSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
