import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { AnyFunction } from '@typings';
import { cn, getSlots } from '@utils';

import { Button, Field, Icon, Render, Slot } from '@components';

import { BillingPopoverItemAll } from './BillingPopoverItemAll';

type Props = {
  active?: boolean;
  /**
   * For checkbox icon
   */
  selectable?: boolean;
  title?: string;
  icon?: IconProp;
  children?: React.ReactNode;
  onClick?: AnyFunction;
};

export const BillingPopoverItem = ({
  active,
  selectable,
  title,
  icon,
  children,
  onClick,
}: Props) => {
  const slots = getSlots<'icon'>(children);

  return (
    <Button
      theme
      className={cn(
        'flex gap-2 px-4 py-2.5 transition-colors hover:bg-neural-01',
        { 'bg-neural-01': active && !selectable },
      )}
      onClick={onClick}
    >
      <Render if={selectable}>
        <Field.Checkbox checked={active} className="pointer-events-none" />
      </Render>
      <Slot slot={slots.icon}>
        <Render if={icon}>
          <Icon icon={icon} className="text-neural-04" />
        </Render>
      </Slot>
      {title ?? children}
    </Button>
  );
};

BillingPopoverItem.All = BillingPopoverItemAll;
