import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { Cluster, Organization, Project } from '@typings';

export type ConfigState = {
  audience: string;
  authorized: boolean;
  authUrl: string;
  callbackUrls: string[];
  clientId: string;
  headlessCallbackUrl: string;
  logoutUrl: string;
  successRedirectUrl: string;
  tokenUrl: string;
  orgs: Organization.Model[];
  clusters: Cluster.Model[];
  projects: Project[];
  adminUrl: string;
};

const initialState = {} as ConfigState;

export const configSlice = createSlice({
  name: 'config',
  initialState,
  reducers: {
    setConfig: (_, action: PayloadAction<ConfigState>) => action.payload,
  },
});

export const { setConfig } = configSlice.actions;

export default configSlice.reducer;
