import { cn } from '@utils';

type Props = {
  className?: string;
  children?: React.ReactNode;
};

export const LayoutContent = ({ className, children }: Props) => {
  return <div className={cn('w-full px-10 py-12', className)}>{children}</div>;
};
