import { useCallback, useEffect, useState } from 'react';
import { faFolder, faUser } from '@fortawesome/pro-regular-svg-icons';
import { faDiagramProject } from '@fortawesome/pro-thin-svg-icons';

import { Flow } from '@typings';
import { PATH } from '@constants';
import { getFlows } from '@services';
import { contextNamesSelector } from '@selectors';
import { useFetchList, useHelmetTitle, useSelector } from '@hooks';
import { path } from '@utils';

import {
  Badge,
  Button,
  ChaseSpinner,
  Field,
  Helmet,
  Icon,
  Link,
  Modal,
  Table,
} from '@components';
import { Layout } from '@components/Layouts';
import { DeleteFlowModal } from '@components/Modals';
import { EmptyContent } from '@components/Ui';

export const FlowsPage = () => {
  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);

  const { makeTitle } = useHelmetTitle();
  const { isEmpty, isFetched, list, getList } = useFetchList<Flow.Model[]>({
    fetchOnMount: false,
    fetchOnFilterChange: false,
    getList: getFlows,
  });

  const [search, setSearch] = useState('');

  const fetchFlows = useCallback(async () => {
    if (clusterName && projectName) {
      getList({
        clusterName,
        organizationName,
        projectName,
      });
    }
  }, [clusterName, organizationName, projectName, getList]);

  useEffect(() => {
    fetchFlows();
  }, [fetchFlows]);

  const makeFlow = ({ id, name, owner, projectName }: Flow.Model) => {
    const searchParams = new URLSearchParams({
      projectId: id,
      flowName: name,
    });
    const liveJobsUrl = `${PATH.JOBS}?${searchParams.toString()}`;

    return (
      <Table.Row key={id}>
        <Table.Cell>
          <Link
            theme
            className="truncate underline"
            to={path.flowBakes(id, name)}
          >
            {name ?? id}
          </Link>
        </Table.Cell>
        <Table.Cell className="flex-row justify-start gap-1">
          <Badge className="gap-1 pl-1">
            <Icon
              icon={faUser}
              className="rounded-full bg-neural-02 text-neural-04"
            />
            {owner}
          </Badge>
          <Badge className="gap-1">
            <Icon icon={faFolder} className="h-4 w-4" />
            {projectName}
          </Badge>
        </Table.Cell>
        <Table.Cell className="flex-row justify-start gap-2">
          <Link
            variant="rebecca"
            to={liveJobsUrl}
            className="h-auto rounded-md py-1 text-footnote-large capitalize"
          >
            View real-time jobs
          </Link>
          <Link
            variant="rebecca"
            className="h-auto rounded-md py-1 text-footnote-large capitalize"
            to={path.flowBakes(id, name)}
          >
            View bakes
          </Link>
          <Modal
            content={
              <DeleteFlowModal
                name={name}
                projectId={id}
                getFlows={fetchFlows}
              />
            }
          >
            <Button
              variant="error"
              className="h-auto rounded-md py-1 text-footnote-large"
            >
              Delete
            </Button>
          </Modal>
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderContent = () => {
    const filteredList = search
      ? list.filter(({ name }) => name.includes(search))
      : list;

    if (!isFetched) {
      return (
        <div className="relative flex min-h-[400px] w-full items-center justify-center">
          <ChaseSpinner color="black" className="h-12 w-12" />
        </div>
      );
    }

    if (isEmpty) {
      return (
        <EmptyContent
          icon={faDiagramProject}
          className="mt-4"
          title="No flows found"
        />
      );
    }

    return (
      <Table className="w-full grid-cols-[repeat(2,minmax(max-content,auto))_max-content] overflow-auto">
        <Table.Header>
          <Table.Row>
            <Table.Head>Name</Table.Head>
            <Table.Head>Tags</Table.Head>
            <Table.Head>Actions</Table.Head>
          </Table.Row>
        </Table.Header>
        <Table.Body>{filteredList.map(makeFlow)}</Table.Body>
      </Table>
    );
  };

  return (
    <Layout title="Flows">
      <Helmet title={makeTitle('Flows', '%p', '%c')} />
      <Layout.Content>
        <div className="mb-14 flex items-center gap-10">
          <Field.Input
            containerClassName="flex-1"
            className="border-neural-03"
            label="Search"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </div>
        <div className="flex items-start gap-10">{renderContent()}</div>
      </Layout.Content>
    </Layout>
  );
};
