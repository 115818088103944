import { cn } from '@utils';

type Props = {
  className?: string;
};

export const MlflowIcon = ({ className }: Props) => (
  <svg
    className={cn('h-6 w-6', className)}
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M18.6733 2.56292C13.9814 -0.720079 7.59336 -0.0745763 3.65313 4.08069C-0.287105 8.23596 -0.592581 14.6493 2.93487 19.1602L6.4567 16.5751C4.70853 14.4065 4.35346 11.4295 5.54253 8.91053C6.7316 6.39153 9.25564 4.77361 12.041 4.74495L11.9577 7.49681L18.6733 2.56292Z"
      fill="#43C9ED"
    />
    <path
      d="M21.2774 5.13172C21.1607 4.9705 21.0384 4.81206 20.9133 4.65918L17.5221 7.16087C19.4365 9.27946 19.9332 12.3227 18.7917 14.9401C17.6502 17.5574 15.082 19.2639 12.2268 19.3024L12.3102 16.5534L5.51953 21.5567C10.1733 24.6905 16.4061 24.0264 20.295 19.9825C24.1841 15.9386 24.6044 9.68463 21.2913 5.15674L21.2774 5.13172Z"
      fill="#79797B"
    />
  </svg>
);
