import {
  Cluster,
  ClusterSettings,
  GpuModel,
  Job,
  SupportedGpuMake,
} from '@typings';
import { SUPPORTED_GPU_MAKES } from '@constants';
import { as } from '@utils/as';
import { ensureArray } from '@utils/ensureArray';

type GetGpuOptions = {
  withPlaceholder?: boolean;
};

export const getGpuMakeKeys = (
  gpuMake: SupportedGpuMake,
): {
  gpuModelKey: keyof Cluster.GpuQuantity;
  gpuModelNameKey: keyof Cluster.GpuModelName;
} => ({
  gpuModelKey: `${gpuMake}Gpu`,
  gpuModelNameKey: `${gpuMake}GpuModel`,
});

export const getGpu = (
  resource:
    | Cluster.ResourcePreset
    | ClusterSettings.NodePool
    | Cluster.ResourcePoolType
    | Job.ContainerResources,
  options?: GetGpuOptions,
) => {
  const { withPlaceholder } = as.o<GetGpuOptions>(options);
  const placeholder = withPlaceholder ? [as.gpu(null)] : [];

  const gpus = SUPPORTED_GPU_MAKES.reduce<GpuModel.Interface[]>(
    (models, gpuMake) => {
      const gpuModel: keyof Cluster.GpuQuantity = `${gpuMake}Gpu`;
      const gpuName: keyof Cluster.GpuModelName = `${gpuMake}GpuModel`;
      const gpuQuantity = resource[gpuModel];
      const gpuModelName = resource[gpuName];

      if (gpuQuantity) {
        return [...models, { gpuQuantity, gpuMake, gpuModelName }];
      }

      return models;
    },
    [],
  );

  return gpus.length ? gpus : placeholder;
};

export const getGpuFromNodePool = (
  nodePool: ClusterSettings.NodePool | Cluster.ResourcePoolType,
  options?: GetGpuOptions,
) => getGpu(nodePool, options);
export const getGpuFromResourcePreset = (
  resourcePreset: Cluster.ResourcePreset,
  options?: GetGpuOptions,
) => getGpu(resourcePreset, options);

export const parseGpuModels = (
  gpus: GpuModel.Model | GpuModel.Model[] = [],
): Cluster.Gpu =>
  ensureArray(gpus).reduce<Cluster.Gpu>((models, { gpuMake, gpuModelName }) => {
    const { gpuModelNameKey } = getGpuMakeKeys(gpuMake);

    return gpuMake ? { ...models, [gpuModelNameKey]: gpuModelName } : models;
  }, {});
