import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { AsyncFunction, ModalProps, Role } from '@typings';
import { toast } from '@features';
import { editProjectUser } from '@services';
import { configSelector, contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import {
  capitalize,
  noop,
  normalizeFormErrors,
  toastifyResponseError,
} from '@utils';

import { Button, Field, Modal } from '@components';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  role: z.nativeEnum(Role),
});

type Props = ModalProps & {
  username: string;
  role: Role;
  getUsers: AsyncFunction;
};

export const ProjectEditUserModal = ({
  username,
  role,
  getUsers,
  closeModal = noop,
}: Props) => {
  const { adminUrl } = useSelector(configSelector);
  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);

  const { register, formState, handleSubmit } = useForm({
    resolver: zodResolver(schema),
    defaultValues: { role },
  });

  const [loading, setLoading] = useState<boolean>(false);

  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const handleUserSubmit = handleSubmit(async ({ role }) => {
    setLoading(true);

    try {
      await editProjectUser({
        adminUrl,
        clusterName: clusterName!,
        organizationName,
        projectName: projectName!,
        role,
        username,
      });
      await getUsers();

      toast.success(`User ${username} is updated`);

      closeModal();
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  });

  const roleOptions = Object.values(Role).map((role) => ({
    value: role,
    text: capitalize(role),
  }));

  return (
    <Modal.Content title={`Edit User ${username}`} className="w-[480px]">
      <form className="flex flex-col gap-6" onSubmit={handleUserSubmit}>
        <Field.Select
          {...register('role')}
          label="Role"
          options={roleOptions}
          error={errors.role}
        />
      </form>
      <Modal.Footer>
        <Button loading={loading} className="px-14" onClick={handleUserSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
