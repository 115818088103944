import { useAuth0 } from '@auth0/auth0-react';
import { faArrowLeftFromLine } from '@fortawesome/pro-regular-svg-icons';

import { JWT_TOKEN } from '@constants';
import { resetContext } from '@slices';
import { userSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import { localStorage, noop } from '@utils';

import { Button, Icon } from '@components';

type Props = {
  closePopover?: () => void;
};

export const UserPanelProfile = ({ closePopover = noop }: Props) => {
  const dispatch = useDispatch();
  const { name, picture, username } = useSelector(userSelector);

  const { logout } = useAuth0();

  const handleLogout = () => {
    localStorage.clear(JWT_TOKEN);
    dispatch(resetContext());

    logout({ logoutParams: { returnTo: window.location.origin } });

    closePopover();
  };

  return (
    <div className="w-full px-3">
      <p className="mb-5 text-caption uppercase text-neural-04">User</p>
      <div className="mb-3 flex min-w-0 items-center gap-3">
        <img src={picture} className="h-10 w-10 shrink-0 rounded-full" alt="" />
        <div className="min-w-0">
          <p className="truncate" title={name}>
            {name}
          </p>
          <p className="truncate text-body-small text-neural-04">{username}</p>
        </div>
      </div>
      <Button
        variant="ghost-error"
        className="mt-auto px-0 font-400"
        onClick={handleLogout}
      >
        <Icon icon={faArrowLeftFromLine} className="mr-1 text-[20px]" />
        Log out
      </Button>
    </div>
  );
};
