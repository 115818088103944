import { faFolder } from '@fortawesome/pro-thin-svg-icons';

import { AnyFunction, Storage } from '@typings';

import { ListItem } from '@components/Ui';

import * as File from './Items';

type Props = Storage.FileStatus & {
  isActive?: boolean;
  currentPath: string;
  onClick: AnyFunction;
};

export const StorageItemDistributor = ({
  isActive,
  currentPath,
  onClick,
  ...file
}: Props) => {
  const { type, path } = file;

  switch (type) {
    case Storage.StatusType.Directory:
      return (
        <ListItem.Grid
          isActive={isActive}
          icon={faFolder}
          title={path}
          onClick={onClick}
        />
      );
    case Storage.StatusType.File:
      return (
        <File.File
          {...file}
          isActive={isActive}
          key={path}
          currentPath={currentPath}
          onClick={onClick}
        />
      );
    default:
      return null;
  }
};
