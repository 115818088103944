import { useState } from 'react';

import { AsyncFunction, ModalProps } from '@typings';
import { removeClusterUser } from '@services';
import { configSelector, contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import { noop, toastifyResponseError } from '@utils';

import { Button, Modal } from '@components';

type Props = ModalProps & {
  username: string;
  getUsers: AsyncFunction;
};

export const ClusterRemoveUserModal = ({
  username,
  getUsers,
  closeModal = noop,
}: Props) => {
  const { clusterName, organizationName } = useSelector(contextNamesSelector);
  const { adminUrl } = useSelector(configSelector);

  const [loading, setLoading] = useState(false);

  const handleUserRemove = async () => {
    try {
      setLoading(true);

      await removeClusterUser({
        adminUrl,
        clusterName: clusterName!,
        organizationName,
        username,
      });
      await getUsers();

      closeModal();
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal.Content
      title={`Remove User ${username}`}
      className="flex flex-col gap-4"
    >
      <p>The user will no longer have access to the cluster</p>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          loading={loading}
          variant="error"
          onClick={handleUserRemove}
          className="px-8"
        >
          Remove
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
