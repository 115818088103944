import { Badge, Render } from '@components';

const TAGS_RESTRICTION_AMOUNT = 3;

type Props = {
  tags?: string[];
};

export const JobTags = ({ tags = [] }: Props) => {
  const visibleTags = tags.slice(0, TAGS_RESTRICTION_AMOUNT);
  const otherTags = tags.slice(TAGS_RESTRICTION_AMOUNT);

  const makeTag = (tag: string) => <Badge key={tag}>{tag}</Badge>;

  if (!tags.length) {
    return null;
  }

  return (
    <div className="flex shrink-0 gap-1">
      {visibleTags.map(makeTag)}
      <Render if={!!otherTags.length}>
        <Badge>+{otherTags.length}</Badge>
      </Render>
    </div>
  );
};
