import { useState } from 'react';
import { faInfoCircle } from '@fortawesome/pro-light-svg-icons';

import { Any, ModalProps, ResponseError } from '@typings';
import { PATH } from '@constants';
import { toast } from '@features';
import { saveJobImage } from '@services';
import { clusterContextSelector, contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import { as, noop, toastifyResponseError } from '@utils';

import { Button, Icon, Link, Modal, Render } from '@components';

type Props = ModalProps & {
  jobId: string;
};

export const SaveJobImageModal = ({ jobId, closeModal = noop }: Props) => {
  const { organizationName, projectName } = useSelector(contextNamesSelector);
  const cluster = useSelector(clusterContextSelector);

  const [loading, setLoading] = useState(false);

  const { registryUrl, monitoringUrl } = as.c(cluster);

  const handleSave = async () => {
    try {
      setLoading(true);

      const response = await saveJobImage({
        registryUrl,
        monitoringUrl,
        organizationName,
        projectName: projectName!,
        jobId,
      });

      if (response.error) {
        const error = new Error() as Any as ResponseError;

        error.error = response.error;

        return await Promise.reject(error);
      }

      toast.success('Image saved');

      closeModal();
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal.Content title="Save Image">
      <p className="text-neural-04">
        You are about to save the image. Once saved, the image will be displayed
        on the{' '}
        <Link
          theme
          className="capitalize text-rebecca hover:underline"
          to={PATH.IMAGES}
        >
          images app
        </Link>
        . Please note that this process may take a few minutes
      </p>
      <Render if={loading}>
        <p className="mt-4 flex items-center text-neural-04">
          <Icon icon={faInfoCircle} className="mr-1 text-[18px]" /> Your image
          will still be saved even if you close this window
        </p>
      </Render>
      <Modal.Footer>
        <Button variant="secondary" className="px-8" onClick={closeModal}>
          Close
        </Button>
        <Button
          loading={loading}
          className="px-10 capitalize"
          onClick={handleSave}
        >
          Save image
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
