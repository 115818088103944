import {
  faDatabase,
  faFile,
  faLockKeyhole,
} from '@fortawesome/pro-regular-svg-icons';

import { JobVolume, VolumeType } from '@typings';

import { Icon, Table } from '@components';

type Volume = JobVolume.Storage | JobVolume.Disk | JobVolume.Secret;
type IterableVolume = JobVolume.Storage & JobVolume.Disk & JobVolume.Secret;

const icons = {
  storage: faFile,
  disk: faDatabase,
  secret: faLockKeyhole,
};

type Props = {
  volumes: Volume[];
};

export const JobDetailsVolumes = ({ volumes }: Props) => {
  const makeVolume = (volume: Volume) => {
    const { dstPath, srcStorageUri, srcDiskUri, srcSecretUri } =
      volume as IterableVolume;
    const uri = srcStorageUri ?? srcDiskUri ?? srcSecretUri;
    const [type] = uri.split('://');
    const icon = icons[type as VolumeType];
    const uriValue = uri.split('/').at(-1);

    return (
      <Table.Row key={uri}>
        <Table.Cell className="h-auto truncate border-none p-0">
          <p className="truncate" title={dstPath}>
            {dstPath}
          </p>
        </Table.Cell>
        <Table.Cell className="h-auto flex-row justify-start gap-2 truncate border-none p-0">
          <Icon icon={icon} />
          <p className="truncate" title={uriValue}>
            /{uriValue}
          </p>
        </Table.Cell>
      </Table.Row>
    );
  };

  if (volumes.length === 0) {
    return <p>-</p>;
  }

  return (
    <Table className="grid grid-cols-2 gap-x-8 gap-y-2">
      <Table.Body>{volumes.map(makeVolume)}</Table.Body>
    </Table>
  );
};
