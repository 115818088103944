import { useCallback, useEffect, useState } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import { captureMessage } from '@sentry/react';
import YAML from 'yamljs';

import { Any, App, Job } from '@typings';
import { PATH } from '@constants';
import { axios } from '@features';
import { getRunningJobs, getStorageList } from '@services';
import {
  clusterContextSelector,
  contextNamesSelector,
  userSelector,
} from '@selectors';
import { useSelector } from '@hooks';
import {
  as,
  formatToCamelCase,
  isObject,
  path,
  toastifyResponseError,
} from '@utils';

import * as Page from '@pages';

type StatusYaml = {
  jobId: string;
  status: App.Status;
  type: string;
};

/**
 * todo: split fetching into `useApps` hook
 */
export const AppsRoutes = () => {
  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);
  const cluster = useSelector(clusterContextSelector);
  const { username } = useSelector(userSelector);

  /**
   * Installed dedicated app names
   */
  const [installedDedicatedApps, setInstalledDedicatedApps] = useState<
    App.DedicatedModel[]
  >([]);
  const [runningJobs, setRunningJob] = useState<Job.Running[]>([]);
  const [title, setTitle] = useState('');

  const { storageUrl } = as.c(cluster);

  const getDedicatedApps = useCallback(async () => {
    if (!projectName) {
      return;
    }

    try {
      const storagePath = path.create(organizationName, projectName, '.apps');

      /**
       * Define the rinning dedicated apps by getting all nested `/{organizationName}/{projectName}/.apps` directories as apps
       */
      const appsDirectories = await getStorageList({ storagePath, storageUrl });
      const appNames = appsDirectories.map(({ path }) => path);

      const appStatusPromises = appNames.map(async (appName) => {
        return axios.get(
          path.create(storageUrl, storagePath, appName, 'status.yaml'),
        );
      });

      const appStatuses = (await Promise.all(
        appStatusPromises,
      )) as Any[] as string[];

      const parsedAppNames = appStatuses
        .map((status, index) => {
          const appName = appNames[index];
          const statusYaml = formatToCamelCase(
            YAML.parse(status as Any),
          ) as StatusYaml;

          try {
            const { jobId, status, type } = statusYaml;

            return { jobId, status, targetName: type, name: appName };
          } catch (error) {
            captureMessage(`Could not parse status.yaml file for ${appName}`);
          }

          return null;
        })
        .filter(isObject<App.DedicatedModel>);

      setInstalledDedicatedApps(parsedAppNames);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ error });
      /**
       * Continue regardless error
       */
    }
  }, [organizationName, projectName, storageUrl]);

  const getJobs = useCallback(async () => {
    if (!clusterName || !projectName) {
      return;
    }

    try {
      const { jobs } = await getRunningJobs({
        clusterName,
        organizationName,
        projectName,
        username,
        isJustCurrentUser: true,
      });

      const runningJobs = jobs.map((job) => {
        const DELIMITER = 'target:';
        const { name, tags = [] } = job;
        const tag = tags.find((tag) => tag.includes(DELIMITER)) ?? '';
        const [, targetName = name] = tag.split(DELIMITER);

        return {
          ...job,
          targetName,
        };
      });

      setRunningJob(runningJobs);
    } catch (error) {
      toastifyResponseError(error);
    }
  }, [clusterName, organizationName, projectName, username]);

  useEffect(() => {
    const INTERVAL_TIMEOUT = 15_000;

    getJobs();
    getDedicatedApps();

    const intervalId = setInterval(() => {
      getJobs();
      getDedicatedApps();
    }, INTERVAL_TIMEOUT);

    return () => {
      clearInterval(intervalId);
    };
  }, [getJobs, getDedicatedApps]);

  return (
    <Routes>
      <Route element={<Page.Apps title={title} />}>
        <Route
          index
          element={
            <Page.AppsOutlet
              runningJobs={runningJobs}
              installedDedicatedApps={installedDedicatedApps}
              setTitle={setTitle}
            />
          }
        />
        <Route
          path="/"
          element={
            <Page.AppsOutlet
              runningJobs={runningJobs}
              installedDedicatedApps={installedDedicatedApps}
              setTitle={setTitle}
            />
          }
        />
        <Route
          path="installed/:appName?"
          element={
            <Page.AppsInstalled
              runningJobs={runningJobs}
              installedDedicatedApps={installedDedicatedApps}
              getJobs={getJobs}
              setTitle={setTitle}
            />
          }
        />
        <Route path="*" element={<Navigate replace to={PATH.APPS} />} />
      </Route>
    </Routes>
  );
};
