import { FieldErrors } from 'react-hook-form';

type NormalizeFormErrors = <Fields extends string>(
  errors?: FieldErrors,
) => { [key in Fields]?: string };

/**
 * Formatting react-hook-form form state errors
 * for best practice using with InputError
 *
 * @example normalizeFormErrors<"email" | "password">(formState.errors);
 * // return { email?: string; password?: string }
 */
export const normalizeFormErrors: NormalizeFormErrors = (errors = {}) =>
  Object.entries(errors).reduce(
    /**
     * todo: resolve type
     */
    // @ts-ignore
    (collection, [name, { message, types }]) => ({
      ...collection,
      [name]: message || types?.message,
    }),
    {},
  );
