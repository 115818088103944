import { useEffect, useRef, useState } from 'react';
import { z } from 'zod';
import { faEnvelope, faUser } from '@fortawesome/pro-regular-svg-icons';
import { faEnvelope as faEnvelopeThin } from '@fortawesome/pro-thin-svg-icons';
import clsx from 'clsx';

import { AsyncFunction, ModalProps, TenantRole } from '@typings';
import { axios, toast } from '@features';
import { inviteUserToOrganization } from '@services';
import { configSelector, contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import { capitalize, invariant, noop, toastifyResponseError } from '@utils';

import { Button, Field, Icon, Modal } from '@components';
import { EmptyContent } from '@components/Ui';

type User = {
  name: string;
  email: string;
};

const emailSchema = z.string().email();

type Props = ModalProps & {
  getInvitedUsers: AsyncFunction;
};

export const OrganizationInviteMemberModal = ({
  getInvitedUsers,
  closeModal = noop,
}: Props) => {
  const { adminUrl } = useSelector(configSelector);
  const { organizationName } = useSelector(contextNamesSelector);

  const roleRef = useRef<HTMLSelectElement>(null);
  const [loading, setLoading] = useState(true);
  const [email, setEmail] = useState('');
  const [input, setInput] = useState('');
  const [users, setUsers] = useState<User[]>([]);

  const roleOptions = Object.values(TenantRole).map((role) => ({
    value: role,
    text: capitalize(role),
  }));

  const filteredUsers = users.filter(({ name, email }) =>
    [name, email].some((field) => field.startsWith(input)),
  );

  const isAtLeastUserDetected = filteredUsers.length > 0;
  const isInputEmail = emailSchema.safeParse(input).success;
  const isUserToEmail = isInputEmail && !isAtLeastUserDetected;

  useEffect(() => {
    const getUsers = async () => {
      try {
        const users = await axios.get<never, User[]>(`${adminUrl}/users`, {
          params: { lookup: true },
        });

        setUsers(users);
      } catch (error) {
        toast.error('Could not fetch user list');
      } finally {
        setLoading(false);
      }
    };

    getUsers();
  }, [adminUrl]);

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
    setEmail('');
  };

  const handleUserInvite = async () => {
    try {
      setLoading(true);

      invariant(organizationName);

      const role = roleRef.current?.value as TenantRole;

      await inviteUserToOrganization({
        organizationName,
        email,
        role,
      });
      await getInvitedUsers();

      toast.success(
        `User ${email} invited to ${organizationName} organization`,
      );

      closeModal();
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  const makeUser = ({ name, email: userEmail }: User) => {
    const icon = isInputEmail ? faEnvelope : faUser;
    const isSelected = userEmail === email;

    return (
      <Button
        key={name}
        theme
        className={clsx(
          'flex items-center gap-3 rounded-md p-2 transition-colors hover:bg-background',
          { 'bg-background': isSelected },
        )}
        onClick={() => setEmail(userEmail)}
      >
        <Icon
          icon={icon}
          className={clsx(
            'h-10 w-10 overflow-hidden rounded-full bg-stroke text-neural-04',
            { 'bg-primary-light text-white': isSelected },
          )}
        />
        <div className="text-left">
          <p>{name}</p>
          <p className="text-caption text-neural-04 transition-colors">
            {userEmail}
          </p>
        </div>
      </Button>
    );
  };

  const renderUsers = () => {
    if (input.length < 2) {
      return null;
    }

    if (isUserToEmail) {
      return makeUser({ name: 'Invite user', email: input });
    }

    if (!isAtLeastUserDetected) {
      return (
        <EmptyContent
          className="py-6"
          icon={faEnvelopeThin}
          title="No users found on platform"
          text="You can invite user by sending email"
        />
      );
    }

    return filteredUsers.map(makeUser);
  };

  return (
    <Modal.Content title="Invite Member" className="w-[578px]">
      <p className="text-footnote text-neural-04">
        Invite a user to
        <span className="space-both text-body text-black">
          {organizationName}
        </span>
        organization. For user that does not exist, enter his email to send them
        an invitation
      </p>
      <div className="mt-3 flex gap-4">
        <Field.Input
          value={input}
          containerClassName="flex-1"
          label="Username / Email"
          note="Search by name or email"
          onChange={handleUsernameChange}
        />
        <Field.Select
          ref={roleRef}
          label="Role"
          options={roleOptions}
          containerClassName="basis-[140px]"
        />
      </div>
      <div className="mt-6 flex h-[240px] flex-col gap-4 overflow-auto">
        {renderUsers()}
      </div>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          icon={faEnvelope}
          disabled={!email}
          loading={loading}
          className="pl-6 pr-8"
          onClick={handleUserInvite}
        >
          Send invite
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
