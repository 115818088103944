import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { AnyFunction } from '@typings';
import { cn, getSlots } from '@utils';

import { Button, Icon, Render, Slot } from '@components';

type Props = {
  isActive?: boolean;
  title?: string;
  icon?: IconProp;
  children?: React.ReactNode;
  onClick?: AnyFunction;
  onDoubleClick?: AnyFunction;
};

export const ListItemGrid = ({
  isActive,
  title,
  icon,
  children,
  onClick,
  onDoubleClick,
}: Props) => {
  const slots = getSlots(children);

  return (
    <Button
      theme
      className={cn(
        'flex h-56 w-56 flex-col items-center justify-center gap-4 rounded-xl border border-neural-02 bg-white p-4 transition-colors hover:border-rebecca',
        { 'border-black': isActive },
      )}
      onClick={onClick}
      onDoubleClick={onDoubleClick}
    >
      <Slot slot={slots.icon}>
        <Icon icon={icon} className="h-14 w-14 text-[48px] text-secondary" />
      </Slot>
      <Slot slot={slots.title}>
        <Render if={title}>
          <p className="text-h6 font-400">{title}</p>
        </Render>
      </Slot>
    </Button>
  );
};
