import { useState } from 'react';
import { FileWithPath, useDropzone } from 'react-dropzone';

import { uploadStorageFiles } from '@services';
import { clusterContextSelector } from '@selectors';
import { useSelector } from '@hooks';
import { cn, toastifyResponseError } from '@utils';

import { ChaseSpinner, Render } from '@components';

type Props = {
  isReadOnly: boolean;
  currentPath: string;
  children: React.ReactNode;
  getStorage: (path: string) => Promise<void>;
};

export const StorageUploadArea = ({
  isReadOnly,
  currentPath,
  children,
  getStorage,
}: Props) => {
  const cluster = useSelector(clusterContextSelector);

  const [loading, setLoading] = useState(false);

  const { storageUrl = '' } = cluster ?? {};

  const handleFilesUpload = async (files: FileWithPath[]) => {
    setLoading(true);

    try {
      await uploadStorageFiles({ storageUrl, storagePath: currentPath, files });
      setLoading(false);
      await getStorage(currentPath);
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    noClick: true,
    noKeyboard: true,
    onDrop: handleFilesUpload,
  });

  return (
    <div
      {...getRootProps({
        className: cn(
          'relative border-2 border-transparent transition-colors',
          {
            'border-primary': isDragAccept,
          },
        ),
      })}
    >
      <Render if={loading}>
        <div className="absolute inset-0 z-30 flex items-center justify-center rounded-lg bg-neural-02/50">
          <ChaseSpinner color="black" className="h-16 w-16" />
        </div>
      </Render>
      <input {...getInputProps()} />
      <Render if={!isReadOnly}>
        <p className="mb-4 text-neural-04">Drag and drop your files here</p>
      </Render>
      {children}
    </div>
  );
};
