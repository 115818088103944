import { useContext, useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';
import { faCoins } from '@fortawesome/pro-solid-svg-icons';

import { Any } from '@typings';
import { toast } from '@features';
import { userCreditsSelector } from '@selectors';
import { useSelector } from '@hooks';
import { isString } from '@utils';

import { ChaseSpinner, Icon, Modal } from '@components';
import { BuyCreditsModal } from '@components/Modals';
import { OrganizationCreditsContext } from '@components/Providers';

import { UserPanelButton } from './UserPanelButton';

export const UserPanelCredits = () => {
  const userCredits = useSelector(userCreditsSelector);

  const { organizationCredits, loading: organizationCreditsLoading } =
    useContext(OrganizationCreditsContext);

  const [searchParams] = useSearchParams();

  const isUserBalanceUnlimited = userCredits === 'unlimited';
  const isUserBalance =
    organizationCredits === null ||
    (organizationCredits !== 'unlimited' && !isUserBalanceUnlimited) ||
    !isUserBalanceUnlimited;
  const balance = isUserBalance ? userCredits : organizationCredits;

  const canBuyCredits = (() => {
    if (organizationCredits === null) {
      return !isUserBalanceUnlimited;
    }
    /**
     * Any user can buy credits for selected organization
     * If user credits are limited inside org then user cannot buy credits
     */
    return organizationCredits !== 'unlimited' && isUserBalanceUnlimited;
  })();

  const buyCreditsIconValue = canBuyCredits ? undefined : null;

  const getFormattedBalance = (): Any => {
    const isDotted = isString(balance) && balance.includes('.');

    if (!isDotted) {
      return balance;
    }

    const dotIndex = balance.indexOf('.');

    if (dotIndex !== -1) {
      const beforeDot = balance.substring(0, dotIndex);
      const afterDot = balance.substring(dotIndex + 1);

      const formattedAfterDot = afterDot.substring(0, 2);

      return `${beforeDot}.${formattedAfterDot}`;
    }
  };

  useEffect(() => {
    const buyCreditsStatus = searchParams.get('buy-credits-status');
    const credits = searchParams.get('credits');

    if (!buyCreditsStatus) {
      return;
    }

    if (buyCreditsStatus === 'success' && credits) {
      toast.success(
        `The purchase of ${credits} credits was successful. Credits will be added shortly`,
      );
    }

    if (buyCreditsStatus === 'failure') {
      toast.error('Something went wrong when buying credits');
    }
  }, [searchParams]);

  if (organizationCreditsLoading) {
    return (
      <div className="px-3">
        <ChaseSpinner color="secondary" className="h-5 w-5" />
      </div>
    );
  }

  return (
    <Modal
      disabled={!canBuyCredits}
      content={
        <BuyCreditsModal isUserBalance={isUserBalance} balance={balance} />
      }
    >
      <UserPanelButton
        icon={buyCreditsIconValue}
        disabled={!canBuyCredits}
        className="flex min-w-[180px] items-center gap-2 text-white"
      >
        <Icon icon={faCoins} className="h-10 w-10 rounded-md bg-success-dark" />
        <div className="min-w-0 flex-1 text-left text-white">
          <p className="text-body-small text-neural-03">Credits</p>
          <p className="truncate capitalize">{getFormattedBalance()}</p>
        </div>
      </UserPanelButton>
    </Modal>
  );
};
