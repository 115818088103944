import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { zodResolver } from '@hookform/resolvers/zod';
import { customAlphabet } from 'nanoid';
import { lowercase } from 'nanoid-dictionary';
import { z } from 'zod';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';
import { faMessageXmark } from '@fortawesome/pro-thin-svg-icons';

import { DEDICATED_APP_NAME, IS_LOCAL, PATH } from '@constants';
import { toast } from '@features';
import { createJob } from '@services';
import { contextNamesSelector } from '@selectors';
import { useHelmetTitle, useSelector } from '@hooks';
import { usePowerlessResourcePresetName } from '@hooks/job';
import {
  AppCommand,
  as,
  formatModelName,
  getInstallingAppImage,
  normalizeFormErrors,
  toastifyResponseError,
} from '@utils';
import { dedicatedApps } from '@content';

import { Button, Field, Helmet, Icon, Link, Modal, Theme } from '@components';
import {
  AppConstructorArguments,
  JobConstructorNavigator,
  JobConstructorSection,
  JobPresetField,
} from '@components/Job';
import { Layout } from '@components/Layouts';
import { AppConstructorNavigationProvider } from '@components/Providers';
import { EmptyContent } from '@components/Ui';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  presetName: z.string().min(1),
  modelName: z.string().min(1),
  tokenizerName: z.string().min(1),
  name: z.string(),
  ingressEnabled: z.boolean().optional(),
  args: z
    .object({
      value: z.string(),
    })
    .array(),
});

export const LlmInferenceConstructorPage = () => {
  const APP_TYPE = DEDICATED_APP_NAME.LLM_INFERENCE;
  const CHART = IS_LOCAL
    ? 'https://github.com/helm/examples.git'
    : 'https://github.com/neuro-inc/app-llm-inference.git';

  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);

  const { makeTitle } = useHelmetTitle();
  const navigate = useNavigate();
  const methods = useForm<Schema>({
    resolver: zodResolver(schema),
    defaultValues: {
      args: [],
    },
  });

  const [loading, setLoading] = useState(false);

  const { register, formState, handleSubmit } = methods;

  const appName = DEDICATED_APP_NAME.LLM_INFERENCE;
  const {
    name,
    title,
    jobResourcePreset,
    tags = [],
    image,
  } = dedicatedApps.find(({ name }) => name === appName)!;
  const staticTags = [...tags, 'kind:web-widget', `target:${name}`];
  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const { powerlessPresetName } = usePowerlessResourcePresetName({
    resourcePreset: jobResourcePreset,
    appName,
  });

  const handleAppInstall = handleSubmit(
    async ({
      name,
      modelName,
      tokenizerName,
      presetName,
      ingressEnabled,
      args,
    }) => {
      try {
        setLoading(true);

        const isIngressEnabled = ingressEnabled ? 'True' : 'False';
        const formattedName = name
          ? formatModelName(name)
          : `${appName}-${customAlphabet(lowercase, 8)()}`;

        const appCommand = new AppCommand();

        args.forEach(({ value }) => {
          appCommand.arg(value);
        });

        const command = appCommand
          .construct(
            `install ${CHART} ${APP_TYPE} ${formattedName} charts/llm-inference-app --timeout 15m0s`,
          )
          .set('preset_name', presetName)
          .set('ingress.enabled', isIngressEnabled)
          .set('ingress.clusterName', clusterName!)
          .set('llm.modelHFName', modelName)
          .set('llm.tokenizerHFName', tokenizerName)
          .compose();

        await createJob({
          organizationName,
          clusterName: clusterName!,
          projectName: projectName!,
          command,
          presetName: powerlessPresetName,
          image: getInstallingAppImage(image),
          name: `${formattedName}-install`,
          passConfig: true,
          tags: [...staticTags, ...tags],
          env: {},
          secretEnv: {},
          volumes: [],
        });

        toast.success(`Installing ${title} App`);

        navigate(PATH.APPS, { replace: true });
      } catch (error) {
        toastifyResponseError(error);
      } finally {
        setLoading(false);
      }
    },
  );

  const header = (
    <div slot="header" className="flex min-w-0 items-center gap-4">
      <Helmet title={makeTitle(`Install ${title}`, 'Apps', '%p', '%c')} />
      <Link
        variant="ghost"
        to={PATH.APPS}
        className="h-auto p-0 text-[24px] text-neural-03"
      >
        <Icon icon={faChevronLeft} className="h-10 w-10" />
      </Link>
      <h3 className="truncate text-h4 text-white">
        Install {as(title, appName)} App
      </h3>
    </div>
  );

  if (!name) {
    return (
      <Layout>
        {header}
        <EmptyContent
          variant="layout"
          icon={faMessageXmark}
          title="App is unavailable"
          text="The app is currently temporarily unavailable. Please return to the apps to try again"
        >
          <Link to={PATH.APPS}>Return to Apps</Link>
        </EmptyContent>
      </Layout>
    );
  }

  return (
    <Layout>
      {header}
      <Layout.Content className="flex gap-10">
        <AppConstructorNavigationProvider>
          <JobConstructorNavigator />
          <FormProvider {...methods}>
            <form
              className="flex flex-1 justify-center"
              onSubmit={handleAppInstall}
            >
              <Theme.Container className="flex w-full max-w-[720px] flex-col gap-20 pb-6">
                <JobConstructorSection name="resources">
                  <JobPresetField error={errors.presetName} />
                </JobConstructorSection>
                <JobConstructorSection name="configuration">
                  <Field.Input
                    {...register('modelName')}
                    required
                    label="Model Name"
                    className="w-full"
                    error={errors.modelName}
                  />
                  <Field.Input
                    {...register('tokenizerName')}
                    required
                    label="Tokenizer Name"
                    className="w-full"
                    error={errors.tokenizerName}
                  />

                  <Field.Checkbox {...register('ingressEnabled')}>
                    Ingress Enabled
                  </Field.Checkbox>

                  <AppConstructorArguments />
                </JobConstructorSection>
                <JobConstructorSection name="metadata">
                  <Field.Input
                    {...register('name')}
                    label="Name"
                    className="w-full"
                    note="App name"
                    error={errors.name}
                  />
                </JobConstructorSection>
                <Modal.Footer sticky className="-mt-8 px-0">
                  <Button
                    type="submit"
                    loading={loading}
                    className="px-10 capitalize"
                  >
                    Install app
                  </Button>
                </Modal.Footer>
              </Theme.Container>
            </form>
          </FormProvider>
        </AppConstructorNavigationProvider>
      </Layout.Content>
    </Layout>
  );
};
