import { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import dayjs from 'dayjs';
import { faCalendar, faChevronRight } from '@fortawesome/pro-regular-svg-icons';

import { Any, Billing, UnixTimestamp } from '@typings';
import { cn, formatTimestamp } from '@utils';

import { Icon, Popover } from '@components';

import 'react-datepicker/dist/react-datepicker.css';

type DateState = {
  startDate?: Date;
  endDate?: Date;
};

type Props = {
  disabled?: boolean;
  startDate: Billing.Params['startDate'];
  endDate: Billing.Params['endDate'];
  onChange: (startDate: UnixTimestamp, endDate: UnixTimestamp) => void;
};

export const BillingDateRange = ({
  disabled,
  startDate,
  endDate,
  onChange,
}: Props) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const [date, setDate] = useState<DateState>({});

  useEffect(() => {
    setDate({
      startDate: dayjs.unix(startDate).toDate(),
      endDate: dayjs.unix(endDate).toDate(),
    });
  }, [startDate, endDate]);

  const formatUnixDate = (date: UnixTimestamp) =>
    dayjs(formatTimestamp(date)).format('L');

  const formatDate = (date: Date) => dayjs(date).unix() as UnixTimestamp;

  const handleInputPrevent = (event: React.KeyboardEvent<HTMLElement>) => {
    event.preventDefault();
  };

  const handleChange = (dates: Any) => {
    const [startDate, endDate]: [Date, Date] = dates ?? [];

    setDate({ startDate, endDate });

    if (endDate) {
      onChange(formatDate(startDate), formatDate(endDate));
      setPopoverOpen(false);
    }
  };

  return (
    <Popover
      disabled={disabled}
      showArrow={false}
      side="bottom"
      sideOffset={4}
      className="p-8"
      open={popoverOpen}
      onOpenChange={setPopoverOpen}
    >
      <div
        slot="trigger"
        className={cn(
          'flex h-12 items-center gap-4 rounded-lg bg-white px-4 py-1 text-neural-04 transition-colors',
          {
            'bg-transparent text-neural-03': disabled,
            'hover:text-rebecca': !disabled,
          },
        )}
      >
        <p>{formatUnixDate(startDate)}</p>
        <Icon icon={faChevronRight} className="text-[20px] text-neural-04" />
        <p>{formatUnixDate(endDate)}</p>
        <Icon icon={faCalendar} className="text-[20px] text-neural-04" />
      </div>
      <DatePicker
        inline
        disabledKeyboardNavigation
        selectsRange
        showPopperArrow={false}
        monthsShown={2}
        startDate={date.startDate}
        endDate={date.endDate}
        onChange={handleChange}
        maxDate={new Date()}
        onKeyDown={handleInputPrevent}
      />
    </Popover>
  );
};
