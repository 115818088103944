import { useEffect, useMemo } from 'react';
import { useOutletContext } from 'react-router-dom';
import dayjs from 'dayjs';
import {
  faBolt,
  faCalendar,
  faCircleNodes,
  faCoins,
  faFile,
  faFolder,
  faLayerGroup,
  faObjectsColumn,
} from '@fortawesome/pro-solid-svg-icons';
import { faFileMagnifyingGlass } from '@fortawesome/pro-thin-svg-icons';
import clsx from 'clsx';

import { Billing, OutletContext } from '@typings';
import { BILLING_ALL_ENTRY } from '@constants';
import { formatTimestamp } from '@utils';

import { Icon, Render, Table } from '@components';
import { EmptyContent } from '@components/Ui';

export const BillingUsagePage = () => {
  const {
    params: {
      clusterName: billingClusterName,
      projectName: billingProjectName,
      groups,
      isGroupedByProject,
    },
    billing,
    setTabTitle,
  } = useOutletContext<OutletContext.Billing>();

  const isGroupedByDay = groups.includes(Billing.Group.Day);
  const isGroupedByMonth = groups.includes(Billing.Group.Month);
  const isGroupedByDate = isGroupedByDay || isGroupedByMonth;
  const isGroupedByCategory = groups.includes(Billing.Group.Category);
  const isClusterSpecified = billingClusterName !== BILLING_ALL_ENTRY;
  const isProjectSpecified = billingProjectName !== BILLING_ALL_ENTRY;
  const showProjectColumn = isGroupedByProject && !isProjectSpecified;

  const usageWithProject = useMemo(() => {
    const normalizedBilling = billing.withProject
      .map(({ clusterName, usage }) =>
        usage.map(({ projectName, usage }) =>
          usage.map((usage) => ({
            ...usage,
            clusterName,
            projectName,
          })),
        ),
      )
      .flat(2);

    if (!isClusterSpecified) {
      return normalizedBilling;
    }

    const normalizedClusterBilling = normalizedBilling.filter(
      ({ clusterName }) => clusterName === billingClusterName,
    );

    if (!isProjectSpecified) {
      return normalizedClusterBilling;
    }

    return normalizedBilling.filter(
      ({ projectName }) => projectName === billingProjectName,
    );
  }, [
    isProjectSpecified,
    isClusterSpecified,
    billingProjectName,
    billingClusterName,
    billing.withProject,
  ]);

  const usageWithoutProject = useMemo(() => {
    const normalizedBilling = billing.withoutProject
      .map(({ clusterName, usage }) =>
        usage.map((usage) => ({ ...usage, clusterName })),
      )
      .flat();

    if (!isClusterSpecified) {
      return normalizedBilling;
    }

    return normalizedBilling.filter(
      ({ clusterName }) => clusterName === billingClusterName,
    );
  }, [isClusterSpecified, billingClusterName, billing.withoutProject]);

  const usage = isGroupedByProject ? usageWithProject : usageWithoutProject;

  const cols = (() => {
    /**
     * `amount` column is always included
     */
    const INITIAL_COLS_AMOUNT = 1;

    return [
      showProjectColumn,
      isGroupedByCategory,
      isGroupedByDate,
      !isClusterSpecified,
    ].reduce((acc, value) => acc + (value ? 1 : 0), INITIAL_COLS_AMOUNT);
  })();

  useEffect(() => {
    setTabTitle(Billing.Tab.Usage);
  }, [setTabTitle]);

  const getCategoryIcon = (categoryName: string) => {
    const icons = {
      apps: faObjectsColumn,
      jobs: faBolt,
      storage: faFile,
    };

    // @ts-expect-error todo: fix type
    return icons[categoryName] ?? faLayerGroup;
  };

  const makeRow = ({
    clusterName,
    projectName,
    credits,
    categoryName,
    date,
  }: (typeof usageWithProject)[number]) => {
    const key = `${clusterName}-${projectName}-${date}-${credits}`;
    const dateFormat = isGroupedByDay ? 'll' : 'MMM, YYYY';
    const formattedDate = dayjs(formatTimestamp(date)).format(dateFormat);

    return (
      <Table.Row key={key}>
        <Render if={isGroupedByDate}>
          <Table.Cell className="flex min-h-[56px] flex-row items-center justify-start gap-1 border-b border-t-0 border-neural-02 py-2 first:border-l">
            {formattedDate}
          </Table.Cell>
        </Render>
        <Render if={!isClusterSpecified}>
          <Table.Cell className="flex min-h-[56px] flex-row items-center justify-start gap-1 border-b border-t-0 border-neural-02 py-2 first:border-l">
            <Icon icon={faCircleNodes} className="text-neural-04" />
            <p className="truncate">{clusterName}</p>
          </Table.Cell>
        </Render>
        <Render if={showProjectColumn}>
          <Table.Cell className="flex min-h-[56px] flex-row items-center justify-start gap-1 border-b border-t-0 border-neural-02 py-2 first:border-l">
            <Icon icon={faFolder} className="text-neural-04" />
            <p className="truncate">{projectName}</p>
          </Table.Cell>
        </Render>
        <Render if={isGroupedByCategory}>
          <Table.Cell className="flex min-h-[56px] flex-row items-center justify-start gap-1 border-b border-t-0 border-neural-02 py-2 capitalize first:border-l">
            <Icon
              icon={getCategoryIcon(categoryName)}
              className="text-neural-04"
            />
            {categoryName}
          </Table.Cell>
        </Render>
        <Table.Cell className="min-h-[56px] truncate border-b border-r border-t-0 border-neural-02 py-2 text-right first:border-l">
          {credits}
        </Table.Cell>
      </Table.Row>
    );
  };

  if (!usage.length) {
    return (
      <EmptyContent
        className="mt-20 px-10 py-6"
        icon={faFileMagnifyingGlass}
        title="No records for this time period"
      >
        <p slot="text" className="m-auto max-w-[320px]">
          Try adjusting your filters or check back later for updated results
        </p>
      </EmptyContent>
    );
  }

  return (
    <div className="px-10 py-6">
      <Table
        className={clsx(
          'w-full auto-cols-auto overflow-auto border-neural-02',
          {
            'grid-cols-1': cols === 1,
            'grid-cols-2': cols === 2,
            'grid-cols-3': cols === 3,
            'grid-cols-4': cols === 4,
            'grid-cols-5': cols === 5,
            'grid-cols-6': cols === 6,
          },
        )}
      >
        <Table.Header>
          <Table.Row>
            <Render if={isGroupedByDate}>
              <Table.Head className="flex items-center gap-1 border-y border-neural-02 py-3.5 first:rounded-tl-lg first:border-l">
                <Icon icon={faCalendar} className="text-primary-dark" />
                Date
              </Table.Head>
            </Render>
            <Render if={!isClusterSpecified}>
              <Table.Head className="flex items-center gap-1 border-y border-neural-02 py-3.5 first:rounded-tl-lg first:border-l">
                <Icon icon={faCircleNodes} className="text-primary-dark" />
                Cluster
              </Table.Head>
            </Render>
            <Render if={showProjectColumn}>
              <Table.Head className="flex items-center gap-1 border-y border-neural-02 py-3.5 first:rounded-tl-lg first:border-l">
                <Icon icon={faFolder} className="text-primary-dark" />
                Project
              </Table.Head>
            </Render>
            <Render if={isGroupedByCategory}>
              <Table.Head className="flex items-center gap-1 border-y border-neural-02 py-3.5 first:rounded-tl-lg first:border-l">
                <Icon icon={faLayerGroup} className="text-primary-dark" />
                Category
              </Table.Head>
            </Render>
            <Table.Head className="flex items-center justify-end gap-1 rounded-tr-lg border-y border-r border-neural-02 py-3.5 text-right first:rounded-tl-lg first:border-l">
              <Icon icon={faCoins} className="text-primary-dark" />
              Amount
            </Table.Head>
          </Table.Row>
        </Table.Header>
        <Table.Body className="[&>tr:last-of-type>td:first-child]:rounded-bl-lg [&>tr:last-of-type>td:last-child]:rounded-br-lg">
          {(usage as typeof usageWithProject).map(makeRow)}
        </Table.Body>
      </Table>
    </div>
  );
};
