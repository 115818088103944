import { Bucket, ModalProps } from '@typings';

import { Link, Modal, Render } from '@components';

type Props = ModalProps & {
  credentials: Bucket.PersistentCredentials;
};

export const BucketCredentialsModal = ({
  credentials: {
    id,
    name,
    owner,
    readOnly,
    credentials: [{ provider, credentials }],
  },
}: Props) => {
  const { keyData } = credentials as Bucket.CredentialsGcp;
  const { sasToken, storageEndpoint } = credentials as Bucket.CredentialsAzure;
  const { accessKeyId, bucketName, endpointUrl, regionName, secretAccessKey } =
    credentials as Bucket.CredentialsModel;

  return (
    <Modal.Content title="Bucket Credentials" className="w-[762px] pb-8">
      <div className="flex flex-col gap-8">
        <div>
          <p className="text-caption capitalize text-neural-03">Owner</p>
          <p>{owner}</p>
        </div>
        <div>
          <p className="text-caption capitalize text-neural-03">ID</p>
          <p>{id}</p>
        </div>
        <Render if={name}>
          <div>
            <p className="text-caption capitalize text-neural-03">Name</p>
            <p>{name}</p>
          </div>
        </Render>
        <div>
          <p className="text-caption capitalize text-neural-03">Read-only?</p>
          <p>{readOnly ? 'Yes' : 'No'}</p>
        </div>
        <Render if={accessKeyId}>
          <div>
            <p className="text-caption capitalize text-neural-03">
              Access Key ID
            </p>
            <p className="truncate">{accessKeyId}</p>
          </div>
        </Render>
        <Render if={secretAccessKey}>
          <div>
            <p className="text-caption capitalize text-neural-03">
              Secret access key
            </p>
            <p className="truncate">{secretAccessKey}</p>
          </div>
        </Render>
        <Render if={sasToken}>
          <div>
            <p className="text-caption capitalize text-neural-03">SAS token</p>
            <p className="truncate">{sasToken}</p>
          </div>
        </Render>
        <Render if={bucketName}>
          <div>
            <p className="text-caption capitalize text-neural-03">
              Bucket name
            </p>
            <p className="truncate">{bucketName}</p>
          </div>
        </Render>
        <Render if={keyData}>
          <div>
            <p className="text-caption capitalize text-neural-03">Key data</p>
            <p className="truncate">{keyData}</p>
          </div>
        </Render>
        <Render if={endpointUrl}>
          <div>
            <p className="text-caption capitalize text-neural-03">
              Endpoint URL
            </p>
            <Link
              theme
              blank
              external
              className="text-primary underline"
              to={endpointUrl}
            >
              {endpointUrl}
            </Link>
          </div>
        </Render>
        <Render if={storageEndpoint}>
          <div>
            <p className="text-caption capitalize text-neural-03">
              Storage URL
            </p>
            <Link
              theme
              blank
              external
              className="text-primary underline"
              to={storageEndpoint}
            >
              {storageEndpoint}
            </Link>
          </div>
        </Render>
        <Render if={regionName}>
          <div>
            <p className="text-caption capitalize text-neural-03">Region</p>
            <p className="truncate">{regionName}</p>
          </div>
        </Render>
        <div>
          <p className="text-caption capitalize text-neural-03">Provider</p>
          <p>{provider}</p>
        </div>
      </div>
    </Modal.Content>
  );
};
