import { Modal } from '@components';
import { Layout } from '@components/Layouts';
import { UserAgreeTermsModal } from '@components/Modals';

export const UserAgreeTermsPage = () => {
  return (
    <Layout showFeatures={false} className="flex justify-center">
      <Modal
        defaultOpen
        preventOutsideClick
        showCloseButton={false}
        content={<UserAgreeTermsModal />}
      />
    </Layout>
  );
};
