import { useLocation } from 'react-router-dom';

import { ClusterSettings } from '@typings';
import { cn, ensureArray } from '@utils';
import { settingsTabs } from '@content';

import { Link } from '@components';

export const BillingTabs = () => {
  const { pathname } = useLocation();

  const makeTab = ({ path, title }: ClusterSettings.Tab) => {
    const urls = ensureArray(path);
    const isActive = urls.includes(pathname);
    const url = urls[0];

    return (
      <Link
        theme
        key={url}
        className={cn(
          'block border-b-2 border-transparent pb-4 pt-2 text-h6 capitalize text-neural-04 transition-colors hover:text-rebecca',
          { 'border-primary text-rebecca': isActive },
        )}
        to={url}
      >
        {title}
      </Link>
    );
  };

  return (
    <header className="flex gap-10 border-b border-neural-01 px-10 pt-6">
      {settingsTabs.billing.map(makeTab)}
    </header>
  );
};
