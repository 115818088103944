import { Render, Table } from '@components';
import { Icons } from '@components/Ui';

type Props = {
  mlflow?: string;
};

export const JobDetailsIntergrations = ({ mlflow }: Props) => {
  const integrations = [mlflow].filter(Boolean);

  if (integrations.length === 0) {
    return <p>-</p>;
  }

  return (
    <Table className="grid grid-cols-2 gap-x-8 gap-y-2">
      <Table.Body>
        <Render if={mlflow}>
          <Table.Row>
            <Table.Cell className="h-auto flex-row justify-start gap-2 truncate border-none p-0">
              <Icons.Mlflow />
              <p>MLFlow</p>
            </Table.Cell>
            <Table.Cell className="h-auto flex-row justify-start gap-2 truncate border-none p-0">
              <p className="truncate" title={mlflow}>
                {mlflow}
              </p>
            </Table.Cell>
          </Table.Row>
        </Render>
      </Table.Body>
    </Table>
  );
};
