import { faMicrochip } from '@fortawesome/pro-regular-svg-icons';
import { faRectangleTerminal } from '@fortawesome/pro-solid-svg-icons';

import { Job } from '@typings';
import { as } from '@utils';
import { dedicatedApps } from '@content';

import { Button, Icon, Link, Render } from '@components';

type Props = Job.Running & {
  killingAppId?: string;
  onJobKill: (id: string) => Promise<void>;
};

export const AppInstalledItem = ({
  killingAppId,
  id,
  name,
  targetName,
  tags,
  presetName,
  httpUrl,
  httpUrlNamed,
  history: { status },
  onJobKill,
}: Props) => {
  const loading = killingAppId === id;
  const { logo } = as.o<Job.Item>(
    dedicatedApps.find(({ name }) => name === targetName),
  );

  const makeTag = (tag: string) => (
    <p
      key={tag}
      className="flex h-[22px] items-center rounded-2xl bg-neural-01 px-2 text-footnote text-black"
    >
      {tag}
    </p>
  );

  return (
    <div className="relative">
      <div className="group peer relative z-20 flex min-h-[160px] gap-6 rounded-xl border border-transparent bg-white p-6 transition-colors hover:border-neural-02">
        <div className="flex h-14 w-14 shrink-0 items-center justify-center rounded-lg bg-rebecca p-2 text-[28px] text-white">
          <Render if={!logo}>
            <Icon
              icon={faRectangleTerminal}
              className="h-14 w-14 text-[36px]"
            />
          </Render>
          <Render if={logo}>
            <img src={logo} alt="" />
          </Render>
        </div>
        <div className="relative flex min-w-0 flex-1 flex-col gap-3">
          <div className="flex items-center gap-4">
            <p className="mr-auto truncate text-h6 text-black">{name}</p>
            <p className="flex h-[22px] items-center rounded-2xl bg-primary-subtle px-2 text-footnote text-black">
              Installed
            </p>
          </div>
          <div className="flex gap-6 overflow-hidden">
            <div className="flex flex-col gap-1">
              <p className="text-footnote capitalize text-neural-04">
                Preset name
              </p>
              <p className="flex items-center gap-2 truncate">
                <Icon icon={faMicrochip} />
                {presetName}
              </p>
            </div>
            <div className="flex flex-col gap-1">
              <p className="text-footnote capitalize text-neural-04">Status</p>
              <p className="flex items-center gap-2 truncate capitalize">
                {status}
              </p>
            </div>
          </div>
          <Render if={tags}>
            <div className="flex gap-x-4 gap-y-2">{tags?.map(makeTag)}</div>
          </Render>
          <div className="absolute inset-x-0 bottom-0 flex justify-end gap-3 bg-white opacity-0 transition-opacity before:absolute before:bottom-full before:h-full before:w-full before:bg-gradient-to-t before:from-white before:to-transparent group-hover:opacity-100">
            <Button
              variant="ghost-error"
              loading={loading}
              onClick={() => onJobKill(id)}
            >
              Uninstall
            </Button>
            <Link external blank to={httpUrlNamed || httpUrl}>
              Open App
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};
