import { faGrid2, faTableList } from '@fortawesome/pro-solid-svg-icons';

import { ViewType } from '@typings';
import { setViewType } from '@slices';
import { viewTypeSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import { cn } from '@utils';

import { Button, Icon } from '@components';

const BUTTONS = [
  {
    icon: faGrid2,
    viewType: ViewType.Grid,
  },
  {
    icon: faTableList,
    viewType: ViewType.List,
  },
];

export const AppsViewType = () => {
  const dispatch = useDispatch();
  const { viewType: activeViewType } = useSelector(viewTypeSelector);

  const handleViewChange = (viewType: ViewType) => {
    dispatch(setViewType(viewType));
  };

  const makeButton = ({ icon, viewType }: (typeof BUTTONS)[number]) => (
    <Button
      theme
      key={viewType}
      className={cn(
        'flex h-12 w-12 items-center justify-center rounded-lg text-[22px] text-neural-04 transition-colors hover:text-black',
        { 'bg-neural-03 text-black': viewType === activeViewType },
      )}
      onClick={() => handleViewChange(viewType)}
    >
      <Icon icon={icon} />
    </Button>
  );

  return <div className="flex gap-4">{BUTTONS.map(makeButton)}</div>;
};
