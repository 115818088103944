import axios, { RawAxiosRequestHeaders } from 'axios';
import base64 from 'base-64';
import LinkHeader from 'http-link-header';

import { Image, Params } from '@typings';
import { JWT_TOKEN } from '@constants';
import { localStorage } from '@utils';

type GetImagesResponse = { repositories: Image.Name[] };

const getAuthorizationHeaders = (username: string): RawAxiosRequestHeaders => {
  const token = localStorage.get(JWT_TOKEN);
  const authorizationToken = base64.encode(`${username}:${token}`);

  return {
    Authorization: `Basic ${authorizationToken}`,
  };
};

export const getImages = async (registryUrl: string, username: string) => {
  const url = `${registryUrl}/v2/_catalog`;
  const headers = getAuthorizationHeaders(username);

  const {
    headers: { link },
    data: { repositories },
  } = await axios.get<GetImagesResponse>(url, { headers });

  const nextLink = LinkHeader.parse(link || '').get('rel', 'next')?.[0];

  if (nextLink) {
    const {
      data: { repositories: nextRepositories },
    } = await axios.get<GetImagesResponse>(nextLink.uri, { headers });

    return [...repositories, ...nextRepositories];
  }

  return repositories;
};

export const getImageTags = async ({
  registryUrl,
  imageName,
  username,
}: Params.GetImageTags) => {
  const url = `${registryUrl}/v2/${imageName}/tags/list`;
  const headers = getAuthorizationHeaders(username);

  const { data: image } = await axios.get<Image.Model>(url, { headers });

  return image;
};
