import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Storage } from '@typings';
import { PATH } from '@constants';
import { toast } from '@features';
import { getStorageList, getStorageStatus } from '@services';
import { clusterContextSelector, contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import { as, path } from '@utils';

type Options = Partial<{
  initialPath: string;
  rootDirectory: string;
  source: 'pathname' | 'state';
}>;

export const useStorage = ({
  initialPath = PATH.STORAGE,
  rootDirectory = '/',
  source = 'pathname',
}: Options = {}): {
  loading: boolean;
  isReadOnly: boolean;
  isRoot: boolean;
  currentPath: string;
  objects: Storage.FileStatus[];
  changePath: (newPath?: string) => void;
  changePathToHome: () => void;
  folderUp: () => void;
  fetchStorage: (path: string) => Promise<void>;
} => {
  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);
  const cluster = useSelector(clusterContextSelector);

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const isPathnameSource = source === 'pathname';

  const [currentPath, setCurrentPath] = useState('');
  const [loading, setLoading] = useState(false);
  const [objects, setObjects] = useState<Storage.FileStatus[]>([]);
  const [isReadOnly, setReadOnly] = useState(true);

  const isInitialPathDefault = initialPath === PATH.STORAGE;
  const homePath =
    !isPathnameSource && !isInitialPathDefault
      ? initialPath
      : path.create(organizationName, projectName);
  const { storageUrl } = as.c(cluster);

  const fetchStorage = useCallback(
    async (path: string) => {
      setLoading(true);

      try {
        const directory = await getStorageStatus({
          storageUrl,
          storagePath: path,
        });
        const objects = await getStorageList({
          storageUrl,
          storagePath: path,
        });

        if (objects) {
          setObjects(objects);
        }
        setReadOnly(directory.permission === Storage.Permission.Read);
      } catch (error) {
        toast.error(error as string);
      } finally {
        setLoading(false);
      }
    },
    [storageUrl],
  );

  useEffect(() => {
    if (clusterName && currentPath && projectName) {
      fetchStorage(currentPath);
    }
  }, [projectName, currentPath, clusterName, fetchStorage]);

  /**
   * `currentPath` initialization
   */
  useEffect(() => {
    if (clusterName) {
      const path = pathname.replace(PATH.STORAGE, '') || homePath;

      setCurrentPath(isPathnameSource ? path : homePath);
    }
    /**
     * Excludes `pathname` on path initialization
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPathnameSource, homePath, clusterName]);

  /**
   * Replaces PATH.STORAGE pathname with home path
   */
  useEffect(() => {
    const isStorageInitialPath = !pathname.replace(PATH.STORAGE, '');

    if (isPathnameSource && isStorageInitialPath) {
      navigate(path.create(PATH.STORAGE, homePath), { replace: true });
    }
  }, [isPathnameSource, homePath, pathname, navigate]);

  const handlePathChange = useCallback(
    (newPath?: string) => {
      const formattedNewPath = newPath === initialPath ? '' : newPath;
      const formattedPath = path.create(newPath);

      setCurrentPath(formattedPath);

      if (isPathnameSource) {
        navigate(path.create(PATH.STORAGE, formattedNewPath));
      }
    },
    [isPathnameSource, initialPath, navigate],
  );

  const handleFolderUp = useCallback(() => {
    handlePathChange(
      path.create(currentPath.split('/').slice(1, -1).join('/')),
    );
  }, [currentPath, handlePathChange]);

  const handleHomePathChange = useCallback(() => {
    handlePathChange(homePath);
  }, [homePath, handlePathChange]);

  return {
    loading,
    isReadOnly,
    isRoot: currentPath === rootDirectory,
    currentPath,
    objects,
    fetchStorage,
    changePath: handlePathChange,
    changePathToHome: handleHomePathChange,
    folderUp: handleFolderUp,
  };
};
