import { createSelector } from 'reselect';

import { State } from '@typings';
import { NO_ORGANIZATION } from '@constants';

import { configSelector } from './config.selectors';

export const contextSelector = (state: State) => state.context;

export const organizationContextSelector = createSelector(
  contextSelector,
  ({ organization = null }) => organization,
);

export const clusterContextSelector = createSelector(
  contextSelector,
  ({ cluster = null }) => cluster,
);

export const projectContextSelector = createSelector(
  contextSelector,
  ({ project = null }) => project,
);

export const contextNamesSelector = createSelector(
  contextSelector,
  ({ project = null, cluster, organization }) => ({
    clusterName: cluster?.name,
    organizationName: organization?.name ?? NO_ORGANIZATION,
    projectName: project?.name,
  }),
);

export const organizationClustersSelector = createSelector(
  contextNamesSelector,
  configSelector,
  ({ organizationName }, { clusters = [] }) =>
    clusters.filter(({ orgs: organizations }) =>
      organizations.includes(organizationName),
    ),
);

export const areClustersPresentSelector = createSelector(
  organizationClustersSelector,
  (organizationClusters) => !!organizationClusters.length,
);
