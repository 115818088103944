import { isArray, isObject } from './typeof';

export type BackendValidationErrors = Array<{
  field: string;
  message: string;
}>;

/**
 * todo: fix types
 */
export const setFormBackendErrors = (
  // @ts-ignore
  errors,
  // @ts-ignore
  { getValues, setError },
  options = {},
) => {
  // @ts-ignore
  const { replace = [] } = options;

  if (!errors || !isObject(errors)) {
    return;
  }

  const values = getValues();
  // @ts-ignore
  Object.entries(errors).forEach(([field, message]) => {
    const [, fieldToReplace] =
      replace.find(([source]: [string]) => source === field) ?? [];

    const formattedMessage = isArray(message) ? message[0] : message;

    if (fieldToReplace || Object.prototype.hasOwnProperty.call(values, field)) {
      setError(fieldToReplace ?? field, { message: formattedMessage });
    }
  });
};
