import { createContext, useCallback, useMemo } from 'react';
import { useImmer } from 'use-immer';

import { noop } from '@utils';
import { appConstructorNavigation } from '@content';

type AppConstructorNavigation = keyof typeof appConstructorNavigation;

export const AppConstructorNavigationContext = createContext<{
  navigation: AppConstructorNavigation[];
  registerNavigation: (navigation: AppConstructorNavigation) => void;
}>({ navigation: [], registerNavigation: noop });

type Props = {
  children?: React.ReactNode;
};

export const AppConstructorNavigationProvider = ({ children }: Props) => {
  const [navigation, setNavigation] = useImmer<AppConstructorNavigation[]>([]);

  const handleNavigationRegister = useCallback(
    (name: AppConstructorNavigation) => {
      setNavigation((navigation) => {
        if (!navigation.includes(name)) {
          navigation.push(name);
        }
      });
    },
    [setNavigation],
  );

  const value = useMemo(
    () => ({ navigation, registerNavigation: handleNavigationRegister }),
    [navigation, handleNavigationRegister],
  );

  return (
    <AppConstructorNavigationContext.Provider value={value}>
      {children}
    </AppConstructorNavigationContext.Provider>
  );
};
