import { useState } from 'react';

import { AsyncFunction, ModalProps } from '@typings';
import { toast } from '@features';
import { removeOrganizationInvite } from '@services';
import { noop, toastifyResponseError } from '@utils';

import { Button, Modal } from '@components';

type Props = ModalProps & {
  inviteId: string;
  email: string;
  getUsers: AsyncFunction;
};

export const OrganizationRemoveUserInviteModal = ({
  inviteId,
  email,
  getUsers,
  closeModal = noop,
}: Props) => {
  const [loading, setLoading] = useState(false);

  const handleInviteRemove = async () => {
    try {
      setLoading(true);

      await removeOrganizationInvite({ inviteId });
      await getUsers();

      toast.success(`The invitation for ${email} has been removed`);
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal.Content title="Remove Invite" className="flex flex-col gap-2">
      <p>
        Are you sure you want to remove the invitation for user{' '}
        <span className="font-600">{email}</span>? They will no longer have
        access to the organization
      </p>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          loading={loading}
          variant="error"
          onClick={handleInviteRemove}
          className="px-6"
        >
          Remove invite
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
