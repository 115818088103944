import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import ReactFlow, {
  MarkerType,
  Node,
  ReactFlowProvider,
  useEdgesState,
  useNodesState,
} from 'reactflow';
import axios from 'axios';
import ELK, { ElkExtendedEdge, ElkNode } from 'elkjs/lib/elk.bundled';
import { nanoid } from 'nanoid';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';

import { AnyObject, DateFormat, Flow, Job } from '@typings';
import { API_ROOT, JWT_TOKEN, PLATFORM_API_URL } from '@constants';
import { useHelmetTitle } from '@hooks';
import { as, isArray, localStorage, path, tv } from '@utils';

import { ChaseSpinner, Helmet, Icon, Link, Modal, Render } from '@components';
import { Layout } from '@components/Layouts';
import { BakeModal } from '@components/Modals';
import { BakeParentNode } from '@components/Ui';

import 'reactflow/dist/style.css';

const variants = tv({
  base: 'border-0 rounded-xl bg-neural-02 text-h5 flex items-center justify-center',
  variants: {
    status: {
      pending: 'bg-warning-light',
      succeeded: 'bg-success',
      failed: 'bg-error-subtle',
      cancelled: 'bg-error-subtle',
      running: 'bg-success-light',
      suspended: 'bg-warning-subtle',
      cached: 'bg-success-light',
    },
    parent: {
      true: 'bg-opacity-50',
    },
  },
});

const NODE = {
  WIDTH: 225,
  HEIGHT: 84,
};

const elk = new ELK();

type Task = {
  jobId: string | null;
  outputs: AnyObject;
  status: Job.Status;
  createdAt: DateFormat;
  label?: string;
};

type Tasks = {
  [taskId: string]: Task;
};

const makeNode = ({
  isParent = false,
  nodeName,
  parentNode,
  width = isParent ? NODE.WIDTH * 1.5 : NODE.WIDTH,
  height = isParent ? NODE.HEIGHT * 2 : NODE.HEIGHT,
  x,
  y,
  status,
  createdAt,
  outputs,
  jobId,
  ...props
}: Partial<Task> & {
  nodeName: string;
  parentNode?: string;
  isParent?: boolean;
  x?: number;
  y?: number;
  width?: number;
  height?: number;
}) => {
  const label = parentNode ? nodeName.split(`${parentNode}.`)[1] : nodeName;
  const position = x && y ? { x, y } : undefined;

  return {
    ...props,
    ...(isParent && { type: 'parent-node' }),
    ...(parentNode && { parentNode }),
    id: nodeName,
    width,
    height,
    data: { label, status, createdAt, outputs, jobId },
    position,
    sourcePosition: 'right',
    targetPosition: 'left',
    style: { width, height },
    className: variants({ parent: isParent, status }),
  };
};

const makeEdge = (source: string, target: string): ElkExtendedEdge => ({
  id: `${source}-${target}`,
  sources: [source],
  targets: [target],
});

const createEdges = (graphs: Flow.BakeGraphs): ElkNode[] =>
  Object.values(graphs)
    .map((naming) =>
      Object.entries(naming).map(([targetName, sourceNames]) =>
        sourceNames.map((sourceName) => makeEdge(sourceName, targetName)),
      ),
    )
    .flat(2);

const createNodes = (graphs: Flow.BakeGraphs, tasks: Tasks) => {
  /**
   * Graphs board to make the connections and relatives
   */
  const graphsBoard = graphs[''];

  return Object.keys(graphsBoard).map((nodeName) => {
    const children = graphs[nodeName];
    const isParent = !!children;
    const node = makeNode({ ...tasks[nodeName], nodeName, isParent });

    if (children) {
      return {
        ...node,
        children: Object.keys(children).map((name) =>
          makeNode({
            ...tasks[name],
            nodeName: name,
            parentNode: nodeName,
          }),
        ),
      };
    }

    return node;
  });
};

const nodeTypes = {
  'parent-node': BakeParentNode,
};

const FlowBakeTasksOutlet = () => {
  const params = useParams();
  const projectId = params.projectId!;
  const flowName = params.flowName!;
  const bakeId = params.bakeId!;

  const { makeTitle } = useHelmetTitle();

  const [nodes, setNodes] = useNodesState([]);
  const [edges, setEdges] = useEdgesState([]);

  const [loading, setLoading] = useState(true);
  const [task, setTask] = useState<Task | null>();
  const [graph, setGraph] = useState(null);

  useEffect(() => {
    const run = async () => {
      if (!graph) {
        return;
      }

      const layout = await elk.layout(graph);

      const nodes = layout.children!.reduce((result, { children, ...node }) => {
        // @ts-expect-error user custom data
        const { id: nodeName, x, y, width, height, data } = node;
        const isParent = isArray(children) && !!children.length;

        result.push(
          // @ts-expect-error occued due to Elk and Reactflow types conflict
          makeNode({ ...data, nodeName, x, y, width, height, isParent }),
        );

        if (!isParent) {
          return result;
        }

        // @ts-expect-error occued due to Elk and Reactflow types conflict
        children.forEach(({ id, x, y, width, height, data }) => {
          return result.push(
            // @ts-expect-error occued due to Elk and Reactflow types conflict
            makeNode({
              ...data,
              nodeName: id,
              parentNode: nodeName,
              x,
              y,
              width,
              height,
            }),
          );
        });

        return result;
      }, []);

      // @ts-expect-error occued due to Elk and Reactflow types conflict
      const edges = graph.edges.map(({ id, sources, targets }) => ({
        id,
        source: sources[0],
        target: targets[0],
      }));

      setNodes(nodes);
      setEdges(edges);
    };

    run();
  }, [graph, setNodes, setEdges]);

  useEffect(() => {
    const run = async () => {
      try {
        const token = localStorage.get<string>(JWT_TOKEN);
        const headers = { Authorization: `Bearer ${token}` };

        const {
          data: {
            graphs,
            // @ts-expect-error forced to skip axios interceptor
            last_attempt: { id: lastAttemptId },
          },
        } = await axios.get<Flow.Bake>(
          path.create(PLATFORM_API_URL, API_ROOT, 'flow/bakes', bakeId),
          {
            params: { fetch_last_attempt: true },
            headers,
          },
        );
        const { data: tasks } = await axios.get<Array<Flow.BakeTask>>(
          path.create(PLATFORM_API_URL, API_ROOT, 'flow/tasks'),
          {
            params: { attempt_id: lastAttemptId },
            headers,
          },
        );

        const normalizedTasks: Tasks = Object.fromEntries(
          tasks.map(
            ({
              // @ts-expect-error forced to skip axios interceptor
              yaml_id: id,
              // @ts-expect-error forced to skip axios interceptor
              raw_id: jobId,
              outputs,
              statuses,
            }): [string, Task] => {
              // @ts-expect-error forced to skip axios interceptor
              const { status, created_at: createdAt } = as.o<Job.History>(
                statuses.at(-1),
              );

              return [
                id,
                {
                  jobId,
                  outputs: as.o(outputs),
                  status,
                  createdAt,
                },
              ];
            },
          ),
        );

        const nodes = createNodes(graphs, normalizedTasks);
        const edges = createEdges(graphs);

        setGraph({
          // @ts-expect-error occued due to Elk and Reactflow types conflict
          id: 'root',
          layoutOptions: {
            'elk.algorithm': 'org.eclipse.elk.layered',
            'elk.direction': 'RIGHT',
            'spacing.nodeNodeBetweenLayers': 88,
            'elk.edgeRouting': 'POLYLINE',
            'elk.spacing.nodeNode': 40,
            'org.eclipse.elk.spacing.componentComponent': 64,
          },
          children: nodes,
          edges,
        });
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log({ error });
      } finally {
        setLoading(false);
      }
    };

    run();
  }, [bakeId]);

  // @ts-expect-error skipping `event`
  const handleNodeClick = (event, { data }: Node) => {
    setTask(data as Task);
  };

  return (
    <Layout>
      <Helmet title={makeTitle('Bake', flowName, 'Flows', '%p', '%c')} />
      <div slot="header" className="flex min-w-0 items-center gap-4">
        <Link
          variant="ghost"
          to={path.flowBakes(projectId, flowName)}
          className="h-auto p-0 text-[24px] text-neural-03"
        >
          <Icon icon={faChevronLeft} className="h-10 w-10" />
        </Link>
        <h3 className="truncate text-h4 text-white">{flowName}</h3>
      </div>
      {task?.status && (
        <Modal
          defaultOpen
          preventOutsideClick
          key={nanoid()}
          onClose={() => setTask(null)}
          content={<BakeModal {...task} />}
        />
      )}
      <Layout.Content className="px-0 pb-0 pt-24">
        <ReactFlow
          fitView
          draggable={false}
          minZoom={0.01}
          maxZoom={0.75}
          nodes={nodes}
          edges={edges}
          nodeTypes={nodeTypes}
          onNodeClick={handleNodeClick}
          defaultEdgeOptions={{
            zIndex: 100,
            markerEnd: {
              type: MarkerType.ArrowClosed,
            },
            style: {
              strokeWidth: 2,
              stroke: '#79797B',
            },
          }}
        >
          <Render if={loading}>
            <div className="absolute inset-0 z-50 flex items-center justify-center bg-white">
              <ChaseSpinner color="black" className="h-14 w-14" />
            </div>
          </Render>
        </ReactFlow>
      </Layout.Content>
    </Layout>
  );
};

export const FlowBakeTasksPage = () => (
  <ReactFlowProvider>
    <FlowBakeTasksOutlet />
  </ReactFlowProvider>
);
