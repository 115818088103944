import { useState } from 'react';

import { Layout } from '@components/Layouts';
import {
  OnboardingOrganization,
  OnboardingProfile,
} from '@components/Onboarding';

export const OnboardingPage = () => {
  const [step, setStep] = useState<'organization' | 'profile'>('profile');

  const getOnboardingCarousel = () => {
    if (step === 'organization') {
      return <OnboardingOrganization />;
    }

    return <OnboardingProfile onComplete={() => setStep('organization')} />;
  };

  return (
    <Layout.Onboarding isOnboarding>
      <div className="flex flex-1 items-center">{getOnboardingCarousel()}</div>
    </Layout.Onboarding>
  );
};
