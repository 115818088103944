import { useCallback, useEffect, useState } from 'react';

import { AsyncFunction, Cluster, ClusterSettings } from '@typings';
import { getClusterSettings } from '@services';
import { clusterContextSelector, contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import { as } from '@utils';

export const useCluster = (): {
  loading: boolean;
  nodePools: ClusterSettings.NodePool[];
  resourcePresets: Cluster.ResourcePreset[];
  clusterName: string;
  getCluster: AsyncFunction;
} => {
  const cluster = useSelector(clusterContextSelector);
  const { clusterName } = useSelector(contextNamesSelector);

  const [loading, setLoading] = useState(false);
  const [nodePools, setNodePools] = useState<ClusterSettings.NodePool[]>([]);

  const { resourcePresets = [] } = as.c(cluster);

  const getCluster = useCallback(async () => {
    setLoading(true);

    try {
      const { nodePools } = await getClusterSettings(clusterName!);

      setNodePools(nodePools);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ error });
    } finally {
      setLoading(false);
    }
  }, [clusterName]);

  useEffect(() => {
    if (clusterName) {
      getCluster();
    }
  }, [clusterName, getCluster]);

  return {
    loading,
    nodePools,
    resourcePresets,
    clusterName: clusterName!,
    getCluster,
  };
};
