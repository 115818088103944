import { useCallback, useRef, useState } from 'react';
import Carousel from 'react-slick';
import { OverlayPanel } from 'primereact/overlaypanel';
import { faClone } from '@fortawesome/pro-regular-svg-icons';
import { faChevronDown, faChevronUp } from '@fortawesome/pro-solid-svg-icons';

import { AnyFunction, OrganizationInvite } from '@typings';
import { CAROUSEL_SETTINGS } from '@constants';
import { organizationsInvitesSelector, userSelector } from '@selectors';
import { useSelector } from '@hooks';
import { noop } from '@utils';

import { Button, Helmet, Icon, Render } from '@components';
import { OnboardingOrganizationInviteSlide } from '@components/Ui';

const SLIDE_COUNT = 2;

type Props = {
  onComplete?: AnyFunction;
};

export const OnboardingProfile = ({ onComplete = noop }: Props) => {
  const { name, username } = useSelector(userSelector);
  const organizationsInvites = useSelector(organizationsInvitesSelector);

  const [slideIndex, setSlideIndex] = useState(0);
  const carouselRef = useRef<Carousel>(null);
  const popoverRef = useRef<OverlayPanel>(null);

  const slideClassName = '!flex h-[500px] flex-col justify-center';
  const isFirstSlide = slideIndex === 0;
  const isLastSlide = slideIndex === SLIDE_COUNT - 1;

  const invites = organizationsInvites.filter(
    ({ status }) => status === OrganizationInvite.Status.Pending,
  );
  const isInvitedToOrganization = !!invites.length;
  const [invite] = invites;

  const handleSlideNext = useCallback(() => {
    carouselRef.current?.slickNext();
  }, []);

  const handleSlidePrev = useCallback(() => {
    carouselRef.current?.slickPrev();
  }, []);

  const handleUsernameCopy = (event: React.MouseEvent<HTMLButtonElement>) => {
    navigator.clipboard.writeText(username);

    popoverRef.current?.toggle(event);

    setTimeout(() => {
      popoverRef.current?.hide();
    }, 2000);
  };

  return (
    <>
      <div className="mr-32 flex flex-col gap-10">
        <Helmet
          title="Welcome Onboard"
          description="Welcome to Apolo! As a new user, get started by creating your first organization. Follow our simple guide to quickly familiarize yourself with the platform and begin your journey"
        />
        <Button
          variant="secondary"
          className="w-12 p-2"
          disabled={isFirstSlide}
          onClick={handleSlidePrev}
        >
          <Icon icon={faChevronUp} />
        </Button>
        <Button
          variant="secondary"
          className="w-12 p-2"
          disabled={isLastSlide}
          onClick={handleSlideNext}
        >
          <Icon icon={faChevronDown} />
        </Button>
      </div>
      <Carousel
        {...CAROUSEL_SETTINGS}
        afterChange={setSlideIndex}
        ref={carouselRef}
      >
        <div className={slideClassName}>
          <h3 className="truncate text-h3">Welcome, {name} 🎉</h3>
          <p className="mt-6 text-h6 font-500 text-neural-05">
            Ready to begin your journey?
          </p>
          <Button className="mt-10 w-[148px]" onClick={handleSlideNext}>
            Let&apos;s do it!
          </Button>
        </div>

        <Render if={isInvitedToOrganization}>
          <OnboardingOrganizationInviteSlide invite={invite} />
        </Render>

        <Render if={!isInvitedToOrganization}>
          <div className={slideClassName}>
            <h5 className="text-h3">Join Organization</h5>
            <p className="mt-6 text-h6 font-400 text-neural-04">
              Pass your username to the organization manager to join existing
              organization
            </p>
            <div className="relative mt-4 flex h-14 max-w-[420px] items-center rounded-lg bg-neural-01 px-4 py-2 text-neural-04">
              <span>{username}</span>
              <Button
                theme
                className="absolute inset-y-0 right-4 text-[22px] text-neural-02 transition-colors hover:text-neural-03"
                onClick={handleUsernameCopy}
              >
                <Icon icon={faClone} className="h-10 w-10" />
                <OverlayPanel ref={popoverRef}>
                  <div className="rounded-lg bg-neural-04 p-4 text-white">
                    Copied
                  </div>
                </OverlayPanel>
              </Button>
            </div>
            <div className="mt-10">
              <p className="text-h6 font-400 text-neural-04">
                Or establish a new organization
              </p>
              <Button className="mt-4 shrink-0" onClick={onComplete}>
                Create organization
              </Button>
            </div>
          </div>
        </Render>
      </Carousel>
    </>
  );
};
