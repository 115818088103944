import dayjs from 'dayjs';
import { faCopy, faFileCircleInfo } from '@fortawesome/pro-regular-svg-icons';

import { Image } from '@typings';
import { toast } from '@features';
import { useImageName } from '@hooks/useImageName';
import { getFormattedMemory, noop } from '@utils';

import { Button, ChaseSpinner, Icon, Tooltip } from '@components';
import { Layout } from '@components/Layouts';
import { DrawerDetailsItem } from '@components/Ui';

type Props = {
  loading?: boolean;
  image?: Image.Model | null;
  resetImage?: () => void;
};

export const ImageDetails = ({ loading, image, resetImage = noop }: Props) => {
  const { imageName } = useImageName(image?.name);

  const { tags = [], manifest = {} } = image ?? ({} as Image.Model);
  const {
    timeCreatedMs,
    imageSizeBytes,
    tag: [tag] = [],
  } = Object.values(manifest)[0]! ?? ({} as Image.Manifest);
  const formattedDate = dayjs(Number(timeCreatedMs)).format('L');

  const handleCopy = (value: string) => {
    navigator.clipboard.writeText(value);

    toast.success('Copied to clipboard');
  };

  const makeTag = (tag: string) => (
    <div key={tag} className="flex items-center gap-3">
      <p className="flex-1 truncate text-neural-04">{tag}</p>
      <Tooltip>
        <Button theme slot="trigger" onClick={() => handleCopy(tag)}>
          <Icon icon={faCopy} className="text-[18px]" />
        </Button>
        <p>Copy image tag</p>
      </Tooltip>
      <Tooltip align="end" alignOffset={-32}>
        <Button
          theme
          slot="trigger"
          onClick={() => handleCopy(`${imageName}:${tag}`)}
        >
          <Icon icon={faFileCircleInfo} className="text-[18px]" />
        </Button>
        <p>Copy image with tag</p>
      </Tooltip>
    </div>
  );

  const renderContent = () => {
    if (loading && !image) {
      return (
        <div className="relative flex min-h-[400px] w-full items-center justify-center">
          <ChaseSpinner color="black" className="h-12 w-12" />
        </div>
      );
    }

    return (
      <>
        <DrawerDetailsItem title="Image Tags">
          <div slot="text" className="flex flex-col gap-2">
            {tags.map(makeTag)}
          </div>
        </DrawerDetailsItem>
        <DrawerDetailsItem title="Image" text={imageName} />
        <DrawerDetailsItem title="Created" text={formattedDate} />
        <DrawerDetailsItem
          title="Size"
          text={getFormattedMemory(Number(imageSizeBytes))}
        />
      </>
    );
  };

  return (
    <Layout.ContentDrawer
      className="flex flex-col gap-6"
      containerClassName="w-[420px]"
      title={tag}
      onClose={resetImage}
      show={loading || !!image}
    >
      {renderContent()}
    </Layout.ContentDrawer>
  );
};
