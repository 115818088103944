export namespace Storage {
  export enum StatusType {
    File = 'FILE',
    Directory = 'DIRECTORY',
    Symlink = 'SYMLINK',
    Unknown = 'UNKNOWN',
  }

  export enum Permission {
    Read = 'read',
    Write = 'write',
    Manage = 'manage',
  }

  export enum Operation {
    ListStatus = 'LISTSTATUS',
    MkDirs = 'MKDIRS',
    Rename = 'RENAME',
    Delete = 'DELETE',
    Open = 'OPEN',
    GetFileStatus = 'GETFILESTATUS',
    GetDiskUsage = 'GETDISKUSAGE',
  }

  export type FileStatus = {
    path: string;
    length: number;
    modificationTime: number;
    permission: Permission;
    type: StatusType;
  };
}
