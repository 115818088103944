/**
 * Disabled ESLint due to useDispatch typed definition
 */
// eslint-disable-next-line @typescript-eslint/no-restricted-imports
import { useDispatch as useDispatchHook, useSelector } from 'react-redux';

import { Dispatch } from '@store';

export const useDispatch = () => useDispatchHook<Dispatch>();

export { useSelector };
