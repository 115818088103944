import { faLockKeyhole } from '@fortawesome/pro-regular-svg-icons';

import { Icon, Render, Table } from '@components';

type Props = {
  envs: { [key: string]: string };
};

export const JobDetailsEnvironment = ({ envs }: Props) => {
  const entries = Object.entries(envs);

  const makeEnvironment = ([name, value]: [string, string]) => {
    const isSecret = value.startsWith('secret://');
    const validValue = isSecret ? value.split('/').at(-1) : value;

    return (
      <Table.Row key={name}>
        <Table.Cell className="h-auto truncate border-none p-0">
          <p className="truncate" title={name}>
            {name}
          </p>
        </Table.Cell>
        <Table.Cell className="h-auto flex-row justify-start gap-2 truncate border-none p-0">
          <Render if={isSecret}>
            <Icon icon={faLockKeyhole} />
          </Render>
          <p className="truncate" title={validValue}>
            {validValue}
          </p>
        </Table.Cell>
      </Table.Row>
    );
  };

  if (entries.length === 0) {
    return <p>-</p>;
  }

  return (
    <Table className="grid grid-cols-2 gap-x-8 gap-y-2">
      <Table.Body>{entries.map(makeEnvironment)}</Table.Body>
    </Table>
  );
};
