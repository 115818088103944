import { useEffect, useState } from 'react';

import { Project, Role, TenantRole, UserInfoCluster } from '@typings';
import { NO_ORGANIZATION } from '@constants';
import {
  configSelector,
  contextNamesSelector,
  projectContextSelector,
  userClustersSelector,
} from '@selectors';
import { useSelector } from '@hooks';
import { as } from '@utils';

const MANAGER_ROLES = {
  ORGANIZATION: [TenantRole.Manager, TenantRole.Admin],
  CLUSTER: [TenantRole.Manager, TenantRole.Admin],
  PROJECT: [Role.Manager, Role.Admin],
};

export const useTenantRole = (): {
  isInitialized: boolean;
  isClusterManager: boolean;
  isProjectManager: boolean;
  isTenantManager: boolean;
  isOrganizationManager: boolean;
  tenantRole: TenantRole;
} => {
  const { orgs: organizations } = useSelector(configSelector);
  const clusters = useSelector(userClustersSelector);
  const project = useSelector(projectContextSelector);
  const { clusterName, organizationName } = useSelector(contextNamesSelector);

  const [isInitialized, setInitialization] = useState(false);

  const { role: projectRole } = as.o<Project>(project);

  useEffect(() => {
    if (clusterName) {
      setInitialization(true);
    }
  }, [clusterName]);

  const isNoOrganization = organizationName === NO_ORGANIZATION;
  const cluster = clusters.find(
    (cluster: UserInfoCluster) =>
      cluster.clusterName === clusterName &&
      as(cluster.orgName, NO_ORGANIZATION) === organizationName,
  );

  const tenantRole = cluster?.role ?? TenantRole.User;
  const isClusterManager =
    isNoOrganization && MANAGER_ROLES.CLUSTER.includes(tenantRole);
  const isTenantManager = MANAGER_ROLES.CLUSTER.includes(tenantRole);
  const isProjectManager =
    !!projectRole && MANAGER_ROLES.PROJECT.includes(projectRole);
  const organizationRole =
    organizations?.find(({ name }) => name === organizationName)?.role ??
    TenantRole.User;
  const isOrganizationManager =
    !isNoOrganization && MANAGER_ROLES.ORGANIZATION.includes(organizationRole);

  return {
    isInitialized,
    isClusterManager,
    isProjectManager,
    isTenantManager,
    isOrganizationManager,
    tenantRole,
  };
};
