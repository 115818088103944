import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { getSlots, tv, VariantProps } from '@utils';

import { Icon, Slot } from '@components';

const variants = tv({
  slots: {
    container: 'flex w-full flex-col items-center',
    icon: 'h-24 w-24 text-[88px] text-neural-03',
    content: 'flex flex-col gap-2 text-center max-w-[440px]',
    title: 'text-h5',
    text: '',
  },
  variants: {
    variant: {
      layout: {
        container: 'gap-10 px-10 pb-12 pt-40',
      },
      component: {
        container: 'gap-4 justify-center',
      },
    },
  },
  defaultVariants: { variant: 'component' },
});

type Props = VariantProps<typeof variants> & {
  className?: string;
  contentClassName?: string;
  /**
   * Prefer thin icons
   */
  icon?: IconProp;
  title?: string;
  text?: string;
  children?: React.ReactNode;
};

export const EmptyContent = ({
  className: containerClassName,
  contentClassName,
  variant,
  icon,
  title,
  text,
  children,
}: Props) => {
  const { content, ...slots } = getSlots<'title' | 'text' | 'icon'>(children);
  const variantSlots = variants({ variant });

  return (
    <div className={variantSlots.container({ className: containerClassName })}>
      <Slot slot={slots.icon}>
        <Icon icon={icon} className={variantSlots.icon()} />
      </Slot>
      <div className={variantSlots.content({ className: contentClassName })}>
        <Slot slot={slots.title}>
          <p className={variantSlots.title()}>{title}</p>
        </Slot>
        <Slot slot={slots.text}>
          <p className={variantSlots.text()}>{text}</p>
        </Slot>
      </div>
      {content}
    </div>
  );
};
