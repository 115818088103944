import { useRef, useState } from 'react';
import { faArrowTurnLeft } from '@fortawesome/pro-regular-svg-icons';
import { faFile, faFolder } from '@fortawesome/pro-solid-svg-icons';

import { ModalProps, Storage } from '@typings';
import { contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import { useStorage } from '@hooks/storage';
import { cn, noop, path } from '@utils';

import { Button, Icon, Modal, Render, Table } from '@components';

type Props = ModalProps & {
  resource?: string;
  onApply?: (field: { path: string; prefix?: string }) => void;
};

export const JobConstructorResourceStorageModal = ({
  resource,
  onApply = noop,
  closeModal = noop,
}: Props) => {
  const { organizationName, projectName } = useSelector(contextNamesSelector);

  const { isRoot, currentPath, objects, changePath, folderUp } = useStorage({
    initialPath: resource ?? path.create(organizationName, projectName),
    rootDirectory: path.create(organizationName),
    source: 'state',
  });

  const clickRef = useRef(0);
  const [activeObjectName, setActiveObjectName] = useState('');

  const handleApply = () => {
    if (activeObjectName) {
      onApply({ path: `${currentPath}/${activeObjectName}` });
      closeModal();
    }
  };

  const handleNameReset = () => {
    setActiveObjectName('');
  };

  const handleFolderChange = ({
    type,
    path: objectPath,
  }: Storage.FileStatus) => {
    const isDirectory = type === Storage.StatusType.Directory;

    if (isDirectory) {
      handleNameReset();
      changePath(path.create(currentPath, objectPath));
    }
  };

  const resetClickCount = () => {
    clickRef.current = 0;
  };

  const handleObjectClick = (object: Storage.FileStatus) => {
    const isDirectory = object.type === Storage.StatusType.Directory;

    clickRef.current += 1;

    if (!isDirectory) {
      resetClickCount();
      setActiveObjectName(object.path);
      return;
    }

    setTimeout(() => {
      if (clickRef.current === 1) {
        setActiveObjectName(object.path);
      } else if (clickRef.current === 2) {
        handleFolderChange(object);
      }

      resetClickCount();
    }, 200);
  };

  const handleFolderUp = () => {
    folderUp();
    handleNameReset();
  };

  const makeFolder = (object: Storage.FileStatus) => {
    const { path, type } = object;
    const isActive = path === activeObjectName;
    const icon = type === 'DIRECTORY' ? faFolder : faFile;

    return (
      <Table.Row key={path} onClick={() => handleObjectClick(object)}>
        <Table.Cell
          className={cn(
            'cursor-pointer border-neural-01 py-2 transition-colors hover:bg-neural-01',
            { '!bg-neural-02': isActive },
          )}
        >
          <div className="flex items-center gap-2">
            <Icon icon={icon} /> {path}
          </div>
        </Table.Cell>
      </Table.Row>
    );
  };

  return (
    <Modal.Content title="Choose a Files Path" className="w-[720px]">
      <p className="mb-4 text-caption text-neural-03">Path: {currentPath}</p>
      <Table className="h-[240px] w-full auto-rows-min grid-cols-1 overflow-auto bg-background">
        <Table.Header>
          <Table.Row>
            <Table.Head className="bg-background">Name</Table.Head>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Render if={!isRoot}>
            <Table.Row onDoubleClick={handleFolderUp}>
              <Table.Cell className="cursor-pointer border-neural-01 py-2 transition-colors hover:bg-neural-01">
                <div className="flex items-center gap-2">
                  <Icon icon={faArrowTurnLeft} /> Folder up
                </div>
              </Table.Cell>
            </Table.Row>
          </Render>
          {objects.map(makeFolder)}
        </Table.Body>
      </Table>
      <Modal.Footer>
        <Button
          disabled={!activeObjectName}
          className="px-14"
          onClick={handleApply}
        >
          Apply
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
