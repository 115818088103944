import { App, Job as JobType } from '@typings';
import { as, path } from '@utils';
import { dedicatedApps } from '@content';

import { Button, Link, Render } from '@components';
import { Job } from '@components/Ui';

type Props = {
  isUninstalling?: boolean;
  appName: string;
  targetName: string;
  status?: App.Status;
  jobId?: string;
  onAppUninstall: (appName: string) => Promise<void>;
};

export const AppInstalledDedicatedItem = ({
  isUninstalling,
  appName,
  targetName,
  status,
  jobId,
  onAppUninstall,
}: Props) => {
  const { logo } = as.o<JobType.Item>(
    dedicatedApps.find(({ name }) => name === targetName),
  );

  return (
    <div className="relative">
      <div className="group peer relative z-20 flex min-h-[160px] gap-6 rounded-xl border border-transparent bg-white p-6 transition-colors hover:border-neural-02">
        <div className="flex h-14 w-14 shrink-0 items-center justify-center rounded-lg bg-rebecca p-2 text-[28px] text-white">
          <img src={logo} alt="" />
        </div>
        <div className="relative flex min-w-0 flex-1 flex-col gap-3">
          <div className="flex items-center gap-4">
            <p className="mr-auto truncate text-h6 text-black">{appName}</p>
            <p className="flex h-[22px] items-center rounded-2xl bg-primary-subtle px-2 text-footnote text-black">
              Installed
            </p>
          </div>
          <Render if={status}>
            <div className="flex flex-col gap-1">
              <p className="text-footnote capitalize text-neural-04">Status</p>
              <Job.Status status={status!} />
            </div>
          </Render>
          <div className="absolute inset-x-0 bottom-0 flex justify-end gap-3 bg-white opacity-0 transition-opacity before:absolute before:bottom-full before:h-full before:w-full before:bg-gradient-to-t before:from-white before:to-transparent group-hover:opacity-100">
            <Button
              variant="ghost-error"
              loading={isUninstalling}
              onClick={() => onAppUninstall(appName)}
            >
              Uninstall
            </Button>
            <Button disabled={!jobId} className="flex p-0">
              <Link theme external blank to={path.app(jobId)} className="px-4">
                Details
              </Link>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};
