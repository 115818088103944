import { forwardRef, useId } from 'react';

import { cn } from '@utils';

import { Field } from '@components';

type Props = React.InputHTMLAttributes<HTMLInputElement> & {
  error?: string | undefined;
};

export const OnboardingInput = forwardRef<HTMLInputElement, Props>(
  ({ disabled = false, className, error, ...props }, ref) => {
    const id = useId();

    return (
      <div className="relative mt-6">
        <input
          {...props}
          ref={ref}
          id={id}
          autoComplete="off"
          disabled={disabled}
          className={cn(
            'border-b border-neural-01 text-h4 font-700 text-black transition-colors hover:border-neural-02 focus:border-neural-02',
            {
              'border-error': !!error,
            },
          )}
        />
        <Field.Error className="-bottom-6">{error}</Field.Error>
      </div>
    );
  },
);

OnboardingInput.displayName = 'OnboardingInput';
