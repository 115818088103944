import { clusterContextSelector } from '@selectors';
import { useSelector } from '@hooks';
import { useCluster } from '@hooks/cluster';
import { as } from '@utils';

export const useJobConstructor = () => {
  const cluster = useSelector(clusterContextSelector);

  const { nodePools } = useCluster();

  const { resourcePresets = [] } = as.c(cluster);

  return {
    nodePools,
    resourcePresets,
  };
};
