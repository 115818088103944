import { useLocation, useParams } from 'react-router-dom';
import { faCheck, faXmark } from '@fortawesome/pro-regular-svg-icons';

import { ClusterSettings, Job } from '@typings';
import { PATH } from '@constants';
import { as, ensureArray, tv } from '@utils';
import { dedicatedApps } from '@content';

import { Icon, Link, Render } from '@components';

const variants = tv({
  base: 'px-6 py-2 flex items-center justify-center rounded-full capitalize gap-1.5',
  variants: {
    isActive: {
      true: 'text-white bg-rebecca',
      false: 'text-rebecca bg-white',
    },
  },
  defaultVariants: {
    isActive: false,
  },
});

const TABS = [
  {
    title: 'All apps',
    path: PATH.APPS,
  },
  {
    title: 'Installed apps',
    path: PATH.APPS_INSTALLED,
  },
];

export const AppsTabs = () => {
  const { pathname } = useLocation();
  const { appName } = useParams();

  const { name, title } = as.o<Job.Item>(
    dedicatedApps.find(({ name }) => name === appName),
  );
  const isAppDedicated = !!name;

  const makeTab = ({ path, title }: ClusterSettings.Tab) => {
    const paths = ensureArray(path);
    const isActive = paths.includes(pathname);
    const [to] = paths;

    return (
      <Link theme key={to} to={to} className={variants({ isActive })}>
        <Render if={isActive}>
          <Icon icon={faCheck} className="text-[20px]" />
        </Render>
        {title}
      </Link>
    );
  };

  if (isAppDedicated) {
    return (
      <div className={variants({ isActive: true })}>
        {title ?? name}
        <Link theme to={PATH.APPS_INSTALLED}>
          <Icon icon={faXmark} className="text-[20px]" />
        </Link>
      </div>
    );
  }

  return <div className="flex gap-4">{TABS.map(makeTab)}</div>;
};
