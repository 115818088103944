import { faCheck } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';

import { AnyFunction } from '@typings';
import { noop } from '@utils';

import { Button, Icon, Render } from '@components';

type Props = {
  name: string;
  projectCount: number;
  active?: boolean;
  onClick?: AnyFunction;
};

export const UserPanelOrganizationItem = ({
  active = false,
  name,
  projectCount,
  onClick = noop,
}: Props) => {
  const nameInitials = name
    .replace(/[^a-zA-Z-]+/g, '')
    .split('-')
    .map((partial) => partial[0])
    .filter(Boolean)
    .slice(0, 2)
    .join('');

  const getProjectCount = () =>
    projectCount === 1 ? `${projectCount} project` : `${projectCount} projects`;

  return (
    <Button
      theme
      className="flex items-center gap-3 rounded-lg px-2 py-3 transition-colors hover:bg-neural-01"
      onClick={onClick}
    >
      <div
        className={clsx(
          'flex h-10 w-10 shrink-0 items-center justify-center rounded-full border bg-primary-subtle text-[14px] uppercase text-primary',
          { 'border-primary': active },
        )}
      >
        <span>{nameInitials}</span>
      </div>
      <div className="min-w-0 flex-1 text-left">
        <p className="truncate" title={name}>
          {name}
        </p>
        <p className="text-caption text-neural-04">{getProjectCount()}</p>
      </div>
      <Render if={active}>
        <Icon icon={faCheck} className="h-10 w-10 text-[20px] text-primary" />
      </Render>
    </Button>
  );
};
