import { Thunk } from '@typings';
import * as services from '@services';
import { OrganizationsInvitesState, setOrganizationsInvites } from '@slices';

export const getOrganizationsInvites =
  (): Thunk<Promise<OrganizationsInvitesState>> => async (dispatch) => {
    try {
      const invites = await services.getOrganizationsInvites();

      dispatch(setOrganizationsInvites(invites));

      return invites;
    } catch (error) {
      return Promise.reject(error);
    }
  };
