import { useEffect, useMemo, useState } from 'react';
import useWebSocket from 'react-use-websocket';

import { Cluster, Job, TelemetryData } from '@typings';
import { JWT_TOKEN } from '@constants';
import { clusterContextSelector } from '@selectors';
import { useSelector } from '@hooks';
import { as, formatToCamelCase, localStorage } from '@utils';

/**
 * It keeps resource preset interface on cluster change with
 * not existing resource preset that telemetry requires
 *
 * Example: user is on telemetry with `default` cluster with `cpu` resource preset.
 * Then user changes cluster from `default` to `default-2`.
 * `default-2` cluster doesn't contains `cpu` resource preset
 */
type EmptyResourcePreset = Cluster.ResourcePreset;

const resourcePresetInitialState = {
  name: '',
  cpu: 0,
  memoryMb: 0,
} as EmptyResourcePreset;

type Options = {
  jobId: string;
  presetName?: string;
  status?: Job.Status;
};

export const useJobTelemetry = ({ jobId, presetName, status }: Options) => {
  const cluster = useSelector(clusterContextSelector);

  const { monitoringUrl = '', resourcePresets } = as.c(cluster);

  const [resourcePreset, setResourcePreset] = useState<Cluster.ResourcePreset>(
    resourcePresetInitialState,
  );

  useEffect(() => {
    if (!presetName) {
      return;
    }

    const resourcePreset = resourcePresets.find(
      ({ name }) => name === presetName,
    );

    if (!resourcePreset) {
      return;
    }

    setResourcePreset(resourcePreset);
  }, [presetName, resourcePresets]);

  const token = localStorage.get(JWT_TOKEN);
  /**
   * Run web sockets for real `status` `running` provided option
   * or `undefined` that triggers web sockets by default
   */
  const isSocketable = !status || status === 'running';
  const formattedMonitoringUrl = isSocketable
    ? monitoringUrl.replace(/https?:\/\//g, 'wss://')
    : null;
  const url = `${formattedMonitoringUrl}/${jobId}/top`;

  const { lastJsonMessage: message, readyState } =
    useWebSocket<TelemetryData | null>(url, {
      protocols: ['top.apolo.us', `bearer.apolo.us-${token}`],
    });

  const formattedMessage = useMemo(
    () => formatToCamelCase(message) as TelemetryData,
    [message],
  );

  return {
    message: formattedMessage,
    readyState,
    resourcePreset,
  };
};
