import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { ViewType } from '@typings';
import { VIEW_TYPE } from '@constants';
import { localStorage } from '@utils';

const defaultViewType = ViewType.Grid as ViewType;
const initialState: ViewType = defaultViewType;

export const viewTypeSlice = createSlice({
  name: 'viewType',
  initialState,
  reducers: {
    initViewType: () => {
      const viewType = localStorage.get<ViewType>(VIEW_TYPE);

      return viewType ?? defaultViewType;
    },
    setViewType: (_, action: PayloadAction<ViewType>) => {
      const viewType = action.payload;

      localStorage.set(VIEW_TYPE, viewType);

      return action.payload;
    },
  },
});

export const { initViewType, setViewType } = viewTypeSlice.actions;

export default viewTypeSlice.reducer;
