import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { faUserPlus } from '@fortawesome/pro-regular-svg-icons';

import { ClusterSettings, OrganizationInvite, TenantRole } from '@typings';
import {
  getOrganizationSentInvites,
  getOrganizationUser,
  getOrganizationUsers,
} from '@services';
import { configSelector, contextNamesSelector, userSelector } from '@selectors';
import { useFetchList, useHelmetTitle, useSelector } from '@hooks';

import {
  Button,
  ChaseSpinner,
  Field,
  Helmet,
  Icon,
  Modal,
  Table,
} from '@components';
import { OrganizationInviteMemberModal } from '@components/Organization';
import {
  OrganizationInviteStatus,
  OrganizationUserActions,
} from '@components/Ui';

export const OrganizationUsersSettingsOutlet = () => {
  const { adminUrl } = useSelector(configSelector);
  const { username } = useSelector(userSelector);
  const { organizationName } = useSelector(contextNamesSelector);

  const {
    list: users,
    isFetched,
    getList,
  } = useFetchList<ClusterSettings.User[]>({
    getList: getOrganizationUsers,
    fetchOnMount: false,
  });
  const { makeTitle } = useHelmetTitle();

  const [organizationInvites, setOrganizationInvites] = useState<
    OrganizationInvite.Interface[]
  >([]);

  const [search, setSearch] = useState('');
  const [tenantRole, setTenantRole] = useState(TenantRole.User);

  const isManager = [TenantRole.Admin, TenantRole.Manager].includes(tenantRole);

  const getUsers = useCallback(async () => {
    getList({ adminUrl, organizationName });
  }, [adminUrl, organizationName, getList]);

  const getOrganizationInvites = useCallback(async () => {
    try {
      const invites = await getOrganizationSentInvites();

      const organizationInvites = invites.filter(
        ({ organizationName: organizationInviteName }) =>
          organizationInviteName === organizationName,
      );

      setOrganizationInvites(organizationInvites.reverse());
    } catch (error) {
      /**
       * Continue regardless error
       */
    }
  }, [organizationName]);

  const getTenantRole = useCallback(async () => {
    try {
      const { role } = await getOrganizationUser({
        organizationName,
        username,
      });

      setTenantRole(role);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ error });
    }
  }, [username, organizationName]);

  useEffect(() => {
    getTenantRole();
  }, [getUsers, getTenantRole]);

  useEffect(() => {
    getUsers();
    getOrganizationInvites();
  }, [getUsers, getOrganizationInvites]);

  const makeUser = (user: ClusterSettings.User) => {
    const {
      role,
      userName,
      userInfo: { email, createdAt },
    } = user;
    const date = createdAt && dayjs(createdAt).format('L');

    return (
      <Table.Row key={email}>
        <Table.Cell>{email}</Table.Cell>
        <Table.Cell className="capitalize">{role}</Table.Cell>
        <Table.Cell>{userName}</Table.Cell>
        <Table.Cell>{date}</Table.Cell>
        <Table.Cell className="flex-row justify-end">
          <OrganizationUserActions user={user} getUsers={getUsers} />
        </Table.Cell>
      </Table.Row>
    );
  };

  const makeInvite = (invite: OrganizationInvite.Interface) => {
    const { id: inviteId, role, email, expiredAt, status } = invite;

    return (
      <Table.Row key={inviteId}>
        <Table.Cell>{email}</Table.Cell>
        <Table.Cell className="capitalize">{role}</Table.Cell>
        <Table.Cell>-</Table.Cell>
        <Table.Cell>
          <OrganizationInviteStatus status={status} expiredAt={expiredAt} />
        </Table.Cell>
        <Table.Cell className="flex-row justify-end">
          <OrganizationUserActions
            invite={invite}
            getUsers={getOrganizationInvites}
          />
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderContent = () => {
    const list = search
      ? users.filter(
          ({ userName, userInfo: { email } }) =>
            userName.includes(search) || email.includes(search),
        )
      : users;

    if (!isFetched) {
      return (
        <div className="relative flex min-h-[400px] w-full items-center justify-center">
          <ChaseSpinner color="black" className="h-12 w-12" />
        </div>
      );
    }

    return (
      <Table className="w-full auto-rows-min grid-cols-[repeat(4,minmax(max-content,auto))_min-content] overflow-auto">
        <Table.Header>
          <Table.Row>
            <Table.Head>Email</Table.Head>
            <Table.Head>Role</Table.Head>
            <Table.Head>Username</Table.Head>
            <Table.Head>Sign up / Expiry Date</Table.Head>
            <Table.Head />
          </Table.Row>
        </Table.Header>
        <Table.Body>{organizationInvites.map(makeInvite)}</Table.Body>
        <Table.Body>{list.map(makeUser)}</Table.Body>
      </Table>
    );
  };

  return (
    <>
      <Helmet
        title={makeTitle('Users', 'Organization Settings', '%o')}
        description="Invite new members and manage access permissions within your organization. Streamline the onboarding process and ensure your team is ready to collaborate effectively"
      />
      <div className="flex flex-col gap-8">
        <div className="flex gap-10">
          <Modal
            disabled={!isManager}
            content={
              <OrganizationInviteMemberModal
                getInvitedUsers={getOrganizationInvites}
              />
            }
          >
            <Button variant="rebecca" className="gap-3 capitalize">
              <Icon icon={faUserPlus} />
              Invite people
            </Button>
          </Modal>
          <Field.Input
            containerClassName="flex-1"
            className="border-neural-03"
            label="Search"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </div>
        {renderContent()}
      </div>
    </>
  );
};
