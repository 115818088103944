import { Outlet, useLocation, useParams } from 'react-router-dom';
import { faChevronLeft } from '@fortawesome/pro-regular-svg-icons';

import { PATH } from '@constants';
import { dedicatedApps } from '@content';

import { Helmet, Icon, Link, Render } from '@components';
import { Layout } from '@components/Layouts';
import { AppsTabs } from '@components/Ui';
import { AppsViewType } from '@components/Ui/Apps';

type Props = {
  title: string;
};

export const AppsPage = ({ title }: Props) => {
  const { pathname } = useLocation();
  const { appName } = useParams();

  const isAllAppsPage = pathname === PATH.APPS;
  const isAppDedicated = !!dedicatedApps.find(({ name }) => name === appName);

  return (
    <Layout title={title}>
      <Helmet
        title="Apps"
        description="Explore and manage your applications effortlessly. View detailed descriptions, add new apps, install updates, and pin your favorite apps for quick access"
      />
      {isAppDedicated && (
        <div slot="header" className="flex items-center gap-4">
          <Link
            variant="ghost"
            to={PATH.APPS}
            className="h-auto p-0 text-[24px] text-neural-03"
          >
            <Icon icon={faChevronLeft} className="h-10 w-10" />
          </Link>
          <h3 className="truncate text-h4 text-white">{title}</h3>
        </div>
      )}
      <Layout.Content>
        <div className="mb-14 flex items-center justify-between">
          <AppsTabs />
          <Render if={!isAllAppsPage}>
            <AppsViewType />
          </Render>
        </div>
        <Outlet />
      </Layout.Content>
    </Layout>
  );
};
