import { useState } from 'react';

import { ModalProps } from '@typings';
import { deleteStorageObject } from '@services';
import { clusterContextSelector } from '@selectors';
import { useSelector } from '@hooks';
import { noop, path, toastifyResponseError } from '@utils';

import { Button, Modal } from '@components';

type Props = ModalProps & {
  name: string;
  currentPath: string;
  getStorage: (path: string) => Promise<void>;
  resetObjectDetails: () => void;
};

export const DeleteStorageObjectModal = ({
  name,
  currentPath,
  getStorage,
  closeModal = noop,
  resetObjectDetails,
}: Props) => {
  const { storageUrl } = useSelector(clusterContextSelector)!;

  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);

    const sourcePath = path.create(currentPath, name, { prefix: '' });

    try {
      await deleteStorageObject({ storageUrl, sourcePath });

      closeModal();
      resetObjectDetails();

      await getStorage(currentPath);
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal.Content title="Delete Storage Object" className="w-[520px]">
      <p className="flex flex-wrap items-center justify-center text-neural-03">
        Are you sure you want to delete
        <span className="space-before inline-flex items-center gap-1 text-body text-black">
          {name}
        </span>
        ?
      </p>

      <Modal.Footer>
        <Button variant="ghost" onClick={closeModal}>
          Cancel
        </Button>
        <Button loading={loading} className="px-14" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
