import { useCallback, useEffect, useState } from 'react';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import { faDatabase } from '@fortawesome/pro-thin-svg-icons';

import { Disk } from '@typings';
import { getDisks } from '@services';
import { clusterContextSelector, contextNamesSelector } from '@selectors';
import { useFetchList, useHelmetTitle, useSelector } from '@hooks';

import { Button, ChaseSpinner, Field, Helmet, Icon, Modal } from '@components';
import { CreateDiskModal, DiskDetails } from '@components/Disks';
import { Layout } from '@components/Layouts';
import { EmptyContent, ListItem } from '@components/Ui';

export const DisksPage = () => {
  const cluster = useSelector(clusterContextSelector);
  const { organizationName, projectName } = useSelector(contextNamesSelector);

  const { makeTitle } = useHelmetTitle();
  const {
    isFetched,
    isEmpty,
    list: disks,
    getList,
  } = useFetchList<Disk[]>({
    getList: getDisks,
    fetchOnMount: false,
  });

  const [search, setSearch] = useState('');
  const [diskId, setDiskId] = useState<string | null>(null);

  const { disksUrl } = cluster! ?? {};

  /**
   * Used for disk details drawer
   */
  const disk = disks.find(({ id }) => id === diskId);

  const fetchDisks = useCallback(async () => {
    if (projectName) {
      await getList({ disksUrl, organizationName, projectName });
    }
  }, [organizationName, projectName, disksUrl, getList]);

  const getSecretList = useCallback(async () => {
    fetchDisks();
  }, [fetchDisks]);

  useEffect(() => {
    getSecretList();
  }, [getSecretList]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) =>
    setSearch(event.target.value);

  const handleDiskIdReset = () => {
    setDiskId(null);
  };

  const makeListItem = ({ id, name }: Disk) => (
    <ListItem.Grid
      key={id}
      isActive={id === diskId}
      title={name}
      icon={faDatabase}
      onClick={() => setDiskId(id)}
    />
  );

  const renderContent = () => {
    const list = search
      ? disks.filter(({ name }) => name.includes(search))
      : disks;

    if (!isFetched) {
      return (
        <div className="relative flex min-h-[400px] w-full items-center justify-center">
          <ChaseSpinner color="black" className="h-12 w-12" />
        </div>
      );
    }

    if (isEmpty) {
      return (
        <EmptyContent
          icon={faDatabase}
          className="mt-20"
          title="No disks found"
        />
      );
    }

    return (
      <div className="flex flex-wrap gap-10">{list.map(makeListItem)}</div>
    );
  };

  return (
    <Layout title="Disks">
      <Helmet
        title={makeTitle('Disks', '%p', '%c')}
        description="Easily manage your disks. Access detailed information and control settings for your disks, ensuring efficient organization and management of your storage resources"
      />
      <Layout.Content className="flex items-start gap-10">
        <div className="flex flex-1 flex-col">
          <div className="mb-14 flex items-center gap-10">
            <Modal content={<CreateDiskModal getDisks={fetchDisks} />}>
              <Button variant="rebecca" className="gap-1 capitalize">
                <Icon icon={faPlus} />
                Create new disk
              </Button>
            </Modal>
            <Field.Input
              containerClassName="flex-1"
              className="border-neural-03"
              label="Search"
              value={search}
              onChange={handleSearchChange}
            />
          </div>
          {renderContent()}
        </div>
        <DiskDetails
          disk={disk}
          resetDiskId={handleDiskIdReset}
          getDisks={fetchDisks}
        />
      </Layout.Content>
    </Layout>
  );
};
