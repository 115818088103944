import { AnyFunction, Job, Primitive } from '@typings';
import { PATH } from '@constants';

import { as } from './as';
import { isNumber, isObject, isString } from './typeof';

type Options = Partial<{
  prefix: string;
  postfix: string;
  separator: string;
}>;

const getDefaultPrefix = (
  isFirstPathUri: boolean,
  separator: string = '/',
): string => (isFirstPathUri ? '' : separator);

/**
 * Concatinates path elements into single string
 *
 * @example
 * p('/home'); // "/home"
 * @example
 * p('default', 'my-org'); // "/default/my-org"
 * @example
 * p(NO_ORGANIZATION, 'my-org', { prefix: '' }); // "my-org"
 */
const p = (...args: Array<Primitive | Options>): string => {
  const lastArgument = args.slice(-1)[0];
  const options = isObject(lastArgument) ? lastArgument : undefined;
  const paths = (options ? args.slice(0, -1) : args) as Primitive[];
  const firstPath = paths[0];
  const isFirstPathUri = isString(firstPath) && /:\//.test(firstPath);
  const {
    separator = '/',
    prefix = getDefaultPrefix(isFirstPathUri, separator),
    postfix = '',
  } = as.o<Options>(options);

  const path =
    paths
      .map((path) => {
        if (isNumber(path)) {
          return path.toString();
        }

        if (isString(path)) {
          /**
           * Removes all "/" at the beginning
           */
          return path.replace(/^\/+/, '');
        }

        return null;
      })
      .filter(Boolean)
      .join(separator) ?? '';
  const concatinatedPath = prefix + path + postfix;

  return concatinatedPath;
};

/**
 * Creates dynamic path
 */
export const path = {
  app: (appName = ':jobId', tab?: Job.Tab) => p(PATH.APP, appName, tab),
  flowBakes: (projectId = ':projectId', flowName = ':flowName') =>
    p(PATH.FLOWS, projectId, flowName),
  bake: (
    projectId = ':projectId',
    flowName = ':flowName',
    bakeId = ':bakeId',
    bakeName = ':bakeName',
  ) => {
    const BAKE_NAME_ROUTE_PARAM = '/:bakeName?';
    const isRouteInitialization = projectId === ':projectId';
    const base = p(PATH.BAKE, projectId, flowName);

    return isRouteInitialization
      ? p(base, `${bakeId}${BAKE_NAME_ROUTE_PARAM}`)
      : p(base, bakeId, bakeName);
  },
  installApp: (appName = ':appName') => p(PATH.APPS, appName, 'install'),
  job: (jobId = ':jobId', tab?: Job.Tab) => p(PATH.JOB, jobId, tab),
  storage: (storagePath = ':storagePath') => p(PATH.STORAGE, storagePath),
  bucket: (
    bucketId = ':bucketId',
    bucketName = ':bucketName',
    pathname?: string,
  ) => p(PATH.BUCKET, bucketId, bucketName, pathname),
  metricsUrl: (metricsUrlBase: string, jobId: string) =>
    `${metricsUrlBase}/d/job/job?orgId=1&var-job_id=${jobId}&from=now-15m&to=now&refresh=10s`,

  create: p,
  catchAll: (target: string | AnyFunction): string => {
    const primitive = typeof target !== 'function';
    const path = primitive ? target : target();

    return `${path}/*`;
  },
};
