import { PopperContentProps } from '@radix-ui/react-popover';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';

import { cn } from '@utils';

import { Icon, Popover, PopoverOpenStateProps } from '@components';

type PopoverProps = Pick<
  PopperContentProps,
  'align' | 'alignOffset' | 'side' | 'sideOffset'
>;

type Props = PopoverOpenStateProps &
  PopoverProps & {
    className?: string;
    disabled?: boolean;
    title: string;
    children: React.ReactNode;
  };

export const BillingPopper = ({
  className,
  open,
  disabled,
  title,
  align = 'end',
  alignOffset,
  side = 'bottom',
  sideOffset = 4,
  children,
  onOpenChange,
}: Props) => {
  return (
    // @ts-expect-error todo: fix PopoverOpenStateProps `open` prop type
    <Popover
      open={open}
      onOpenChange={onOpenChange}
      disabled={disabled}
      align={align}
      alignOffset={alignOffset}
      side={side}
      sideOffset={sideOffset}
      showArrow={false}
      className={cn('min-w-[240px] px-0 py-2 [&>div]:gap-0', className)}
    >
      <div
        slot="trigger"
        className={cn(
          'flex h-12 items-center justify-center gap-1.5 rounded-full bg-rebecca px-6 py-1  text-white transition-colors hover:bg-rebecca-dark',
          { '!bg-neural-01 !text-neural-03': disabled },
        )}
      >
        {title}
        <Icon icon={faCaretDown} className="text-[20px] text-neural-03" />
      </div>
      {children}
    </Popover>
  );
};
