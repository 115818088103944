import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import base64 from 'base-64';
import utf8 from 'utf8';
import { z } from 'zod';

import { AsyncFunction, ModalProps, ResponseError } from '@typings';
import { toast } from '@features';
import { createSecret } from '@services/secret.services';
import { clusterContextSelector, contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import {
  isString,
  noop,
  normalizeFormErrors,
  toastifyResponseError,
} from '@utils';

import { Button, Field, Modal } from '@components';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  key: z.string().min(1),
  value: z.string().min(1),
});

type Props = ModalProps & {
  getSecrets: AsyncFunction;
};

export const CreateSecretModal = ({ getSecrets, closeModal = noop }: Props) => {
  const { secretsUrl } = useSelector(clusterContextSelector)!;
  const { organizationName, projectName } = useSelector(contextNamesSelector);

  const { register, formState, handleSubmit } = useForm({
    resolver: zodResolver(schema),
  });

  const [loading, setLoading] = useState(false);

  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const handleSecretSubmit = handleSubmit(async ({ key, value }) => {
    setLoading(true);

    const formattedKey = key.replace(/\s/g, '');

    try {
      await createSecret({
        secretsUrl,
        key: formattedKey,
        value: base64.encode(utf8.encode(value)),
        organizationName,
        projectName: projectName!,
      });

      toast.success('The secret is created');

      await getSecrets();

      closeModal();
    } catch (error) {
      const typedError = error as ResponseError;
      const errorMessage = isString(typedError.error) ? typedError.error : null;

      if (errorMessage) {
        toastifyResponseError(error);
      } else {
        toast.error('Something went wrong');
      }
    } finally {
      setLoading(false);
    }
  });

  return (
    <Modal.Content title="Create New Secret" className="w-[520px]">
      <form className="flex flex-col gap-6" onSubmit={handleSecretSubmit}>
        <Field.Input
          {...register('key')}
          error={errors.key}
          label="Key name"
          className="w-full"
        />
        <Field.Input
          {...register('value')}
          error={errors.value}
          label="Value"
          className="w-full"
        />
      </form>
      <Modal.Footer>
        <Button
          loading={loading}
          className="px-10 capitalize"
          onClick={handleSecretSubmit}
        >
          Create secret
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
