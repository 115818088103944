import { cn } from '@utils';

type Props = {
  className?: string;
};

export const MegaportLogo = ({ className }: Props) => {
  return (
    <svg
      className={cn('h-[141px] w-[34px] text-white', className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 141 34"
      fill="currentColor"
    >
      <g clipPath="url(#clip0_1_2)">
        <path d="M46.9 18.2L43.2 9.2H37.5V24.6H41.7V16.2L45.1 24.6H48.4L51.9 16.1V24.6H56V9.2H50.3L46.9 18.2Z" />
        <path d="M79 15.8C79.2 16.1 79.3 16.4 79.4 16.8C79.5 17.2 79.4 17.7 79.4 18.1C79.4 18.5 79.4 18.9 79.2 19.2C79.2 19.6 78.9 19.9 78.7 20.1C78.5 20.3 78.3 20.6 77.9 20.7C77.5 20.8 77.2 20.9 76.8 20.9C76.4 20.9 76 20.8 75.7 20.6C75.4 20.4 75.2 20.2 75 19.9C74.8 19.6 74.7 19.3 74.6 18.9C74.5 18.5 74.6 18.1 74.6 17.7C74.6 17.3 74.6 16.9 74.6 16.5C74.6 16.1 74.8 15.8 75 15.5C75.2 15.2 75.4 15 75.7 14.9C76 14.8 76.3 14.7 76.7 14.7C77.1 14.7 77.6 14.8 77.9 14.9C78.4 15.2 78.7 15.5 78.9 15.8M79.5 12.9C79.2 12.6 78.9 12.3 78.5 12.1C77.9 11.8 77.1 11.6 76.3 11.6C75.5 11.6 74.6 11.8 73.9 12.1C73.2 12.5 72.6 12.9 72.2 13.5C71.7 14.1 71.4 14.8 71.2 15.5C71 16.2 70.9 17 70.9 17.8C70.9 18.6 70.9 19.5 71.2 20.2C71.4 21 71.7 21.7 72.2 22.2C72.6 22.8 73.2 23.3 73.9 23.6C74.6 23.9 75.4 24.1 76.4 24.1C77.4 24.1 77.9 23.9 78.6 23.6C79 23.4 79.3 23.2 79.6 22.9V23.5C79.6 24.3 79.4 24.9 79.1 25.4C78.8 25.9 78.2 26.1 77.4 26.1C76.6 26.1 76.4 26 76 25.8C75.7 25.6 75.4 25.3 75.3 24.8V24.5H71.3V24.9C71.3 25.6 71.6 26.3 71.9 26.8C72.3 27.3 72.7 27.8 73.3 28.1C73.8 28.4 74.4 28.7 75.1 28.8C75.7 29 76.4 29 77 29C78.4 29 79.6 28.8 80.4 28.4C81.2 28 81.9 27.5 82.4 26.9C82.9 26.3 83.2 25.7 83.3 25C83.3 24.4 83.5 23.8 83.5 23.3V12H79.7V12.9H79.5Z" />
        <path d="M106.5 18.3C106.5 18.8 106.5 19.2 106.5 19.6C106.5 20 106.3 20.4 106.1 20.7C105.9 21 105.7 21.2 105.3 21.4C105 21.6 104.6 21.7 104.1 21.7C103.6 21.7 103.3 21.6 102.9 21.4C102.6 21.2 102.3 21 102.1 20.7C101.9 20.4 101.8 20 101.7 19.6C101.6 19.2 101.7 18.7 101.7 18.3C101.7 17.9 101.7 17.4 101.7 16.9C101.7 16.5 101.9 16.1 102.1 15.8C102.3 15.5 102.5 15.2 102.9 15C103.2 14.8 103.6 14.7 104.1 14.7C104.6 14.7 104.9 14.8 105.2 15C105.5 15.2 105.8 15.5 106 15.8C106.2 16.1 106.4 16.5 106.4 16.9C106.6 17.4 106.6 17.9 106.6 18.3M109.2 13.7C108.7 13.1 108.1 12.6 107.4 12.2C106.7 11.8 105.8 11.6 104.8 11.6C103.8 11.6 103.3 11.8 102.6 12.1C102.2 12.3 101.9 12.5 101.5 12.8V11.9H97.7V28.8H101.7V23.7C102 24 102.4 24.2 102.8 24.4C103.5 24.7 104.2 24.9 105 24.9C105.8 24.9 106.8 24.7 107.5 24.4C108.2 24 108.8 23.5 109.3 22.9C109.8 22.3 110.1 21.6 110.3 20.8C110.5 20 110.6 19.2 110.6 18.4C110.6 17.6 110.6 16.7 110.3 15.9C110 15.1 109.6 14.3 109.2 13.7Z" />
        <path d="M119.3 21.5C119 21.7 118.6 21.8 118.1 21.8C117.6 21.8 117.2 21.7 116.9 21.5C116.6 21.3 116.3 21.1 116.1 20.8C115.9 20.5 115.8 20.1 115.7 19.7C115.6 19.3 115.7 18.8 115.7 18.4C115.7 18 115.7 17.5 115.7 17.1C115.7 16.7 115.9 16.3 116.1 16C116.3 15.7 116.5 15.5 116.9 15.2C117.2 15 117.6 14.9 118.1 14.9C118.6 14.9 119 15 119.3 15.2C119.6 15.4 119.9 15.6 120.1 16C120.3 16.3 120.4 16.7 120.5 17.1C120.6 17.5 120.5 18 120.5 18.4C120.5 18.8 120.5 19.3 120.5 19.7C120.5 20.1 120.3 20.5 120.1 20.8C119.8 21 119.6 21.3 119.3 21.5ZM122.8 13.5C122.2 12.9 121.5 12.5 120.7 12.1C119.9 11.8 119 11.6 118 11.6C117 11.6 116.1 11.8 115.3 12.1C114.5 12.4 113.8 12.9 113.2 13.5C112.6 14.1 112.2 14.8 111.9 15.6C111.6 16.4 111.4 17.3 111.4 18.3C111.4 19.3 111.6 20.2 111.9 21C112.2 21.8 112.7 22.5 113.2 23.1C113.7 23.7 114.5 24.1 115.3 24.4C116.1 24.7 117 24.9 118 24.9C119 24.9 119.9 24.7 120.7 24.4C121.5 24.1 122.2 23.6 122.8 23.1C123.4 22.6 123.8 21.8 124.1 21C124.4 20.2 124.6 19.3 124.6 18.3C124.6 17.3 124.4 16.4 124.1 15.6C123.8 14.7 123.4 14 122.8 13.5Z" />
        <path d="M140.7 15.3V11.6H138.3V8.39999H134.3V21.6C134.3 22.3 134.4 22.8 134.6 23.2C134.8 23.6 135.2 24 135.6 24.2C136 24.4 136.4 24.6 136.9 24.7C137.4 24.8 137.9 24.8 138.4 24.8H139.4C139.8 24.8 140.1 24.8 140.4 24.7H140.7V21.4H140.3C140.2 21.5 140 21.5 139.9 21.6C139.8 21.7 139.6 21.6 139.4 21.6C138.8 21.6 138.6 21.5 138.5 21.4C138.5 21.4 138.3 21.2 138.3 20.5V15.4H140.7V15.3Z" />
        <path d="M133.3 11.6C132.9 11.6 131.9 11.7 131.5 11.8C131.1 11.9 130.7 12.1 130.3 12.4C129.9 12.7 129.6 12.9 129.3 13.3V11.9H125.4V24.6H129.4V18.8C129.4 18.3 129.5 17.8 129.5 17.4C129.6 17 129.8 16.6 130 16.3C130.2 16 130.5 15.8 130.9 15.6C131.4 15.4 132 15.3 132.8 15.4H133.2V11.6" />
        <path d="M62.4 15C62.7 14.9 63.1 14.8 63.5 14.8C64.2 14.8 64.7 15 65 15.3C65.3 15.6 65.5 16 65.6 16.6H61.1C61.1 16.5 61.1 16.4 61.1 16.3C61.1 16.1 61.3 15.8 61.5 15.6C61.8 15.4 62 15.2 62.3 15M68.4 13.9C67.9 13.2 67.2 12.6 66.4 12.2C65.6 11.8 64.6 11.6 63.6 11.6C62.6 11.6 61.8 11.8 61 12.1C60.2 12.4 59.5 12.9 59 13.5C58.4 14.1 58 14.8 57.7 15.6C57.4 16.4 57.2 17.3 57.2 18.2C57.2 19.1 57.4 20.1 57.7 20.9C58 21.7 58.4 22.4 59 23C59.6 23.6 60.2 24 61.1 24.4C61.9 24.7 62.8 24.9 63.8 24.9C64.8 24.9 66.5 24.6 67.5 23.9C68.3 23.4 68.9 22.6 69.4 21.5L69.6 21H65.6C65.6 21.1333 65.5333 21.2333 65.4 21.3C65 21.6 64.5 21.7 63.9 21.7C63.3 21.7 62.5 21.5 62 21.1C61.6 20.7 61.4 20.2 61.3 19.4H69.9V19.1C69.9 18.1 69.9 17.2 69.7 16.3C69.3 15.4 68.9 14.6 68.4 13.9Z" />
        <path d="M92.2 19V19.6C92.2 19.8 92.2 20 92.2 20.3C92.2 20.6 92.2 20.8 91.9 21.1C91.9 21.3 91.5 21.6 91.2 21.7C91.2 21.9 90.6 22 90.1 22C89.6 22 89.6 22 89.4 21.9C89.2 21.9 89 21.8 88.9 21.7C88.8 21.6 88.7 21.5 88.6 21.4C88.6 21.3 88.6 21.1 88.6 20.8C88.6 20.5 88.6 20.4 88.6 20.2C88.6 20 88.8 19.9 88.9 19.8C88.9 19.7 89.2 19.6 89.4 19.5C89.6 19.4 89.8 19.4 90.1 19.3C90.3 19.3 90.6 19.2 90.9 19.2C91.2 19.2 91.4 19.1 91.7 19.1C91.7 19.2 92.1 19.1 92.3 19.1M96.3 21.6V15.4C96.3 14.6 96.1 13.9 95.7 13.4C95.3 12.9 94.9 12.5 94.3 12.3C93.8 12 93.2 11.9 92.5 11.8C91.2 11.6 89.9 11.6 88.6 11.9C87.9 12 87.3 12.3 86.7 12.6C86.1 12.9 85.7 13.4 85.3 13.9C84.9 14.4 84.7 15.2 84.7 16V16.4H88.7V16.1C88.7 15.6 88.9 15.2 89.2 15C89.5 14.8 89.9 14.7 90.5 14.7H91.3C91.5 14.7 91.7 14.8 91.8 14.9C91.9 15 92 15.1 92.1 15.3C92.2 15.5 92.1 15.7 92.1 16C92.1 16.3 92.1 16.4 91.9 16.5C91.7 16.6 91.5 16.7 91.2 16.8C90.9 16.9 90.4 16.9 90 17C89.5 17 89 17.1 88.5 17.2C88 17.3 87.5 17.4 87 17.5C86.5 17.6 86 17.9 85.6 18.1C85.2 18.4 84.8 18.8 84.6 19.3C84.3 19.8 84.2 20.4 84.2 21.2C84.2 22 84.2 22.4 84.5 23C84.7 23.5 85.1 23.9 85.5 24.2C85.9 24.5 86.4 24.8 86.9 24.9C87.4 25 88 25.1 88.6 25.1C89.2 25.1 90.1 25 90.9 24.8C91.4 24.6 91.9 24.4 92.4 24C92.4 24.2 92.4 24.4 92.6 24.5V24.7H96.3V22.7C96.2 22.3 96.2 21.9 96.2 21.6" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M30.5 26.2C32.3 23.5 33.3 20.2 33.3 17C33.3 13.8 31.5 8.4 28.4 5.2C25.3 2 21.1 0.300003 16.6 0.300003C12.1 0.300003 10.1 1.3 7.3 3.1C4.6 4.9 2.4 7.5 1.2 10.6C-4.76837e-06 13.7 -0.400004 17 0.299996 20.2C0.999996 23.4 2.5 26.4 4.9 28.7C7.3 31 10.2 32.6 13.4 33.3C16.6 33.9 20 33.6 23 32.3C26.1 31 28.7 28.9 30.5 26.2ZM18.8 13.5L16.7 11.4L14.6 13.5V17.9L16.7 20L18.8 17.9V13.5ZM21.8 17.9L25.4 21.5V27.1L23.9 28.1L22.4 27.1V22.7L20.3 20.6L18.2 22.7V27.1L16.7 28.1L15.2 27.1V22.7L13.1 20.6L11 22.7V27.1L9.5 28.1L8 27.1V21.5L11.6 17.9V12.3L15.2 8.7V4.2L16.7 3.2L18.2 4.2V8.6L21.8 12.2V17.9Z"
          fill="#E31B48"
        />
      </g>
      <defs>
        <clipPath id="clip0_1_2">
          <rect width="141" height="34" />
        </clipPath>
      </defs>
    </svg>
  );
};
