import { useCallback, useMemo, useState } from 'react';

import { Job } from '@typings';

type List = Job.Model[];

type Params = {
  initialValue?: string;
  jobs: List;
};

type ReturnValue = {
  search: string;
  jobs: List;
  handleSearchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  resetSearch: () => void;
};

const EMPTY_LIST: List = [];

/**
 * Job list search handled by client
 */
export const useJobList = ({
  initialValue = '',
  jobs,
}: Params): ReturnValue => {
  const [search, setSearch] = useState(initialValue);

  const list: List = jobs ?? EMPTY_LIST;

  const filteredJobs = useMemo(() => {
    if (!search) {
      return list;
    }

    return list.filter(({ id, name }) => {
      const escapedSearch = search.replace(/[-[\]{}()*+?.,\\^$|#\s]/g, '\\$&');
      const regExp = new RegExp(escapedSearch, 'i');

      return regExp.test(id.toString()) || regExp.test(name);
    });
  }, [search, list]);

  const handleSearchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setSearch(event.target.value);
    },
    [],
  );

  const handleResetSearch = useCallback(() => {
    setSearch('');
  }, []);

  return {
    search,
    jobs: filteredJobs ?? EMPTY_LIST,
    handleSearchChange,
    resetSearch: handleResetSearch,
  };
};
