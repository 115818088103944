import {
  faBolt,
  faBoxesStacked,
  faCompactDisc,
  faDatabase,
  faDiagramProject,
  faFile,
  faLockKeyhole,
  faObjectsColumn,
} from '@fortawesome/pro-regular-svg-icons';
import {
  faBolt as faBoltSolid,
  faBoxesStacked as faBoxesStackedSolid,
  faCompactDisc as faCompactDiscSolid,
  faDatabase as faDatabaseSolid,
  faDiagramProject as faDiagramProjectSolid,
  faFile as faFileSolid,
  faLockKeyhole as faLockKeyholeSolid,
  faObjectsColumn as faObjectsColumnSolid,
} from '@fortawesome/pro-solid-svg-icons';

import { ClusterSettings, Hostname, Job, SidebarItem } from '@typings';
import { PATH } from '@constants';

export const sidebarItems: SidebarItem[] = [
  {
    regularIcon: faObjectsColumn,
    solidIcon: faObjectsColumnSolid,
    name: 'Apps',
    path: [PATH.APPS, PATH.APP],
  },
  {
    regularIcon: faFile,
    solidIcon: faFileSolid,
    name: 'Files',
    path: PATH.STORAGE,
  },
  {
    regularIcon: faBoxesStacked,
    solidIcon: faBoxesStackedSolid,
    name: 'Buckets',
    path: [PATH.BUCKETS, PATH.BUCKET],
  },
  {
    regularIcon: faDatabase,
    solidIcon: faDatabaseSolid,
    name: 'Disks',
    path: PATH.DISKS,
  },
  {
    regularIcon: faCompactDisc,
    solidIcon: faCompactDiscSolid,
    name: 'Images',
    path: PATH.IMAGES,
  },
  {
    regularIcon: faLockKeyhole,
    solidIcon: faLockKeyholeSolid,
    name: 'Secrets',
    path: PATH.SECRETS,
  },
  {
    regularIcon: faBolt,
    solidIcon: faBoltSolid,
    name: 'Jobs',
    path: [PATH.JOBS, PATH.NEW_JOB, PATH.JOB],
  },
  {
    regularIcon: faDiagramProject,
    solidIcon: faDiagramProjectSolid,
    name: 'Flows',
    path: [PATH.FLOWS, PATH.FLOW, PATH.BAKE],
  },
];

export const bucketProviderIcon = {
  gcp: 'Gcp',
  azure: 'Azure',
  aws: 'Aws',
};

export const bucketProviderFormattedName = {
  gcp: 'Google Cloud',
  azure: 'Azure',
  aws: 'AWS',
  minio: 'MinIO',
};

export const formattedGpuMake = {
  amd: 'AMD',
  intel: 'Intel',
  nvidia: 'NVIDIA',
};

export const appsTabs: ClusterSettings.Tab[] = [
  {
    path: PATH.APPS,
    title: 'All Apps',
  },
  {
    path: PATH.APPS_INSTALLED,
    title: 'Installed Apps',
  },
];

export const settingsTabs = {
  cluster: [
    {
      path: [PATH.CLUSTER_SETTINGS_GENERAL, PATH.CLUSTER_SETTINGS],
      title: 'General',
    },
    {
      path: PATH.CLUSTER_SETTINGS_USERS,
      title: 'Users',
    },
    {
      path: PATH.CLUSTER_SETTINGS_REPORTS,
      title: 'Reports',
    },
    {
      path: PATH.CLUSTER_SETTINGS_NODES,
      title: 'Nodes',
    },
    {
      path: PATH.CLUSTER_SETTINGS_RESOURCES,
      title: 'Resources',
    },
  ],
  clusterUser: [
    {
      path: PATH.CLUSTER_SETTINGS_USERS,
      title: 'Users',
    },
    {
      path: PATH.CLUSTER_SETTINGS_REPORTS,
      title: 'Reports',
    },
    {
      path: PATH.CLUSTER_SETTINGS_RESOURCES,
      title: 'Resources',
    },
  ],
  organization: [
    {
      path: [PATH.ORGANIZATION_SETTINGS, PATH.ORGANIZATION_SETTINGS_USERS],
      title: 'Users',
    },
    {
      path: PATH.ORGANIZATION_SETTINGS_REPORTS,
      title: 'Reports',
    },
  ],
  organizationUser: [
    {
      path: [PATH.ORGANIZATION_SETTINGS, PATH.ORGANIZATION_SETTINGS_USERS],
      title: 'Users',
    },
  ],
  project: [
    {
      path: [PATH.PROJECT_SETTINGS_GENERAL, PATH.PROJECT_SETTINGS],
      title: 'General',
    },
    {
      path: PATH.PROJECT_SETTINGS_USERS,
      title: 'Users',
    },
    {
      path: PATH.PROJECT_SETTINGS_REPORTS,
      title: 'Reports',
    },
  ],
  projectNoReports: [
    {
      path: [PATH.PROJECT_SETTINGS_GENERAL, PATH.PROJECT_SETTINGS],
      title: 'General',
    },
    {
      path: PATH.PROJECT_SETTINGS_USERS,
      title: 'Users',
    },
  ],
  projectUser: [
    {
      path: PATH.PROJECT_SETTINGS_USERS,
      title: 'Users',
    },
  ],
  job: [
    {
      path: Job.Tab.Overview,
      title: 'Details',
    },
    {
      path: Job.Tab.Observability,
      title: 'Observability',
    },
    {
      path: Job.Tab.Log,
      title: 'Log',
    },
  ],
  billing: [
    {
      path: PATH.BILLING_USAGE,
      title: 'Usage',
    },
    {
      path: PATH.BILLING_CREDITS,
      title: 'Credits',
    },
  ],
};

export const resourceVolume = {
  storage: {
    title: 'Files',
    icon: faFile,
  },
  disk: {
    title: 'Disks',
    icon: faDatabase,
  },
  secret: {
    title: 'Secrets',
    icon: faLockKeyhole,
  },
};

export const appConstructorNavigation = {
  image: {
    title: 'Image',
    name: 'image',
  },
  configuration: {
    title: 'Configuration',
    name: 'configuration',
  },
  resources: {
    title: 'Resources',
    name: 'resources',
  },
  integrations: {
    title: 'Integrations',
    name: 'integrations',
  },
  networking: {
    title: 'Networking',
    name: 'networking',
  },
  metadata: {
    title: 'Metadata',
    name: 'metadata',
  },
  scheduling: {
    title: 'Scheduling',
    name: 'scheduling',
  },
  advanced: {
    title: 'Advanced',
    name: 'advanced',
  },
  secrets: {
    title: 'Secrets',
    name: 'secrets',
  },
  llm: {
    title: 'LLM Inference Config',
    name: 'llm',
  },
  pgvector: {
    title: 'PGVector Config',
    name: 'pgvector',
  },
  tei: {
    title: 'Embeddings Config',
    name: 'tei',
  },
};

export const origin = {
  [Hostname.Scottdc]: 'Scott Data',
  [Hostname.Novoserve]: 'NovoServe',
  [Hostname.Imhpc]: 'iMHPC',
  [Hostname.Megaport]: 'Megaport',
  [Hostname.Wwt]: 'World Wide Technology',
} as const;
