import { cn } from '@utils';

type Props = {
  color?: boolean;
  className?: string;
};

export const LogoIcon = ({ color = true, className }: Props) => {
  const renderContent = () => {
    if (color) {
      return (
        <>
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="-159.65"
              y1="4371.85"
              x2="822.07"
              y2="3390.14"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#272782" />
              <stop offset="0.37" stopColor="#5f5fc9" />
              <stop offset="0.69" stopColor="#48d0ff" />
              <stop offset="1" stopColor="#00ffb0" />
            </linearGradient>
            <linearGradient
              id="linear-gradient-2"
              x1="3166.43"
              y1="68.24"
              x2="-1024.28"
              y2="4258.94"
              xlinkHref="#linear-gradient"
            />
            <linearGradient
              id="linear-gradient-3"
              x1="4423.06"
              y1="1324.87"
              x2="232.36"
              y2="5515.57"
              xlinkHref="#linear-gradient"
            />
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <polygon
                className="fill-[url(#linear-gradient)]"
                points="2643.61 1085.09 2998.39 1697.32 2289.36 1697.32 2643.61 1085.09"
              />
              <polygon
                className="fill-[url(#linear-gradient-2)]"
                points="1895.36 1932.34 1013.11 3455.6 0 3455.6 882.21 1932.34 1316.21 1182.46 1436.45 975.42 1494.08 875.1 1503.32 859.45 2000.62 0 2498.65 859.45 2507.19 874.39 2448.86 975.42 2328.63 1182.46 2000.62 1749.5 1895.36 1932.34"
              />
              <polygon
                className="fill-[url(#linear-gradient-3)]"
                points="4001.27 3455.6 2988.16 3455.6 2106.64 1932.34 3119.79 1932.34 4001.27 3455.6"
              />
            </g>
          </g>
        </>
      );
    }

    return (
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <polygon points="2643.61 1085.09 2998.39 1697.32 2289.36 1697.32 2643.61 1085.09" />
          <polygon points="1895.36 1932.34 1013.11 3455.6 0 3455.6 882.21 1932.34 1316.21 1182.46 1436.45 975.42 1494.08 875.1 1503.32 859.45 2000.62 0 2498.65 859.45 2507.19 874.39 2448.86 975.42 2328.63 1182.46 2000.62 1749.5 1895.36 1932.34" />
          <polygon points="4001.27 3455.6 2988.16 3455.6 2106.64 1932.34 3119.79 1932.34 4001.27 3455.6" />
        </g>
      </g>
    );
  };

  return (
    <svg
      className={cn('h-[275px] w-[320px] text-black', className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 4001.27 3455.6"
      fill="currentColor"
    >
      {renderContent()}
    </svg>
  );
};
