import { useCallback, useMemo } from 'react';
import dayjs from 'dayjs';
import { Updater as BillingParamsUpdater } from 'use-immer';

import { Billing, UnixTimestamp } from '@typings';
import { billingYears, defaultBillingParams, months } from '@content';

import {
  BillingDateRange,
  BillingPopoverClusters,
  BillingPopoverDatePreset,
  BillingPopoverGroups,
  BillingPopoverProjects,
} from '@components/Billing';

type Props = {
  isCenterDisabled?: boolean;
  billing: Billing.State;
  params: Billing.Params;
  setParams: BillingParamsUpdater<Billing.Params>;
};

export const BillingHandleCenter = ({
  isCenterDisabled,
  billing: billingInterface,
  params,
  setParams,
}: Props) => {
  const { isGroupedByProject, groups } = params;
  const billing = params.isGroupedByProject
    ? billingInterface.withProject
    : billingInterface.withoutProject;

  /**
   * Manually adding Billing.Group.Project based on params.isGroupedByProject value
   */
  const normalizedGroups = useMemo(
    () => (isGroupedByProject ? [...groups, Billing.Group.Project] : groups),
    [isGroupedByProject, groups],
  );
  const clusters = useMemo(
    () => billing.map(({ clusterName }) => clusterName),
    [billing],
  );
  const projects = useMemo(() => {
    const clusterBilling = billingInterface.withProject.find(
      ({ clusterName }) => clusterName === params.clusterName,
    );

    if (clusterBilling) {
      return clusterBilling.usage.map(({ projectName }) => projectName);
    }

    return [];
  }, [params.clusterName, billingInterface]);

  const resetParam = useCallback(
    (name: keyof Billing.Params) => {
      setParams((params) => {
        // @ts-expect-error todo: fix Group type
        params[name] = defaultBillingParams[name];
      });
    },
    [setParams],
  );

  const handleClusterChange = useCallback(
    (clusterName: string) => {
      setParams((params) => {
        params.clusterName = clusterName;
      });
      resetParam('projectName');
    },
    [setParams, resetParam],
  );

  const handleProjectChange = useCallback(
    (projectName: string) => {
      setParams((params) => {
        params.projectName = projectName;
      });
    },
    [setParams],
  );

  const handleGroupChange = useCallback(
    (groupName: Billing.Group, value: boolean) => {
      setParams((params) => {
        const set = new Set(params.groups);

        /**
         * Grouping by project is internal param state
         * todo: desctribe reason in Wiki
         */
        if (groupName === Billing.Group.Project) {
          params.isGroupedByProject = value;

          return;
        }

        if (value) {
          set.add(groupName);
        } else {
          set.delete(groupName);
        }

        /**
         * Billing.Group.Day and Billing.Group.Month are mutually exclusive option.
         * Only one of them can be enabled or both can be disabled
         */
        if (groupName === Billing.Group.Day && value) {
          set.delete(Billing.Group.Month);
        }

        if (groupName === Billing.Group.Month && value) {
          set.delete(Billing.Group.Day);
        }

        params.groups = Array.from(set);
      });
    },
    [setParams],
  );

  const handleDateChange = (
    startDate: UnixTimestamp,
    endDate: UnixTimestamp,
  ) => {
    setParams((params) => {
      params.startDate = startDate;
      params.endDate = endDate;
    });
  };

  const curryPeriodChange = (period: 'month' | 'year') => (value: string) => {
    let date = dayjs();

    if (period === 'month') {
      date = date.month(months.findIndex((month) => month === value));
    } else {
      date = date.year(Number(value));
    }

    setParams((params) => {
      params.startDate = date.startOf(period).unix() as UnixTimestamp;
      params.endDate = date.endOf(period).unix() as UnixTimestamp;
    });
  };

  return (
    <div className="mb-8 flex justify-between gap-2">
      <div className="flex gap-3">
        <BillingPopoverClusters
          disabled={isCenterDisabled}
          clusterName={params.clusterName}
          clusters={clusters}
          onSelect={handleClusterChange}
        />
        <BillingPopoverProjects
          disabled={isCenterDisabled}
          isGroupedByProject={params.isGroupedByProject}
          projectName={params.projectName}
          clusterName={params.clusterName}
          projects={projects}
          onSelect={handleProjectChange}
        />
      </div>
      <div className="flex gap-3">
        <BillingDateRange
          disabled={isCenterDisabled}
          startDate={params.startDate}
          endDate={params.endDate}
          onChange={handleDateChange}
        />
        <BillingPopoverDatePreset
          enabled={!isCenterDisabled}
          title="Month"
          items={months}
          onSelect={curryPeriodChange('month')}
        />
        <BillingPopoverDatePreset
          enabled={!isCenterDisabled}
          title="Year"
          items={billingYears}
          onSelect={curryPeriodChange('year')}
        />
      </div>
      <div className="flex gap-3">
        <BillingPopoverGroups
          disabled={isCenterDisabled}
          groups={normalizedGroups}
          onSelect={handleGroupChange}
        />
      </div>
    </div>
  );
};
