import { useEffect, useState } from 'react';
import { faDatabase } from '@fortawesome/pro-solid-svg-icons';

import { Disk, ModalProps } from '@typings';
import { getDisks } from '@services';
import { clusterContextSelector, contextNamesSelector } from '@selectors';
import { useFetchList, useSelector } from '@hooks';
import { as, cn, noop, path } from '@utils';

import { Button, Icon, Modal, Table } from '@components';

type Props = ModalProps & {
  onApply?: (field: { path: string; prefix?: string }) => void;
};

export const JobConstructorResourceDiskModal = ({
  onApply = noop,
  closeModal = noop,
}: Props) => {
  const cluster = useSelector(clusterContextSelector);
  const { organizationName, projectName } = useSelector(contextNamesSelector);

  const { list: disks, getList } = useFetchList<Disk[]>({
    getList: getDisks,
    fetchOnMount: false,
  });

  const { disksUrl } = as.c(cluster);
  const [activeObjectName, setActiveObjectName] = useState('');

  useEffect(() => {
    const getDisks = async () => {
      if (disksUrl) {
        await getList({ disksUrl, organizationName, projectName });
      }
    };

    getDisks();
  }, [organizationName, projectName, disksUrl, getList]);

  const handleApply = () => {
    if (activeObjectName) {
      onApply({
        path: `/${activeObjectName}`,
        prefix: path.create(organizationName, projectName),
      });
      closeModal();
    }
  };

  const makeDisk = ({ name }: Disk) => {
    const isActive = name === activeObjectName;

    return (
      <Table.Row key={name} onClick={() => setActiveObjectName(name)}>
        <Table.Cell
          className={cn(
            'cursor-pointer border-neural-01 py-2 transition-colors hover:bg-neural-01',
            {
              '!bg-neural-02': isActive,
            },
          )}
        >
          <div className="flex items-center gap-2">
            <Icon icon={faDatabase} /> {name}
          </div>
        </Table.Cell>
      </Table.Row>
    );
  };

  return (
    <Modal.Content title="Choose a Disks Path" className="w-[720px]">
      <Table className="h-[240px] w-full auto-rows-min grid-cols-1 overflow-auto bg-background">
        <Table.Header>
          <Table.Row>
            <Table.Head className="bg-background">Name</Table.Head>
          </Table.Row>
        </Table.Header>
        <Table.Body>{disks.map(makeDisk)}</Table.Body>
      </Table>
      <Modal.Footer>
        <Button
          disabled={!activeObjectName}
          className="px-14"
          onClick={handleApply}
        >
          Apply
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
