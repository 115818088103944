import clsx from 'clsx';

type Props = {
  className?: string;
  children?: React.ReactNode;
};

export const JobDetailsContent = ({ className, children }: Props) => {
  return (
    <div className={clsx('flex flex-col gap-6 px-10 py-6', className)}>
      {children}
    </div>
  );
};
