import {
  faCirclePlus,
  faFileArrowDown,
  faFilePen,
  faTrashCan,
  faUserPlus,
} from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';

import { Payload, Storage } from '@typings';
import { shareAccess } from '@services';
import { clusterContextSelector, contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import { as, getStorageDownloadUrl, path } from '@utils';

import { Button, Icon, Modal } from '@components';
import { ShareAccessModal } from '@components/Modals';

import { DeleteStorageObjectModal, RenameStorageObjectModal } from './Modals';

type Props = {
  currentPath: string;
  object: Storage.FileStatus | undefined;
  getStorage: (path: string) => Promise<void>;
  resetObjectDetails: () => void;
};

export const StorageFileManager = ({
  currentPath,
  object,
  getStorage,
  resetObjectDetails,
}: Props) => {
  const cluster = useSelector(clusterContextSelector);
  const { clusterName, organizationName } = useSelector(contextNamesSelector);

  if (!object) {
    return null;
  }

  const { storageUrl = '', usersUrl } = as.c(cluster);
  const { path: objectPath, type } = object;
  const isDirectory = type === Storage.StatusType.Directory;
  const sharingType = isDirectory ? 'folder' : 'file';
  const url = getStorageDownloadUrl({
    storageUrl,
    currentPath,
    objectPath,
  });

  const handleDownload = () => {
    const link = document.createElement('a');

    link.href = url;
    link.target = '_blank';
    link.click();
  };

  const handleAccessShare = async ({
    permission,
    username,
  }: Payload.ShareAccess) => {
    try {
      const uri = path.create(
        'storage:/',
        clusterName,
        organizationName,
        currentPath,
        objectPath,
      );

      await shareAccess({
        usersUrl,
        uri,
        username,
        action: permission,
      });
    } catch (error) {
      return Promise.reject(error);
    }
  };

  return (
    <div className="fixed bottom-0 left-[280px] right-0 z-10 flex h-16 items-center justify-center gap-6 bg-white px-10 py-5 shadow-[0px_-6px_8px_0px_rgba(0,0,0,0.03)] animate-in fade-in slide-in-from-bottom-6">
      <Modal
        content={
          <ShareAccessModal
            title={`Sharing ${sharingType} "${objectPath}"`}
            onShare={handleAccessShare}
          />
        }
      >
        <Button theme>
          <Icon icon={faUserPlus} />
        </Button>
      </Modal>
      <Button
        theme
        disabled={isDirectory}
        onClick={handleDownload}
        className={clsx({ 'text-neural-02': isDirectory })}
      >
        <Icon icon={faFileArrowDown} />
      </Button>
      <Button theme disabled className="text-neural-02">
        <Icon icon={faCirclePlus} />
      </Button>
      <Modal
        content={
          <RenameStorageObjectModal
            currentName={objectPath}
            currentPath={currentPath}
            getStorage={getStorage}
          />
        }
      >
        <Button theme>
          <Icon icon={faFilePen} />
        </Button>
      </Modal>
      <div className="h-4 w-px bg-neural-02" />
      <Modal
        content={
          <DeleteStorageObjectModal
            name={objectPath}
            currentPath={currentPath}
            getStorage={getStorage}
            resetObjectDetails={resetObjectDetails}
          />
        }
      >
        <Button theme className="text-error">
          <Icon icon={faTrashCan} />
        </Button>
      </Modal>
    </div>
  );
};
