import { useEffect } from 'react';

import { App, Job } from '@typings';
import { DEDICATED_APP_NAME } from '@constants';
import { builtItApps, dedicatedApps } from '@content';

import { AppItem } from '@components/Ui';

type Props = {
  runningJobs: Job.Running[];
  installedDedicatedApps: App.DedicatedModel[];
  setTitle: (title: string) => void;
};

export const AppsOutlet = ({
  runningJobs,
  installedDedicatedApps,
  setTitle,
}: Props) => {
  useEffect(() => {
    setTitle('All Apps');
  }, [setTitle]);

  const makeDedicatedApp = (app: Job.Item, installingJobCount?: number) => {
    const { name } = app;
    const installedDedicatedAppsCount = installedDedicatedApps.filter(
      ({ targetName }) => targetName === name,
    ).length;

    return (
      <AppItem
        key={name}
        name={name}
        installingJobCount={installingJobCount}
        installedJobCount={installedDedicatedAppsCount}
        job={app}
      />
    );
  };

  const makeApp = (app: Job.Item) => {
    const { name, group } = app;
    const isDedicatedApp = Object.values(DEDICATED_APP_NAME).some(
      (appName) => appName === name,
    );
    const installedJobCount = runningJobs.filter(
      ({ targetName }) => targetName && [name, group].includes(targetName),
    ).length;

    if (isDedicatedApp) {
      return makeDedicatedApp(app, installedJobCount);
    }

    return (
      <AppItem
        key={name}
        name={name}
        installedJobCount={installedJobCount}
        job={app}
      />
    );
  };

  const makeBuiltInApp = ({
    icon,
    name,
    path,
    tags,
    description,
  }: App.BuiltInItem) => (
    <AppItem.BuiltIn
      key={name}
      name={name}
      icon={icon}
      path={path}
      tags={tags}
      description={description}
    />
  );

  return (
    <div className="grid auto-rows-min grid-cols-3 gap-10 hdp:grid-cols-2">
      {dedicatedApps.map(makeApp)}
      {builtItApps.map(makeBuiltInApp)}
    </div>
  );
};
