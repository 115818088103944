import { mimes } from 'mrmime';
import {
  faFile,
  faFileGif,
  faFileJpg,
  faFileMov,
  faFileMp3,
  faFileMp4,
  faFilePdf,
  faFilePng,
  faFileSvg,
  faFileZip,
} from '@fortawesome/pro-thin-svg-icons';

import { Storage } from '@typings';
import { clusterContextSelector } from '@selectors';
import { useSelector } from '@hooks';
import { getFormattedStorageFile, getStorageDownloadUrl } from '@utils';

import { ListItem } from '@components/Ui';

import { StorageItemImage } from './StorageItemImage';

const DEFAULT_ICON = faFile;

/**
 * todo: define definition place
 */
const ICONS = {
  [mimes.pdf]: faFilePdf,
  [mimes.mp3]: faFileMp3,
  [mimes.mp4]: faFileMp4,
  [mimes.jpg]: faFileJpg,
  [mimes.png]: faFilePng,
  [mimes.gif]: faFileGif,
  [mimes.zip]: faFileZip,
  [mimes.svg]: faFileSvg,
  [mimes.mov]: faFileMov,
};

type Props = Omit<Storage.FileStatus, 'type'> & {
  isActive?: boolean;
  currentPath: string;
  onClick: () => void;
};

export const StorageItemFile = ({
  isActive,
  path: objectPath,
  currentPath,
  onClick,
}: Props) => {
  const { storageUrl } = useSelector(clusterContextSelector)!;

  const { mime, name, isImage } = getFormattedStorageFile(objectPath);
  const url = getStorageDownloadUrl({ storageUrl, currentPath, objectPath });
  const icon = ICONS[mime] ?? DEFAULT_ICON;

  if (isImage) {
    return (
      <StorageItemImage
        isActive={isActive}
        name={name}
        url={url}
        onClick={onClick}
      />
    );
  }

  return (
    <ListItem.Grid
      isActive={isActive}
      icon={icon}
      title={name}
      onClick={onClick}
    />
  );
};
