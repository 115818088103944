import { faXmark } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

import { AnyFunction } from '@typings';
import { cn } from '@utils';

import { Button, Icon, Render } from '@components';

type Props = {
  className?: string;
  containerClassName?: string;
  show?: boolean;
  title?: string;
  children?: React.ReactNode;
  onClose?: AnyFunction;
};

export const LayoutContentDrawer = ({
  className,
  containerClassName,
  show,
  title,
  children,
  onClose,
}: Props) => {
  if (!show) {
    return null;
  }

  return (
    <div
      className={cn(
        'w-[480px] shrink-0 rounded-lg bg-white duration-500 animate-in fade-in slide-in-from-right-24',
        containerClassName,
      )}
    >
      <div className="flex items-start justify-between border-b border-stroke px-10 py-6">
        <p className="text-h5">{title}</p>
        <Render if={onClose}>
          <Button
            theme
            className="relative top-1.5 text-[20px] text-neural-03 transition-colors hover:text-black"
            onClick={onClose}
          >
            <Icon icon={faXmark} />
          </Button>
        </Render>
      </div>
      <div className={clsx('p-10', className)}>{children}</div>
    </div>
  );
};
