import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

import { Mode, VolumeType } from '@typings';
import { resourceVolume } from '@content';

import { Button, Field, Icon, Modal, Render, Theme } from '@components';

import {
  JobConstructorResourceDiskModal,
  JobConstructorResourceSecretModal,
  JobConstructorResourceStorageModal,
} from './Modals';

type FieldArrayElement = {
  id: string;
  type: VolumeType;
  path: string;
  resource: string;
  mode: Mode;
  editable?: boolean;
  removable?: boolean;
  createDiskIfMissing?: boolean;
  defaultDiskSizeGB?: number;
};

const modal = {
  storage: JobConstructorResourceStorageModal,
  disk: JobConstructorResourceDiskModal,
  secret: JobConstructorResourceSecretModal,
};

export const JobConstructorResources = () => {
  const { control, register, setValue } = useFormContext();
  const { fields, append, remove, update } = useFieldArray({
    control,
    name: 'volumes',
  });

  const isSingleItem = fields.length === 1;

  const handlePathChange = (
    index: number,
    { path: pathValue, prefix = '' }: { path: string; prefix?: string },
  ) => {
    setValue(`volumes.${index}.resource`, pathValue);

    if (prefix) {
      setValue(`volumes.${index}.prefix`, prefix);
    }
  };

  const makePresetPathItem = ([value, { title, icon }]: [
    string,
    {
      title: string;
      icon: IconProp;
    },
  ]) => (
    <Field.SelectItem key={title} value={value}>
      <div className="flex items-center gap-2.5 px-2 py-1" slot="value">
        <Icon icon={icon} className="text-[20px]" />
        <p>{title}</p>
      </div>
    </Field.SelectItem>
  );

  const makeField = (field: FieldArrayElement, index: number) => {
    const { id, type, editable = true, resource, createDiskIfMissing } = field;
    const isStorageType = type === VolumeType.Storage;
    const isSecretType = type === VolumeType.Secret;
    const ModalContent = modal[type];

    return (
      <Theme.Container className="flex flex-col gap-6 p-5" key={id}>
        <div className="flex gap-6">
          <Controller
            name={`volumes.${index}.type`}
            control={control}
            render={({ field: { value, onChange } }) => {
              const { icon, title } = resourceVolume[value as VolumeType];

              return (
                <Field.CustomSelect
                  value={value}
                  disabled={!editable}
                  containerClassName="flex-1"
                  onChange={(value) => {
                    update(index, {
                      ...field,
                      name: '',
                      type: value as VolumeType,
                    });
                    onChange({ target: { value } });
                  }}
                >
                  <div className="flex items-center gap-2" slot="value">
                    <Icon icon={icon} className="text-[20px]" />
                    <div className="text-left">
                      <p className="text-caption text-neural-03">
                        Pick path from the
                      </p>
                      <p>{title}</p>
                    </div>
                  </div>
                  {Object.entries(resourceVolume).map(makePresetPathItem)}
                </Field.CustomSelect>
              );
            }}
          />
          <Field.Select
            {...register(`volumes.${index}.mode`)}
            disabled={isSecretType || !editable}
            containerClassName="flex-1"
          >
            <option value={Mode.ReadOnly}>Read-Only</option>
            <option value={Mode.ReadWrite}>Read and Write</option>
          </Field.Select>
        </div>
        <div className="flex flex-1">
          <div className="flex-1">
            <Field.Input
              {...register(`volumes.${index}.resource`)}
              disabled={!isStorageType || !editable}
              label="Resource"
              containerClassName="flex-1"
              className={clsx('w-full', { 'rounded-r-none': editable })}
            >
              <div
                className={clsx('contents', { hidden: !createDiskIfMissing })}
                slot="note"
              >
                <Field.Note>
                  A disk will be created if it does not exist yet
                </Field.Note>
              </div>
            </Field.Input>
          </div>
          <Render if={editable}>
            <Modal
              content={
                <ModalContent
                  resource={resource}
                  onApply={(field: { path: string; prefix?: string }) =>
                    handlePathChange(index, field)
                  }
                />
              }
            >
              <Button
                variant="ghost"
                className="h-14 rounded-l-none bg-background"
              >
                Browse
              </Button>
            </Modal>
          </Render>
        </div>
        <Field.Input
          {...register(`volumes.${index}.path`)}
          disabled={!editable}
          label="Path"
          note="This is a path where the volume will be mounted"
        />
        <Render if={!isSingleItem && editable}>
          <div className="flex justify-end">
            <Button
              variant="ghost-error"
              className="flex gap-1"
              onClick={() => remove(index)}
            >
              <Icon icon={faTrashAlt} className="text-[18px]" />
              <span>Delete volume</span>
            </Button>
          </div>
        </Render>
      </Theme.Container>
    );
  };

  return (
    <div className="flex flex-col gap-5 rounded-lg bg-background p-5">
      {(fields as FieldArrayElement[]).map(makeField)}
      <div className="flex justify-end">
        <Button
          onClick={() =>
            append({
              name: '',
              path: '',
              resource: '',
              type: VolumeType.Storage,
              mode: Mode.ReadOnly,
            })
          }
        >
          <Icon icon={faPlus} />
          Add volume
        </Button>
      </div>
    </div>
  );
};
