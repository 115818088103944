import { useLocation } from 'react-router-dom';

import { SUPPORT_EMAIL } from '@constants';
import { contextNamesSelector, userSelector } from '@selectors';
import { useSelector } from '@hooks';
import { capitalize, getFormattedOrganizationName } from '@utils';

export const useMailContext = () => {
  const { username } = useSelector(userSelector);
  const { organizationName, clusterName, projectName } =
    useSelector(contextNamesSelector);

  const { pathname } = useLocation();

  const formattedUsername = capitalize(username);
  const formattedOrganization = getFormattedOrganizationName(organizationName);

  const bodyObject = {
    Username: formattedUsername,
    Organization: formattedOrganization,
    Cluster: clusterName,
    Project: projectName,
    URL: pathname,
  };

  const subject = `${formattedUsername} Support Request`;
  const body = encodeURIComponent(
    Object.entries(bodyObject)
      .map(([name, value]) => (value ? `${name}: ${value}` : null))
      .filter(Boolean)
      .join('\n'),
  );
  const mailto = `mailto:${SUPPORT_EMAIL}?subject=${subject}&body=${body}`;

  return { mailto, body, subject };
};
