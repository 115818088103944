import { createContext, useEffect, useMemo, useState } from 'react';

import { Primitive } from '@typings';
import { getClusterOrganizationSettings } from '@services';
import { configSelector, contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';

export const OrganizationCreditsContext = createContext<{
  loading: boolean;
  organizationCredits: Primitive;
}>({ loading: true, organizationCredits: null });

const formatCredits = (creditsString: string | undefined) => {
  if (typeof creditsString === 'undefined') {
    return 'unlimited';
  }

  return creditsString;
};

type Props = {
  children?: React.ReactNode;
};

/**
 * Temporary solution until react-router Outlet will be implemented
 */
export const OrganizationCreditsProvider = ({ children }: Props) => {
  const { adminUrl } = useSelector(configSelector);
  const { organizationName, clusterName } = useSelector(contextNamesSelector);

  const [organizationCredits, setOrganizationCredits] = useState<Primitive>();
  const [loading, setLoading] = useState(true);

  const value = useMemo(
    () => ({ organizationCredits, loading }),
    [loading, organizationCredits],
  );

  useEffect(() => {
    const run = async () => {
      try {
        setLoading(true);

        const {
          balance: { credits },
        } = await getClusterOrganizationSettings({
          adminUrl,
          clusterName: clusterName!,
          organizationName: organizationName!,
        });

        setOrganizationCredits(formatCredits(credits));
      } catch (error) {
        setOrganizationCredits(null);
      } finally {
        setLoading(false);
      }
    };

    if (clusterName && organizationName) {
      run();
    } else {
      setOrganizationCredits(null);
      setLoading(false);
    }
  }, [adminUrl, clusterName, organizationName]);

  return (
    <OrganizationCreditsContext.Provider value={value}>
      {children}
    </OrganizationCreditsContext.Provider>
  );
};
