import { useState } from 'react';

import { capitalize } from '@utils';

import { BillingPopoverItem, BillingPopper } from '@components/Billing';

type Props = {
  enabled?: boolean;
  title: string;
  items: string[];
  onSelect: (period: string) => void;
};

export const BillingPopoverDatePreset = ({
  enabled = true,
  title,
  items,
  onSelect,
}: Props) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const currySelect = (value: string) => () => {
    onSelect(value);
    setPopoverOpen(false);
  };

  const makeItem = (period: string) => (
    <BillingPopoverItem
      key={period}
      title={capitalize(period)}
      onClick={currySelect(period)}
    />
  );

  return (
    <BillingPopper
      open={popoverOpen}
      onOpenChange={setPopoverOpen}
      disabled={!enabled}
      title={title}
    >
      {items.map(makeItem)}
    </BillingPopper>
  );
};
