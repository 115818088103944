import { useLocation } from 'react-router-dom';
import { faArrowRight, faBellOn } from '@fortawesome/pro-regular-svg-icons';

import { PATH } from '@constants';
import { organizationsInvitesSelector } from '@selectors';
import { useSelector } from '@hooks';

import { Icon, Link } from '@components';

export const UserPanelOrganizationsInvites = () => {
  const { pathname } = useLocation();

  const [invite] = useSelector(organizationsInvitesSelector);

  const isInvitesPage = pathname === PATH.ORGANIZATIONS_INVITES;

  if (!invite || isInvitesPage) {
    return null;
  }

  const { organizationName } = invite;

  return (
    <Link
      theme
      to={PATH.ORGANIZATIONS_INVITES}
      className="mt-5 flex items-center gap-3 rounded-lg bg-primary px-4 py-2 text-body-medium text-white transition-colors hover:bg-primary-medium active:bg-primary-dark"
    >
      <Icon icon={faBellOn} className="text-[20px]" />
      <div className="min-w-0">
        <p>You have been invited to</p>
        <p className="truncate font-700">{organizationName}</p>
        <p className="flex items-center">
          Accept or Decline
          <Icon icon={faArrowRight} className="ml-1 h-4 w-4" />
        </p>
      </div>
    </Link>
  );
};
