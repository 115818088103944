import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { ModalProps } from '@typings';
import { renameStorageObject } from '@services';
import { clusterContextSelector } from '@selectors';
import { useSelector } from '@hooks';
import { noop, normalizeFormErrors, path, toastifyResponseError } from '@utils';

import { Button, Field, Modal } from '@components';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  newName: z.string().min(1),
});

type Props = ModalProps & {
  currentName: string;
  currentPath: string;
  getStorage: (path: string) => Promise<void>;
};

export const RenameStorageObjectModal = ({
  currentName,
  currentPath,
  getStorage,
  closeModal = noop,
}: Props) => {
  const { storageUrl } = useSelector(clusterContextSelector)!;

  const { register, handleSubmit, formState } = useForm({
    defaultValues: { newName: currentName },
    resolver: zodResolver(schema),
  });

  const [loading, setLoading] = useState(false);

  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const normalizeName = (targetPath: string, prefix: string = ''): string =>
    path.create(currentPath, targetPath, { prefix });

  const handleObjectRename = handleSubmit(async ({ newName }) => {
    setLoading(true);

    const sourcePath = normalizeName(currentName);
    const newPath = normalizeName(newName, '/');

    try {
      await renameStorageObject({
        storageUrl,
        sourcePath,
        newPath,
      });
      await getStorage(currentPath);

      closeModal();
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  });

  return (
    <Modal.Content title="Enter new name" className="w-[480px]">
      <form onSubmit={handleObjectRename}>
        <Field.Input
          {...register('newName')}
          required
          label="New name"
          error={errors.newName}
        />
      </form>
      <Modal.Footer>
        <Button
          loading={loading}
          className="px-14"
          onClick={handleObjectRename}
        >
          Rename
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
