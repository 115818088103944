import {
  ListObjectsCommand,
  S3Client,
  S3ClientConfig,
} from '@aws-sdk/client-s3';

import { Bucket, Params } from '@typings';
import { PATH } from '@constants';
import { axios } from '@features';
import { formatModelName, getOrganizationName } from '@utils';

export const getBuckets = ({
  organizationName,
  projectName,
  bucketsUrl,
}: Params.GetBuckets) => {
  const params = {
    org_name: getOrganizationName(organizationName),
    project_name: projectName,
  };

  return axios.get<never, Bucket.Model[]>(`${bucketsUrl}/buckets`, { params });
};

export const getBucketsCredentials = ({
  organizationName,
  projectName,
  bucketsUrl,
}: Params.GetBuckets) => {
  const params = {
    org_name: getOrganizationName(organizationName),
    project_name: projectName,
  };

  return axios.get<never, Bucket.PersistentCredentials[]>(
    `${bucketsUrl}/persistent_credentials`,
    { params },
  );
};

/**
 * Used for bucket page credentials
 */
export const getBucketCredentials = (bucketsUrl: string, bucketId: string) =>
  axios.post<never, { credentials: Bucket.CredentialsModel }>(
    `${bucketsUrl}${PATH.BUCKETS}/${bucketId}/make_tmp_credentials`,
  );

export const createBucketCredentials = ({
  bucketId,
  name,
  bucketsUrl,
  isReadOnly,
}: Params.CreateBucketCredentials) => {
  const payload = {
    bucket_ids: [bucketId],
    read_only: isReadOnly,
    name: formatModelName(name),
  };

  return axios.post<never, never>(
    `${bucketsUrl}/persistent_credentials`,
    payload,
  );
};

export const getBucketList = async (credentials: Params.GetBucketList) => {
  const { endpointUrl, regionName } = credentials;
  const command = new ListObjectsCommand({
    Bucket: credentials.bucketName,
  });
  const s3Config: S3ClientConfig = {
    region: regionName,
    endpoint: endpointUrl,
    forcePathStyle: true,
    // @ts-ignore
    credentials,
  };
  const s3client = new S3Client(s3Config);
  const { Contents: list = [] } = await s3client.send(command);

  return list;
};
