import { useEffect, useRef, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { AnsiUp } from 'ansi_up';
import clsx from 'clsx';

import { Job, OutletContext, ResponseError } from '@typings';
import { JWT_TOKEN } from '@constants';
import { toast } from '@features';
import { clusterContextSelector } from '@selectors';
import { useSelector } from '@hooks';
import { as, isString, localStorage, path } from '@utils';

import { Button } from '@components';
import { JobDetailsContent } from '@components/Job';

export const JobLogPage = () => {
  const cluster = useSelector(clusterContextSelector);

  const params = useParams();
  const { setTabTitle } = useOutletContext<OutletContext.Job>();

  const logRef = useRef<HTMLParagraphElement>(null);
  const [log, setLog] = useState<string | null>(null);

  const jobId = params.jobId!;
  const { monitoringUrl } = as.c(cluster);
  const isLogFetched = isString(log);

  useEffect(() => {
    setTabTitle(Job.Tab.Log);
  }, [setTabTitle]);

  const handleLogDownload = () => {
    const element = document.createElement('a');
    const file = new Blob([as<string>(log, '')], { type: 'text/plain' });

    element.href = URL.createObjectURL(file);
    element.download = jobId;
    document.body.appendChild(element);
    element.click();
  };

  useEffect(() => {
    const params = new URLSearchParams({ timestamps: 'false', debug: 'false' });
    const token = localStorage.get(JWT_TOKEN);

    const run = async () => {
      try {
        const response = await fetch(
          path.create(monitoringUrl, jobId, `log?${params}`),
          {
            headers: { Authorization: `Bearer ${token}` },
          },
        );
        const reader = response.body?.getReader();
        const decoder = new TextDecoder();

        try {
          const ansiUp = new AnsiUp();

          // eslint-disable-next-line no-constant-condition
          while (true) {
            // eslint-disable-next-line no-await-in-loop
            const { done, value } = await reader!.read();

            if (done) {
              break;
            }

            const decodedLog = decoder.decode(value, { stream: true });

            setLog((log) => as(log, '') + decodedLog);

            if (decodedLog) {
              const html = ansiUp.ansi_to_html(decodedLog);

              logRef.current!.insertAdjacentHTML('beforeend', html);
            }
          }
        } finally {
          /**
           * Ensure the reader is closed even in case of an error
           */
          reader!.releaseLock();
        }
      } catch (error) {
        /**
         * todo: resolve parsing error
         *
         * https://apolocloud.slack.com/archives/D07131SJWFM/p1723626039320929
         */
        toast.error((error as ResponseError).error);
      }
    };

    if (monitoringUrl) {
      run();
    }
  }, [jobId, monitoringUrl]);

  const renderContent = () => {
    if (!isLogFetched) {
      return null;
    }

    if (!log) {
      return <p className="h-16">Log is empty</p>;
    }

    return null;
  };

  return (
    <JobDetailsContent>
      <div className="absolute right-10 top-7 flex justify-end">
        <Button
          variant="secondary"
          className="h-8 rounded-md text-body"
          disabled={!log}
          onClick={handleLogDownload}
        >
          Download log
        </Button>
      </div>
      <div className="min-h-[64px]">
        {renderContent()}
        <p
          ref={logRef}
          className={clsx(
            'overflow-hidden whitespace-pre-line break-words text-footnote-large',
            { hidden: !log },
          )}
        />
      </div>
    </JobDetailsContent>
  );
};
