import { useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { faMicrochip } from '@fortawesome/pro-regular-svg-icons';
import { faCaretDown } from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

import { Cluster } from '@typings';
import { useJobConstructor } from '@hooks/job';
import { cn, isFunction } from '@utils';

import { Button, Field, Icon, Modal, Render } from '@components';

import { JobConstructorPresetsModal } from '../Modals';

type Props = {
  name?: string;
  required?: boolean;
  label?: string;
  error?: string;
  note?: string;
  onPresetChange?: (resourcePreset: Cluster.ResourcePreset) => void;
};

export const JobPresetField = ({
  name: fieldName = 'presetName',
  required = true,
  label = 'Preset',
  error,
  note,
  onPresetChange,
}: Props) => {
  const { resourcePresets } = useJobConstructor();

  const { register, watch, setValue } = useFormContext();

  const presetName: string = watch(fieldName);

  useEffect(() => {
    register(fieldName);
  }, [fieldName, register]);

  const handleResourcePresetChange = (
    presetName: string,
    resourcePreset: Cluster.ResourcePreset,
  ) => {
    setValue(fieldName, presetName);

    if (isFunction(onPresetChange)) {
      onPresetChange(resourcePreset);
    }
  };

  return (
    <>
      <Modal
        content={
          <JobConstructorPresetsModal
            resourcePresets={resourcePresets}
            onApply={handleResourcePresetChange}
          />
        }
      >
        <Button
          theme
          className={cn(
            'relative flex h-14 items-center gap-2.5 rounded-lg border border-transparent bg-neural-01 px-4 py-2 transition-colors hover:border-neural-03',
            { '!border-error': !!error },
          )}
        >
          <Icon icon={faMicrochip} className="text-[22px]" />
          <div className="text-left">
            <p
              className={clsx('text-neural-04', {
                'text-caption': !!presetName,
                'required-label': required,
              })}
            >
              {label}
            </p>
            <Render if={presetName}>
              <p>{presetName}</p>
            </Render>
          </div>
          <Icon
            icon={faCaretDown}
            className="pointer-events-none absolute inset-y-0 right-4 m-auto text-neural-03"
          />
        </Button>
      </Modal>
      <Field.Error variant="static" className="-mt-4">
        {error}
      </Field.Error>
      <Render if={!error}>
        <Field.Note className="-mt-4">{note}</Field.Note>
      </Render>
    </>
  );
};
