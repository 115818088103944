import { Params, Secret } from '@typings';
import { axios } from '@features';
import { getOrganizationName, removeEmptyProperties } from '@utils';

export const getSecrets = ({
  secretsUrl,
  organizationName,
  projectName,
}: Params.GetSecrets) => {
  const params = {
    project_name: projectName,
    org_name: getOrganizationName(organizationName),
  };

  return axios.get<never, Secret[]>(secretsUrl, { params });
};

export const createSecret = (params: Params.CreateSecret) => {
  const { secretsUrl, key, value, organizationName, projectName } =
    removeEmptyProperties(params) as Params.CreateSecret;
  const payload = {
    key,
    value,
    org_name: organizationName,
    project_name: projectName,
  };

  return axios.post(secretsUrl, payload);
};

export const deleteSecret = ({
  secretsUrl,
  key,
  projectName,
}: Params.DeleteSecret) => {
  const params = { project_name: projectName };

  return axios.delete(`${secretsUrl}/${key}`, { params });
};
