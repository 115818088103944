import { Any, Job, Mode, Params, Payload } from '@typings';
import { API_ROOT, DEFAULT_JOB_RUNTIME_MINUTES } from '@constants';
import { axios } from '@features';
import {
  getFormattedJobVolumes,
  getOrganizationName,
  isBoolean,
  path,
  removeEmptyProperties,
  resolve,
} from '@utils';

export const runJob = ({
  clusterName,
  organizationName,
  projectName,
  env,
  httpAuth,
  httpPort,
  name,
  tags,
  image,
  volume: { jobPath, storagePath, mode },
}: Payload.RunJob) => {
  const [storagePathBase, storagePathRest] = storagePath.split(':');
  const payload = {
    name,
    env,
    disk_volumes: [],
    cluster_name: clusterName,
    org_name: organizationName,
    project_name: projectName,
    http: {
      port: httpPort,
      requires_auth: httpAuth,
    },
    tags,
    max_run_time_minutes: DEFAULT_JOB_RUNTIME_MINUTES,
    image,
    pass_config: true,
    secret_env: {},
    secret_volumes: [],
    volumes: [
      {
        dst_path: jobPath,
        read_only: mode !== Mode.ReadWrite,
        src_storage_uri: path.create(
          `${storagePathBase}:/`,
          clusterName,
          organizationName,
          projectName,
          storagePathRest,
          { prefix: '' },
        ),
      },
    ],
  };

  return axios.post<never, { httpUrlNamed: string }>(
    `${API_ROOT}/jobs?from_preset=true`,
    payload,
  );
};

export const getRunningJobs = ({
  clusterName,
  organizationName,
  projectName,
  username,
  isJustCurrentUser,
  tags,
}: Payload.GetRunningJob) => {
  const params = {
    cluster_name: clusterName,
    ...(organizationName && { org_name: organizationName }),
    project_name: projectName,
    owner: username,
    status: 'running',
    isJustCurrentUser,
    reverse: true,
    tag: tags,
  };

  return axios.get<never, { jobs: Job.Model[] }>(`${API_ROOT}/jobs`, {
    params,
    paramsSerializer: { indexes: null },
  });
};

export const killRunningJob = (jobId: string) =>
  axios.delete(`${API_ROOT}/jobs/${jobId}`);

export const getJobs = ({
  organizationName,
  clusterName,
  projectName,
  status,
  ...restParams
}: Any = {}) => {
  const params = {
    ...restParams,
    /**
     * todo: add `all` to enum/constant
     */
    status: status === 'all' ? null : status,
    cluster_name: clusterName,
    org_name: organizationName,
    project_name: projectName,
  };

  return axios.get<never, Job.Model[]>(`${API_ROOT}/jobs`, { params });
};

/**
 * Flow live jobs
 */
export const getLiveJobs = async ({
  projectId,
  projectName,
  flowName,
  organizationName = null,
  status,
}: Params.GetLiveJobs) => {
  const formattedFlowName = flowName.replaceAll('_', '-');
  const params = {
    /**
     * todo: add `all` to enum/constant
     */
    status: status === 'all' ? null : status,
    org_name: getOrganizationName(organizationName),
    project_name: projectName,
    tag: ['flow:live', `project:${formattedFlowName}`],
  };

  const liveJobs = await axios.get<never, Job.LiveModel[]>(
    path.create(API_ROOT, 'flow', 'live_jobs'),
    { params: { project_id: projectId } },
  );
  const { jobs } = await axios.get<never, { jobs: Job.Model[] }>(
    `${API_ROOT}/jobs`,
    {
      params,
      paramsSerializer: { indexes: null },
    },
  );

  const filteredJobs = jobs.filter(({ tags = [] }) => {
    return liveJobs.some((job) => job.tags.every((tag) => tags.includes(tag)));
  });

  return resolve<Job.Model[]>(filteredJobs);
};

export const createJob = ({
  organizationName,
  clusterName,
  projectName,
  presetName,
  restartPolicy,
  waitForJobs,
  privilegedMode,
  image,
  passConfig = false,
  lifespanMinutes,
  priority,
  httpAuth,
  httpPort,
  name,
  description,
  command,
  entrypoint,
  extendedSharedMemory,
  scheduleTimeout,
  tags,
  ttyAllocation,
  workingDirectory,
  env = {},
  secretEnv = {},
  volumes = [],
}: Payload.CreateJob) => {
  /**
   * Excludes engine until realization
   */
  const { mlflow, engine, ...restEnv } = env;
  const { storageVolumes, diskVolumes, secretVolumes } = getFormattedJobVolumes(
    volumes,
    clusterName,
  );
  const resources = isBoolean(extendedSharedMemory)
    ? { shm: extendedSharedMemory }
    : undefined;
  const http = httpPort
    ? { port: httpPort, requires_auth: httpAuth }
    : undefined;
  const optionalFields = removeEmptyProperties(
    {
      name,
      description,
      command,
      entrypoint,
      resources,
      schedule_timeout: scheduleTimeout,
      tty: ttyAllocation,
      working_dir: workingDirectory,
      tags: tags?.length ? tags : null,
    },
    { pattern: '' },
  );
  const payload = {
    ...optionalFields,
    org_name: organizationName,
    cluster_name: clusterName,
    project_name: projectName,
    preset_name: presetName,
    ...removeEmptyProperties({
      restart_policy: restartPolicy,
      wait_for_jobs_quota: waitForJobs,
      max_run_time_minutes: lifespanMinutes,
      privileged: privilegedMode,
      priority,
    }),
    image,
    http,
    env: removeEmptyProperties({
      ...restEnv,
      MLFLOW_TRACKING_URI: mlflow ? `http://${mlflow}` : null,
    }),
    pass_config: passConfig,
    secret_env: secretEnv,
    volumes: storageVolumes,
    disk_volumes: diskVolumes,
    secret_volumes: secretVolumes,
  };

  return axios.post<never, Job.Model>(`${API_ROOT}/jobs`, payload, {
    params: { from_preset: true },
  });
};

export const getJob = ({ jobId }: Params.GetJob) => {
  return axios.get<never, Job.Model>(path.create(API_ROOT, 'jobs', jobId));
};

export const getJobLog = ({ jobId, monitoringUrl }: Params.GetJobLog) => {
  return axios.get<never, string>(path.create(monitoringUrl, jobId, 'log'), {
    params: { timestamps: false, debug: false },
  });
};

export const saveJobImage = ({
  monitoringUrl,
  registryUrl,
  organizationName,
  projectName,
  jobId,
}: Params.SaveJobImage) => {
  const payload = {
    container: {
      image: path.create(
        registryUrl.replace(/https?:\/\//g, ''),
        organizationName,
        projectName,
        'ubuntu',
        'alpine:latest',
        { prefix: '' },
      ),
    },
  };

  return axios.post<never, Any>(
    path.create(monitoringUrl, jobId, 'save'),
    payload,
  );
};
