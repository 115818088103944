import { Auth0Provider } from '@auth0/auth0-react';

import { AUTH0 } from '@constants';

type Props = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: Props) => {
  return (
    <Auth0Provider
      domain={AUTH0.AUTH0_DOMAIN}
      clientId={AUTH0.AUTH0_CLIENT_ID}
      cacheLocation="localstorage"
      authorizationParams={{
        audience: AUTH0.AUTH0_AUDIENCE,
        redirect_uri: window.location.origin,
        origin: window.location.origin,
      }}
    >
      {children}
    </Auth0Provider>
  );
};
