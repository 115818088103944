import { useState } from 'react';

import { AsyncFunction, ModalProps } from '@typings';
import { removeOrganizationUser } from '@services';
import { configSelector, contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import { noop, toastifyResponseError } from '@utils';

import { Button, Modal } from '@components';

type Props = ModalProps & {
  username: string;
  getUsers: AsyncFunction;
};

export const OrganizationRemoveUserModal = ({
  username,
  getUsers,
  closeModal = noop,
}: Props) => {
  const { organizationName } = useSelector(contextNamesSelector);
  const { adminUrl } = useSelector(configSelector);

  const [loading, setLoading] = useState(false);

  const handleUserRemove = async () => {
    try {
      setLoading(true);

      await removeOrganizationUser({
        adminUrl,
        organizationName,
        username,
      });
      await getUsers();

      closeModal();
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal.Content title="Remove User" className="flex flex-col gap-2">
      <p>
        The user <span className="font-600">{username}</span> will no longer
        have access to the organization
      </p>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          loading={loading}
          variant="error"
          onClick={handleUserRemove}
          className="px-14"
        >
          Remove
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
