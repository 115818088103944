import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  faPenToSquare,
  faTrash,
  faUserPlus,
} from '@fortawesome/pro-regular-svg-icons';

import { ClusterSettings } from '@typings';
import { NO_ORGANIZATION } from '@constants';
import { getClusterUsers } from '@services';
import { configSelector, contextNamesSelector, userSelector } from '@selectors';
import { useFetchList, useHelmetTitle, useSelector } from '@hooks';
import { as } from '@utils';

import {
  Button,
  ChaseSpinner,
  Field,
  Helmet,
  Icon,
  Modal,
  Table,
} from '@components';
import {
  ClusterAddUser,
  ClusterEditUserModal,
  ClusterRemoveUserModal,
} from '@components/Cluster';

type Props = {
  isManager: boolean;
};

export const ClusterUsersSettingsOutlet = ({ isManager }: Props) => {
  const { username } = useSelector(userSelector);
  const { adminUrl } = useSelector(configSelector);
  const { clusterName, organizationName } = useSelector(contextNamesSelector);

  const { makeTitle } = useHelmetTitle();
  const {
    list: users,
    isFetched,
    getList,
  } = useFetchList<ClusterSettings.User[]>({
    getList: getClusterUsers,
    fetchOnMount: false,
  });
  const [search, setSearch] = useState('');

  const organizationUsers = users.filter(
    ({ orgName }) => as(orgName, NO_ORGANIZATION) === organizationName,
  );

  const getUsers = useCallback(async () => {
    if (clusterName) {
      getList({ adminUrl, clusterName, organizationName });
    }
  }, [adminUrl, clusterName, organizationName, getList]);

  useEffect(() => {
    getUsers();
  }, [getUsers]);

  const makeItem = ({
    role,
    userName,
    // @ts-expect-error todo: unexpected undefined user
    userInfo: { email, createdAt } = {},
    // @ts-expect-error todo: unexpected undefined user
    balance: { credits } = {},
  }: ClusterSettings.User) => {
    /**
     * Created key for re-render row entry on user update
     */
    const key = `${userName}-${role}-${credits}`;
    const date = createdAt && dayjs(createdAt).format('L');

    return (
      <Table.Row key={key}>
        <Table.Cell>{userName}</Table.Cell>
        <Table.Cell className="capitalize">{role}</Table.Cell>
        <Table.Cell>{email}</Table.Cell>
        <Table.Cell>{credits ?? 'Unlimited'}</Table.Cell>
        <Table.Cell>{date}</Table.Cell>
        <Table.Cell className="flex-row">
          <div className="flex gap-4">
            <Modal
              disabled={!isManager}
              content={
                <ClusterEditUserModal
                  username={userName}
                  role={role}
                  credits={credits}
                  getUsers={getUsers}
                />
              }
            >
              <Button variant="ghost" className="h-6 w-6 p-0 text-[18px]">
                <Icon icon={faPenToSquare} />
              </Button>
            </Modal>
            <Modal
              disabled={!isManager}
              content={
                <ClusterRemoveUserModal
                  username={userName}
                  getUsers={getUsers}
                />
              }
            >
              <Button
                variant="ghost-error"
                className="h-6 w-6 p-0 text-[18px]"
                disabled={!isManager || userName === username}
              >
                <Icon icon={faTrash} />
              </Button>
            </Modal>
          </div>
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderContent = () => {
    const list = search
      ? organizationUsers.filter(
          ({ userName, userInfo: { email } }) =>
            userName.startsWith(search) || email.startsWith(search),
        )
      : organizationUsers;

    if (!isFetched) {
      return (
        <div className="relative flex min-h-[400px] w-full items-center justify-center">
          <ChaseSpinner color="black" className="h-12 w-12" />
        </div>
      );
    }

    return (
      <Table className="w-full auto-rows-min grid-cols-[repeat(5,minmax(max-content,auto))_min-content] overflow-auto">
        <Table.Header>
          <Table.Row>
            <Table.Head>Name</Table.Head>
            <Table.Head>Role</Table.Head>
            <Table.Head>Email</Table.Head>
            <Table.Head>Credits</Table.Head>
            <Table.Head>Sign up Date</Table.Head>
            <Table.Head />
          </Table.Row>
        </Table.Header>
        <Table.Body>{list.map(makeItem)}</Table.Body>
      </Table>
    );
  };

  return (
    <>
      <Helmet title={makeTitle('Users', 'Cluster Settings', '%c')} />
      <div className="flex flex-col gap-8">
        <div className="flex gap-10">
          <Modal
            disabled={!isManager}
            content={
              <ClusterAddUser
                organizationUsers={organizationUsers}
                getOrganizationUsers={getUsers}
              />
            }
          >
            <Button variant="rebecca" className="gap-3 capitalize">
              <Icon icon={faUserPlus} />
              Invite people
            </Button>
          </Modal>
          <Field.Input
            containerClassName="flex-1"
            className="border-neural-03"
            label="Search"
            value={search}
            onChange={(event) => setSearch(event.target.value)}
          />
        </div>
        {renderContent()}
      </div>
    </>
  );
};
