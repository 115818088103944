import { useCallback, useEffect, useState } from 'react';
import * as sentry from '@sentry/react';

import { clusterContextSelector } from '@selectors';
import { useSelector } from '@hooks';
import { as, getParsedMemory } from '@utils';

const MIN_CPU = 0.5;
const MIN_MEMORY = getParsedMemory('1GB') as number;

type Options = {
  resourcePreset?: string;
  appName?: string;
};

/**
 * When the job is running as part of an app, we want to use the "powerless"
 * resource preset to execute the job with minimal resource consumption
 */
export const usePowerlessResourcePresetName = ({
  resourcePreset,
  appName,
}: Options = {}) => {
  const cluster = useSelector(clusterContextSelector);

  const { resourcePresets } = as.c(cluster);

  const [presetName, setPresetName] = useState('');

  const report = useCallback(() => {
    const appMentioning = appName ? `for ${appName} app` : '';

    sentry.captureMessage(
      `No resource preset name found for ${resourcePreset} ${appMentioning}`,
    );
  }, [appName, resourcePreset]);

  const getPowerlessResourcePreset = useCallback(
    () =>
      resourcePresets
        .filter(({ cpu, memory }) => cpu >= MIN_CPU && memory >= MIN_MEMORY)
        .sort((a, b) =>
          a.cpu === b.cpu ? a.memory - b.memory : a.cpu - b.cpu,
        )[0],
    [resourcePresets],
  );

  useEffect(() => {
    const isResourcePresetFound = resourcePresets?.some(
      ({ name }) => name === resourcePreset,
    );

    if (resourcePreset && isResourcePresetFound) {
      setPresetName(resourcePreset);

      return;
    }

    const preset = getPowerlessResourcePreset();

    if (preset) {
      setPresetName(preset.name);
    } else {
      report();
    }
  }, [resourcePreset, resourcePresets, report, getPowerlessResourcePreset]);

  return { powerlessPresetName: presetName, getPowerlessResourcePreset };
};
