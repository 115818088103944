import { useNavigate } from 'react-router-dom';

import { PATH } from '@constants';

import { Layout } from '@components/Layouts';
import { OnboardingOrganization } from '@components/Onboarding';

export const CreateOrganizationPage = () => {
  const navigate = useNavigate();

  return (
    <Layout.Onboarding>
      <div className="flex flex-1 items-center">
        <OnboardingOrganization onComplete={() => navigate(PATH.HOME)} />
      </div>
    </Layout.Onboarding>
  );
};
