import { Outlet } from 'react-router-dom';

import { useHelmetTitle } from '@hooks';
import { settingsTabs } from '@content';

import { ClusterSettingsTabs } from '@components/Cluster';
import { Layout } from '@components/Layouts';

type Props = {
  isManager: boolean;
};

export const OrganizationSettingsLayout = ({ isManager }: Props) => {
  const { makeTitle } = useHelmetTitle();

  const tabs = isManager
    ? settingsTabs.organization
    : settingsTabs.organizationUser;

  return (
    <Layout
      isProjectReffered={false}
      clusterContextTracker={false}
      projectContextTracker={false}
      title={makeTitle('Organization Settings', '%o')}
    >
      <Layout.Content>
        <ClusterSettingsTabs organizationTracker tabs={tabs} />
        <Outlet />
      </Layout.Content>
    </Layout>
  );
};
