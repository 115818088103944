import { faRectangleTerminal } from '@fortawesome/pro-solid-svg-icons';

import { Job } from '@typings';
import { PATH } from '@constants';
import { useContextSearchParams } from '@hooks';
import { path, tv } from '@utils';

import { Icon, Link, Render } from '@components';

import { AppItemBuiltIn } from './AppItemBuiltIn';

const labelVariants = tv({
  base: 'flex h-[22px] items-center rounded-2xl bg-warning-subtle px-2 text-footnote text-black shrink-0',
  variants: {
    variant: {
      installing: 'bg-warning-subtle',
      installed: 'bg-primary-subtle',
    },
  },
});

type Props = {
  name: string;
  job: Job.Item;
  installedJobCount: number;
  installingJobCount?: number;
};

export const AppItem = ({
  name,
  installedJobCount,
  installingJobCount,
  job: { title, logo, tags, description },
}: Props) => {
  const { contextSearchParams } = useContextSearchParams();

  const Component = installedJobCount ? Link : 'div';

  const makeTag = (tag: string) => (
    <p
      key={tag}
      className="flex h-[22px] items-center rounded-2xl bg-neural-01 px-2 text-footnote text-black"
    >
      {tag}
    </p>
  );

  const renderInstallationStatus = () => {
    if (installingJobCount) {
      return (
        <p className={labelVariants({ variant: 'installing' })}>
          Installing ({installingJobCount})
        </p>
      );
    }

    if (installedJobCount) {
      return (
        <p className={labelVariants({ variant: 'installed' })}>
          Installed ({installedJobCount})
        </p>
      );
    }

    return null;
  };

  return (
    <div className="relative flex">
      <Component
        theme={installedJobCount ? true : undefined}
        to={`${PATH.APPS_INSTALLED}/${name}`}
        className="group peer relative z-20 flex min-h-[160px] max-w-full flex-1 gap-6 overflow-hidden rounded-xl border border-transparent bg-white p-6 transition-colors hover:border-neural-02"
      >
        <div className="flex h-14 w-14 shrink-0 items-center justify-center rounded-lg bg-rebecca p-2 text-[28px] text-white">
          <Render if={!logo}>
            <Icon
              icon={faRectangleTerminal}
              className="h-14 w-14 text-[36px]"
            />
          </Render>
          <Render if={logo}>
            <img src={logo} alt="" />
          </Render>
        </div>
        <div className="relative flex min-w-0 flex-1 flex-col">
          <div className="flex items-center gap-4">
            <p className="mr-auto truncate text-h6 text-black">{title}</p>
            {renderInstallationStatus()}
          </div>
          <Render if={tags}>
            <div className="mt-3 flex gap-x-4 gap-y-2">
              {tags?.map(makeTag)}
            </div>
          </Render>
          <Render if={description}>
            <p className="mt-4 text-footnote text-neural-04 transition-opacity group-hover:opacity-0">
              {description}
            </p>
          </Render>

          <div className="absolute inset-x-0 bottom-0 flex justify-end gap-3 opacity-0 transition-opacity group-hover:opacity-100">
            <Link
              className="capitalize"
              to={`${path.installApp(name)}?${contextSearchParams}`}
            >
              Install
            </Link>
          </div>
        </div>
      </Component>
      <Render if={installedJobCount > 0}>
        <div className="absolute -bottom-1 -right-1 z-10 h-full w-full rounded-xl bg-neural-03/30" />
        <div className="absolute -bottom-2 -right-2 z-10 h-full w-full rounded-xl bg-neural-03/20" />
      </Render>
    </div>
  );
};

AppItem.BuiltIn = AppItemBuiltIn;
