import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { AxiosError } from 'axios';

import { AnyFunction, ModalProps, Organization } from '@typings';
import { NO_ORGANIZATION } from '@constants';
import { checkOrganizationExists, createOrganization } from '@services';
import { setContext } from '@slices';
import { getConfig, getUserClusters } from '@thunks';
import { userSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import {
  debounce,
  formatModelName,
  formatToCamelCase,
  isString,
  noop,
  parseResponseError,
} from '@utils';

import { Button, Field, Modal } from '@components';

const nameValidationSchema = {
  required: 'Organization name is required',
  pattern: {
    value: /^[a-zA-Z0-9 -]*$/,
    message: 'Organization name is invalid',
  },
  validate: {
    'empty-string': (value: string) =>
      !!value.trim() || 'Organization name is required',
  },
};

type Props = ModalProps & {
  onSuccess?: AnyFunction;
};

export const CreateOrganizationModal = ({
  onSuccess = noop,
  closeModal = noop,
}: Props) => {
  const dispatch = useDispatch();
  const { username } = useSelector(userSelector);

  const { control, clearErrors, setError, handleSubmit } = useForm();

  const [loading, setLoading] = useState(false);

  const handleNameError = (message: string) => {
    setError('name', { message });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceNameChange = useCallback(
    debounce(async (name: string) => {
      const formattedName = formatModelName(name);

      setLoading(true);

      try {
        await checkOrganizationExists(formattedName);

        handleNameError('Organization with such name already exits');
      } catch (error) {
        const status = (error as AxiosError)?.response?.status;

        if (status === 403) {
          handleNameError('Organization with such name already exits');
        }
      } finally {
        setLoading(false);
      }
    }, 500),
    [],
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const formattedValue = formatModelName(value);

    if (formattedValue) {
      debounceNameChange(formattedValue);
    } else {
      debounceNameChange.clear();
    }

    clearErrors('name');
  };

  const handleOrganizationNameSubmit = handleSubmit(async ({ name }) => {
    const formattedName = formatModelName(name);

    setLoading(true);

    try {
      const createdOrganization = await createOrganization(formattedName);
      const organization = formatToCamelCase(
        createdOrganization,
      ) as Organization.Model;

      const { clusters, adminUrl } = await dispatch(getConfig());
      const userClusters = await dispatch(getUserClusters(adminUrl, username));

      const { name: createdOrganizationName } = organization;

      /**
       * User clusters which belongs to the created organization
       */
      const userCluster = userClusters.find(
        ({ orgName = NO_ORGANIZATION }) => orgName === createdOrganizationName,
      );
      const cluster = clusters.find(
        ({ name }) => name === userCluster?.clusterName,
      );

      dispatch(
        setContext({
          organization,
          cluster,
          project: null,
        }),
      );

      onSuccess();
      closeModal();
    } catch (error) {
      const parsedError = parseResponseError(error);
      const normalizedError = isString(parsedError)
        ? parsedError
        : parsedError.messages[0];
      handleNameError(normalizedError);
    } finally {
      setLoading(false);
    }
  });

  return (
    <Modal.Content title="Create Organization" className="w-[640px]">
      <form onSubmit={handleOrganizationNameSubmit}>
        <Controller
          name="name"
          control={control}
          rules={nameValidationSchema}
          render={({
            field: { onChange, ...field },
            fieldState: { error },
          }) => (
            <Field.Input
              {...field}
              required
              label="Organization Name"
              containerClassName="mb-4"
              error={error?.message}
              onChange={(event) => {
                handleChange(event);
                onChange(event);
              }}
            />
          )}
        />
      </form>

      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          loading={loading}
          className="px-10 capitalize"
          onClick={handleOrganizationNameSubmit}
        >
          Create
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
