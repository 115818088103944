import { Fragment } from 'react';

import { Billing } from '@typings';
import { capitalize, noop } from '@utils';

import { Render } from '@components';
import { BillingPopoverItem, BillingPopper } from '@components/Billing';

type Props = {
  disabled?: boolean;
  groups: Billing.Group[];
  onSelect?: (groupName: Billing.Group, groupValue: boolean) => void;
};
export const BillingPopoverGroups = ({
  disabled,
  groups,
  onSelect = noop,
}: Props) => {
  const handleGroupChange = (group: Billing.Group) => {
    onSelect(group, !groups.includes(group));
  };

  const makeItem = (group: Billing.Group) => {
    const isDivided = group === Billing.Group.Day;

    return (
      <Fragment key={group}>
        <Render if={isDivided}>
          <div className="my-1 h-px bg-neural-01" />
        </Render>
        <BillingPopoverItem
          selectable
          active={groups.includes(group)}
          title={capitalize(group)}
          onClick={() => handleGroupChange(group)}
        />
      </Fragment>
    );
  };

  return (
    <BillingPopper disabled={disabled} title="Group By">
      {Object.values(Billing.Group).map(makeItem)}
    </BillingPopper>
  );
};
