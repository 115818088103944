import { useState } from 'react';
import { faCompactDisc } from '@fortawesome/pro-regular-svg-icons';

import { Image, ModalProps } from '@typings';
import { useImage } from '@hooks/image';
import { useImageName } from '@hooks/useImageName';
import { as, cn, noop } from '@utils';

import { Button, ChaseSpinner, Field, Icon, Modal, Table } from '@components';
import { EmptyContent } from '@components/Ui';

type Props = ModalProps & {
  onChoose?: (imageName: string) => void;
};

export const JobConstructorImagesModal = ({
  onChoose = noop,
  closeModal = noop,
}: Props) => {
  const {
    loading,
    imageLoading,
    list,
    isEmpty,
    image,
    setImage,
    setImageName,
  } = useImage();
  const { getImageName } = useImageName();

  const [search, setSearch] = useState('');
  const [activeImage, setActiveImage] = useState('');
  const [activeTag, setActiveTag] = useState('');

  const { tags } = as.o<Image.Model>(image);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearch(event.target.value);
  };

  const handleModalTagsChange = () => {
    if (activeImage) {
      setImageName(activeImage);
    }
  };

  const handleImageApply = () => {
    if (activeImage && activeTag) {
      const image = `${activeImage}:${activeTag}`;

      onChoose(image);
      closeModal();
    }
  };

  const handleReset = () => {
    setActiveTag('');
    setActiveImage('');
    setImage(null);
    setImageName(null);
  };

  const makeImage = (image: string) => {
    const isActive = image === activeImage;

    return (
      <Table.Row key={image} onClick={() => setActiveImage(image)}>
        <Table.Cell
          className={cn(
            'cursor-pointer border-neural-01 py-2 transition-colors hover:bg-neural-01',
            { '!bg-neural-02': isActive },
          )}
        >
          <div className="flex items-center gap-2">
            <Icon icon={faCompactDisc} />
            <span className="truncate">{getImageName(image)}</span>
          </div>
        </Table.Cell>
      </Table.Row>
    );
  };

  const makeTag = (tag: string) => {
    const isActive = tag === activeTag;

    return (
      <Table.Row key={tag} onClick={() => setActiveTag(tag)}>
        <Table.Cell
          className={cn(
            'cursor-pointer border-neural-01 py-2 transition-colors hover:bg-neural-01',
            { '!bg-neural-02': isActive },
          )}
        >
          {tag}
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderImagesContent = () => {
    const filteredList = search
      ? list.filter((repository) => repository.includes(search))
      : list;

    if (loading) {
      return (
        <div className="relative flex min-h-[400px] w-full items-center justify-center">
          <ChaseSpinner color="black" className="h-12 w-12" />
        </div>
      );
    }

    if (isEmpty) {
      return (
        <EmptyContent
          icon={faCompactDisc}
          className="mt-4 pb-20"
          title="No images found"
        />
      );
    }

    return (
      <>
        <Field.Input
          label="Search"
          value={search}
          onChange={handleSearchChange}
        />
        <Table className="mt-8 h-[240px] w-full auto-rows-min grid-cols-1 overflow-auto bg-background">
          <Table.Header>
            <Table.Row>
              <Table.Head className="bg-background">Name</Table.Head>
            </Table.Row>
          </Table.Header>
          <Table.Body>{filteredList.map(makeImage)}</Table.Body>
        </Table>
        <Modal.Footer className="px-0">
          <Button
            className="px-14"
            loading={imageLoading}
            disabled={!activeImage}
            onClick={handleModalTagsChange}
          >
            Next
          </Button>
        </Modal.Footer>
      </>
    );
  };

  if (image) {
    return (
      <Modal.Content title="Choose Image Tag" className="w-[720px]">
        <p className="mb-4 text-caption text-neural-04">
          Image: {getImageName(activeImage)}
        </p>
        <Table className="h-[240px] w-full auto-rows-min grid-cols-1 overflow-auto bg-background">
          <Table.Header>
            <Table.Row>
              <Table.Head className="bg-background">Name</Table.Head>
            </Table.Row>
          </Table.Header>
          <Table.Body>{tags.map(makeTag)}</Table.Body>
        </Table>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleReset}>
            Back to images
          </Button>
          <Button
            loading={imageLoading}
            disabled={!activeImage}
            onClick={handleImageApply}
            className="px-16"
          >
            Apply
          </Button>
        </Modal.Footer>
      </Modal.Content>
    );
  }

  return (
    <Modal.Content title="Choose Image" className="w-[720px]">
      {renderImagesContent()}
    </Modal.Content>
  );
};
