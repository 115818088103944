import { useEffect, useRef, useState } from 'react';

import { cn } from '@utils';

type Props = {
  className?: string;
  sticky?: boolean;
  children?: React.ReactNode;
};

export const ModalHeader = ({ className, sticky, children }: Props) => {
  const [isPinned, setPinned] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!sticky) {
      return;
    }

    const observer = new IntersectionObserver(
      (events) => {
        const [event] = events;

        setPinned(event.intersectionRatio < 1);
      },
      { threshold: [1] },
    );

    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [sticky]);

  return (
    <h2
      className={cn('p-8 text-h4', className, {
        'sticky -top-px z-30 border-b transition-colors border-transparent bg-inherit before:absolute before:bg-gradient-to-b before:from-white before:h-20 before:inset-x-0 before:top-full before:translate-y-px before:pointer-events-none before:opacity-0 before:transition-opacity':
          sticky,
        'border-neural-02 before:opacity-100': isPinned,
      })}
      ref={ref}
    >
      {children}
    </h2>
  );
};
