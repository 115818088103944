import { cn } from '@utils';

type Props = {
  className?: string;
};

export const NovoserveLogo = ({ className }: Props) => {
  return (
    <svg
      className={cn('h-[64px] w-[580px] text-[#11F5AC]', className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 579.23 63.73"
      fill="currentColor"
    >
      <g id="novo">
        <polygon
          fill="#11F5AC"
          id="n"
          points="13.68,21.82 13.68,63.73 0,63.73 0,0 17.61,0 38.56,43.92 38.56,0 52.24,0 52.24,63.73 34.54,63.73  "
        />
        <path
          fill="#11F5AC"
          id="o1"
          d="M203.69,63.64v-16c3.52,0,4.87-1.25,5.8-2.61c1.26-1.84,2.77-5.62,2.77-13.17c0-3.02-0.26-5.75-0.78-8.12   c-0.44-1.99-1.07-3.63-1.88-4.89c-0.6-0.92-1.29-1.57-2.17-2.04c-0.92-0.49-2.17-0.73-3.73-0.73v-16c4.2,0,7.98,0.87,11.23,2.6   c3.3,1.75,6.03,4.27,8.11,7.49c1.85,2.87,3.22,6.28,4.07,10.14c0.77,3.49,1.16,7.38,1.16,11.55c0,9.5-1.83,16.77-5.58,22.24   C218.39,60.34,211.82,63.64,203.69,63.64z"
        />
        <polygon
          fill="#11F5AC"
          id="v1"
          points="160.72,63.73 140.25,63.73 122.26,0 136.99,0 150.48,50.71 163.97,0 178.71,0  "
        />
        <path
          fill="#11F5AC"
          id="o2"
          d="M79.04,63.64v-16c3.52,0,4.87-1.25,5.8-2.61c1.26-1.84,2.77-5.62,2.77-13.17c0-3.02-0.26-5.75-0.78-8.12   c-0.44-1.99-1.07-3.63-1.88-4.89c-0.6-0.92-1.29-1.57-2.17-2.04c-0.92-0.49-2.17-0.73-3.73-0.73v-16c4.2,0,7.98,0.87,11.23,2.6   c3.31,1.75,6.04,4.27,8.12,7.49c1.85,2.87,3.22,6.28,4.07,10.14c0.77,3.49,1.16,7.38,1.16,11.55c0,9.5-1.83,16.77-5.58,22.24   C93.73,60.34,87.17,63.64,79.04,63.64z"
        />
      </g>
      <g id="serve">
        <path
          id="s"
          fill="#11F5AC"
          d="M269.2,63.73c-7.37,0-15.12-1.58-20.57-3.99l2.39-10.78c5.84,1.77,11.77,2.97,18.37,2.97   c7.37,0,8.81-1.39,8.81-6.6c0-5.57-0.29-6.22-10.72-8.45c-15.41-3.25-17.7-6.22-17.7-19.6c0-12.35,4.5-17.28,22.01-17.28   c5.55,0,12.25,0.56,19.33,2.04l-1.44,11.71c-7.27-1.21-11.96-1.77-17.7-1.77c-6.41,0-7.85,1.12-7.85,5.67   c0,5.94,0.29,6.04,10.33,8.36c17.23,4,18.09,7.25,18.09,19.42C292.55,57.69,288.44,63.73,269.2,63.73"
        />
        <polygon
          id="e1"
          fill="#11F5AC"
          points="519.65,63.73 519.65,0 559.75,0 559.75,12.73 534.01,12.73 534.01,24.4 552.7,24.4    552.7,37.13 534.01,37.13 534.01,51 559.75,51 559.75,63.73  "
        />
        <path
          id="r"
          fill="#11F5AC"
          d="M410.32,25.93v-7.85C410.32,6.12,404.58,0,389.08,0H378.1v12.73h10.22c5.83,0,7.66,1.91,7.66,6.7   v5.07c0,4.78-1.53,6.98-7.66,6.98H378.1v12.44h7.25l14.07,19.81h17.03l-15.41-21.82C407.45,39.23,410.32,33.87,410.32,25.93"
        />
        <polygon
          id="v2"
          fill="#11F5AC"
          points="477.58,63.73 457.1,63.73 439.11,0 453.85,0 467.34,50.71 480.83,0 495.57,0  "
        />
        <polygon
          id="e2"
          fill="#11F5AC"
          points="315.14,63.73 315.14,0 355.24,0 355.24,12.73 329.5,12.73 329.5,24.4 348.19,24.4    348.19,37.13 329.5,37.13 329.5,51 355.24,51 355.24,63.73  "
        />
        <g id="copyright">
          <path
            id="copyright_r"
            fill="#11F5AC"
            d="M573.66,5.27c0-0.33-0.12-0.45-0.52-0.45h-0.77v1.27h0.77c0.42,0,0.52-0.15,0.52-0.47    V5.27z M572.94,6.93h-0.57v1.34h-0.97V3.95h1.8c1.05,0,1.43,0.42,1.43,1.22v0.53c0,0.54-0.19,0.9-0.63,1.08l1.04,1.48h-1.15    L572.94,6.93z"
          />
          <path
            id="copyright_ring"
            fill="#11F5AC"
            d="M572.8,1.3c2.83,0,5.14,2.3,5.14,5.14s-2.3,5.14-5.14,5.14s-5.14-2.3-5.14-5.14    S569.97,1.3,572.8,1.3 M572.8,0c-3.55,0-6.44,2.88-6.44,6.44c0,3.55,2.88,6.44,6.44,6.44c3.55,0,6.44-2.88,6.44-6.44    C579.23,2.88,576.35,0,572.8,0L572.8,0z"
          />
        </g>
      </g>
    </svg>
  );
};
