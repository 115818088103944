import { contextNamesSelector } from '@selectors';
import { useHelmetTitle, useSelector } from '@hooks';
import { getDashboardLinkParams } from '@utils';

import { Helmet } from '@components';
import { DashboardReportLink } from '@components/Ui';

export const ProjectSettingsReportsOutlet = () => {
  const { organizationName, projectName } = useSelector(contextNamesSelector);

  const { makeTitle } = useHelmetTitle();

  const getParams = (source: string, searchParams?: Record<string, string>) => {
    const params = getDashboardLinkParams({
      source,
      titlePattern: 'Project {{source}} dashboard',
    });

    return {
      ...params,
      prefix: 'project',
      params: {
        ...searchParams,
        'var-org_name': organizationName!,
        'var-project_name': projectName!,
        refresh: '1m',
      },
    };
  };

  return (
    <>
      <Helmet title={makeTitle('Reports', 'Project Settings', '%p')} />
      <div className="flex flex-wrap gap-10">
        <DashboardReportLink {...getParams('credits')} />
        <DashboardReportLink {...getParams('jobs', { orgId: '1' })} />
      </div>
    </>
  );
};
