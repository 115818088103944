import { faExternalLink } from '@fortawesome/pro-thin-svg-icons';

import { clusterContextSelector } from '@selectors';
import { useSelector } from '@hooks';
import { as } from '@utils';

import { Icon, Link } from '@components';

type Props = {
  title: string;
  source: string;
  prefix?: string;
  params?: Record<string, string>;
};

export const DashboardReportLink = ({
  title,
  source,
  prefix,
  params,
}: Props) => {
  const cluster = useSelector(clusterContextSelector);
  const { metricsUrl } = as.c(cluster);

  const makeUrl = (): string => {
    const sourcePartial = prefix ? `${prefix}_${source}` : source;
    const url = new URL(`${metricsUrl}/d/${sourcePartial}/${source}`);

    if (params) {
      const search = new URLSearchParams(params);

      url.search = search.toString();
    }

    return url.toString();
  };

  const url = makeUrl();

  return (
    <Link
      theme
      external
      blank
      className="flex h-56 w-56 flex-col items-center justify-center gap-4 rounded-xl border border-neural-02 bg-white p-4 transition-colors hover:border-rebecca"
      to={url}
    >
      <Icon
        icon={faExternalLink}
        className="h-14 w-14 text-[48px] text-primary"
      />
      <p className="text-center text-h6 font-400 capitalize">{title}</p>
    </Link>
  );
};
