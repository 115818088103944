import { faUsersGear } from '@fortawesome/pro-thin-svg-icons';

import { useHostname } from '@hooks/useHostname';
import { useMailContext } from '@hooks/useMailContext';

import { Link } from '@components';
import { Layout } from '@components/Layouts';
import { EmptyContent } from '@components/Ui';

export const ClusterRequiredPage = () => {
  const { title } = useHostname();
  const { mailto } = useMailContext();

  return (
    <Layout showFeatures={false} className="flex justify-center">
      <EmptyContent
        variant="layout"
        icon={faUsersGear}
        title={`Welcome to ${title}!`}
      >
        <p slot="text" className="mt-4 text-center">
          Looks like you don&apos;t have access to any clusters yet <br />
          Please contact your organization&apos;s administrator or&nbsp;
          <Link
            theme
            external
            to={mailto}
            className="inline-block text-primary underline hover:no-underline"
          >
            Apolo support
          </Link>
        </p>
      </EmptyContent>
    </Layout>
  );
};
