import { CSSTransition } from 'react-transition-group';

type Props = {
  className?: string;
  in?: boolean;
  mountOnEnter?: boolean;
  unmountOnExit?: boolean;
  timeout?: number;
  children: React.ReactNode;
};

export const Transition = ({
  in: state = false,
  mountOnEnter = false,
  unmountOnExit = false,
  className,
  timeout = 300,
  children,
  ...props
}: Props) => (
  <CSSTransition
    {...props}
    classNames={className}
    in={state}
    mountOnEnter={mountOnEnter}
    unmountOnExit={unmountOnExit}
    timeout={timeout}
  >
    {children}
  </CSSTransition>
);
