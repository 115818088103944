import { useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { ModalProps } from '@typings';
import { createDisk } from '@services';
import { clusterContextSelector, contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import {
  formatModelName,
  noop,
  normalizeFormErrors,
  toastifyResponseError,
} from '@utils';

import { Button, Field, Modal } from '@components';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  storage: z
    .string()
    .min(1)
    .transform((value, context) => {
      const formattedValue = Number(value);

      if (!value || Number.isNaN(formattedValue)) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Storage must be a number',
        });

        return z.NEVER;
      }

      if (formattedValue <= 0) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          message: 'Storage must be positive',
        });

        return z.NEVER;
      }

      return formattedValue;
    }),
  name: z
    .string()
    .min(3)
    .max(40)
    .refine((value) => /^[a-zA-Z0-9 -]*$/.test(value), 'Invalid name'),
  lifeSpan: z.string().transform((value, context) => {
    const match = value.match(
      /^(?<d>[1-9]\d*d\s*)?(?<h>[1-9]\d*h\s*)?(?<m>[1-9]\d*m?\s*)?$/,
    );

    if (!match) {
      context.addIssue({
        code: z.ZodIssueCode.custom,
        message: 'Invalid lifespan value',
      });

      return z.NEVER;
    }

    const toNum = (value: string | undefined, trimSuffix: string): number => {
      return Number(value?.trim().replace(trimSuffix, '') || 0);
    };

    const days = toNum(match.groups?.d, 'd');
    const hours = toNum(match.groups?.h, 'h');
    const minutes = toNum(match.groups?.m, 'm');

    return (days * 1440 + hours * 60 + minutes) * 60;
  }),
});

type Props = ModalProps & {
  getDisks: () => Promise<void>;
};

export const CreateDiskModal = ({ getDisks, closeModal = noop }: Props) => {
  /**
   * todo: split to constants
   */
  const FACTOR = {
    GB: 10 ** 9,
    TB: 10 ** 12,
  };

  const { disksUrl } = useSelector(clusterContextSelector)!;
  const { organizationName, projectName } = useSelector(contextNamesSelector);

  const { register, formState, handleSubmit } = useForm({
    resolver: zodResolver(schema),
  });

  const factorAliasRef = useRef<HTMLSelectElement>(null);
  const [loading, setLoading] = useState(false);

  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const handleFormSubmit = handleSubmit(async ({ name, storage, lifeSpan }) => {
    setLoading(true);

    const factorAlias = factorAliasRef.current?.value as keyof typeof FACTOR;

    try {
      const formattedName = formatModelName(name);

      await createDisk({
        disksUrl,
        organizationName,
        projectName: projectName!,
        name: formattedName,
        lifeSpan,
        storage: storage * FACTOR[factorAlias],
      });
      await getDisks();

      closeModal();
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  });

  return (
    <Modal.Content className="w-[500px]" title="Create New Disk">
      <form className="flex flex-col gap-6">
        <div className="flex items-end gap-4">
          <Field.Input
            {...register('storage')}
            label="Storage"
            className="w-full"
            containerClassName="flex-1"
            error={errors.storage}
          />
          <Field.Select
            defaultValue="GB"
            ref={factorAliasRef}
            containerClassName="w-[108px]"
          >
            <option value="GB">GB</option>
            <option value="TB">TB</option>
          </Field.Select>
        </div>
        <Field.Input
          {...register('name')}
          label="Name"
          className="w-full"
          note="Name should uniquely identify disk within cluster"
          error={errors.name}
        />
        <Field.Input
          {...register('lifeSpan')}
          label="Lifespan"
          className="w-full"
          note="Maximum period of time a disk is allowed to live, sequence of numbers with an optional suffix ('d' for days, 'h' for hours, 'm' for minutes), e.g. 1d 2h 3m"
          error={errors.lifeSpan}
        />
      </form>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button loading={loading} className="px-10" onClick={handleFormSubmit}>
          Create
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
