/* eslint-disable @typescript-eslint/no-use-before-define */
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import { clsx } from 'clsx';

import { AnyFunction } from '@typings';
import { cn } from '@utils';

import { Button, Icon } from '@components';

type Props = {
  attention?: boolean;
  disabled?: boolean;
  icon: IconProp;
  children: React.ReactNode;
  onClick?: AnyFunction;
};

export const OrganizationUserAction = ({
  attention,
  disabled,
  icon,
  children,
  onClick,
}: Props) => {
  const classNames = clsx({
    'text-error': attention,
    'text-neural-02 pointer-events-none': disabled,
    'text-error-subtle': attention && disabled,
  });

  return (
    <Button
      theme
      onClick={onClick}
      className={cn(
        'flex items-center gap-2.5 px-4 py-2.5 text-rebecca',
        classNames,
      )}
    >
      <Icon
        icon={icon}
        className={cn('text-[20px] text-neural-04', classNames)}
      />
      {children}
    </Button>
  );
};
