import { lookup } from 'mrmime';

type ReturnType = {
  isImage: boolean;
  isAudio: boolean;
  isVideo: boolean;
  isText: boolean;
  name: string;
  mime: string;
  type: string;
};

export const getFormattedStorageFile = (fileName: string): ReturnType => {
  const mime = lookup(fileName) ?? '';
  const type = mime.split('/')[0] ?? '';

  return {
    mime,
    name: fileName,
    type,
    isImage: type === 'image',
    isAudio: type === 'audio',
    isVideo: type === 'video',
    isText: type === 'text',
  };
};
