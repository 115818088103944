import { createSelector } from 'reselect';

import { State, ViewType } from '@typings';

export const cacheSelector = (state: State) => state.cache;

export const permissionSelector = createSelector(
  cacheSelector,
  ({ permissions }) => permissions,
);

export const userClustersSelector = createSelector(
  cacheSelector,
  ({ userClusters }) => userClusters,
);

export const viewTypeSelector = createSelector(
  cacheSelector,
  ({ viewType }) => {
    const isGridView = viewType === ViewType.Grid;
    const isListView = viewType === ViewType.List;

    return { viewType, isGridView, isListView };
  },
);

export const organizationsInvitesSelector = createSelector(
  cacheSelector,
  ({ organizationInvites }) => organizationInvites,
);
