import React from 'react';
import ReactDOM from 'react-dom/client';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import * as Sentry from '@sentry/react';

import { store } from '@store';

import { Application } from '@components/Application';
import { AuthProvider, PrimeReactProvider } from '@components/Providers';
import { FallbackError } from '@components/Ui';

import '@features/dayjs.feature';

import './globals.css';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);

root.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={<FallbackError />}>
      <Provider store={store}>
        <BrowserRouter>
          <AuthProvider>
            <PrimeReactProvider>
              <Application />
            </PrimeReactProvider>
          </AuthProvider>
        </BrowserRouter>
      </Provider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
);
