import '@features/dayjs.feature';

import { Timestamp, UnixTimestamp } from './shared';

export namespace BillingUsage {
  type Base = {
    credits: number;
    date: Timestamp;
    categoryName: string;
  };

  export type WithProject = {
    projectName: string;
    usage: Base[];
  };

  export type WithoutProject = Base;
}

export namespace Billing {
  export enum Tab {
    Usage = 'usage',
    Credits = 'credits',
  }

  export enum Group {
    Category = 'category',
    Project = 'project',
    Day = 'day',
    Month = 'month',
  }

  export type Params = {
    groups: Group[];
    clusterName: string;
    projectName: string;
    startDate: UnixTimestamp;
    endDate: UnixTimestamp;
    /**
     * Internal param to show billing with or without project column
     * It depends what kind of billing interface to show to user
     */
    isGroupedByProject: boolean;
    monthToDateUsage: BillingInterface.WithoutProject[];
  };

  export type Response<Usage = unknown> = {
    clusterName: string;
    orgName: string;
    projectName?: string;
    startDate: UnixTimestamp;
    endDate: UnixTimestamp;
    /**
     * Set manually on fetch based on billing params
     */
    usage: Usage[];
  };

  export type Interface<Usage = unknown> = {
    clusterName: string;
    /**
     * Set manually on fetch based on billing params
     */
    usage: Usage[];
  };

  export type State = {
    withProject: BillingInterface.WithProject[];
    withoutProject: BillingInterface.WithoutProject[];
  };
}

export namespace BillingInterface {
  /**
   * Grouped by "category" param adds `categoryName` to response `usage`.
   * It is soft change and can be supported just diplaying or hiding `categoryName`
   */
  export type WithCategory = never;

  /**
   * Grouped by "day" or "month" param adds `date` to response `usage`.
   * It is soft change and can be supported just diplaying or hiding `date`
   */
  export type WithDate = never;

  /**
   * Grouped by "project" param makes totally different billing response `usage`
   * with nested project `usage`
   */
  export type WithProject = Billing.Interface<BillingUsage.WithProject>;

  /**
   * Regular billing response
   */
  export type WithoutProject = Billing.Interface<BillingUsage.WithoutProject>;
}
