import { Any, Cluster, GpuModel } from '@typings';

export const as = <T = Any>(source: Any, defaultSource: T): T =>
  source ?? defaultSource;

/**
 * As any object
 */
as.o = <T = Any>(source: Any): T => as(source, {} as T);

/**
 * As any array
 */
as.a = <T = Any>(source: Any): T => as(source, [] as T);

/**
 * As cluster model
 */
as.c = <T = Cluster.Model>(source: Any) => as.o<T>(source);

/**
 * As GPU model
 */
as.gpu = <T = GpuModel.Interface>(source: Any) => as.o<T>(source);
