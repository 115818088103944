import { useState } from 'react';
import { faDatabase } from '@fortawesome/pro-solid-svg-icons';

import { ModalProps } from '@typings';
import { deleteDisk } from '@services';
import { clusterContextSelector } from '@selectors';
import { useSelector } from '@hooks';
import { noop, toastifyResponseError } from '@utils';

import { Button, Icon, Modal } from '@components';

type Props = ModalProps & {
  id: string;
  name: string;
  getDisks: () => Promise<void>;
};

export const DeleteDiskModal = ({
  id,
  name,
  getDisks,
  closeModal = noop,
}: Props) => {
  const { disksUrl } = useSelector(clusterContextSelector)!;

  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    setLoading(true);

    try {
      await deleteDisk({ disksUrl, id });
      await getDisks();

      closeModal();
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Modal.Content title="Delete Disk" className="w-[520px]">
      <p className="flex flex-wrap items-center justify-center text-neural-03">
        Are you sure you want to delete
        <span className="space-both inline-flex items-center gap-1 text-body text-black">
          <Icon icon={faDatabase} className="h-4 w-4 text-[14px]" />
          {name}
        </span>
        disk?
      </p>

      <Modal.Footer>
        <Button variant="ghost" onClick={closeModal}>
          Cancel
        </Button>
        <Button loading={loading} className="px-10" onClick={handleDelete}>
          Delete
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
