import { Navigate, Route, Routes } from 'react-router-dom';

import { Billing } from '@typings';
import { PATH } from '@constants';

import * as Page from '@pages';
import { BillingLayout } from '@components/Layouts';

export const BillingRoutes = () => {
  return (
    <Routes>
      <Route path="" element={<Navigate replace to={PATH.BILLING_USAGE} />} />
      <Route element={<BillingLayout />}>
        <Route path={Billing.Tab.Usage} element={<Page.BillingUsage />} />
        <Route path={Billing.Tab.Credits} element={<Page.BillingCredits />} />
      </Route>
      <Route path="*" element={<Navigate replace to={PATH.BILLING_USAGE} />} />
    </Routes>
  );
};
