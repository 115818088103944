import { useState } from 'react';
import { faCircleNodes } from '@fortawesome/pro-solid-svg-icons';

import { BILLING_ALL_ENTRY } from '@constants';

import { BillingPopoverItem, BillingPopper } from '@components/Billing';

type Props = {
  disabled?: boolean;
  clusterName: string;
  clusters: string[];
  onSelect: (clusterName: string) => void;
};
export const BillingPopoverClusters = ({
  disabled,
  clusterName: billingClusterName,
  clusters,
  onSelect,
}: Props) => {
  const [popoverOpen, setPopoverOpen] = useState(false);

  const isAllSelected = billingClusterName === BILLING_ALL_ENTRY;
  const popperTitle = isAllSelected ? 'All Clusters' : billingClusterName;

  const currySelect = (clusterName: string) => () => {
    onSelect(clusterName);
    setPopoverOpen(false);
  };

  const makeItem = (clusterName: string) => (
    <BillingPopoverItem
      key={clusterName}
      active={billingClusterName === clusterName}
      title={clusterName}
      icon={faCircleNodes}
      onClick={currySelect(clusterName)}
    />
  );

  return (
    <BillingPopper
      disabled={disabled}
      open={popoverOpen}
      onOpenChange={setPopoverOpen}
      title={popperTitle}
      align="start"
    >
      <BillingPopoverItem.All
        active={isAllSelected}
        icon={faCircleNodes}
        onClick={currySelect(BILLING_ALL_ENTRY)}
      />
      {clusters.map(makeItem)}
    </BillingPopper>
  );
};
