import { useEffect, useRef, useState } from 'react';
import { faEnvelope, faUser } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';

import {
  AsyncFunction,
  ClusterSettings,
  ModalProps,
  TenantRole,
} from '@typings';
import { NO_ORGANIZATION } from '@constants';
import { axios, toast } from '@features';
import { addClusterUser } from '@services';
import { configSelector, contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import {
  as,
  capitalize,
  getFormattedApiUrl,
  getFormattedOrganizationName,
  noop,
  toastifyResponseError,
} from '@utils';

import { Button, Field, Icon, Modal, Render } from '@components';

type User = {
  name: string;
  email: string;
};

type Props = ModalProps & {
  organizationUsers: ClusterSettings.User[];
  getOrganizationUsers: AsyncFunction;
};

export const ClusterAddUser = ({
  organizationUsers,
  getOrganizationUsers,
  closeModal = noop,
}: Props) => {
  const { adminUrl } = useSelector(configSelector);
  const { organizationName, clusterName } = useSelector(contextNamesSelector);

  const roleRef = useRef<HTMLSelectElement>(null);
  const [loading, setLoading] = useState(false);
  const [username, setUsername] = useState('');
  const [input, setInput] = useState('');
  const [lookupUsers, setLookupUsers] = useState<User[]>([]);

  const formatUser = ({
    userName: name,
    userInfo: { email },
  }: ClusterSettings.User): User => ({
    name,
    email,
  });

  const isNoOrganization = organizationName === NO_ORGANIZATION;
  const usernameInputNote = as(
    !isNoOrganization &&
      'You can only add users that are members of your organization',
    'Search by name or email',
  );
  const formattedOrganizationUsers = organizationUsers.map(formatUser);
  const notOrganizationUsers = lookupUsers.filter(
    ({ name }) =>
      !formattedOrganizationUsers.some(
        ({ name: organizationUserName }) => name === organizationUserName,
      ),
  );

  useEffect(() => {
    const getLookupUsers = async () => {
      try {
        setLoading(true);

        const url = getFormattedApiUrl({ apiUrl: adminUrl, organizationName });

        const users = await axios.get<never, User[] | ClusterSettings.User[]>(
          `${url}/users`,
          { params: { lookup: isNoOrganization, with_user_info: true } },
        );
        const user = users[0];

        const formattedLookupUsers = (user as ClusterSettings.User).userInfo
          ? (users as ClusterSettings.User[]).map(formatUser)
          : (users as User[]);

        setLookupUsers(formattedLookupUsers);
      } catch (error) {
        toast.error('Could not fetch user lookup list');
      } finally {
        setLoading(false);
      }
    };

    if (clusterName) {
      getLookupUsers();
    }
  }, [isNoOrganization, adminUrl, clusterName, organizationName]);

  const handleUsernameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setInput(event.target.value);
    setUsername('');
  };

  const handleUserAdd = async () => {
    if (!username) {
      return;
    }

    try {
      setLoading(true);

      const role = roleRef.current?.value as TenantRole;

      await addClusterUser({
        adminUrl,
        clusterName: clusterName!,
        organizationName,
        username,
        role,
      });
      await getOrganizationUsers();

      toast.success(`User ${username} added to ${clusterName} cluster`);

      closeModal();
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  const roleOptions = Object.values(TenantRole).map((role) => ({
    value: role,
    text: capitalize(role),
  }));

  const filterUser = ({ name, email }: User): boolean =>
    [name, email].some((field) => field.startsWith(input));

  const makeUser = ({ name, email }: User) => {
    const isFilteredByName = name.startsWith(input);
    const icon = isFilteredByName ? faUser : faEnvelope;
    const isSelected = username === name;

    return (
      <Button
        key={name}
        theme
        className={clsx(
          'flex items-center gap-3 rounded-xl p-2 transition-colors hover:bg-background',
          { 'bg-background': isSelected },
        )}
        onClick={() => setUsername(name)}
      >
        <Icon
          icon={icon}
          className={clsx(
            'h-10 w-10 overflow-hidden rounded-full bg-stroke text-neural-04 transition-colors',
            { 'bg-primary-light text-white': isSelected },
          )}
        />
        <div className="text-left">
          <p>{name}</p>
          <p className="text-caption text-neural-04 transition-colors">
            {email}
          </p>
        </div>
      </Button>
    );
  };

  const renderUsers = () => {
    const filteredUsers = notOrganizationUsers.filter(filterUser);

    if (input.length < 2) {
      return null;
    }

    if (!filteredUsers.length) {
      return (
        <p className="mt-8 break-words text-center text-neural-03">
          No users found in&nbsp;
          <span className="text-rebecca">
            {getFormattedOrganizationName(organizationName)}
          </span>
          &nbsp;organization
        </p>
      );
    }

    return filteredUsers.map(makeUser);
  };

  return (
    <Modal.Content title="Add User" className="w-[578px]">
      <p className="text-footnote text-neural-03">
        Add user to
        <span className="space-both text-body text-black">{clusterName}</span>
        cluster
        <Render if={!isNoOrganization}>
          &nbsp;within
          <span className="space-both text-body text-black">
            {organizationName}
          </span>
          organization
        </Render>
      </p>
      <div className="mt-8 flex gap-4">
        <Field.Input
          value={input}
          containerClassName="flex-1"
          label="Username / Email"
          note={usernameInputNote}
          onChange={handleUsernameChange}
        />
        <Field.Select
          ref={roleRef}
          options={roleOptions}
          label="Role"
          containerClassName="basis-[140px]"
        />
      </div>
      <div className="mt-6 flex h-[200px] flex-col gap-4 overflow-auto">
        {renderUsers()}
      </div>
      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          loading={loading}
          disabled={!username}
          className="px-10"
          onClick={handleUserAdd}
        >
          Add
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
