import { Params, Storage } from '@typings';
import { API_ROOT } from '@constants';
import { axios } from '@features';
import { path } from '@utils';

export const getStorageStatus = async ({
  storageUrl,
  storagePath,
}: Params.GetStorageStatus) => {
  const { FileStatus } = await axios.get<
    never,
    { FileStatus: Storage.FileStatus }
  >(`${storageUrl}${storagePath}`, {
    params: { op: Storage.Operation.GetFileStatus },
  });

  return FileStatus;
};

export const getStorageList = async ({
  storageUrl,
  storagePath,
}: Params.GetStorageList) => {
  const {
    FileStatuses: { FileStatus },
  } = await axios.get<
    never,
    { FileStatuses: { FileStatus: Storage.FileStatus[] } }
  >(`${storageUrl}${storagePath}`, {
    params: { op: Storage.Operation.ListStatus },
  });

  return FileStatus;
};

export const createStorageFolder = ({
  storageUrl,
  pathWithFolder,
}: Params.CreateStorageFolder) =>
  axios.put(`${storageUrl}${pathWithFolder}`, undefined, {
    params: { op: Storage.Operation.MkDirs },
  });

export const renameStorageObject = ({
  newPath,
  sourcePath,
  storageUrl,
}: Params.RenameStorageObject) =>
  axios.post(path.create(storageUrl, sourcePath), undefined, {
    params: { op: Storage.Operation.Rename, destination: newPath },
  });

export const deleteStorageObject = ({
  sourcePath,
  storageUrl,
}: Params.DeleteStorageObject) =>
  axios.delete(`${storageUrl}/${sourcePath}`, {
    params: { op: Storage.Operation.Delete, recursive: true },
  });

export const uploadStorageFiles = ({
  storageUrl,
  storagePath,
  files,
}: Params.UploadStorageFiles) => {
  const promises = files.map((file) => {
    const filePath = path.create(storagePath, file.path);

    return axios.put<never, never>(path.create(storageUrl, filePath), file, {
      headers: { 'Content-Type': file.type },
    });
  });

  return Promise.all(promises);
};

export const shareStorage = ({
  username,
  uri,
  permission,
}: {
  username: string;
  uri: string;
  permission: Storage.Permission;
}) => {
  // eslint-disable-next-line no-console
  console.log(username, uri, permission);

  return axios.post(`${API_ROOT}`);
};
