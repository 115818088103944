import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { useContextSearchParams } from '@hooks';

import { Icon, Link, Render } from '@components';

type Props = {
  name: string;
  path: string;
  icon?: IconProp;
  tags?: string[];
  description?: string;
};

export const AppItemBuiltIn = ({
  name,
  path,
  icon,
  tags,
  description,
}: Props) => {
  const { contextSearchParams } = useContextSearchParams();

  const makeTag = (tag: string) => (
    <p
      key={tag}
      className="flex h-[22px] items-center rounded-2xl bg-neural-01 px-2 text-footnote text-black"
    >
      {tag}
    </p>
  );

  return (
    <div className="group flex min-h-[160px] gap-6 rounded-xl border border-transparent bg-white p-6 transition-colors hover:border-neural-02">
      <Icon
        icon={icon}
        className="h-14 w-14 rounded-lg bg-rebecca text-[36px] text-white"
      />
      <div className="relative flex flex-1 flex-col">
        <div className="flex items-center gap-4">
          <p className="mr-auto text-h6 text-black">{name}</p>
          <p className="flex h-[22px] items-center rounded-2xl bg-primary-subtle px-2 text-footnote text-black">
            Installed
          </p>
        </div>
        <Render if={tags}>
          <div className="mt-3 flex gap-x-4 gap-y-2">{tags?.map(makeTag)}</div>
        </Render>
        <Render if={description}>
          <p className="mt-4 text-footnote text-neural-04 transition-opacity group-hover:opacity-0">
            {description}
          </p>
        </Render>
        <div className="absolute inset-x-0 bottom-0 flex justify-end gap-3 opacity-0 transition-opacity group-hover:opacity-100 ">
          <Link to={`${path}?${contextSearchParams}`} className="capitalize">
            Open
          </Link>
        </div>
      </div>
    </div>
  );
};
