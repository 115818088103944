import dayjs from 'dayjs';
import {
  faCircleXmark,
  faFlag,
  faMicrochip,
  faTrophy,
} from '@fortawesome/pro-solid-svg-icons';
import clsx from 'clsx';

import { App, Job } from '@typings';
import { cn } from '@utils';

import { Badge, Icon, Render, Tooltip } from '@components';

type Props = {
  className?: string;
  status: Job.Status | App.Status;
  createdAt?: string;
  description?: string | null;
  variant?: 'details' | 'default';
};

export const JobStatus = ({
  className,
  status,
  createdAt,
  description,
  variant = 'default',
}: Props) => {
  const title = description ?? '';
  const isDetails = variant === 'details';

  const icon = (() => {
    switch (status) {
      case 'pending':
        return faFlag;
      case 'succeeded':
        return faTrophy;
      case 'failed':
      case 'cancelled':
        return faCircleXmark;
      default:
        return faMicrochip;
    }
  })();

  const badgeVariant = (() => {
    switch (status) {
      case 'pending':
      case 'cancelled':
        return 'yellow';
      case 'succeeded':
        return 'green';
      case 'failed':
        return 'red';
      default:
        return 'grey';
    }
  })();

  const iconClassName = isDetails ? 'text-[20px]' : 'h-4 w-4 text-[14px]';

  return (
    <Badge
      className={cn('flex w-max items-center gap-1.5', className)}
      variant={badgeVariant}
    >
      <Icon icon={icon} className={iconClassName} />
      <Tooltip disabled={!description} className="w-60">
        <div slot="trigger" title={title}>
          <p className="capitalize">{status}</p>
          <Render if={createdAt}>
            <p
              className={clsx('text-caption-3', {
                'text-neural-04 leading-normal': isDetails,
              })}
            >
              {dayjs(createdAt).fromNow()}
            </p>
          </Render>
        </div>
        {title}
      </Tooltip>
    </Badge>
  );
};
