import clsx from 'clsx';

import { getSlots } from '@utils';

import { Render, Slot } from '@components';

type Props = {
  className?: string;
  title?: string;
  text?: string | number;
  children?: React.ReactNode;
};

export const DrawerDetailsItem = ({
  className,
  title,
  text,
  children,
}: Props) => {
  const slots = getSlots(children);

  return (
    <div className={clsx('flex flex-col gap-2', className)}>
      <Render if={title}>
        <p className="text-h6">{title}</p>
      </Render>
      <Slot slot={slots.text}>
        <Render if={text}>
          <p className="text-neural-04">{text}</p>
        </Render>
      </Slot>
    </div>
  );
};
