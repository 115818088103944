import { useCallback, useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';

import { JWT_TOKEN } from '@constants';
import { initViewType, setUser, UserState } from '@slices';
import {
  getConfig,
  getPermissions as getPermissionsThunk,
  getUserClusters as getUserClustersThunk,
} from '@thunks';
import { configSelector, userSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import { formatToCamelCase, getUsernameFromToken, localStorage } from '@utils';

export const useInitialization = (): {
  isInitialized: boolean;
  isError: boolean;
} => {
  const dispatch = useDispatch();
  const { username } = useSelector(userSelector);
  const config = useSelector(configSelector);

  const {
    isLoading: isAuthenticating,
    isAuthenticated,
    user,
    getAccessTokenSilently,
  } = useAuth0();

  const [areUserClustersResolved, setUserClustersResolved] = useState(false);
  const [isConfigFetched, setConfigFetched] = useState(false);
  const [isError, setError] = useState(false);

  const { adminUrl } = config;

  const getToken = useCallback(async () => {
    try {
      const token = await getAccessTokenSilently();

      localStorage.set<string>(JWT_TOKEN, token);

      return token;
    } catch (error) {
      /**
       * Continue regardless error
       */
    }
  }, [getAccessTokenSilently]);

  const fetchConfig = useCallback(async () => {
    try {
      await dispatch(getConfig());
    } catch (error) {
      setError(true);
    } finally {
      setConfigFetched(true);
    }
  }, [dispatch]);

  /**
   * Fetches token on initialization for axios request interceptor
   */
  useEffect(() => {
    const run = async () => {
      try {
        await getToken();
      } catch (error) {
        /**
         * Continue regardless error
         */
      } finally {
        await fetchConfig();
      }
    };

    if (!isAuthenticating) {
      run();
    }
  }, [isAuthenticating, getToken, fetchConfig]);

  /**
   * Updates user state on every user model change
   */
  useEffect(() => {
    const getPermissions = async (username: string) => {
      try {
        await dispatch(getPermissionsThunk(username, 'blob:'));
      } catch (error) {
        /**
         * Continue regardless error
         */
      }
    };

    if (isConfigFetched && isAuthenticated && user) {
      const username = getUsernameFromToken(localStorage.get(JWT_TOKEN));
      const formattedUser = formatToCamelCase(user) as UserState;

      if (!username) {
        // eslint-disable-next-line no-console
        console.error(
          'Could not get username from token for authenticated user',
        );
      }

      if (formattedUser && username) {
        formattedUser.username = username;
      }

      if (username) {
        getPermissions(username);
      }

      dispatch(setUser(formattedUser));
    }
  }, [isConfigFetched, isAuthenticated, user, dispatch, getToken]);

  useEffect(() => {
    dispatch(initViewType());
  }, [dispatch]);

  useEffect(() => {
    const getUserClusters = async () => {
      if (!isAuthenticated) {
        setUserClustersResolved(true);
        return;
      }

      try {
        /**
         * There is case for authorized but not verified user without `username`. For this case skip fetching user clusters
         */
        if (!username || !adminUrl) {
          return;
        }

        await dispatch(getUserClustersThunk(adminUrl!, username));
      } catch (error) {
        /**
         * Continue regardless error
         */
      } finally {
        setUserClustersResolved(true);
      }
    };

    if (!isAuthenticating) {
      getUserClusters();
    }
  }, [isAuthenticating, isAuthenticated, username, adminUrl, dispatch]);

  return {
    isInitialized: isConfigFetched && areUserClustersResolved,
    isError,
  };
};
