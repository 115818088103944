import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { Primitive } from '@typings';
import { PATH } from '@constants';
import { buyCredits } from '@services';
import { contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import {
  normalizeFormErrors,
  setFormBackendErrors,
  toastifyResponseError,
} from '@utils';

import { Badge, Button, Field, Modal } from '@components';

type Props = {
  isUserBalance: boolean;
  balance: Primitive;
};

type Schema = z.infer<typeof schema>;

const schema = z.object({
  credits: z.coerce.number().positive(),
});

export const BuyCreditsModal = ({ isUserBalance, balance }: Props) => {
  const { organizationName, clusterName } = useSelector(contextNamesSelector);

  const { register, formState, setValue, handleSubmit, getValues, setError } =
    useForm<Schema>({
      resolver: zodResolver(schema),
      defaultValues: { credits: 100 },
    });

  const [loading, setLoading] = useState(false);

  const errors = normalizeFormErrors<keyof Schema>(formState.errors);
  const text = isUserBalance
    ? 'Your current balance'
    : 'Current organization balance';

  const getFormattedCredits = (creditsAmount: number): number => {
    const credits = creditsAmount.toString();
    const isDotted = credits.includes('.');

    if (!isDotted) {
      return creditsAmount;
    }

    const dotIndex = credits.indexOf('.');

    if (dotIndex !== -1) {
      const beforeDot = credits.substring(0, dotIndex);
      const afterDot = credits.substring(dotIndex + 1);

      const formattedAfterDot = afterDot.substring(0, 2);

      return Number(`${beforeDot}.${formattedAfterDot}`);
    }

    return creditsAmount;
  };

  const handleFormSubmit = handleSubmit(async ({ credits }) => {
    try {
      setLoading(true);

      const formattedCredits = getFormattedCredits(credits);

      const { checkoutUrl } = await buyCredits({
        clusterName: clusterName!,
        organizationName,
        amountInCents: formattedCredits * 100,
        successUrl: `${window.location.origin}${PATH.HOME}?buy-credits-status=success&credits=${formattedCredits}`,
        cancelUrl: `${window.location.origin}${PATH.HOME}?buy-credits-status=failure`,
      });

      window.location.href = checkoutUrl;
    } catch (error) {
      toastifyResponseError(error);

      setFormBackendErrors(
        error,
        { getValues, setError },
        { replace: [['amount_in_cents', 'credits']] },
      );
    } finally {
      setLoading(false);
    }
  });

  const makeAmount = (amount: number) => (
    <Button theme key={amount} onClick={() => setValue('credits', amount)}>
      <Badge variant="primary" className="leading-3">
        {amount}
      </Badge>
    </Button>
  );

  return (
    <Modal.Content title="Buy Credits" className="w-[480px]">
      <form className="flex flex-col gap-4" onSubmit={handleFormSubmit}>
        <p className="text-caption text-neural-04">
          {text}: <span className="text-rebecca">{balance}</span>
        </p>
        <Field.Input
          {...register('credits')}
          required
          inputMode="numeric"
          type="number"
          label="Credit Amount"
          error={errors.credits}
        />
        <div className="flex gap-2">{[10, 100, 1000].map(makeAmount)}</div>
      </form>
      <Modal.Footer>
        <Button
          loading={loading}
          className="px-10 capitalize"
          onClick={handleFormSubmit}
        >
          Buy credits
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
