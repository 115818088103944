import { useCallback } from 'react';

import { contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';

export const useImageName = (image?: string) => {
  const { clusterName } = useSelector(contextNamesSelector);

  const getImageName = useCallback(
    (imageInput = image) => `image://${clusterName}/${imageInput}`,
    [image, clusterName],
  );

  return {
    imageName: getImageName(),
    getImageName,
  };
};
