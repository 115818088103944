import { useHelmetTitle } from '@hooks';
import { getDashboardLinkParams } from '@utils';

import { Helmet } from '@components';
import { DashboardReportLink } from '@components/Ui';

type Props = {
  isManager: boolean;
};

export const ClusterSettingsReportsOutlet = ({ isManager }: Props) => {
  const { makeTitle } = useHelmetTitle();

  const getParams = (source: string, refreshTimeoutMinutes: string = '1m') => {
    const params = getDashboardLinkParams({
      source,
      titlePattern: 'Cluster {{source}} dashboard',
    });

    return {
      ...params,
      params: {
        orgId: '1',
        refresh: refreshTimeoutMinutes,
      },
    };
  };

  const renderContent = () => {
    if (isManager) {
      return (
        <>
          <DashboardReportLink {...getParams('overview')} />
          <DashboardReportLink {...getParams('nodes')} />
          <DashboardReportLink {...getParams('jobs')} />
          <DashboardReportLink {...getParams('services')} />
          <DashboardReportLink {...getParams('credits', '5m')} />
          <DashboardReportLink {...getParams('prices', '5m')} />
        </>
      );
    }

    return (
      <>
        <DashboardReportLink {...getParams('jobs')} />
        <DashboardReportLink {...getParams('prices', '5m')} />
      </>
    );
  };

  return (
    <>
      <Helmet title={makeTitle('Reports', 'Cluster Settings', '%c')} />
      <div className="flex flex-wrap gap-10">{renderContent()}</div>
    </>
  );
};
