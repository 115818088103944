import { useState } from 'react';
import {
  faCheck,
  faGear,
  faUserGroup,
} from '@fortawesome/pro-regular-svg-icons';
import { faFolder, faPlus } from '@fortawesome/pro-solid-svg-icons';
import { faFolder as faFolderThin } from '@fortawesome/pro-thin-svg-icons';
import clsx from 'clsx';

import { Project } from '@typings';
import { NO_ORGANIZATION, PATH } from '@constants';
import { setContext } from '@slices';
import {
  configSelector,
  contextNamesSelector,
  userClustersSelector,
} from '@selectors';
import {
  useContextSearchParams,
  useDispatch,
  useSelector,
  useTenantRole,
} from '@hooks';
import { as } from '@utils';

import { Button, Icon, Link, Modal, Popover, Render } from '@components';
import { CreateProjectModal } from '@components/Modals';

import { UserPanelButton } from './UserPanelButton';

export const UserPanelProjects = () => {
  const dispatch = useDispatch();
  const { projects = [] } = useSelector(configSelector);
  const {
    projectName,
    organizationName,
    clusterName: contextClusterName,
  } = useSelector(contextNamesSelector);
  const userClusters = useSelector(userClustersSelector);

  const { contextSearchParams } = useContextSearchParams();
  const { isProjectManager } = useTenantRole();

  const [popoverOpen, setPopoverOpen] = useState(false);

  const organizationClusters = userClusters.filter(
    ({ orgName }) => as(orgName, NO_ORGANIZATION) === organizationName,
  );
  const organizationProjects = projects.filter(
    ({ orgName, clusterName }) =>
      organizationName === as(orgName, NO_ORGANIZATION) &&
      clusterName === contextClusterName,
  );
  const projectList = organizationProjects.filter(
    ({ name }) => name !== projectName,
  );

  const hidePopover = () => setPopoverOpen(false);

  const handleProjectChange = (project: Project) => {
    dispatch(setContext({ project }));
  };

  const makeProject = (project: Project) => {
    const { name } = project;

    return (
      <Button
        theme
        key={name}
        className="flex w-full items-center gap-3 rounded-lg p-2 transition-colors hover:bg-neural-01"
        onClick={() => {
          handleProjectChange(project);
          hidePopover();
        }}
      >
        <Icon
          icon={faFolder}
          className="h-10 w-10 rounded-md bg-primary text-[16px] text-white"
        />
        <p className="flex-1 text-left">{name}</p>
      </Button>
    );
  };

  const renderContent = () => {
    if (organizationProjects.length) {
      return (
        <div className="w-full">
          <p className="p-5 text-caption uppercase text-neural-04">Projects</p>
          <Render if={projectName}>
            <div className="bg-neural-01 p-5">
              <div className="flex items-center gap-3">
                <Icon
                  icon={faFolder}
                  className="h-10 w-10 rounded-md bg-primary text-[16px] text-white"
                />
                <p className="flex-1">{projectName}</p>
                <Icon icon={faCheck} className="text-[20px] text-primary" />
              </div>
              <Render if={organizationProjects}>
                <Render if={isProjectManager}>
                  <Link
                    theme
                    to={`${PATH.PROJECT_SETTINGS}?${contextSearchParams}`}
                    className="group mt-2 flex items-center gap-2 py-3 transition-colors hover:bg-white/10"
                  >
                    <Icon
                      icon={faGear}
                      className="text-[20px] transition-colors group-hover:text-primary"
                    />
                    Settings
                  </Link>
                </Render>
                <Link
                  theme
                  to={`${PATH.PROJECT_SETTINGS_USERS}?${contextSearchParams}`}
                  className={clsx(
                    'group flex items-center gap-2 py-3 transition-colors hover:bg-white/10',
                    { 'mt-2': !isProjectManager },
                  )}
                >
                  <Icon
                    icon={faUserGroup}
                    className="text-[20px] transition-colors group-hover:text-primary"
                  />
                  People
                </Link>
              </Render>
            </div>
          </Render>
          <Render if={projectList}>
            <div className="flex max-h-[320px] flex-col gap-2 overflow-auto px-3 py-2">
              {projectList.map(makeProject)}
            </div>
          </Render>
          <Modal content={<CreateProjectModal onSuccess={hidePopover} />}>
            <Button
              theme
              className="flex w-full items-center gap-1 border-t border-black/10 p-5 capitalize text-neural-05 transition-colors hover:text-primary"
            >
              <Icon icon={faPlus} className="text-[20px]" />
              Create new project
            </Button>
          </Modal>
        </div>
      );
    }

    return (
      <div className="flex flex-col items-center gap-6 px-4 py-10">
        <Icon
          icon={faFolderThin}
          className="h-24 w-24 text-[96px] text-neural-03"
        />
        <p className="text-center">
          <p className="mb-2 text-h6">You don&apos;t have any projects</p>
          Create a new project for
          <span className="word-break-case space-both inline-block text-primary">
            {organizationName ?? 'No organization'}
          </span>
          organization
        </p>
        <Modal content={<CreateProjectModal onSuccess={hidePopover} />}>
          <Button className="gap-1 capitalize">
            <Icon icon={faPlus} />
            Create new project
          </Button>
        </Modal>
      </div>
    );
  };

  if (!organizationClusters.length) {
    return null;
  }

  if (!contextClusterName) {
    return (
      <>
        <div className="h-12 w-px bg-white/25" />
        <UserPanelButton
          disabled
          className="flex items-center gap-2 text-white opacity-40"
        >
          <Icon icon={faFolder} className="h-10 w-10 rounded-md bg-white/50" />
          <div className="text-left">
            <p className="text-body-small">Project</p>
            <p>Missing cluster context</p>
          </div>
        </UserPanelButton>
      </>
    );
  }

  return (
    <>
      <div className="h-12 w-px bg-white/25" />
      <Popover
        open={popoverOpen}
        showArrow={false}
        side="bottom"
        align="end"
        className="w-[320px] p-0"
        onOpenChange={setPopoverOpen}
      >
        <UserPanelButton
          slot="trigger"
          className="flex w-[240px] items-center gap-2 text-white"
        >
          <Icon icon={faFolder} className="h-10 w-10 rounded-md bg-primary" />
          <div className="min-w-0 flex-1 text-left text-white">
            <p className="text-body-small text-neural-03">Project</p>
            <Render if={projectName}>
              <p className="truncate">{projectName}</p>
            </Render>
          </div>
        </UserPanelButton>
        <div className="flex flex-col items-start gap-6">{renderContent()}</div>
      </Popover>
    </>
  );
};
