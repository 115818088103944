import { useCallback, useEffect, useState } from 'react';

import { ProjectRole } from '@typings';
import { getProjectSettings } from '@services';
import { configSelector, contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';

type Project = {
  isDefault: boolean;
  defaultRole: ProjectRole;
};

export const useProject = (): {
  loading: boolean;
  isDefault: boolean;
  defaultRole: ProjectRole;
} => {
  const { adminUrl } = useSelector(configSelector);
  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);

  const [loading, setLoading] = useState(false);
  const [project, setProject] = useState<Project | null>(null);

  const getProject = useCallback(async () => {
    setLoading(true);

    try {
      const project = await getProjectSettings({
        adminUrl,
        clusterName: clusterName!,
        organizationName,
        projectName: projectName!,
      });

      setProject(project);
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ error });
    } finally {
      setLoading(false);
    }
  }, [adminUrl, clusterName, organizationName, projectName]);

  useEffect(() => {
    if (clusterName) {
      getProject();
    }
  }, [clusterName, getProject]);

  return {
    ...(project as Project),
    loading,
  };
};
