import { useFieldArray, useFormContext } from 'react-hook-form';
import { faTrashAlt } from '@fortawesome/pro-regular-svg-icons';
import { faPlus } from '@fortawesome/pro-solid-svg-icons';

import { Button, Field, Icon, Table, Theme } from '@components';

type FieldArrayElement = {
  id: string;
  value: string;
};

export const AppConstructorArguments = () => {
  const { register, control } = useFormContext();
  const { fields, append, remove } = useFieldArray({
    control,
    name: 'args',
  });

  const makeItem = ({ id }: FieldArrayElement, index: number) => {
    return (
      <Table.Row key={id}>
        <Table.Cell className="border-0 py-2">
          <Field.Input {...register(`args.${index}.value`)} />
        </Table.Cell>
        <Table.Cell className="border-0 py-2">
          <Button theme onClick={() => remove(index)}>
            <Icon icon={faTrashAlt} className="text-error" />
          </Button>
        </Table.Cell>
      </Table.Row>
    );
  };

  return (
    <div className="mt-4 flex flex-col gap-5 rounded-lg bg-background p-5">
      <div className="flex items-center justify-between gap-4">
        <Field.Label className="mb-0 font-500 capitalize">
          Server extra arguments
        </Field.Label>
        <Button className="capitalize" onClick={() => append('')}>
          <Icon icon={faPlus} />
          Add argument
        </Button>
      </div>
      <Theme.Container className="p-0">
        <Table className="w-full grid-cols-[auto_min-content] overflow-auto">
          <Table.Body>
            {(fields as FieldArrayElement[]).map(makeItem)}
          </Table.Body>
        </Table>
      </Theme.Container>
    </div>
  );
};
