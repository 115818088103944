/**
 * There is no canvasjs/react-charts types definition
 */
// @ts-ignore
import CanvasJSReact from '@canvasjs/react-charts';

import { TELEMETRY } from '@constants';
import { formatTimestamp, isNumber } from '@utils';

const ChartComponent = CanvasJSReact.CanvasJSChart;

type Props = {
  title: string;
  label?: string;
  maximum?: number;
  data: (number | null)[];
  timestamp?: number[];
  valueModifier?: (value: number) => number;
};

export const JobTelementryChart = ({
  title,
  label,
  maximum,
  data,
  timestamp = [],
  valueModifier,
}: Props) => {
  const getInterval = () => {
    /**
     * todo: resolve undefined type
     */
    const division = (maximum as number) / TELEMETRY.INTERVAL_Y;

    if (!maximum) {
      return undefined;
    }

    if (maximum < TELEMETRY.INTERVAL_Y) {
      return Number(division.toFixed(2));
    }

    return Math.round(division);
  };

  const getCurrentValue = () => {
    const initialCurrentValue = data.at(-1);

    if (!isNumber(initialCurrentValue)) {
      return title.replace('#', '');
    }

    const currentValue =
      typeof valueModifier === 'function'
        ? valueModifier(initialCurrentValue)
        : initialCurrentValue;

    return title.replace('#', currentValue.toString());
  };

  const dataPoints = data.map((value, index) => {
    const x = formatTimestamp(timestamp[index]);
    const y = value;

    return { x, y, label };
  });

  const options = {
    animationDuration: 2000,
    axisY2: {
      includeZero: true,
      tickLength: 0,
      gridThickness: 0,
      lineColor: '#71717B',
      lineThickness: 0,
      /**
       * `null` for rendering calculated maximum value
       */
      maximum: maximum || null,
      minimum: 0,
      interval: getInterval(),
    },
    axisX: {
      valueFormatString: 'h:mm:ss TT',
      tickLength: 0,
      lineThickness: 0,
      lineColor: '#71717B',
      interval: 5,
      intervalType: 'second',
    },
    data: [
      {
        type: 'column',
        color: '#5F5FC4',
        indexLabelFontSize: 0,
        axisYType: 'secondary',
        xValueType: 'dateTime',
        dataPoints,
      },
    ],
  };

  return (
    <div className="relative flex-1 rounded-xl border border-neural-02 p-2">
      <p className="absolute left-3 top-2 z-10 p-2">{getCurrentValue()}</p>
      <ChartComponent options={options} />
    </div>
  );
};
