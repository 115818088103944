import { createSelector } from 'reselect';

import { Balance, State } from '@typings';
import { as, isObjectEmpty } from '@utils';

import { userClustersSelector } from './cache.selectors';
import { contextNamesSelector } from './context.selectors';

export const userSelector = (state: State) => state.user;

export const isAuthorizedSelector = createSelector(
  userSelector,
  (user) => !isObjectEmpty(user),
);

export const userCreditsSelector = createSelector(
  userClustersSelector,
  contextNamesSelector,
  (userClusters, { organizationName, clusterName }) => {
    if (!clusterName) {
      return null;
    }

    const cluster = userClusters.find(
      ({ orgName = null, clusterName: userClusterName }) =>
        clusterName === userClusterName && orgName === organizationName,
    )!;
    const { credits: userCredits = 'unlimited' } = as.o<Balance>(
      cluster?.balance,
    );

    return userCredits;
  },
);
