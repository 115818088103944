import { Outlet } from 'react-router-dom';

import { contextNamesSelector } from '@selectors';
import { useHelmetTitle, useSelector } from '@hooks';
import { settingsTabs } from '@content';

import { ChaseSpinner, Helmet } from '@components';
import { ClusterSettingsTabs } from '@components/Cluster';
import { Layout } from '@components/Layouts';

type Props = {
  loading?: boolean;
  isManager: boolean;
};

export const ProjectSettingsPage = ({ loading = true, isManager }: Props) => {
  const { organizationName } = useSelector(contextNamesSelector);

  const { makeTitle } = useHelmetTitle();

  const getTabs = () => {
    if (organizationName && isManager) {
      return settingsTabs.project;
    }

    if (isManager) {
      return settingsTabs.projectNoReports;
    }

    return settingsTabs.projectUser;
  };

  const tabs = getTabs();

  const renderContent = () => {
    if (loading) {
      return (
        <div className="relative flex min-h-[400px] w-full items-center justify-center">
          <ChaseSpinner color="black" className="h-12 w-12" />
        </div>
      );
    }

    return <Outlet />;
  };

  return (
    <Layout title={makeTitle('%p', 'Project Settings')}>
      <Helmet description="Invite new team members and manage access permissions for your projects. Configure project-specific settings to ensure seamless collaboration and efficient project management" />
      <Layout.Content>
        <ClusterSettingsTabs projectTracker clusterTracker tabs={tabs} />
        {renderContent()}
      </Layout.Content>
    </Layout>
  );
};
