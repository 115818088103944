import { SupportedGpuMake } from '@typings';
/**
 * Has to be kind of absolute path using alias due to
 * error throw from helpers
 */
import { formattedGpuMake } from '@content/shared.content';

export const getFormattedGpuMake = (make: SupportedGpuMake | undefined) => {
  if (!make) {
    return make;
  }

  return formattedGpuMake[make] ?? make;
};
