import { cn } from '@utils';

type Props = {
  className?: string;
};

export const WwtLogo = ({ className }: Props) => {
  return (
    <svg
      className={cn('h-[313px] w-[1500px] text-white', className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 1500 313"
      fill="currentColor"
    >
      <polygon points="630.4 176.29 594.93 176.29 594.93 154.18 691.57 154.18 691.57 176.29 656.1 176.29 656.1 278.07 630.4 278.07 630.4 176.29" />
      <path d="M703.41,225.12h38.21c-.69-9.59-7.37-17.31-18.85-17.31-10.8,0-17.3,5.32-19.36,17.31m62.2,23.65c-3.43,18.68-19.88,30.67-41.13,30.67-29.29,0-44.38-23-44.38-44.72,0-21.93,13.37-44.38,42.67-44.38,30.5,0,42.66,22.45,42.66,42.15,0,3.26-.16,5.82-.34,7.71h-62c1.71,12.17,9.42,19.7,21.41,19.7,9.6,0,15.94-3.77,17.82-11.13Z" />
      <path d="M778.44,234.89c0-25.19,17.82-44.56,44.55-44.56,22.62,0,40.61,13.71,42.15,33.76h-24c-1.37-7.71-8.74-13.36-17.48-13.36-12.85,0-20.39,10.11-20.39,24.16s7.7,24,20.73,24c8.74,0,15.77-5.3,17.48-13h24.33C863.26,265,845.44,279.44,823,279.44c-26.73,0-44.55-19.37-44.55-44.55" />
      <path d="M878.66,154.18h23.82V203.7c5-8.39,14.39-13.36,26.38-13.36,19.36,0,32,14,32,33.92v53.81H937.09V229.58c0-10.46-6.86-18-16.8-18-10.44,0-17.81,8-17.81,19.19v47.29H878.66Z" />
      <path d="M978.26,191.71h24v12c4.8-8.4,14.39-13.36,26.22-13.36,19.53,0,32.22,14,32.22,33.93v53.8h-24V229.58c0-10.46-6.68-18-16.62-18-10.45,0-17.82,8.06-17.82,19.19v47.3h-24Z" />
      <path d="M1140.09,234.89c0-14.4-8.74-24.16-21.59-24.16s-21.58,9.76-21.58,24.16c0,14.22,8.73,24,21.58,24s21.59-9.76,21.59-24m-67.17,0c0-25.71,19-44.56,45.58-44.56s45.58,18.85,45.58,44.56-19,44.55-45.58,44.55-45.58-18.85-45.58-44.55" />
      <rect x="1177.83" y="154.18" width="23.82" height="123.89" />
      <path d="M1282.39,234.89c0-14.4-8.75-24.16-21.59-24.16s-21.6,9.76-21.6,24.16c0,14.22,8.75,24,21.6,24s21.59-9.76,21.59-24m-67.17,0c0-25.71,19-44.56,45.58-44.56s45.58,18.85,45.58,44.56-19,44.55-45.58,44.55-45.58-18.85-45.58-44.55" />
      <path d="M1381.43,234.89c0-14.57-8.39-24.16-21.24-24.16-12.68,0-21.08,9.59-21.08,24.16s8.4,24,21.08,24c12.85,0,21.24-9.43,21.24-24m22.45-43.18v91.5c0,18-13,29.82-32.56,29.82H1327.8V294.35h39.76c7.2,0,12.5-5.66,12.5-13.71V269.33c-4.45,6.34-13.53,10.11-23.64,10.11-25.53,0-40.78-17.65-40.78-44.55s15.25-44.55,40.44-44.55c10.45,0,19,3.94,23.82,9.93v-8.56Z" />
      <polygon points="1473.82 191.71 1455.82 247.4 1437.67 191.71 1413.33 191.71 1444.69 277.38 1432.01 313.02 1454.79 313.02 1466.97 277.72 1498.32 191.71 1473.82 191.71" />
      <polygon points="587.74 0.03 613.78 0.03 636.75 86.01 661.25 0.03 683.01 0.03 707.68 86.01 730.81 0.03 756.87 0.03 721.4 123.89 696.03 123.89 672.22 40.13 648.4 123.89 623.38 123.89 587.74 0.03" />
      <polygon points="1095.76 0.03 1121.79 0.03 1144.76 86.01 1169.26 0.03 1191.02 0.03 1215.7 86.01 1238.83 0.03 1264.88 0.03 1229.41 123.89 1204.05 123.89 1180.24 40.13 1156.41 123.89 1131.39 123.89 1095.76 0.03" />
      <path d="M818.37,80.7c0-14.39-8.75-24.15-21.59-24.15s-21.6,9.76-21.6,24.15c0,14.23,8.75,24,21.6,24s21.59-9.76,21.59-24m-67.17,0c0-25.7,19-44.54,45.58-44.54S842.35,55,842.35,80.7s-19,44.56-45.57,44.56S751.2,106.41,751.2,80.7" />
      <path d="M896.34,57.4c-11.82,0-17.13,9.77-17.13,24.51v42h-24V37.52h24v14.4c4.63-10.8,11.14-14.4,21.59-14.4h9.77V57.4Z" />
      <rect x="920.61" width="23.82" height="123.89" />
      <path d="M1024.33,80.7c0-14.56-8.4-24.15-21.07-24.15s-21.09,9.59-21.09,24.15c0,14.4,8.41,24,21.09,24s21.07-9.59,21.07-24m-65.8,0c0-26.9,15.43-44.54,40.44-44.54,10.8,0,19.19,4.11,24,10.44V0h23.83V123.89H1023v-9.43c-4.79,6.68-13.71,10.8-23.64,10.8-25.35,0-40.78-17.65-40.78-44.56" />
      <path d="M1275.72,123.89h24V37.53h-24Zm-.69-98.7h25.35V0H1275Z" />
      <path d="M1378.52,80.7c0-14.56-8.4-24.15-21.08-24.15s-21.08,9.59-21.08,24.15c0,14.4,8.4,24,21.08,24s21.08-9.59,21.08-24m-65.8,0c0-26.9,15.42-44.54,40.43-44.54,10.8,0,19.2,4.11,24,10.44V0H1401V123.89h-23.83v-9.43c-4.79,6.68-13.71,10.8-23.64,10.8-25.36,0-40.78-17.65-40.78-44.56" />
      <path d="M1437.8,70.94H1476c-.69-9.6-7.37-17.31-18.85-17.31-10.8,0-17.31,5.32-19.36,17.31M1500,94.59c-3.43,18.67-19.88,30.67-41.13,30.67-29.3,0-44.38-23-44.38-44.73,0-21.93,13.37-44.37,42.67-44.37,30.5,0,42.66,22.44,42.66,42.15,0,3.25-.16,5.82-.34,7.71h-62c1.71,12.16,9.42,19.7,21.41,19.7,9.6,0,15.94-3.77,17.82-11.13Z" />
      <path d="M340.47,214.15l63.18,63.17a2.83,2.83,0,0,0,2,.81h79a2.79,2.79,0,0,0,2-4.76L383.9,170.71l63.17-63.17a2.78,2.78,0,0,0,0-3.94L407.59,64.11a2.79,2.79,0,0,0-3.95,0L233.86,233.89a2.79,2.79,0,0,0,0,4l39.49,39.48a2.79,2.79,0,0,0,3.95,0Z" />
      <path d="M336.61.85,275.32,62.14,214,.85a2.79,2.79,0,0,0-2-.82h-79a2.79,2.79,0,0,0-2,4.77L231.89,105.57l-21.72,21.72L83.74.85a2.82,2.82,0,0,0-2-.82H2.8a2.8,2.8,0,0,0-2,4.77L208.2,212.17a2.79,2.79,0,0,0,4,0L419.53,4.8a2.8,2.8,0,0,0-2-4.77h-79a2.8,2.8,0,0,0-2,.82" />
      <path d="M466.9.85l-37.6,37.6a2.79,2.79,0,0,0,0,3.95l39.49,39.48a2.78,2.78,0,0,0,3.94,0L549.82,4.8a2.8,2.8,0,0,0-2-4.77h-79a2.84,2.84,0,0,0-2,.82" />
    </svg>
  );
};
