import { Organization } from '@typings';

type Value = string | Organization.Name | undefined;

type Params = {
  apiUrl: string;
  clusterName?: string;
  organizationName?: Organization.Name;
  projectName?: string;
};

const makeUrl = (url: string, value: Value): string =>
  value ? `/${url}/${value}` : '';

/**
 * params = { apiUrl: 'admin-url', clusterName: 'default', organizationName: 'org-name' }
 * @example getFormattedApiUrl(params); // "/admin-url/clusters/default/orgs/org-name"
 *
 * params = { apiUrl: 'admin-url', clusterName: 'default', organizationName: null }
 * @example getFormattedApiUrl(params); // "/admin-url/clusters/default"
 */
export const getFormattedApiUrl = ({
  apiUrl,
  clusterName,
  organizationName,
  projectName,
}: Params): string => {
  const cluster = makeUrl('clusters', clusterName);
  const organization = makeUrl('orgs', organizationName);
  const project = makeUrl('projects', projectName);

  return `${apiUrl}${cluster}${organization}${project}`;
};
