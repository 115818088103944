import { Outlet } from 'react-router-dom';

import { contextNamesSelector } from '@selectors';
import { useSelector } from '@hooks';
import { capitalize } from '@utils';
import { settingsTabs } from '@content';

import { ChaseSpinner, Helmet } from '@components';
import { ClusterSettingsTabs } from '@components/Cluster';
import { Layout } from '@components/Layouts';

type Props = {
  isManager: boolean;
  loading?: boolean;
};

export const ClusterSettingsPage = ({ isManager, loading }: Props) => {
  const { clusterName } = useSelector(contextNamesSelector);

  const title = `${capitalize(clusterName!)} - Cluster Settings`;
  const tabs = isManager ? settingsTabs.cluster : settingsTabs.clusterUser;

  const renderContent = () => {
    if (loading) {
      return (
        <div className="relative flex min-h-[400px] w-full items-center justify-center">
          <ChaseSpinner color="black" className="h-12 w-12" />
        </div>
      );
    }

    return <Outlet />;
  };

  return (
    <Layout
      isProjectReffered={false}
      projectContextTracker={false}
      title={title}
    >
      <Helmet description="Monitor and configure your cluster settings with structured resource presets. Ensure optimal performance and resource allocation by accessing detailed cluster information and management tools" />
      <Layout.Content>
        <ClusterSettingsTabs clusterTracker tabs={tabs} />
        {renderContent()}
      </Layout.Content>
    </Layout>
  );
};
