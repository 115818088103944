import { Storage } from '@typings';
import { JWT_TOKEN } from '@constants';
import { localStorage, path } from '@utils';

type Params = {
  storageUrl: string;
  currentPath: string;
  objectPath: string;
};

export const getStorageDownloadUrl = ({
  storageUrl,
  currentPath,
  objectPath,
}: Params) => {
  const token = localStorage.get(JWT_TOKEN);
  const downloadUrl = new URL(
    path.create(storageUrl, currentPath, objectPath, { prefix: '' }),
  );

  downloadUrl.searchParams.set('op', Storage.Operation.Open);
  downloadUrl.searchParams.set('neuro-auth-token', token);

  return String(downloadUrl);
};
