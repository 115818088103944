import { faExternalLink, faLink } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';

import { cn } from '@utils';

import { Icon, Link } from '@components';

type Props = {
  external?: boolean;
  className?: string;
  to?: string;
  text?: string;
};

export const JobDetailsLink = ({
  external,
  className,
  to,
  text = to,
}: Props) => {
  const icon = external ? faExternalLink : faLink;

  if (!to) {
    return <p className="w-fit bg-black/10">{text}</p>;
  }

  return (
    <Link
      theme
      external={external}
      blank={external}
      to={to}
      className={cn(
        'inline-flex items-center gap-2 text-primary transition-colors hover:text-primary-dark',
        className,
      )}
    >
      {text}
      <Icon icon={icon} className={clsx({ 'translate-y-px': !external })} />
    </Link>
  );
};
