import { cn } from '@utils';

import { LogoIcon } from './LogoIcon';

type Props = {
  color?: boolean;
  className?: string;
};

export const Logo = ({ color = true, className }: Props) => {
  const renderContent = () => {
    if (color) {
      return (
        <>
          <defs>
            <linearGradient
              id="linear-gradient"
              x1="-72.12"
              y1="1974.92"
              x2="371.36"
              y2="1531.44"
              gradientUnits="userSpaceOnUse"
            >
              <stop offset="0" stopColor="#272782" />
              <stop offset="0.37" stopColor="#5f5fc9" />
              <stop offset="0.69" stopColor="#48d0ff" />
              <stop offset="1" stopColor="#00ffb0" />
            </linearGradient>
            <linearGradient
              id="linear-gradient-2"
              x1="1430.39"
              y1="30.82"
              x2="-462.7"
              y2="1923.91"
              xlinkHref="#linear-gradient"
            />
            <linearGradient
              id="linear-gradient-3"
              x1="1998.05"
              y1="598.49"
              x2="104.96"
              y2="2491.58"
              xlinkHref="#linear-gradient"
            />
          </defs>
          <g id="Layer_2" data-name="Layer 2">
            <g id="Layer_1-2" data-name="Layer 1">
              <path d="M2538.29,566.83c145.79,0,249.42,66.74,302.11,152.8V580.88h300.4V1561H2840.4V1422.25c-54.43,86.09-158.06,152.83-303.85,152.83-238.89,0-430.36-196.73-430.36-505.88S2297.66,566.83,2538.29,566.83Zm87.84,261.7c-112.42,0-214.31,84.31-214.31,240.67s101.89,244.15,214.31,244.15c114.16,0,214.27-86.06,214.27-242.41S2740.29,828.53,2626.13,828.53Z" />
              <path d="M3873.23,566.83c240.64,0,432.1,193.21,432.1,502.37s-191.46,505.88-432.1,505.88c-144,0-247.68-68.52-302.11-151v604.25h-300.4V580.88h300.4V719.63C3625.55,633.57,3729.19,566.83,3873.23,566.83Zm-89.58,261.7c-112.42,0-214.31,86.09-214.31,242.41s101.89,242.41,214.31,242.41c114.16,0,216-87.83,216-244.15S3897.81,828.53,3783.65,828.53Z" />
              <path d="M4821.72,1575.08c-288.06,0-505.89-193.21-505.89-504.14s223.09-504.11,509.41-504.11c288.05,0,509.4,193.21,509.4,504.11S5109.81,1575.08,4821.72,1575.08Zm0-260c107.15,0,207.26-79,207.26-244.15,0-166.85-98.37-244.16-203.74-244.16-108.9,0-203.78,77.31-203.78,244.16C4621.46,1236.05,4712.82,1315.09,4821.72,1315.09Z" />
              <path d="M5406.65,261.17H5707V1561H5406.65Z" />
              <path d="M6283.16,1575.08c-288.09,0-505.88-193.21-505.88-504.14s223.06-504.11,509.37-504.11c288.09,0,509.41,193.21,509.41,504.11S6571.22,1575.08,6283.16,1575.08Zm0-260c107.15,0,207.27-79,207.27-244.15,0-166.85-98.37-244.16-203.78-244.16-108.9,0-203.74,77.31-203.74,244.16C6082.91,1236.05,6174.23,1315.09,6283.16,1315.09Z" />
              <polygon
                className="fill-[url(#linear-gradient)]"
                points="1194.21 490.17 1354.47 766.74 1034.18 766.74 1194.21 490.17"
              />
              <polygon
                className="fill-[url(#linear-gradient-2)]"
                points="856.2 872.91 457.66 1561.01 0 1561.01 398.53 872.91 594.58 534.16 648.89 440.63 674.93 395.31 679.1 388.24 903.75 0 1128.72 388.24 1132.59 394.99 1106.24 440.63 1051.92 534.16 903.75 790.31 856.2 872.91"
              />
              <polygon
                className="fill-[url(#linear-gradient-2)]"
                points="1807.51 1561.01 1349.86 1561.01 951.64 872.91 1409.32 872.91 1807.51 1561.01"
              />
            </g>
          </g>
        </>
      );
    }

    return (
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="M2538.29,566.83c145.79,0,249.42,66.74,302.11,152.8V580.88h300.4V1561H2840.4V1422.25c-54.43,86.09-158.06,152.83-303.85,152.83-238.89,0-430.36-196.73-430.36-505.88S2297.66,566.83,2538.29,566.83Zm87.84,261.7c-112.42,0-214.31,84.31-214.31,240.67s101.89,244.15,214.31,244.15c114.16,0,214.27-86.06,214.27-242.41S2740.29,828.53,2626.13,828.53Z" />
          <path d="M3873.23,566.83c240.64,0,432.1,193.21,432.1,502.37s-191.46,505.88-432.1,505.88c-144,0-247.68-68.52-302.11-151v604.25h-300.4V580.88h300.4V719.63C3625.55,633.57,3729.19,566.83,3873.23,566.83Zm-89.58,261.7c-112.42,0-214.31,86.09-214.31,242.41s101.89,242.41,214.31,242.41c114.16,0,216-87.83,216-244.15S3897.81,828.53,3783.65,828.53Z" />
          <path d="M4821.72,1575.08c-288.06,0-505.89-193.21-505.89-504.14s223.09-504.11,509.41-504.11c288.05,0,509.4,193.21,509.4,504.11S5109.81,1575.08,4821.72,1575.08Zm0-260c107.15,0,207.26-79,207.26-244.15,0-166.85-98.37-244.16-203.74-244.16-108.9,0-203.78,77.31-203.78,244.16C4621.46,1236.05,4712.82,1315.09,4821.72,1315.09Z" />
          <path d="M5406.65,261.17H5707V1561H5406.65Z" />
          <path d="M6283.16,1575.08c-288.09,0-505.88-193.21-505.88-504.14s223.06-504.11,509.37-504.11c288.09,0,509.41,193.21,509.41,504.11S6571.22,1575.08,6283.16,1575.08Zm0-260c107.15,0,207.27-79,207.27-244.15,0-166.85-98.37-244.16-203.78-244.16-108.9,0-203.74,77.31-203.74,244.16C6082.91,1236.05,6174.23,1315.09,6283.16,1315.09Z" />
          <polygon points="1194.21 490.17 1354.47 766.74 1034.18 766.74 1194.21 490.17" />
          <polygon points="856.2 872.91 457.66 1561.01 0 1561.01 398.53 872.91 594.58 534.16 648.89 440.63 674.93 395.31 679.1 388.24 903.75 0 1128.72 388.24 1132.59 394.99 1106.24 440.63 1051.92 534.16 903.75 790.31 856.2 872.91" />
          <polygon points="1807.51 1561.01 1349.86 1561.01 951.64 872.91 1409.32 872.91 1807.51 1561.01" />
        </g>
      </g>
    );
  };

  return (
    <svg
      className={cn('h-[358px] w-[1200px] text-black', className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 6796.06 2028.28"
      fill="currentColor"
    >
      {renderContent()}
    </svg>
  );
};

Logo.Icon = LogoIcon;
