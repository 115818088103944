import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { PermissionModel } from '@typings';

export type PermissionsState = PermissionModel[];

const initialState = [] as PermissionsState;

export const permissionsSlice = createSlice({
  name: 'permissions',
  initialState,
  reducers: {
    setPermissions: (_, action: PayloadAction<PermissionsState>) =>
      action.payload,
  },
});

export const { setPermissions } = permissionsSlice.actions;

export default permissionsSlice.reducer;
