import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { OrganizationInvite } from '@typings';

export type OrganizationsInvitesState = OrganizationInvite.Interface[];

const initialState = [] as OrganizationInvite.Interface[];

export const organizationsInvitesSlice = createSlice({
  name: 'organizationsInvites',
  initialState,
  reducers: {
    setOrganizationsInvites: (
      _,
      action: PayloadAction<OrganizationsInvitesState>,
    ) => action.payload,
  },
});

export const { setOrganizationsInvites } = organizationsInvitesSlice.actions;

export default organizationsInvitesSlice.reducer;
