import { useCallback, useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  faImage,
  faMicrochip,
  faPlus,
} from '@fortawesome/pro-regular-svg-icons';
import { faBolt } from '@fortawesome/pro-thin-svg-icons';
import clsx from 'clsx';

import { Job as JobType } from '@typings';
import { PATH } from '@constants';
import { toast } from '@features';
import { getJobs, getLiveJobs, killRunningJob } from '@services';
import { clusterContextSelector, contextNamesSelector } from '@selectors';
import {
  FiltersChange,
  FilterValue,
  useContextSearchParams,
  useFetchList,
  useHelmetTitle,
  useSelector,
} from '@hooks';
import { useJobList } from '@hooks/job';
import { path, toastifyResponseError } from '@utils';

import {
  Button,
  ChaseSpinner,
  Helmet,
  Icon,
  Link,
  Render,
  Table,
} from '@components';
import { Layout } from '@components/Layouts';
import { EmptyContent, Job } from '@components/Ui';

export const JobsPage = () => {
  const { contextSearchParams } = useContextSearchParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);
  const { metricsUrl = '' } = useSelector(clusterContextSelector) ?? {};

  const { makeTitle } = useHelmetTitle();

  /**
   * todo: split `status` as enum/constant
   */
  const initialStatus = searchParams.get('status') ?? 'running';
  const projectId = searchParams.get('projectId');
  const flowName = searchParams.get('flowName') ?? undefined;

  const { isEmpty, isFetched, list, filters, getList, handleFilterChange } =
    useFetchList<JobType.Model[]>({
      fetchOnMount: false,
      fetchOnFilterChange: false,
      getList: projectId ? getLiveJobs : getJobs,
      responseParser: !projectId ? ({ jobs }) => jobs : undefined,
      defaultFilters: {
        reverse: '1',
        status: initialStatus,
        flowName,
      },
    });
  const { search, jobs, handleSearchChange, resetSearch } = useJobList({
    jobs: list,
  });

  /**
   * Used for Job Details content
   */
  const [jobId, setJobId] = useState('');
  const [loading, setLoading] = useState(false);

  const job = jobs?.find(({ id }) => id === jobId);

  useEffect(() => {
    if (projectId) {
      getList({
        projectId,
        projectName,
        organizationName,
      });
    } else if (clusterName && projectName) {
      getList({
        clusterName,
        organizationName,
        projectName,
      });
    }
  }, [projectId, clusterName, organizationName, projectName, getList]);

  const handleJobIdClear = () => setJobId('');

  const handleFilterChangeHOF: FiltersChange = useCallback(
    (filter: string, value: FilterValue | undefined) => {
      setSearchParams((searchParams) => {
        /**
         * todo: fix type
         */
        // @ts-ignore
        searchParams.set(filter, value);

        return searchParams;
      });
      handleFilterChange(filter, value);
    },
    [setSearchParams, handleFilterChange],
  );

  const handleFilterAll = () => {
    resetSearch();
    handleFilterChange('status', 'all');
  };

  const handleJobKill = async (jobId: string) => {
    if (!jobId) {
      return;
    }

    setLoading(true);

    try {
      await killRunningJob(jobId);

      toast.success('Job killed');
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  const makeJob = ({
    id,
    name,
    presetName,
    history,
    history: { status },
    tags,
    container: { image },
  }: JobType.Model) => {
    const isKilling = id === jobId && loading;
    const isJobNonTerminated = ['suspended', 'pending', 'running'].includes(
      status,
    );
    const jobMetricsUrl = path.metricsUrl(metricsUrl!, id);

    return (
      <Table.Row
        key={id}
        onClick={() => setJobId(id)}
        className={clsx('cursor-pointer', {
          '[&>td]:bg-background': jobId === id,
        })}
      >
        <Table.Cell>
          <Link theme className="truncate underline" to={path.job(id)}>
            {name ?? id}
          </Link>
        </Table.Cell>
        <Table.Cell>
          <Button theme>
            <Link
              external
              blank
              theme
              to={`https://${image}`}
              className="flex items-center gap-2 underline"
            >
              <Icon icon={faImage} />
              <p title={image} className="truncate">
                {image}
              </p>
            </Link>
          </Button>
        </Table.Cell>
        <Table.Cell>
          <div className="flex items-center gap-2">
            <Icon icon={faMicrochip} />
            {presetName}
          </div>
        </Table.Cell>
        <Table.Cell>
          <Job.Status {...history} />
        </Table.Cell>
        <Table.Cell>
          <Job.Tags tags={tags} />
        </Table.Cell>
        <Table.Cell className="flex-row">
          <div className="flex gap-2">
            <Link
              external
              blank
              variant="rebecca"
              to={jobMetricsUrl}
              className="h-auto rounded-md py-1 text-footnote-large"
            >
              Monitor
            </Link>
            <Button
              variant="error"
              className="h-auto rounded-md py-1 text-footnote-large"
              onClick={() => handleJobKill(id)}
              loading={isKilling}
              disabled={!isJobNonTerminated}
            >
              Kill
            </Button>
          </div>
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderContent = () => {
    if (!isFetched) {
      return (
        <div className="relative flex min-h-[400px] w-full items-center justify-center">
          <ChaseSpinner color="black" className="h-12 w-12" />
        </div>
      );
    }

    if (isEmpty) {
      return (
        <EmptyContent icon={faBolt} className="mt-20" title="No jobs found">
          <Render if={filters.status !== 'all'}>
            <Button className="capitalize" onClick={handleFilterAll}>
              Show all jobs
            </Button>
          </Render>
        </EmptyContent>
      );
    }

    return (
      <Table className="w-full auto-rows-min grid-cols-[repeat(2,minmax(320px,auto))_minmax(160px,max-content)_max-content_max-content_min-content] overflow-auto">
        <Table.Header>
          <Table.Row>
            <Table.Head>ID / Name</Table.Head>
            <Table.Head>Image</Table.Head>
            <Table.Head>Preset</Table.Head>
            <Table.Head>Status</Table.Head>
            <Table.Head>Tags</Table.Head>
            <Table.Head>Actions</Table.Head>
          </Table.Row>
        </Table.Header>
        <Table.Body>{jobs.map(makeJob)}</Table.Body>
      </Table>
    );
  };

  return (
    <Layout title="Jobs">
      <Helmet
        title={makeTitle('Jobs', '%p', '%c')}
        description="Track and manage your jobs with ease. View detailed information and statuses for each job, and access specific job details through convenient links to ensure efficient workflow monitoring"
      />
      <Layout.Content>
        <div className="mb-14 flex items-center gap-10">
          <Link
            variant="rebecca"
            className="capitalize"
            to={`${PATH.NEW_JOB}?${contextSearchParams}`}
          >
            <Icon icon={faPlus} />
            Create new job
          </Link>
          <Job.Filters
            search={search}
            // todo: fix type
            // @ts-ignore
            filters={filters}
            onSearchChange={handleSearchChange}
            onFilterChange={handleFilterChangeHOF}
          />
        </div>
        <div className="flex items-start gap-10">
          {renderContent()}
          <Job.Details
            isKilling={loading}
            job={job}
            onKill={handleJobKill}
            onClose={handleJobIdClear}
          />
        </div>
      </Layout.Content>
    </Layout>
  );
};
