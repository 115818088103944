import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { ModalProps } from '@typings';
import { createStorageFolder } from '@services';
import { clusterContextSelector } from '@selectors';
import { useSelector } from '@hooks';
import { noop, normalizeFormErrors, path } from '@utils';

import { Button, Field, Modal } from '@components';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  folderName: z.string().min(1),
});

type Props = ModalProps & {
  currentPath: string;
  getStorage: (path: string) => Promise<void>;
};

export const CreateStorageFolderModal = ({
  currentPath,
  getStorage,
  closeModal = noop,
}: Props) => {
  const { storageUrl } = useSelector(clusterContextSelector)!;

  const { register, handleSubmit, formState } = useForm({
    resolver: zodResolver(schema),
  });

  const [loading, setLoading] = useState(false);

  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const handleFolderCreate = handleSubmit(async ({ folderName }) => {
    setLoading(true);

    try {
      await createStorageFolder({
        storageUrl,
        pathWithFolder: path.create(currentPath, folderName),
      });
      await getStorage(currentPath);

      closeModal();
    } catch (error) {
      // eslint-disable-next-line no-console
      console.log({ error });
    } finally {
      setLoading(false);
    }
  });

  return (
    <Modal.Content title="New folder" className="w-[480px]">
      <form onSubmit={handleFolderCreate}>
        <Field.Input
          {...register('folderName')}
          required
          label="Folder name"
          className="w-full"
          error={errors.folderName}
        />
      </form>
      <Modal.Footer>
        <Button
          loading={loading}
          className="px-14"
          onClick={handleFolderCreate}
        >
          Create
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
