import { useRef, useState } from 'react';
import { faFolder, faGrid2, faHouse } from '@fortawesome/pro-solid-svg-icons';
import { faArrowTurnLeft } from '@fortawesome/pro-thin-svg-icons';

import { Storage } from '@typings';
import { useStorage } from '@hooks/storage';
import { path } from '@utils';

import { Button, ChaseSpinner, Field, Icon, Modal, Render } from '@components';
import { Layout } from '@components/Layouts';
import {
  CreateStorageFolderModal,
  StorageDetails,
  StorageFileManager,
  StorageItemDistributor,
  StorageUploadArea,
  StorageUploadButton,
} from '@components/Storage';
import { ListItem } from '@components/Ui';

export const BucketPage = () => {
  const {
    loading,
    isReadOnly,
    isRoot,
    objects,
    currentPath,
    folderUp,
    changePath,
    changePathToHome,
    fetchStorage,
  } = useStorage();

  const clickRef = useRef(0);
  const [object, setObject] = useState<Storage.FileStatus>();
  const [search, setSearch] = useState('');

  const buttonClassName = 'p-0 w-12';
  const filteredObjects = search
    ? objects.filter((object) => object.path.includes(search))
    : objects;

  const handleObjectReset = () => {
    setObject(undefined);
  };

  const handleFolderChange = ({
    type,
    path: objectPath,
  }: Storage.FileStatus) => {
    const isDirectory = type === Storage.StatusType.Directory;

    if (isDirectory) {
      handleObjectReset();
      changePath(path.create(currentPath, objectPath));
    }
  };

  const resetClickCount = () => {
    clickRef.current = 0;
  };

  const handleObjectClick = (object: Storage.FileStatus) => {
    const isDirectory = object.type === Storage.StatusType.Directory;

    clickRef.current += 1;

    if (!isDirectory) {
      resetClickCount();
      setObject(object);
      return;
    }

    setTimeout(() => {
      if (clickRef.current === 1) {
        setObject(object);
      } else if (clickRef.current === 2) {
        handleFolderChange(object);
      }

      resetClickCount();
    }, 200);
  };

  const handleFolderUp = () => {
    folderUp();
    handleObjectReset();
  };

  const makeObject = (targetObject: Storage.FileStatus) => (
    <StorageItemDistributor
      {...targetObject}
      key={targetObject.path}
      isActive={targetObject.path === object?.path}
      currentPath={currentPath}
      onClick={() => handleObjectClick(targetObject)}
    />
  );

  const renderContent = () => {
    if (loading) {
      return (
        <div className="absolute inset-0 flex min-h-[400px] w-full items-center justify-center">
          <ChaseSpinner color="black" className="h-12 w-12" />
        </div>
      );
    }

    return (
      <>
        <Render if={!isRoot && !search}>
          <ListItem.Grid icon={faArrowTurnLeft} onDoubleClick={handleFolderUp}>
            <p slot="title" className="text-h6 font-400 text-primary">
              Folder up
            </p>
          </ListItem.Grid>
        </Render>
        {filteredObjects.map(makeObject)}
      </>
    );
  };

  return (
    <Layout title="Files">
      <Layout.Content className="flex items-start gap-10">
        <div className="w-full">
          <div className="mb-10 flex items-center gap-10">
            <div className="flex gap-2">
              <Modal
                content={
                  <CreateStorageFolderModal
                    currentPath={currentPath}
                    getStorage={fetchStorage}
                  />
                }
              >
                <Button className="gap-3 capitalize" disabled={isReadOnly}>
                  <Icon icon={faFolder} />
                  Add folder
                </Button>
              </Modal>
              <StorageUploadButton
                isReadOnly={isReadOnly}
                currentPath={currentPath}
                getStorage={fetchStorage}
              />
            </div>
            <Field.Input
              containerClassName="flex-1"
              className="border-neural-03"
              label="Search"
              value={search}
              onChange={(event) => setSearch(event.target.value)}
            />
            <div className="flex gap-2">
              <Button variant="ghost" className={buttonClassName}>
                <Icon icon={faGrid2} />
              </Button>
              <Button
                variant="ghost"
                className={buttonClassName}
                onClick={changePathToHome}
              >
                <Icon icon={faHouse} />
              </Button>
            </div>
          </div>
          <StorageUploadArea
            isReadOnly={isReadOnly}
            currentPath={currentPath}
            getStorage={fetchStorage}
          >
            <div className="flex flex-wrap gap-10">{renderContent()}</div>
          </StorageUploadArea>
        </div>
        <StorageFileManager
          currentPath={currentPath}
          object={object}
          getStorage={fetchStorage}
          resetObjectDetails={handleObjectReset}
        />
        <StorageDetails
          object={object}
          currentPath={currentPath}
          resetObject={handleObjectReset}
        />
      </Layout.Content>
    </Layout>
  );
};
