import dayjs from 'dayjs';

import { Job as JobType } from '@typings';
import { as, capitalize } from '@utils';

import { Table, Tooltip } from '@components';
import { Job } from '@components/Ui';

type Props = {
  statuses: JobType.TransitionStatus[];
};

export const JobDetailsStatuses = ({ statuses }: Props) => {
  const makeStatus = ({
    description,
    reason,
    status,
    transitionTime,
  }: JobType.TransitionStatus) => {
    const timePassed = dayjs(transitionTime).format('lll');
    const formattedStatus = capitalize(status);

    return (
      <Table.Row key={transitionTime}>
        <Table.Cell className="border-b border-l border-t-0 border-neural-02 py-2">
          {timePassed}
        </Table.Cell>
        <Table.Cell className="border-b border-t-0 border-neural-02 py-2">
          <Job.Status status={status} createdAt={transitionTime} />
        </Table.Cell>
        <Table.Cell className="border-b border-t-0 border-neural-02 py-2">
          {as(reason, formattedStatus)}
        </Table.Cell>
        <Table.Cell className="truncate border-b border-r border-t-0 border-neural-02 py-2">
          <Tooltip side="left" className="max-w-[640px]">
            <p slot="trigger" className="truncate">
              {description}
            </p>
            {description}
          </Tooltip>
        </Table.Cell>
      </Table.Row>
    );
  };

  return (
    <>
      <h2 className="text-h5 capitalize">Status transition</h2>
      <Table className="w-full auto-rows-min grid-cols-[repeat(3,max-content),auto] overflow-auto border-neural-02">
        <Table.Header>
          <Table.Row>
            <Table.Head className="rounded-tl-lg border-y border-l border-neural-02 py-3.5">
              Date
            </Table.Head>
            <Table.Head className="border-y border-neural-02 py-3.5">
              Status
            </Table.Head>
            <Table.Head className="border-y border-neural-02 py-3.5">
              Reason
            </Table.Head>
            <Table.Head className="rounded-tr-lg border-y border-r border-neural-02 py-3.5">
              Description
            </Table.Head>
          </Table.Row>
        </Table.Header>
        <Table.Body className="[&>tr:last-of-type>td:first-child]:rounded-bl-lg [&>tr:last-of-type>td:last-child]:rounded-br-lg">
          {statuses.slice().reverse().map(makeStatus)}
        </Table.Body>
      </Table>
    </>
  );
};
