import { Navigate, Route, Routes } from 'react-router-dom';

import { PATH } from '@constants';
import { contextNamesSelector } from '@selectors';
import { useSelector, useTenantRole } from '@hooks';
import { useProject } from '@hooks/cluster';

import * as Page from '@pages';
import { ChaseSpinner } from '@components';
import { Layout } from '@components/Layouts';

export const ProjectSettingsRoutes = () => {
  const { organizationName } = useSelector(contextNamesSelector);

  const { loading, defaultRole, isDefault } = useProject();
  const { isProjectManager, isInitialized } = useTenantRole();

  const defaultPath = isProjectManager
    ? PATH.PROJECT_SETTINGS
    : PATH.PROJECT_SETTINGS_USERS;

  if (!isInitialized) {
    return (
      <Layout title="Project Settings">
        <Layout.Content>
          <div className="mt-24 flex justify-center">
            <ChaseSpinner color="black" className="h-14 w-14" />
          </div>
        </Layout.Content>
      </Layout>
    );
  }

  return (
    <Routes>
      <Route
        element={
          <Page.ProjectSettings
            isManager={isProjectManager}
            loading={loading}
          />
        }
      >
        {isProjectManager && (
          <>
            <Route
              index
              element={
                <Page.ProjectGeneralSettings
                  isDefault={isDefault}
                  defaultRole={defaultRole}
                />
              }
            />
            <Route
              path="general"
              element={
                <Page.ProjectGeneralSettings
                  isDefault={isDefault}
                  defaultRole={defaultRole}
                />
              }
            />
            {organizationName && (
              <Route path="reports" element={<Page.ProjectSettingsReports />} />
            )}
          </>
        )}

        <Route
          path="users"
          element={<Page.ProjectUsersSettings isManager={isProjectManager} />}
        />

        <Route path="*" element={<Navigate replace to={defaultPath} />} />
      </Route>
    </Routes>
  );
};
