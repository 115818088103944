import { Disk, Params } from '@typings';
import { API_ROOT } from '@constants';
import { axios } from '@features';
import { getOrganizationName, path } from '@utils';

export const getDisks = ({
  organizationName,
  projectName,
  disksUrl,
}: Params.GetDisks) => {
  const params = {
    org_name: getOrganizationName(organizationName),
    project_name: projectName,
  };

  return axios.get<never, Disk[]>(disksUrl, { params });
};

export const getDisk = ({
  organizationName,
  projectName,
  disksUrl,
  diskName,
}: Params.GetDisk) => {
  const params = {
    org_name: organizationName ?? undefined,
    project_name: projectName,
  };

  return axios.get<never, Disk>(path.create(disksUrl, diskName), { params });
};

export const createDisk = ({
  disksUrl,
  lifeSpan,
  name,
  organizationName,
  projectName,
  storage,
}: Params.CreateDisk) => {
  const payload = {
    life_span: lifeSpan ?? null,
    project_name: projectName,
    org_name: organizationName,
    storage,
    name,
  };

  return axios.post<never, Disk>(disksUrl, payload);
};

export const deleteDisk = ({ disksUrl, id }: Params.DeleteDisk) =>
  axios.delete(`${disksUrl}/${id}`);

export const shareDiskAccess = ({
  username,
  id,
  permission,
}: Params.ShareDiskAccess) => {
  const payload = {
    action: permission,
    uri: id,
  };

  return axios.post(`${API_ROOT}/${username}/${id}`, payload);
};
