import { useCallback } from 'react';
import { useSearchParams } from 'react-router-dom';

import { Organization } from '@typings';
import {
  NO_ORGANIZATION,
  NO_ORGANIZATION_NAME,
  PLATFORM_CONTEXT,
} from '@constants';
import { ContextStorage, setContext } from '@slices';
import { configSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import { localStorage } from '@utils';

export const usePlatformContext = () => {
  const dispatch = useDispatch();
  const {
    orgs: organizations = [],
    clusters = [],
    projects = [],
  } = useSelector(configSelector);

  const [searchParams] = useSearchParams();

  const isEmpty = (value: unknown): value is undefined =>
    typeof value === 'undefined';

  const initializeOrganizationContext = (
    organizationName: Organization.Name | undefined,
  ) => {
    /**
     * When organization was not stored
     */
    if (isEmpty(organizationName)) {
      return organizations[0];
    }

    if ([NO_ORGANIZATION, NO_ORGANIZATION_NAME].includes(organizationName)) {
      return NO_ORGANIZATION;
    }

    return (
      organizations.find(({ name }) => name === organizationName) ??
      organizations[0]
    );
  };

  const initializeClusterContext = (
    organizationName: Organization.Name,
    clusterName: string | undefined,
  ) => {
    const list = clusters.filter(({ orgs }) => orgs.includes(organizationName));
    const firstCluster = list[0];

    if (isEmpty(clusterName)) {
      /**
       * When cluster was not stored
       */
      return firstCluster;
    }

    return list.find(({ name }) => name === clusterName) ?? firstCluster;
  };

  const initializeProjectContext = (
    organizationName: Organization.Name,
    clusterName: string | undefined,
    projectName: string | undefined,
  ) => {
    const list = projects.filter(
      ({ orgName = NO_ORGANIZATION, clusterName: projectClusterName }) =>
        orgName === organizationName && projectClusterName === clusterName,
    );
    const firstProject = list[0];

    if (isEmpty(projectName)) {
      /**
       * When project was not stored
       */
      return firstProject;
    }

    return list.find(({ name }) => name === projectName) ?? firstProject;
  };

  const initialize = useCallback(() => {
    if (!organizations.length) {
      return;
    }

    const { organization, cluster, project } =
      localStorage.get<ContextStorage>(PLATFORM_CONTEXT) ?? {};

    const initialCluster = searchParams.get('cluster') ?? cluster;
    const initialProject = searchParams.get('project') ?? project;
    const initialOrganization = searchParams.get('org') ?? organization;

    const organizationContext =
      initializeOrganizationContext(initialOrganization);
    const organizationContextName =
      organizationContext === NO_ORGANIZATION
        ? organizationContext
        : organizationContext.name;

    const clusterContext = initializeClusterContext(
      organizationContextName,
      initialCluster,
    );

    const projectContext = initializeProjectContext(
      organizationContextName,
      clusterContext?.name,
      initialProject,
    );

    const contextState = {
      organization: organizationContext,
      cluster: clusterContext,
      project: projectContext,
    };

    dispatch(setContext(contextState));

    /**
     * Array of dependencies is unnecessary
     * due to single context local storage initialization
     */
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { initializePlatformContext: initialize };
};
