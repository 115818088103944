import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Entries } from 'type-fest';

import { Cluster, Organization, Project } from '@typings';
import { NO_ORGANIZATION, PLATFORM_CONTEXT } from '@constants';
import { localStorage } from '@utils';

export type ContextState = {
  organization: Organization.Model | null;
  cluster: Cluster.Model | null;
  /**
   * Nullable project for no organization
   */
  project: Project | null;
};

export type ContextStorage = {
  organization: Organization.Name;
  cluster: string | undefined;
  project: string | undefined;
};

const initialState = {} as ContextState;

export const contextSlice = createSlice({
  name: 'platform/context',
  initialState,
  reducers: {
    setContext: (state, action: PayloadAction<Partial<ContextState>>) => {
      const context =
        localStorage.get<ContextStorage>(PLATFORM_CONTEXT) ??
        ({} as ContextStorage);

      (
        Object.entries(action.payload) as Entries<typeof action.payload>
      ).forEach(([key, value]) => {
        const name = value?.name;
        const isNoOrganizationContext =
          key === 'organization' && value === NO_ORGANIZATION;

        /**
         * todo: resolve type
         */
        // @ts-ignore
        state[key] = value;

        if (name) {
          context[key] = name;
        } else if (isNoOrganizationContext) {
          context[key] = NO_ORGANIZATION;
        }
      });

      localStorage.set<ContextStorage>(PLATFORM_CONTEXT, context);
    },
    resetContext: () => {
      localStorage.clear(PLATFORM_CONTEXT);

      return initialState;
    },
  },
});

export const { setContext, resetContext } = contextSlice.actions;

export default contextSlice.reducer;
