import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { Cluster, Image } from '@typings';
import { PATH } from '@constants';
import { getImages, getImageTags } from '@services';
import {
  clusterContextSelector,
  contextNamesSelector,
  userSelector,
} from '@selectors';
import { useSelector } from '@hooks';
import { path } from '@utils';

export const useImage = (): {
  loading: boolean;
  imageLoading: boolean;
  isEmpty: boolean;
  list: string[];
  image: Image.Model | null;
  setImage: (name: Image.Model | null) => void;
  setImageName: (name: string | null) => void;
  resetImageName: () => void;
  resetImage: () => void;
} => {
  const cluster = useSelector(clusterContextSelector);
  const { organizationName, projectName } = useSelector(contextNamesSelector);
  const { username } = useSelector(userSelector);

  const navigate = useNavigate();
  const { pathname } = useLocation();

  const [loading, setLoading] = useState(true);
  const [imageLoading, setImageLoading] = useState(false);
  const [list, setList] = useState<string[]>([]);
  const [imageName, setImageName] = useState<Image.Name | null>(null);
  const [image, setImage] = useState<Image.Model | null>(null);

  const { registryUrl } = cluster ?? ({} as Cluster.Model);

  const contextPrefix = path.create(organizationName, projectName, {
    prefix: '',
  });

  useEffect(() => {
    const isImagesPage = pathname.startsWith(PATH.IMAGES);

    if (!isImagesPage) {
      return;
    }

    const imageName = pathname.replace(PATH.IMAGES, '') ?? null;

    if (!imageName) {
      setImage(null);
    }

    setImageName(imageName);
  }, [pathname]);

  useEffect(() => {
    const run = async () => {
      setImageLoading(true);

      try {
        const image = await getImageTags({
          registryUrl,
          imageName: imageName!,
          username,
        });

        setImage(image);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log({ error });
      } finally {
        setImageLoading(false);
      }
    };

    if (imageName && registryUrl) {
      run();
    }
  }, [registryUrl, imageName, username]);

  useEffect(() => {
    const run = async () => {
      try {
        const repositories = await getImages(registryUrl, username);

        const filteredRepositories = repositories.filter((repository) =>
          repository.includes(contextPrefix),
        );

        setList(filteredRepositories);
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log({ error });
      } finally {
        setLoading(false);
      }
    };

    if (registryUrl) {
      run();
    }
  }, [registryUrl, contextPrefix, username]);

  const handleResetImage = useCallback(() => {
    setImage(null);
    navigate(PATH.IMAGES, { replace: true });
  }, [navigate]);

  const handleResetImageName = useCallback(() => {
    setImageName(null);
  }, []);

  return {
    loading,
    imageLoading,
    isEmpty: list.length === 0,
    list,
    image,
    setImage,
    setImageName,
    resetImageName: handleResetImageName,
    resetImage: handleResetImage,
  };
};
