import { PermissionUri, Thunk, UserInfoCluster } from '@typings';
import * as services from '@services';
import {
  ConfigState,
  setConfig,
  setPermissions,
  setUserClusters,
  setUserTerms,
} from '@slices';

export const getConfig =
  (): Thunk<Promise<ConfigState>> => async (dispatch) => {
    try {
      const config = await services.getConfig();

      dispatch(setConfig(config));

      return config;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getPermissions =
  (username: string, uri: PermissionUri): Thunk =>
  async (dispatch) => {
    try {
      const permissions = await services.getPermissions({ username, uri });

      dispatch(setPermissions(permissions));

      return permissions;
    } catch (error) {
      return Promise.reject(error);
    }
  };

export const getUserClusters =
  (adminUrl: string, username: string): Thunk<Promise<UserInfoCluster[]>> =>
  async (dispatch) => {
    try {
      const { clusters, termsAccepted = false } =
        await services.getUserClusters(adminUrl, username);

      dispatch(setUserClusters(clusters));
      dispatch(setUserTerms(termsAccepted));

      return clusters;
    } catch (error) {
      return Promise.reject(error);
    }
  };
