import { Badge } from '@components';

type Props = {
  tags: string[] | undefined;
};

export const JobDetailsTags = ({ tags = [] }: Props) => {
  const makeTag = (tag: string) => <Badge key={tag}>{tag}</Badge>;

  if (tags.length === 0) {
    return <p>-</p>;
  }

  return (
    <div className="flex flex-wrap gap-x-1 gap-y-2">{tags.map(makeTag)}</div>
  );
};
