import { IconProp } from '@fortawesome/fontawesome-svg-core';

import { AnyFunction } from '@typings';
import { BILLING_ALL_ENTRY } from '@constants';
import { capitalize } from '@utils';

import { BillingPopoverItem } from './BillingPopoverItem';

type Props = {
  active?: boolean;
  /**
   * For checkbox icon
   */
  selectable?: boolean;
  title?: string;
  icon?: IconProp;
  children?: React.ReactNode;
  onClick?: AnyFunction;
};

export const BillingPopoverItemAll = ({
  active,
  selectable,
  title = BILLING_ALL_ENTRY,
  icon,
  children,
  onClick,
}: Props) => {
  return (
    <BillingPopoverItem
      title={capitalize(title)}
      active={active}
      selectable={selectable}
      icon={icon}
      onClick={onClick}
    >
      {children}
    </BillingPopoverItem>
  );
};
