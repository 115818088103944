import { useCallback, useEffect, useState } from 'react';
import dayjs from 'dayjs';
import { faMicrochip } from '@fortawesome/pro-regular-svg-icons';

import { Job, TelemetryData, TelemetryItem, UnixTimestamp } from '@typings';
import { TELEMETRY } from '@constants';
import { useJobTelemetry } from '@hooks/job';
import { formatTimestamp, getGpu } from '@utils';

import { Icon } from '@components';
import { JobTelementryChart } from '@components/Ui';

type Props = {
  presetName: string;
  jobId: string;
  resources: Job.ContainerResources;
};

export const JobTelemetry = ({ jobId, presetName, resources }: Props) => {
  const { message, resourcePreset } = useJobTelemetry({ jobId, presetName });

  const [telemetry, setTelemetry] = useState<TelemetryItem[]>([]);

  const [{ gpuQuantity }] = getGpu(resources, { withPlaceholder: true });

  /**
   * Array of timestamp for chart X axis
   */
  const timestamp = telemetry.map(({ timestamp }) => timestamp);

  /**
   * Fill array by timestamp
   */
  const initializeTelemetry = useCallback(
    (telemetryTimestamp: UnixTimestamp): TelemetryItem[] => {
      const telemetry = new Array(TELEMETRY.CHART_COUNT).fill(undefined);
      const timestamp = dayjs.unix(telemetryTimestamp);

      return telemetry
        .map((_, index) => ({
          cpu: null,
          memory: null,
          gpuMemory: null,
          gpuDutyCycle: null,
          timestamp: formatTimestamp(
            timestamp.subtract(index + 1, 'second').valueOf(),
            'milliseconds',
          ) as UnixTimestamp,
        }))
        .reverse();
    },
    [],
  );

  const handleTelemetryChange = useCallback(
    (message: TelemetryData) => {
      setTelemetry((state) => {
        const telemetry = state.length
          ? [...state]
          : initializeTelemetry(message.timestamp);

        telemetry.push({ ...message });
        telemetry.shift();

        return telemetry;
      });
    },
    [initializeTelemetry],
  );

  useEffect(() => {
    if (message) {
      handleTelemetryChange(message);
    }
  }, [message, handleTelemetryChange]);

  const getTelemetryDataPoints = (field: keyof TelemetryItem) =>
    telemetry.map(({ [field]: value }) => value);

  const getCpuPercentageValue = (cpu: number) => {
    const maximum = resourcePreset.cpu;

    if (!maximum) {
      return cpu;
    }

    const percentage = (cpu / maximum) * 100;

    return Math.round(percentage);
  };

  return (
    <div className="mt-2 flex flex-col gap-6">
      <div className="text-h5">Telemetry</div>
      <div>
        <p className="mb-2 text-footnote text-neural-04">Preset</p>
        <p className="flex items-center gap-2">
          <Icon icon={faMicrochip} />
          {presetName}
        </p>
      </div>
      <div className="grid grid-cols-2 gap-6">
        <JobTelementryChart
          title="CPU, #%"
          label="CPU"
          maximum={resourcePreset.cpu}
          data={getTelemetryDataPoints('cpu')}
          timestamp={timestamp}
          valueModifier={getCpuPercentageValue}
        />
        <JobTelementryChart
          title="Memory, #MB"
          label="Memory"
          maximum={resourcePreset.memoryMb}
          data={getTelemetryDataPoints('memory')}
          timestamp={timestamp}
        />
        {gpuQuantity && (
          <JobTelementryChart
            title="GPU, #%"
            label="GPU"
            maximum={100}
            data={getTelemetryDataPoints('gpuDutyCycle')}
            timestamp={timestamp}
          />
        )}
      </div>
    </div>
  );
};
