import { Job } from '@typings';
import { useJobTelemetry } from '@hooks/job';
import { getGpu } from '@utils';

import { JobResource } from './JobResource';

type Props = {
  jobId: string;
  resources: Job.ContainerResources;
};

export const JobDetailsTelemetry = ({
  jobId,
  resources,
  resources: { cpu, memoryMb },
}: Props) => {
  const { message: telemetry } = useJobTelemetry({ jobId });

  const [{ gpuQuantity }] = getGpu(resources, { withPlaceholder: true });

  const memoryModifier = (memory: number) => `${memory}MB`;

  const gpuMemoryModifier = () => `${telemetry.gpuMemory}MB`;

  if (!telemetry) {
    return null;
  }

  return (
    <div className="flex flex-wrap gap-4">
      <JobResource title="CPU" value={telemetry.cpu} resource={cpu} />
      <JobResource
        title="Memory"
        value={telemetry.memory}
        resource={memoryMb}
        resourceModifier={memoryModifier}
      />
      {gpuQuantity && (
        <JobResource
          actualValue
          title="GPU"
          value={telemetry.gpuDutyCycle}
          resource={100}
          resourceModifier={gpuMemoryModifier}
        />
      )}
    </div>
  );
};
