type Params = {
  source: string;
  titlePattern?: string;
};

export const getDashboardLinkParams = ({ source, titlePattern }: Params) => {
  const title = titlePattern ? titlePattern.replace('{{source}}', source) : '';

  if (titlePattern && !titlePattern.includes('{{source}}')) {
    // eslint-disable-next-line no-console
    console.warn(
      'getDashboardLinkParams: `titlePattern` is provided, but no `{{source}}` found',
    );
  }

  return { source, title };
};
