import { useLocation } from 'react-router-dom';

import { ClusterSettings } from '@typings';
import { useContextSearchParams } from '@hooks';
import { cn, ensureArray } from '@utils';

import { Link } from '@components';

type Props = {
  tabs: ClusterSettings.Tab[];
  projectTracker?: boolean;
  clusterTracker?: boolean;
  organizationTracker?: boolean;
};

export const ClusterSettingsTabs = ({
  projectTracker = false,
  clusterTracker = false,
  organizationTracker = false,
  tabs,
}: Props) => {
  const { pathname } = useLocation();
  const { contextSearchParams } = useContextSearchParams({
    project: projectTracker,
    cluster: clusterTracker,
    organization: organizationTracker,
  });

  const makeTab = ({ path, title }: ClusterSettings.Tab) => {
    const paths = ensureArray(path);
    const isActive = paths.includes(pathname);
    const [to] = paths;

    return (
      <Link
        theme
        key={to}
        className={cn(
          'block border-b-2 border-transparent py-3 text-h6 capitalize text-neural-04 transition-colors hover:text-rebecca',
          { 'border-primary text-rebecca': isActive },
        )}
        to={`${to}?${contextSearchParams}`}
      >
        {title}
      </Link>
    );
  };

  return <div className="mb-14 flex gap-10">{tabs.map(makeTab)}</div>;
};
