import { ResponseError } from '@typings';
import { toast } from '@features';

import { capitalize } from './formatters';
import { isArray, isObject, isString } from './typeof';

type PlainError = {
  message?: string;
  description?: string;
};

type ParsedError = {
  errors: { [key: string]: string };
  messages: string[];
};

const parseError = (error: string) => {
  const errors: { [key: string]: string } = {};
  const messages: string[] = [];
  /**
   * Regex to match the pattern for the error key-value pairs
   */
  const regex = /'([^']+)':\s*DataError\((["'])(.+?)\2\)/g;
  let match;

  // eslint-disable-next-line no-cond-assign
  while ((match = regex.exec(error)) !== null) {
    const key = match[1];
    const value = match[3]
      /**
       * Remove the exact match "DataError" from the value
       */
      .replace(/\bDataError\b/g, '')
      /**
       * Filter out all unwanted characters except letters, numbers, spaces, and double quotes
       */
      .replace(/[^a-zA-Z0-9\s"]/g, '')
      /**
       * Replace multiple spaces with a single space and trim the string
       */
      .replace(/\s+/g, ' ');

    /**
     * Add the error to the errors object
     */
    errors[key] = value;

    const formattedKeyName = isString(key)
      ? capitalize(key).replace(/_/g, ' ')
      : key;

    messages.push(`${formattedKeyName}: ${value}`);
  }

  return { errors, messages };
};

export const parseResponseError = (
  unnormalizedError: unknown,
): string | ParsedError => {
  if (!isObject(unnormalizedError)) {
    return unnormalizedError as string;
  }

  const plainErrorMessage =
    (unnormalizedError as PlainError).message ||
    (unnormalizedError as PlainError).description;

  if (plainErrorMessage) {
    return plainErrorMessage;
  }

  const { error: unparsedResponseError } = unnormalizedError as ResponseError;

  if (!isString(unparsedResponseError)) {
    return unparsedResponseError as ParsedError;
  }

  if (/DataError/.test(unparsedResponseError)) {
    return parseError(unparsedResponseError) as ParsedError;
  }

  return unparsedResponseError as string;
};

type Options = {
  toastifyMessages?: boolean;
};

export const toastifyResponseError = (
  unparsedError: unknown,
  { toastifyMessages = true }: Options = {},
) => {
  const error = parseResponseError(unparsedError);

  if (!error) {
    return;
  }

  if (isString(error)) {
    toast.error(error);

    return;
  }

  if (toastifyMessages && isArray(error.messages)) {
    // eslint-disable-next-line no-plusplus
    for (let i = 0; i < error.messages.length; i++) {
      toast.error(error.messages[i]);
    }
  }
};
