import dayjs from 'dayjs';
import {
  faCalendarCircleExclamation,
  faMessageXmark,
  faUserCheck,
  faUserPlus,
} from '@fortawesome/pro-solid-svg-icons';

import { OrganizationInvite } from '@typings';

import { Badge, Icon, Render } from '@components';

type Props = {
  status: OrganizationInvite.Status;
  expiredAt?: string;
};

export const OrganizationInviteStatus = ({ status, expiredAt }: Props) => {
  const icon = {
    [OrganizationInvite.Status.Pending]: faUserPlus,
    [OrganizationInvite.Status.Accepted]: faUserCheck,
    [OrganizationInvite.Status.Expired]: faCalendarCircleExclamation,
    [OrganizationInvite.Status.Declined]: faMessageXmark,
  };

  const statusText =
    status === OrganizationInvite.Status.Pending ? 'invited' : status;

  const color = (() => {
    switch (status) {
      case OrganizationInvite.Status.Accepted:
        return 'grey';
      case OrganizationInvite.Status.Pending:
        return 'primary';
      case OrganizationInvite.Status.Expired:
        return 'yellow';
      case OrganizationInvite.Status.Declined:
        return 'red';
      default:
        return undefined;
    }
  })();

  return (
    <Badge
      className="w-min shrink cursor-default gap-1.5 capitalize"
      variant={color}
    >
      <Icon icon={icon[status]} className="h-5 w-5" />
      <div>
        <p>{statusText}</p>
        <Render if={expiredAt}>
          <p className="text-caption-2 leading-none text-neural-04">
            {dayjs(expiredAt).format('L')}
          </p>
        </Render>
      </div>
    </Badge>
  );
};
