import { useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { faArrowLeftFromLine } from '@fortawesome/pro-regular-svg-icons';

import { JWT_TOKEN, NO_ORGANIZATION } from '@constants';
import { resetContext } from '@slices';
import {
  contextNamesSelector,
  isAuthorizedSelector,
  userSelector,
} from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import { localStorage } from '@utils';

import { Button, Icon, Popover, Render } from '@components';

import { UserPanelPlatform } from './UserPanelPlatform';
import { UserPanelProfile } from './UserPanelProfile';

export const UserPanelMenu = () => {
  const dispatch = useDispatch();
  const { name, picture, emailVerified } = useSelector(userSelector);
  const isAuthorized = useSelector(isAuthorizedSelector);
  const { organizationName } = useSelector(contextNamesSelector);

  const { logout } = useAuth0();

  const [popoverOpen, setPopoverOpen] = useState(false);

  const formattedOrganizationName =
    organizationName === NO_ORGANIZATION ? 'No organization' : organizationName;

  const handleLogout = () => {
    localStorage.clear(JWT_TOKEN);
    dispatch(resetContext());

    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  if (!isAuthorized) {
    return (
      <div className="mt-5 w-full">
        <Button variant="ghost-error" onClick={handleLogout}>
          <Icon icon={faArrowLeftFromLine} className="mr-1 text-[20px]" />
          Log out
        </Button>
      </div>
    );
  }

  return (
    <Popover
      open={popoverOpen}
      showArrow={false}
      side="right"
      sideOffset={24}
      align="end"
      alignOffset={-24}
      triggerClassName="max-w-full w-full"
      onOpenChange={setPopoverOpen}
    >
      <div
        slot="trigger"
        className="mt-5 flex flex-1 items-center gap-2 px-4 text-neural-04 hover:text-primary"
      >
        <img src={picture} className="h-10 w-10 rounded-full" alt="Avatar" />
        <div className="min-w-0">
          <p className="truncate text-left text-body-small">{name}</p>
          <Render if={formattedOrganizationName}>
            <p className="truncate text-left text-footnote-large text-neural-04">
              {formattedOrganizationName}
            </p>
          </Render>
        </div>
      </div>
      <div className="w-[320px]">
        <Render if={emailVerified}>
          <div className="w-px bg-neural-01 " />
          <UserPanelPlatform hidePopover={() => setPopoverOpen(false)} />
        </Render>
        <UserPanelProfile />
      </div>
    </Popover>
  );
};
