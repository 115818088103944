import { isObject } from '@utils';

type Token = string | null | undefined;

type Payload = {
  'https://platform.apolo.us/origin': string;
  'https://platform.neuromation.io/user': string;
};

export const getPayloadFromToken = (token: Token): Payload | null => {
  if (!token) {
    return null;
  }

  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map((c) => {
          /**
           * Cannot parse username from authorization token
           * https://github.com/neuro-inc/neuro-web-ui/issues/35
           */
          // eslint-disable-next-line
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  } catch {
    // eslint-disable-next-line no-console
    console.log('Token parsing error');

    return null;
  }
};

export const getUsernameFromToken = (token: Token): string | null => {
  const payload = getPayloadFromToken(token);

  if (isObject(payload)) {
    return payload['https://platform.neuromation.io/user'];
  }

  return null;
};

export const getOriginFromToken = (token: Token): string | null => {
  const payload = getPayloadFromToken(token);

  if (isObject(payload)) {
    return payload['https://platform.apolo.us/origin'];
  }

  return null;
};
