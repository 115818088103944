import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';
import {
  faBoxesStacked,
  faChevronDown,
} from '@fortawesome/pro-solid-svg-icons';

import { ModalProps, Mode } from '@typings';
import { toast } from '@features';
import { createBucketCredentials } from '@services';
import { clusterContextSelector } from '@selectors';
import { useSelector } from '@hooks';
import { noop, normalizeFormErrors, toastifyResponseError } from '@utils';

import { Button, Field, Icon, Modal } from '@components';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  name: z.string().min(1),
  mode: z.nativeEnum(Mode),
});

type Props = ModalProps & {
  bucketId: string;
  bucketName: string;
  getCredentials: () => Promise<void>;
};

export const BucketCreateCredentialsModal = ({
  bucketId,
  bucketName,
  getCredentials,
  closeModal = noop,
}: Props) => {
  const { bucketsUrl } = useSelector(clusterContextSelector)!;

  const { register, formState, handleSubmit } = useForm({
    resolver: zodResolver(schema),
  });

  const [loading, setLoading] = useState(false);

  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const handleCredentialsCreate = handleSubmit(async ({ name, mode }) => {
    setLoading(true);

    try {
      /**
       * `undefined` `mode` for `Mode.ReadOnly`
       */
      const isReadOnly = mode !== Mode.ReadWrite;

      await createBucketCredentials({ bucketsUrl, name, bucketId, isReadOnly });
      await getCredentials();

      toast.success(`Bucket credentials ${name} was created`);

      closeModal();
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  });

  return (
    <Modal.Content title="Bucket Credentials" className="w-[488px]">
      <form onSubmit={handleCredentialsCreate}>
        <p className="flex flex-wrap items-center text-footnote text-neural-03">
          Create new credentials for
          <span className="space-both inline-flex items-center gap-1 text-body text-black">
            <Icon icon={faBoxesStacked} className="h-4 w-4 text-[14px]" />
            {bucketName}
          </span>
          with type
          <span
            style={{ gridTemplateAreas: 'select' }}
            className="space-before relative ml-1 inline-flex cursor-pointer text-body capitalize text-black"
          >
            <select
              {...register('mode')}
              className="cursor-pointer appearance-none border-none bg-transparent pr-6"
            >
              <option value={Mode.ReadOnly}>Read-Only</option>
              <option value={Mode.ReadWrite}>Read & Write</option>
            </select>
            <Icon
              icon={faChevronDown}
              className="pointer-events-none absolute inset-y-0 right-0 text-[12px] text-neural-03"
            />
          </span>
        </p>
        <Field.Input
          {...register('name')}
          containerClassName="mt-6"
          error={errors.name}
          label="Credentials name"
        />
      </form>
      <Modal.Footer>
        <Button
          loading={loading}
          className="px-10 capitalize"
          onClick={handleCredentialsCreate}
        >
          Create credentials
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
