import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { faCaretDown, faCheck } from '@fortawesome/pro-solid-svg-icons';

import { ClusterSettings, GpuModel, SupportedGpuMake } from '@typings';
import {
  getFormattedGpuMake,
  getGpuFromNodePool,
  normalizeFormErrors,
} from '@utils';

import { Button, Field, Icon, Popover, Render } from '@components';

const FIELD_NAME = 'gpuModels';

type Props = {
  nodePools: ClusterSettings.NodePool[];
};

export const ClusterSettingsResourceGpuModelsField = ({ nodePools }: Props) => {
  const { register, watch, setValue, formState } = useFormContext();

  const gpuModelsWatcher: GpuModel.Model[] = watch(FIELD_NAME);
  const isNoneGpuModels = gpuModelsWatcher.length === 0;

  const errors = normalizeFormErrors(formState.errors);

  const gpuModels = useMemo(() => {
    const gpuModels = nodePools.reduce<GpuModel.Model[]>((models, nodePool) => {
      const gpuModels = getGpuFromNodePool(nodePool);

      const nodePoolModels = gpuModels.filter(
        ({ gpuMake, gpuModelName }) =>
          !models.some(
            (model) =>
              model.gpuMake === gpuMake && model.gpuModelName === gpuModelName,
          ),
      );

      return nodePoolModels.length ? [...models, ...nodePoolModels] : models;
    }, []);

    /**
     * Not models specified shared GPU models
     */
    const sharedGpuModels = gpuModels.reduce<GpuModel.Model[]>(
      (gpuMakes, { gpuMake, gpuModelName }) => {
        const isGpuModelAdded = gpuMakes.some(
          (gpuModel) => gpuModel.gpuMake === gpuMake,
        );

        if (isGpuModelAdded || !gpuModelName) {
          return gpuMakes;
        }

        return [...gpuMakes, { gpuMake }];
      },
      [],
    );

    return [...sharedGpuModels, ...gpuModels];
  }, [nodePools]);

  const sortedGpuModels = gpuModels.sort((a, b) =>
    a.gpuMake.localeCompare(b.gpuMake),
  );

  useEffect(() => {
    register(FIELD_NAME);
  }, [register]);

  const resetGpuModels = () => {
    setValue(FIELD_NAME, []);
  };

  const handleGpuModelChange = (gpu: GpuModel.Model, isSelected: boolean) => {
    let models: GpuModel.Model[];

    if (isSelected) {
      models = gpuModelsWatcher.filter(
        ({ gpuMake, gpuModelName }) =>
          !(gpuMake === gpu.gpuMake && gpuModelName === gpu.gpuModelName),
      );
    } else {
      const excludedSameGpuModels = gpuModelsWatcher.filter(
        ({ gpuMake }) => gpuMake !== gpu.gpuMake,
      );

      models = [...excludedSameGpuModels, gpu];
    }

    setValue(FIELD_NAME, models);
  };

  const renderSelectedGpuModels = () =>
    gpuModelsWatcher
      .map(({ gpuMake, gpuModelName = '' }) =>
        `${getFormattedGpuMake(gpuMake)} ${gpuModelName}`.trim(),
      )
      .join(', ');

  const renderGpuQuantityField = (gpuModel: SupportedGpuMake) => {
    const fieldName = `${gpuModel}Gpu`;
    const isGpuModelSelected = gpuModelsWatcher.some(
      (gpu) => gpu.gpuMake === gpuModel,
    );

    if (!isGpuModelSelected) {
      return null;
    }

    return (
      <Field.Input
        {...register(fieldName, { shouldUnregister: true })}
        label={`${getFormattedGpuMake(gpuModel)} GPU Quantity`}
        containerClassName="basis-1/2"
        error={errors[fieldName]}
      />
    );
  };

  const makeGpuModel = (
    gpu: GpuModel.Model,
    index: number,
    gpus: GpuModel.Model[],
  ) => {
    const { gpuMake, gpuModelName } = gpu;
    const key = `${gpuMake}-${gpuModelName}`;
    const isFirstGpu = index === 0;
    const isDivided = isFirstGpu || gpuMake !== gpus[index - 1].gpuMake;
    const isSelected = gpuModelsWatcher.some(
      (gpu) => gpu.gpuMake === gpuMake && gpu.gpuModelName === gpuModelName,
    );

    return (
      <>
        <Render if={isDivided}>
          <div className="mx-3 my-1 h-px flex-1 bg-neural-01" />
        </Render>
        <Button
          key={key}
          theme
          className="w-full px-3 py-2 text-left transition-colors hover:bg-background"
          onClick={() => handleGpuModelChange(gpu, isSelected)}
        >
          <div className="flex items-center gap-1">
            <p className="flex-1 self-center pr-8">
              {getFormattedGpuMake(gpuMake)} {gpuModelName}
            </p>
            <Render if={isSelected}>
              <Icon icon={faCheck} className="text-primary" />
            </Render>
          </div>
        </Button>
      </>
    );
  };

  return (
    <>
      <Popover
        triggerClassName="h-14 w-full appearance-none rounded-lg border border-transparent bg-neural-01 px-4 text-body text-rebecca transition-colors placeholder:text-neural-04 hover:bg-primary-subtle focus:border-primary disabled:pointer-events-none disabled:text-neural-03 min-w-0"
        className="z-50 px-0 py-2"
        side="bottom"
        align="start"
        showArrow={false}
        sideOffset={4}
      >
        <div slot="trigger" className="peer">
          <Render if={!isNoneGpuModels}>
            <div className="flex items-center justify-between gap-2">
              <div className="min-w-0 text-left">
                <p className="text-caption capitalize text-neural-04">
                  GPU models
                </p>
                <p className="truncate">{renderSelectedGpuModels()}</p>
              </div>
              <Icon icon={faCaretDown} className="text-neural-04" />
            </div>
          </Render>
          <Render if={isNoneGpuModels}>
            <div className="flex items-center justify-between gap-2">
              <p className="capitalize text-neural-04">GPU model</p>
              <Icon icon={faCaretDown} className="text-neural-04" />
            </div>
          </Render>
        </div>
        <div className="w-[360px]">
          <Button
            theme
            className="w-full px-3 py-2 text-left transition-colors hover:bg-background"
            onClick={resetGpuModels}
          >
            <div className="flex items-center gap-1">
              <p className="flex-1 self-center pr-8">None</p>
              <Render if={isNoneGpuModels}>
                <Icon icon={faCheck} className="text-primary" />
              </Render>
            </div>
          </Button>
          {sortedGpuModels.map(makeGpuModel)}
        </div>
      </Popover>
      <Field.Note className="-mt-4">Type of GPUs to allocate</Field.Note>
      <Render if={!isNoneGpuModels}>
        <div className="grid grid-cols-2 gap-2">
          {renderGpuQuantityField('nvidia')}
          {renderGpuQuantityField('amd')}
          {renderGpuQuantityField('intel')}
        </div>
      </Render>
    </>
  );
};
