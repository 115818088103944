import { Any } from '@typings';

export const localStorage = {
  get: <T = Any>(key: string): T | null => {
    const data = window.localStorage.getItem(key);

    return data && JSON.parse(data);
  },

  set: <T = Any>(key: string, data: T): void => {
    window.localStorage.setItem(key, JSON.stringify(data));
  },

  clear: (key: string): void => {
    window.localStorage.removeItem(key);
  },
};
