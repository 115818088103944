import { useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { faFileArrowUp } from '@fortawesome/pro-solid-svg-icons';

import { uploadStorageFiles } from '@services';
import { clusterContextSelector } from '@selectors';
import { useSelector } from '@hooks';
import { toastifyResponseError } from '@utils';

import { Button, Icon } from '@components';

type Props = {
  isReadOnly: boolean;
  currentPath: string;
  getStorage: (path: string) => Promise<void>;
};

export const StorageUploadButton = ({
  isReadOnly,
  currentPath,
  getStorage,
}: Props) => {
  const cluster = useSelector(clusterContextSelector);

  const [loading, setLoading] = useState(false);

  const { storageUrl = '' } = cluster ?? {};

  const handleFilesUpload = async (files: File[]) => {
    setLoading(true);

    try {
      await uploadStorageFiles({ storageUrl, storagePath: currentPath, files });
      await getStorage(currentPath);
    } catch (error) {
      toastifyResponseError(error);
    } finally {
      setLoading(false);
    }
  };

  const { getInputProps, open: handleUploadOpen } = useDropzone({
    onDrop: handleFilesUpload,
  });

  return (
    <>
      <input {...getInputProps()} />
      <Button
        variant="rebecca"
        className="gap-3 capitalize"
        onClick={handleUploadOpen}
        loading={loading}
        disabled={isReadOnly}
      >
        <Icon icon={faFileArrowUp} />
        Upload
      </Button>
    </>
  );
};
