import { useState } from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import { ProjectRole } from '@typings';
import { toast } from '@features';
import { updateProjectSettings } from '@services';
import { configSelector, contextNamesSelector } from '@selectors';
import { useHelmetTitle, useSelector } from '@hooks';
import { capitalize, normalizeFormErrors, toastifyResponseError } from '@utils';

import { Button, Field, Helmet } from '@components';

type Schema = z.infer<typeof schema>;

const schema = z.object({
  isDefault: z.boolean(),
  defaultRole: z.nativeEnum(ProjectRole),
});

type Props = {
  isDefault: boolean;
  defaultRole: ProjectRole;
};

export const ProjectGeneralSettingsOutlet = ({
  isDefault,
  defaultRole,
}: Props) => {
  const { adminUrl } = useSelector(configSelector);
  const { clusterName, organizationName, projectName } =
    useSelector(contextNamesSelector);

  const { makeTitle } = useHelmetTitle();
  const { register, formState, handleSubmit } = useForm({
    resolver: zodResolver(schema),
    defaultValues: {
      isDefault,
      defaultRole,
    },
  });

  const [loading, setLoading] = useState<boolean>(false);

  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const roleOptions = Object.values(ProjectRole).map((role) => ({
    value: role,
    text: capitalize(role as string),
  }));

  const handleSettingsSubmit = handleSubmit(
    async ({ isDefault, defaultRole }) => {
      setLoading(true);
      try {
        await updateProjectSettings({
          adminUrl,
          clusterName: clusterName!,
          organizationName,
          projectName: projectName!,
          isDefault,
          defaultRole,
        });
        toast.success('Project Settings are updated');
      } catch (error) {
        toastifyResponseError(error);
      } finally {
        setLoading(false);
      }
    },
  );

  return (
    <form
      className="relative max-w-[780px] rounded-xl bg-white p-10"
      onSubmit={handleSettingsSubmit}
    >
      <Helmet title={makeTitle('General', 'Project Settings', '%p', '%c')} />
      <h6 className="text-h6">General</h6>
      <div className="mt-4 flex flex-col gap-6">
        <Field.Input disabled label="Project name" value={projectName} />
        <Field.Select
          {...register('defaultRole')}
          label="Default role"
          note="The default role that will be assigned to each new user added to the project"
          options={roleOptions}
          error={errors.defaultRole}
        />

        <Field.Checkbox
          {...register('isDefault')}
          note="If checked, all new tenant users will be automatically added to this project"
          error={errors.isDefault}
        >
          Make project as default
        </Field.Checkbox>
        <div className="flex justify-end">
          <Button type="submit" loading={loading} className="px-10">
            Save
          </Button>
        </div>
      </div>
    </form>
  );
};
