import { Any, AnyFunction } from '@typings';

type Debounce = (
  func: AnyFunction,
  delay: number,
) => {
  (...args: Any[]): void;
  clear: () => void;
};

export const debounce: Debounce = (func, delay) => {
  let timerId: NodeJS.Timeout;

  const debounced = (...args: Any[]): void => {
    clearTimeout(timerId);
    timerId = setTimeout(() => func(...args), delay);
  };

  debounced.clear = () => {
    clearTimeout(timerId);
  };

  return debounced;
};
