import { Billing, BillingUsage, Params } from '@typings';
import { API_BILLING } from '@constants';
import { axios } from '@features';
import { getFormattedApiUrl, path } from '@utils';

export const getBilling = <
  Usage extends BillingUsage.WithProject | BillingUsage.WithoutProject,
>({
  clusterName,
  organizationName,
  groups,
  startDate,
  endDate,
}: Params.GetOrganizationBilling) => {
  const urlBase = getFormattedApiUrl({
    apiUrl: API_BILLING,
    clusterName,
    organizationName,
  });

  return axios.get<never, Billing.Response<Usage>>(
    path.create(urlBase, 'credits', 'usage'),
    {
      params: { group_by: groups, start_date: startDate, end_date: endDate },
      paramsSerializer: { indexes: null },
    },
  );
};
