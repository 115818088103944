import { cn } from '@utils';

type Props = {
  className?: string;
};

export const ScottdcLogo = ({ className }: Props) => {
  return (
    <svg
      className={cn('h-[358px] w-[1200px] text-white', className)}
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 3951 719"
      fill="currentColor"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0 687.466V0L1246 474.151V687.466L858.501 687.467C858.501 549.243 722.679 411.847 563.057 411.847C403.436 411.847 265.473 549.243 265.473 687.467L0 687.466Z"
      />
      <path d="M806.204 687.964C552.79 687.964 698.89 687.964 565.252 687.964C431.615 687.964 539.289 687.964 324.3 687.964C323.625 695.386 324.3 680.365 324.3 687.964C335.587 563.865 439.156 466.671 565.252 466.671C691.348 466.671 794.917 563.865 806.204 687.964C806.204 680.365 806.879 695.386 806.204 687.964Z" />
      <path d="M1559.8 697.198C1493.35 697.198 1450.16 654.564 1449.06 587.013H1495.01C1496.12 630.755 1521.04 653.456 1559.24 653.456C1601.32 653.456 1621.81 627.433 1621.81 590.889C1621.81 507.835 1455.15 505.62 1455.15 391.559C1455.15 336.743 1496.12 295.216 1558.69 295.216C1622.36 295.216 1661.12 337.85 1663.34 401.525H1617.38C1615.72 358.891 1593.02 338.958 1559.24 338.958C1522.7 338.958 1501.1 359.998 1501.1 392.112C1501.1 474.613 1668.32 472.952 1668.32 591.442C1668.32 646.812 1630.12 697.198 1559.8 697.198Z" />
      <path d="M1663 411V401.5" strokeWidth="0.7" />
      <path d="M1617.5 411V401.5H1663V411H1617.5Z" strokeWidth="0.25" />
      <path d="M1449 590V573H1494.5L1495 590H1449Z" strokeWidth="0.25" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3100 685L3204 309.5H3308.5L3410 685H3327L3306.5 601.5L3199 602.5L3178 685H3100ZM3250.5 381.5L3214.5 543.5H3289.5L3250.5 381.5Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3641 685.5L3745 310H3849.5L3951 685.5H3868L3847.5 602L3740 603L3719 685.5H3641ZM3791.5 382L3755.5 544H3830.5L3791.5 382Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2822 310H2978C3001.67 311 3052.2 328.6 3065 391C3077.8 453.4 3076.33 527.667 3074 557C3073 599.5 3048.5 684.4 2958.5 684C2868.5 683.6 2830 683.833 2822 684V310ZM2899.5 367H2952C2977.5 374 2986.5 391 2993 420C2998.2 443.2 2995.59 519.084 2993 557C2988.5 623 2961.67 627.333 2946.5 627.5H2899.5V367Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M2131 692C2247.5 695 2265.5 634 2265.5 497C2265.5 388.2 2253 304 2134 302C2018 306 2006 388.2 2006 497C2006 605.8 2012.91 688.959 2131 692ZM2134.63 656C2209.13 658.5 2219.63 588.747 2219.63 499C2219.63 396 2208.63 338 2135.13 338C2083.49 338 2048.63 365 2051.13 499C2051.13 588.747 2058.63 658.5 2134.63 656Z"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1951.84 574C1947.51 646.275 1927.43 693.236 1835.68 692C1728.25 690.552 1719.5 605.8 1719.5 497C1719.5 388.2 1728.5 302 1838.41 302C1931.09 303.867 1949.25 348.249 1952.51 418H1904.14C1901.62 367.386 1889.74 338 1839.43 338C1792.47 338 1767.5 366 1767.5 499C1767.5 588.746 1774.5 656 1838.98 656C1887.59 656 1900.29 623.701 1903.51 574H1951.84Z"
      />
      <path d="M2296 309V355.183H2359.55V637.475C2358.31 673.906 2387.45 685.005 2402.18 686H2482V639.797H2430.85C2409.77 641.588 2407.08 621.133 2408.38 610.682V355.183H2482V309H2296Z" />
      <path d="M3655 309H3403V371.248H3489.83V685H3568.17V371.248H3655V309Z" />
      <path d="M2526 309V355.183H2589.55V637.475C2588.31 673.906 2617.45 685.005 2632.18 686H2712V639.797H2660.85C2639.77 641.588 2637.08 621.133 2638.38 610.682V355.183H2712V309H2526Z" />
    </svg>
  );
};
