import { matchRoutes, useLocation } from 'react-router-dom';

import { ClusterSettings, Job } from '@typings';
import { useContextSearchParams } from '@hooks';
import { cn, path } from '@utils';
import { settingsTabs } from '@content';

import { Link } from '@components';

const jobRoute = [{ path: path.job() }, { path: path.app() }];

type Props = {
  isJobPage: boolean;
  jobId: string;
};

export const JobTabs = ({ isJobPage, jobId }: Props) => {
  const { pathname } = useLocation();
  const { contextSearchParams } = useContextSearchParams();

  const routes = matchRoutes(jobRoute, { pathname });

  const isJobRoute = () => {
    const route = isJobPage ? path.job() : path.app();

    if (!routes) {
      return false;
    }

    return routes.some(({ route: { path } }) => path === route);
  };

  const getPath = (tab: Job.Tab | string) => {
    const formattedTab = tab === '/' ? undefined : (tab as Job.Tab);
    const url = isJobPage ? path.job : path.app;

    return `${url(jobId, formattedTab)}?${contextSearchParams}`;
  };

  const makeTab = ({ path, title }: ClusterSettings.Tab) => {
    const tab = path as string;
    const isIndexJobPath = isJobRoute() && path === Job.Tab.Overview;
    const isActive = pathname.includes(`/${tab}`) || isIndexJobPath;

    return (
      <Link
        theme
        key={tab}
        className={cn(
          'block border-b-2 border-transparent pb-4 pt-2 text-h6 capitalize text-neural-04 transition-colors hover:text-rebecca',
          { 'border-primary text-rebecca': isActive },
        )}
        to={getPath(tab)}
      >
        {title}
      </Link>
    );
  };

  return (
    <header className="flex gap-10 border-b border-neural-01 px-10 pt-6">
      {settingsTabs.job.map(makeTab)}
    </header>
  );
};
