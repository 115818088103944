import { useFormContext } from 'react-hook-form';
import { faCompactDisc } from '@fortawesome/pro-regular-svg-icons';
import clsx from 'clsx';

import { useImageName } from '@hooks/useImageName';

import { Button, Field, Icon, Modal } from '@components';

import { JobConstructorImagesModal } from '../Modals';

type Props = {
  error?: string;
};

export const JobImageField = ({ error }: Props) => {
  const { register, setValue, watch } = useFormContext();
  const { getImageName } = useImageName();

  const image: string = watch('image');
  const isImaged = image?.startsWith('image://');

  const handleImagePick = (image: string) => {
    setValue('image', getImageName(image), { shouldValidate: true });
  };

  return (
    <div>
      <div className="flex w-full">
        <Field.Input
          {...register('image')}
          required
          containerClassName="flex-1 relative"
          className={clsx('w-full rounded-r-none', { 'pl-10': isImaged })}
          label="Image Name"
          note="Job image"
          error={error}
        >
          {isImaged && (
            <div slot="icon" className="absolute left-3.5 top-5">
              <Icon icon={faCompactDisc} className="text-black" />
            </div>
          )}
        </Field.Input>
        <Modal
          content={<JobConstructorImagesModal onChoose={handleImagePick} />}
        >
          <Button className="h-14 rounded-l-none">Choose image</Button>
        </Modal>
      </div>
    </div>
  );
};
