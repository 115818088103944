import { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { z } from 'zod';

import {
  Any,
  AsyncFunction,
  Cluster,
  ClusterSettings,
  GpuModel,
  ModalProps,
} from '@typings';
import { SUPPORTED_GPU_MAKES } from '@constants';
import {
  createClusterResourcePreset,
  updateClusterResourcePreset,
} from '@services';
import { setContext } from '@slices';
import { getConfig } from '@thunks';
import { contextNamesSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import {
  as,
  formatModelName,
  getFormattedMemory,
  getGpuMakeKeys,
  getParsedMemory,
  invariant,
  noop,
  normalizeFormErrors,
  toastifyResponseError,
  transformNumberFormat,
} from '@utils';

import { Button, Field, Modal, Render } from '@components';
import { ClusterResourcePresetChips } from '@components/Ui';

import { ClusterSettingsResourceGpuModelsField } from '../ClusterSettingsResourceGpuModelsField';

type Schema = z.infer<ReturnType<typeof makeSchema>>;

type SchemaParams = {
  nodePools: ClusterSettings.NodePool[];
};

const makeSchema = ({ nodePools }: SchemaParams) => {
  const MIN_CPU = 0.01;
  const maxCpu = Math.max(...nodePools.map(({ cpu }) => cpu));
  const MIN_MEMORY = 64 * 10 ** 6;
  const maxMemory = Math.max(...nodePools.map(({ memory }) => memory));
  const getMaxGpu = ({ gpuMake, gpuModelName }: GpuModel.Model) => {
    const { gpuModelKey, gpuModelNameKey } = getGpuMakeKeys(gpuMake);
    const isSharedGpuModel = !gpuModelName;

    const gpuModels = nodePools.filter((nodePool) =>
      isSharedGpuModel
        ? nodePool[gpuModelKey]
        : nodePool[gpuModelKey] && gpuModelName === nodePool[gpuModelNameKey],
    );

    const sortedGpuModels = gpuModels.sort(
      // @ts-expect-error todo: resolve type
      (a, b) => b[gpuModelKey] - a[gpuModelKey],
    );

    return sortedGpuModels[0][gpuModelKey] ?? 0;
  };

  return z
    .object({
      name: z.string().min(1),
      cpu: z.coerce.number().superRefine((cpu: number, context) => {
        if (cpu < MIN_CPU) {
          context.addIssue({
            code: z.ZodIssueCode.too_small,
            type: 'number',
            inclusive: false,
            minimum: MIN_CPU,
            message: `Number of CPU cores cannot be less than ${MIN_CPU} cores`,
          });

          return z.NEVER;
        }

        if (cpu > maxCpu) {
          context.addIssue({
            code: z.ZodIssueCode.too_big,
            type: 'number',
            inclusive: false,
            maximum: maxCpu,
            message:
              'Number of CPU cores cannot be larger than number of cores in any node pool',
          });
        }
      }),
      gpuModels: z
        .object({
          gpuMake: z.enum(SUPPORTED_GPU_MAKES),
          gpuModelName: z.string().optional(),
        })
        .array()
        .optional(),
      creditsPerHour: z.coerce.number().int().nonnegative(),
      memory: z.coerce.number(),
      memoryPrefix: z.string(),
      isPreemptible: z.boolean(),
      isSchedulerEnabled: z.boolean().optional(),
      nvidiaGpu: z.coerce.number().optional(),
      intelGpu: z.coerce.number().optional(),
      amdGpu: z.coerce.number().optional(),
      resourcePoolNames: z
        .object({ name: z.string(), value: z.boolean() })
        .array(),
    })
    .superRefine(({ memory, memoryPrefix }, context) => {
      const parsedMemory = getParsedMemory(`${memory}${memoryPrefix}`) ?? 0;
      const isMemoryNaN = Number.isNaN(memory);

      if (isMemoryNaN) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['memory'],
          message: 'Memory is not a number',
        });

        return z.NEVER;
      }

      if (!parsedMemory) {
        context.addIssue({
          code: z.ZodIssueCode.custom,
          path: ['memory'],
          message: 'Memory cannot be blank',
        });

        return z.NEVER;
      }

      if (parsedMemory < MIN_MEMORY) {
        context.addIssue({
          code: z.ZodIssueCode.too_small,
          type: 'number',
          inclusive: false,
          minimum: MIN_MEMORY,
          path: ['memory'],
          message: `Memory cannot be less than ${getFormattedMemory(
            MIN_MEMORY,
          )}`,
        });

        return z.NEVER;
      }

      if (parsedMemory > maxMemory) {
        context.addIssue({
          code: z.ZodIssueCode.too_big,
          type: 'number',
          inclusive: false,
          maximum: maxMemory,
          path: ['memory'],
          message:
            'Memory cannot be larger than amount of memory in any node pool',
        });

        return z.NEVER;
      }
    })
    .superRefine(({ gpuModels = [], ...data }, context) => {
      gpuModels.forEach((gpuModel) => {
        const { gpuModelKey } = getGpuMakeKeys(gpuModel.gpuMake);
        const gpuQuantity = data[gpuModelKey];
        const maxGpuQuantity = getMaxGpu(gpuModel);

        if (!gpuQuantity) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            path: [gpuModelKey],
            message: 'Number cannot be 0 for GPU model',
          });

          return z.NEVER;
        }

        if (gpuQuantity > maxGpuQuantity) {
          context.addIssue({
            code: z.ZodIssueCode.custom,
            path: [gpuModelKey],
            message:
              'Number cannot be larger than number of GPUs for selected GPU model',
          });
        }
      });

      return z.NEVER;
    })
    .refine(
      ({ cpu, memory, isPreemptible }) =>
        nodePools.some((nodePool) => {
          return (
            cpu <= nodePool.availableCpu &&
            memory <= nodePool.availableMemory &&
            isPreemptible === nodePool.isPreemptible
          );
        }),
      {
        message: "This preset won't fit into any node pool",
        path: ['name'],
      },
    );
};

/**
 * todo: fix type
 */
type ResourcePreset = Any;

type Props = ModalProps & {
  edit?: boolean;
  name?: string;
  resourcePreset?: Cluster.ResourcePreset;
  nodePools: ClusterSettings.NodePool[];
  getCluster: AsyncFunction;
};

export const ClusterResourcePresetModal = ({
  edit = false,
  name,
  nodePools,
  resourcePreset,
  getCluster,
  closeModal = noop,
}: Props) => {
  const dispatch = useDispatch();
  const { clusterName } = useSelector(contextNamesSelector);

  const getDefaultValues = (): ResourcePreset | undefined => {
    const {
      isPreemptible,
      schedulerEnabled,
      name,
      cpu,
      memory,
      creditsPerHour,
      resourcePoolNames = [],
      ...restResourcePreset
    } = as.o<Cluster.ResourcePreset>(resourcePreset);

    const defaultResourcePoolNames = nodePools.map(({ name }) => ({
      name,
      value: resourcePoolNames.includes(name),
    }));

    if (!resourcePreset) {
      return { resourcePoolNames: defaultResourcePoolNames, gpuModels: [] };
    }

    const formattedMemory = getFormattedMemory(memory, {
      separator: ' ',
      fix: 0,
    });
    const { amdGpu, intelGpu, nvidiaGpu } = restResourcePreset;
    const [memoryValue, memoryPrefix] = formattedMemory.split(' ');
    const gpuModels = SUPPORTED_GPU_MAKES.reduce<GpuModel.Model[]>(
      (models, gpuMake) => {
        const { gpuModelKey, gpuModelNameKey } = getGpuMakeKeys(gpuMake);

        const { [gpuModelKey]: gpuQuantity, [gpuModelNameKey]: gpuModelName } =
          restResourcePreset;

        return gpuQuantity ? [...models, { gpuMake, gpuModelName }] : models;
      },
      [],
    );

    return {
      name,
      memory: memoryValue,
      memoryPrefix,
      amdGpu,
      intelGpu,
      nvidiaGpu,
      gpuModels,
      cpu,
      isSchedulerEnabled: schedulerEnabled,
      isPreemptible,
      creditsPerHour,
      resourcePoolNames: defaultResourcePoolNames,
    };
  };

  const methods = useForm<Schema>({
    resolver: zodResolver(makeSchema({ nodePools })),
    defaultValues: getDefaultValues(),
  });

  const [loading, setLoading] = useState(false);

  const { register, formState, handleSubmit } = methods;
  const errors = normalizeFormErrors<keyof Schema>(formState.errors);

  const normalizeGpu = (
    gpu: Cluster.GpuQuantity,
    gpus: GpuModel.Model[] = [],
  ): Cluster.Gpu =>
    gpus.reduce<Cluster.Gpu>((models, { gpuMake, gpuModelName }) => {
      const { gpuModelKey, gpuModelNameKey } = getGpuMakeKeys(gpuMake);
      const gpuQuantity = gpu[gpuModelKey];
      const model: Cluster.Gpu = {};

      if (!gpuMake || !gpuQuantity) {
        return models;
      }

      if (gpuModelName) {
        model[gpuModelNameKey] = gpuModelName;
      }

      model[gpuModelKey] = gpuQuantity;

      return { ...models, ...model };
    }, {});

  const handleFormSubmit = handleSubmit(
    async ({
      name: newName,
      cpu,
      memory,
      memoryPrefix,
      isSchedulerEnabled,
      isPreemptible,
      creditsPerHour,
      resourcePoolNames,
      gpuModels,
      nvidiaGpu,
      amdGpu,
      intelGpu,
    }) => {
      try {
        setLoading(true);

        const formattedName = formatModelName(newName);
        const parsedMemory = getParsedMemory(`${memory}${memoryPrefix}`) ?? 0;
        const gpu = normalizeGpu(
          {
            nvidiaGpu,
            intelGpu,
            amdGpu,
          },
          gpuModels,
        );
        const formattedResourcePoolNames = resourcePoolNames
          .filter(({ value }) => value)
          .map(({ name }) => name);

        invariant(clusterName);

        const payload = {
          ...gpu,
          clusterName,
          name: formattedName,
          memory: parsedMemory,
          cpu,
          isPreemptible,
          isSchedulerEnabled: isSchedulerEnabled ?? false,
          creditsPerHour,
          resourcePoolNames: formattedResourcePoolNames,
        };

        if (edit) {
          await updateClusterResourcePreset(name as string, payload);
        } else {
          await createClusterResourcePreset(payload);
        }

        await getCluster();
        const { clusters } = await dispatch(getConfig());

        const cluster = clusters.find(({ name }) => name === clusterName);

        if (cluster) {
          dispatch(setContext({ cluster }));
        }

        closeModal();
      } catch (error) {
        toastifyResponseError(error);
      } finally {
        setLoading(false);
      }
    },
  );

  return (
    <Modal.Content className="w-[578px]">
      <Modal.Header sticky className="capitalize">
        {edit ? `Edit preset ${name}` : 'Create resouce preset'}
      </Modal.Header>
      <FormProvider {...methods}>
        <form
          noValidate
          className="flex flex-col gap-6"
          onSubmit={handleFormSubmit}
        >
          <Field.Input
            {...register('name')}
            required
            label="Name"
            note="Name should uniqly identify resource preset within cluster"
            className="w-full"
            error={errors.name}
          />
          <Field.Input
            {...register('cpu', { setValueAs: transformNumberFormat })}
            required
            type="number"
            label="Number of vCPU cores"
            note="Amount of virtual CPU cores to allocate"
            className="w-full"
            error={errors.cpu}
          />
          <div className="flex gap-3">
            <Field.Input
              {...register('memory', { setValueAs: transformNumberFormat })}
              required
              label="Amount of RAM"
              note="Amount of RAM to allocate"
              className="w-full"
              containerClassName="flex-1"
              error={errors.memory}
            />
            <Field.Select
              {...register('memoryPrefix')}
              containerClassName="basis-[140px]"
            >
              <option value="MB">MB</option>
              <option value="GB">GB</option>
              <option value="TB">TB</option>
            </Field.Select>
          </div>
          <ClusterSettingsResourceGpuModelsField nodePools={nodePools} />
          <Render if={nodePools}>
            <div>
              <Field.Label className="capitalize text-neural-04">
                Resource pool names
              </Field.Label>
              <ClusterResourcePresetChips />
            </div>
          </Render>
          <Field.Checkbox
            {...register('isSchedulerEnabled')}
            note="Enables suspending and rescheduling of jobs by the platform"
          >
            Round-robin
          </Field.Checkbox>
          <Field.Checkbox
            {...register('isPreemptible')}
            note="Marks that this preset requires preemtible node"
          >
            Preemptability
          </Field.Checkbox>
          <Field.Input
            {...register('creditsPerHour')}
            type="number"
            label="Price for hour"
            note="The cost of using this preset for 1 hour in credits"
            className="w-full"
            error={errors.creditsPerHour}
          />
        </form>
      </FormProvider>
      <Modal.Footer sticky className="items-center justify-between bg-white">
        <p className="text-neural-03">
          <span className="text-error">*</span> Required
        </p>
        <Button className="px-10" loading={loading} onClick={handleFormSubmit}>
          Save
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
