import { useCallback, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import * as sentry from '@sentry/react';

import { AnyFunction, ModalProps, Project, Role } from '@typings';
import { toast } from '@features';
import { checkProjectExists, createProject } from '@services';
import { setContext } from '@slices';
import { getConfig } from '@thunks';
import { contextNamesSelector } from '@selectors';
import { useDispatch, useSelector } from '@hooks';
import {
  debounce,
  formatModelName,
  formatToCamelCase,
  isString,
  noop,
  parseResponseError,
} from '@utils';

import { Button, Field, Modal } from '@components';

const nameValidationSchema = {
  required: 'Project name is required',
  pattern: {
    value: /^[a-zA-Z0-9 -]*$/,
    message: 'Project name is invalid',
  },
  validate: {
    'empty-string': (value: string) =>
      !!value.trim() || 'Project name is required',
  },
};

const roleOptions = [
  {
    value: Role.Reader,
    text: 'Reader',
  },
  {
    value: Role.Writer,
    text: 'Writer',
  },
  {
    value: Role.Manager,
    text: 'Manager',
  },
];

type Props = ModalProps & {
  onSuccess?: AnyFunction;
};

export const CreateProjectModal = ({
  onSuccess = noop,
  closeModal = noop,
}: Props) => {
  const dispatch = useDispatch();
  const { organizationName, clusterName } = useSelector(contextNamesSelector);

  const { control, register, clearErrors, setError, handleSubmit } = useForm();

  const [loading, setLoading] = useState(false);

  const handleNameError = (message: string) => {
    setError('name', { message });
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debounceNameChange = useCallback(
    debounce(async (name: string) => {
      const formattedName = formatModelName(name);

      setLoading(true);

      try {
        await checkProjectExists({
          organization: organizationName,
          cluster: clusterName!,
          project: formattedName,
        });

        handleNameError('Project with such name already exits');
      } catch (error) {
        /**
         * Continue regardless error
         */
      } finally {
        setLoading(false);
      }
    }, 500),
    [organizationName, clusterName],
  );

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    const formattedValue = formatModelName(value);

    if (formattedValue) {
      debounceNameChange(formattedValue);
    } else {
      debounceNameChange.clear();
    }

    clearErrors('name');
  };

  const handleProjectSubmit = handleSubmit(
    async ({ name, isDefault, defaultRole }) => {
      const formattedName = formatModelName(name);

      setLoading(true);

      try {
        const payload = {
          is_default: isDefault,
          default_role: defaultRole,
          name: formattedName,
        };
        const project = await createProject({
          organization: organizationName,
          cluster: clusterName!,
          payload,
        });
        const formattedProject = formatToCamelCase({
          ...project,
          role: Role.Admin,
        }) as Project;

        dispatch(setContext({ project: formattedProject }));

        await dispatch(getConfig());

        onSuccess();
        closeModal();
      } catch (error) {
        const parsedError = parseResponseError(error);
        const normalizedError = isString(parsedError)
          ? parsedError
          : parsedError.messages[0];

        if (normalizedError) {
          handleNameError(normalizedError);
        } else {
          toast.error('Something went wrong');
          sentry.captureException(error);
        }
      } finally {
        setLoading(false);
      }
    },
  );

  return (
    <Modal.Content title="Create Project" className="w-[640px]">
      <form onSubmit={handleProjectSubmit}>
        <p className="mb-4 text-body-medium text-neural-04">
          Create new project for{' '}
          <span className="text-rebecca">{organizationName ?? 'no'}</span>{' '}
          organization
        </p>
        <Controller
          name="name"
          control={control}
          rules={nameValidationSchema}
          render={({
            field: { onChange, ...field },
            fieldState: { error },
          }) => (
            <Field.Input
              {...field}
              required
              label="Project Name"
              containerClassName="mb-4"
              error={error?.message}
              onChange={(event) => {
                handleChange(event);
                onChange(event);
              }}
            />
          )}
        />
        <Field.Checkbox {...register('isDefault')}>
          Make project as default
        </Field.Checkbox>
        <Field.Select
          {...register('defaultRole')}
          label="Default Role"
          containerClassName="my-6"
          note="Default role assigned to each new user"
          options={roleOptions}
        />
      </form>

      <Modal.Footer>
        <Button variant="secondary" onClick={closeModal}>
          Cancel
        </Button>
        <Button
          type="submit"
          loading={loading}
          className="px-14 capitalize"
          onClick={handleProjectSubmit}
        >
          Create
        </Button>
      </Modal.Footer>
    </Modal.Content>
  );
};
