import { useEffect } from 'react';
import { useOutletContext } from 'react-router-dom';
import { faCompactDisc, faMicrochip } from '@fortawesome/pro-regular-svg-icons';

import { Job, OutletContext } from '@typings';
import { as } from '@utils';

import { Icon, Render } from '@components';
import {
  JobDetailsContent,
  JobDetailsEnvironment,
  JobDetailsIntergrations,
  JobDetailsItem,
  JobDetailsLink,
  JobDetailsPresetResources,
  JobDetailsTags,
  JobDetailsVolumes,
} from '@components/Job';

type Props = {
  job: Job.Model;
};

export const JobOverviewPage = ({
  job: {
    id,
    name,
    owner,
    tags,
    clusterName,
    httpUrl,
    httpUrlNamed,
    internalHostname,
    internalHostnameNamed,
    orgName,
    presetName,
    container: {
      resources,
      image,
      command,
      entrypoint,
      tty,
      env,
      secretEnv,
      volumes: storageVolumes = [],
      diskVolumes = [],
      secretVolumes = [],
      http: {
        port: httpPort,
        requiresAuth: httpAuth,
      } = {} as Job.Container['http'],
    },
  },
}: Props) => {
  const { setTabTitle } = useOutletContext<OutletContext.Job>();

  useEffect(() => {
    setTabTitle(Job.Tab.Overview);
  }, [setTabTitle]);

  const envs = { ...env, ...secretEnv };
  const volumes = [...storageVolumes, ...diskVolumes, ...secretVolumes];
  const { MLFLOWTRACKINGURI: mlflow } = envs;

  const getBooleanText = (value?: boolean): string =>
    value ? 'True' : 'False';

  return (
    <JobDetailsContent>
      <JobDetailsItem copy={image} title="Image">
        <div className="flex items-center gap-2">
          <Icon icon={faCompactDisc} /> {image}
        </div>
      </JobDetailsItem>
      <JobDetailsItem title="Preset Name">
        <div className="flex items-center gap-2">
          <Icon icon={faMicrochip} /> {presetName}
        </div>
      </JobDetailsItem>
      <JobDetailsItem title="Preset Resources">
        <JobDetailsPresetResources resources={resources} />
      </JobDetailsItem>
      <JobDetailsItem title="Volumes">
        <JobDetailsVolumes volumes={volumes} />
      </JobDetailsItem>
      <JobDetailsItem title="Intergrations">
        <JobDetailsIntergrations mlflow={mlflow} />
      </JobDetailsItem>
      <JobDetailsItem copy title="Job ID" text={id} />
      <JobDetailsItem copy title="Job Name" text={as(name, '-')} />
      <JobDetailsItem title="Owner" text={owner} />
      <JobDetailsItem title="Cluster" text={clusterName} />
      <JobDetailsItem title="Organization" text={as(orgName, '-')} />
      <JobDetailsItem title="Command">
        <Render if={command}>
          <code className="whitespace-pre-wrap text-footnote-large">
            {command}
          </code>
        </Render>
        <Render if={!command}>
          <p>-</p>
        </Render>
      </JobDetailsItem>
      <JobDetailsItem title="Entrypoint">
        <Render if={entrypoint}>
          <code className="whitespace-pre-wrap text-footnote-large">
            {entrypoint}
          </code>
        </Render>
        <Render if={!entrypoint}>
          <p>-</p>
        </Render>
      </JobDetailsItem>
      <JobDetailsItem title="TTY" text={getBooleanText(tty)} />
      <JobDetailsItem title="Tags">
        <JobDetailsTags tags={tags} />
      </JobDetailsItem>
      <JobDetailsItem copy title="Internal Hostname" text={internalHostname} />
      <JobDetailsItem
        copy
        title="Internal Hostname Named"
        text={as(internalHostnameNamed, '-')}
      />
      <JobDetailsItem title="HTTP URL">
        <JobDetailsLink external to={httpUrlNamed ?? httpUrl} />
      </JobDetailsItem>
      <JobDetailsItem title="HTTP Port" text={httpPort} />
      <JobDetailsItem
        title="HTTP Authentication"
        text={getBooleanText(httpAuth)}
      />
      <JobDetailsItem title="Environment">
        <JobDetailsEnvironment envs={envs} />
      </JobDetailsItem>
    </JobDetailsContent>
  );
};
