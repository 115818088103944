type Props = {
  /**
   * Display actual value avoiding calculation
   */
  actualValue?: boolean;
  title: string;
  value: number;
  /**
   * Used to show resource value
   */
  resource: number;
  resourceModifier?: (resource: number) => number | string;
};

export const JobResource = ({
  actualValue = false,
  title,
  value,
  resource,
  resourceModifier,
}: Props) => {
  /**
   * Percentage
   */
  const currentLoad = actualValue
    ? value
    : Math.round((value * 100) / resource);
  const text = resourceModifier ? resourceModifier(resource) : resource;

  return (
    <div className="flex items-end justify-end gap-3">
      <p className="text-right text-footnote-large text-neural-04">
        <span className="font-600">{currentLoad}%</span>
        <br />
        <span className="font-600">{text}</span>
        <br />
        <span>{title}</span>
      </p>
      <div className="relative h-[60px] w-6 shrink-0 overflow-hidden rounded-[4px] bg-stroke">
        <div
          className="absolute inset-x-0 bottom-0 bg-primary transition-all"
          style={{ height: `${currentLoad}%` }}
        />
      </div>
    </div>
  );
};
